import { ElementRef, Injectable } from '@angular/core';
import { AnalysisService, BackendPrintService } from '../../shared/services';
import * as csvTemplate from '!raw-loader!./template.csv';
import * as csvTemplate2 from '!raw-loader!./template2.csv';
import { autodownload } from '../../shared/components/utils';
import { Observable, of } from 'rxjs';
import { InfoService } from '../../shared/services/info.service';
import { tap } from 'rxjs/operators';
import { MatGridTile } from '@angular/material/grid-list';

@Injectable({
  providedIn: 'root',
})
export class DiversityTargetsExportService {
  constructor(
    private infoService: InfoService,
    private analysisService: AnalysisService,
  ) {}

  public getDiversityTargetCSVFile(
    diversityTargetList: { targetNo?: string; smiles?: string; similarityScore?: number }[],
    analysisName: string,
  ): void {
    if (diversityTargetList.length > 0) {
      this.getDiversityCsvContent(diversityTargetList)
        .pipe(
          tap(
            () => {
              this.infoService.showInfo('Data collected, creating CSV...');
            },
            () => {
              this.infoService.showError('Data collecting fail. CSV export aborted');
            },
          ),
        )
        .subscribe(
          (csvDataBlob: string) => {
            const data: BlobPart[] = [];
            data.push(csvDataBlob);
            const csvDataUrl = window.URL.createObjectURL(new Blob(data));
            const fileName = `Diversity_Library_from_${analysisName}_filters_applied.csv`;
            autodownload(csvDataUrl, fileName);
            return true;
          },
          (err) => {
            console.log(err);
          },
        );
    } else {
      this.infoService.showError('No Targets are selected');
    }
  }

  public getDiversityCsvContent(
    diversityTargetList: { targetNo?: string; smiles?: string; similarityScore?: number }[],
  ): Observable<string> {
    interface CsvData {
      targetNo: string;
      smiles: string;
      similarityScore?: string;
    }

    const csvTemplateContext = {
      Data: Array<CsvData>(),
    };
    for (const diversityTarget of diversityTargetList) {
      if (this.analysisService.analysisSettingsBehaviorSubjects.showSimilarityScore.value) {
        csvTemplateContext.Data.push({
          targetNo: diversityTarget.targetNo ? diversityTarget.targetNo : '',
          smiles: diversityTarget.smiles ? diversityTarget.smiles : '',
          similarityScore: diversityTarget.similarityScore
            ? diversityTarget.similarityScore.toString()
            : '',
        });
      } else {
        csvTemplateContext.Data.push({
          targetNo: diversityTarget.targetNo ? diversityTarget.targetNo : '',
          smiles: diversityTarget.smiles ? diversityTarget.smiles : '',
        });
      }
    }
    csvTemplateContext.Data = csvTemplateContext.Data.map((dataLine) => {
      return {
        ...dataLine,
        targetNo: dataLine.targetNo,
        smiles: dataLine.smiles,
        similarityScore: dataLine.similarityScore,
      };
    });
    let template: string = '';
    if (this.analysisService.analysisSettingsBehaviorSubjects.showSimilarityScore.value) {
      template = BackendPrintService.parseTemplate(csvTemplate2, csvTemplateContext);
    } else {
      template = BackendPrintService.parseTemplate(csvTemplate, csvTemplateContext);
    }
    return of(template);
  }

  public downloadAsHTML(htmlString: string, analysisName: string) {
    const htmlContent = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
      .grid-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: 10px;
      }
      .molecule-tile {
        min-width: 320px !important;
        max-width: 432px !important;
        box-shadow: 0px 0px 15px 1px #00000017 !important;
        &:hover {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
        }
      }

      .target-diversity-container {
        border-radius: 3px;
        border: 4px solid #2dbecd80;
        text-align: center;
        padding: 0px !important;
        width: 290px !important;
        position: relative;
        display: flex;
      
        a {
          text-decoration: none;
        }
      
        img,
        a {
          user-select: none;
          -webkit-user-drag: none;
        }
      
        .menu-container {
          visibility: hidden;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
      
          .right-container {
            display: grid;
            padding: 0;
          }
        }
      }

      .mol-diversity-container {
        border-radius: 3px;
        padding: 0px !important;
        position: relative;
        height:278px;
        display: flex;

        &:hover {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
        }
      
        .menu-container {
          width: 0px;
          visibility: hidden;
        }

        .favorite-icon {
          z-index: 10;
          position: absolute;
          top:10px;
          right: 11px;
          width: 22px;
          height: 22px;
          color: #522e91;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      
        .favorite-icon mat-icon {
          font-size: 22px;
          display: inline-block;
          line-height: inherit;
          width: auto;
          height: auto;
          vertical-align: middle;
        }
      
        .favorite-icon button {
          border: none;
          background: none;
          padding: 0;
          margin: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        
        button.mat-icon-button {
          border: none;
          background-color: transparent;
          box-shadow: none;
          padding: 0;
          margin: 0;
          min-width: 0;
          min-height: 0;
          line-height: inherit;
        }
      }

      .analysis-path-index-container {
        position: relative;
        width: 25px;
        margin-top: 10px !important;
        margin-left: 10px;
      
        .pathway-index {
          position: absolute;
          width: 100%;
          text-align: center;
          color: #503291;
          font-size: 14px;
          font-weight: 700;
          margin-left: -1px;
          font-family: Roboto, "Helvetica Neue", sans-serif;
          margin-top: 5px !important;
        }
      
        .pathway-index-icon {
          position: absolute;
          z-index: 19 !important;
        }

        .mat-card-head-rate {
          color: #eb3c96 !important;
          font-weight: 700 !important;
          font-size: 14px !important;
          line-height: 12px;
          position: absolute;
          text-align: center;
          color: #503291;
          font-size: 14px;
          font-weight: 700;
          margin-left: 40px;
          margin-top: 8px !important;
        }

        .cost-with-similarity {
          margin-left: 82px !important;
        }
      }

      .new-analysis-image {
        margin-top: 25px !important; 
        margin: auto;
      }
      
      .mat-grid-tile {
        padding-bottom: 7px !important;
        padding-left: 5px !important;
        padding-right: 4px !important;
        display: inline-block;
        width: calc(27% - 12.8px) !important;
        height: calc(275px) !important;
        overflow: hidden;
        box-shadow: 0px 0px 15px 1px #00000017 !important;
      }

      .favorite-icon {
        position: absolute;
        left: 88%;
        top: 5%;
        color: rgb(82, 46, 145);
        font-size: 20px;
      }

      .diversity-path-index-container {
        width: 28px;
      }

      .diversity-pathway-index {
        position: absolute;
        width: 100%;
        text-align: center;
        color: #503291;
        font-size: 12px;
        font-weight: 700;
        margin-left: -1px;
        margin-top: 7px !important;
      }

      .diversity-pathway-index-icon {
        height: 28px;
        width: 26px;
      }

      .diversity-pathway-index-four-digit {
        position: absolute;
        width: 100%;
        text-align: center;
        color: #503291;
        font-size: 10px;
        font-weight: 700;
        margin-left: -1px;
        margin-top: 7px !important;
      }
      
      .pathway-similarity-score {
        margin-top: 4px;
        margin-left: 35px;
        position: absolute;
        text-align: center;
        color: #503291;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-weight: 700;
        border-radius: 4px !important;
        border: 1px solid #503291;
        padding: 0 4px;
      }

      .hide-element {
        display: none;
      }
      </style>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    </head>
    <body>
      ${htmlString}
    </body>
    </html>
  `;
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = window.URL.createObjectURL(blob);
    const building = document.createElement('a');
    building.href = url;
    const downloadFilename = `${analysisName}.html`;
    building.download = downloadFilename;
    building.click();
    window.URL.revokeObjectURL(url);
  }
}
