import { isString } from '../../components/utils';

export class SimilarityScore {
  /* tslint:disable:variable-name */
  public id: string = '';
  public similarity_score: number = 0;

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        switch (field) {
          case 'similarity_score':
            if (jsonData[field] === null) {
              this[field] = 0;
              break;
            }
            break;
          default:
            if (typeof jsonData[field] === typeof this[field]) {
              this[field] = jsonData[field];
            } else {
              console.error(
                `Unexpected type '${typeof jsonData[field]}' of field '${field}'
                \nin data provided to SimilarityScore.
                \nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
              );
            }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to SimilarityScore`);
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
