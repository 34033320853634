import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import {
  TechSupportService,
  InfoService,
  ParametersStashService,
  AppConstantsService,
} from '../shared/services';
import { AuthorizationService } from '../authorization/authorization.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { bytesToSize } from '../shared/components/utils';
import { AppState } from '../app-state.service';

interface ImageData {
  name: string;
  data: string | ArrayBuffer;
}

@Component({
  selector: 'ch-tech-support',
  styleUrls: ['./tech-support.component.scss'],
  templateUrl: './tech-support.component.html',
})
export class TechSupportComponent implements OnInit, OnDestroy {
  public issueTitle: string = '';
  public otherIssueTitle: string = '';
  public description: string = '';
  public email: string = '';
  public agreed: boolean = false;
  public selectedFiles: File[] = [];
  public emailAttachments: ImageData[] = [];
  public sendingForm: boolean = false;
  public isAuthorized: boolean = false;
  public predefinedTitles: any[] = [
    {
      name: 'Inactive license',
      value: 'inactiveLicense',
    },
    {
      name: 'Cannot Run a Search',
      value: 'canNotRunSearch',
    },
    {
      name: 'Report an error message',
      value: 'reportError',
    },
    {
      name: 'Search finished with no results',
      value: 'noResults',
    },
    {
      name: 'Not finding desired results',
      value: 'unexpectedResults',
    },
    {
      name: 'Computation finished with errors',
      value: 'computationError',
    },
    {
      name: 'Computation finished before autostop',
      value: 'computationAutostop',
    },
    {
      name: 'Other',
      value: 'other',
    },
  ];
  public version: string = APP_CONFIG.LAST_TAG;
  public userCountry: string = '';
  public issueTitleControl = new UntypedFormControl();
  public userEmailControl = new UntypedFormControl();
  public isSynthiaLiteEnabled: boolean = false;
  public maximumMailAttachmentsSize: number = 0;
  public bytesToSize = bytesToSize;

  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private authorizationServiceSubscriptions: Subscription;
  private userInformationSubscription: Subscription;
  @ViewChild('techSupportForm') private techSupportForm;

  constructor(
    public stashService: ParametersStashService,
    private techSupportService: TechSupportService,
    private infoService: InfoService,
    private authorizationService: AuthorizationService,
    private deviceService: DeviceDetectorService,
    private appStateService: AppState,
    private appConstantsService: AppConstantsService,
  ) {}

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;

    this.authorizationServiceSubscriptions = new Subscription();
    this.authorizationServiceSubscriptions.add(
      this.authorizationService.isAuthorized().subscribe((authorized) => {
        this.isAuthorized = authorized;
        if (authorized) {
          this.getUserInfo();
        } else {
          this.resetForm();
        }
      }),
    );

    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((midendConfiguration) => {
        if (midendConfiguration) {
          this.maximumMailAttachmentsSize = midendConfiguration.MAXIMUM_MAIL_ATTACHMENTS_SIZE;
        }
      });

    this.description = this.techSupportService.errorMessage.getValue();
    this.issueTitleControl.patchValue(this.techSupportService.requestTitle.getValue());
    this.appStateService.country
      .pipe(take(2))
      .subscribe((country: string) => (this.userCountry = country));
  }

  public ngOnDestroy() {
    this.techSupportService.errorMessage.next('');
    this.techSupportService.requestTitle.next('');

    if (this.authorizationServiceSubscriptions && !this.authorizationServiceSubscriptions.closed) {
      this.authorizationServiceSubscriptions.unsubscribe();
    }

    if (this.userInformationSubscription && !this.userInformationSubscription.closed) {
      this.userInformationSubscription.unsubscribe();
    }
  }

  public fileChangeListener(event: Event) {
    this.readThis(event.currentTarget as HTMLInputElement, event);
  }

  public readThis(inputValue: HTMLInputElement, event: any) {
    const inputFiles: FileList = inputValue.files;
    Array.from(inputFiles).forEach((inputFile) => {
      const file: File = inputFile;
      const myReader: FileReader = new FileReader();
      myReader.onloadend = () => {
        const attachmentsSize: number = this.getAttachmentsSize();
        if (attachmentsSize + file.size <= this.maximumMailAttachmentsSize) {
          const imageData: ImageData = {
            name: file.name,
            data: myReader.result,
          };
          this.selectedFiles.push(file);
          this.emailAttachments.push(imageData);
        } else {
          const uploadedSize: number = attachmentsSize + file.size;
          event.target.value = '';
          const warningDetails: string = `
            You tried to upload a ${bytesToSize(uploadedSize, 'decimal')} of attachments.
            The maximum size of all attachments is ${bytesToSize(
              this.maximumMailAttachmentsSize,
              'decimal',
            )}.
            `;
          this.infoService.showWarning(
            'Too big size of selected file',
            'Details',
            3000,
            warningDetails,
          );
        }
      };
      myReader.readAsDataURL(inputFile);
    });
  }

  public removeImage(imageToRemove: File) {
    this.emailAttachments = this.emailAttachments.filter(
      (image) => image.name !== imageToRemove.name,
    );
    this.selectedFiles = this.selectedFiles.filter((file) => file.name !== imageToRemove.name);
  }

  public getImageSize(image: File) {
    return bytesToSize(image.size, 'decimal');
  }

  public sendEmail() {
    this.sendingForm = true;
    this.issueTitleControl.disable();
    this.userEmailControl.disable();
    const browserInfo = `${this.deviceService.browser} ${this.deviceService.browser_version}`;
    const additionaInformation: string = `
      Web-app instance: ${location.host}
      Web-app version: ${this.version}
      User browser: ${browserInfo}\n
    `;
    const fullDescription: string = additionaInformation + this.description;
    const mailContent = {
      title:
        this.issueTitleControl.value === 'Other'
          ? this.otherIssueTitle
          : this.issueTitleControl.value,
      description: fullDescription,
      email: this.userEmailControl.value,
      attachments: this.emailAttachments,
    };

    this.techSupportService
      .sendEmail(mailContent)
      .pipe(
        finalize(() => {
          this.issueTitleControl.enable();
          if (!this.isAuthorized) {
            this.userEmailControl.enable();
          }
          this.sendingForm = false;
        }),
      )
      .subscribe(
        (result: any) => {
          if (result.status === 200) {
            this.resetForm();
            let emailSuccessMessage = this.appConstantsService.unknownUserEmailSuccess;
            if (this.isAuthorized) {
              this.getUserInfo();
              emailSuccessMessage = this.appConstantsService.knownUserEmailSuccess;
            }
            this.infoService.showInfo(emailSuccessMessage, 4000);
          }
        },
        () => {
          // Note: techSupportService handles errors
        },
      );
  }

  public isUserFromUSA() {
    return this.userCountry === 'US' || this.userCountry === 'CA';
  }

  private getAttachmentsSize() {
    let attachmentsSize: number = 0;
    for (const attachment of this.selectedFiles) {
      attachmentsSize += attachment.size;
    }

    return attachmentsSize;
  }

  private resetForm() {
    this.techSupportForm.reset();
    this.issueTitleControl.reset();
    if (!this.isAuthorized) {
      this.userEmailControl.reset();
    }
    this.emailAttachments = [];
    this.selectedFiles = [];
  }

  private getUserInfo() {
    if (this.userInformationSubscription && !this.userInformationSubscription.closed) {
      this.userInformationSubscription.unsubscribe();
    }

    this.userInformationSubscription = this.authorizationService
      .getUserInformation()
      .subscribe((user) => {
        this.userEmailControl.setValue(user.email);
        this.email = user.email;
      });
  }
}
