import { isString } from '../../components/utils';

export class Batch {
  /* tslint:disable:variable-name */
  public id: number = 0;
  public name: string = '';
  public icon: string = '';
  public url: string = '';
  public created_at: Date = null;
  public updated_at: Date = null;
  public user: number = 0;
  public children?: any[] = [];
  public svgIcon?: string = 'ch:batch-folder';
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else {
          console.warn(`Unexpected type of '${field}' in data provided to Batch.`);
        }
      } else {
        console.warn(`Unexpected field '${field}' in data provided to Batch.`);
      }
    }
  }
}
