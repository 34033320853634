import { BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ChemicalEntry } from '../molecule-search/models/chemical-entry';
import { ScoringFunction } from '../scoring-functions/models/scoring-function';
import {
  SimpleScoringFunction,
  ProtectingGroupsType,
} from '../scoring-functions/models/simple-scoring-function';
import {
  DEFAULT_CHEMICAL_SCORING_FUNCTION,
  DEFAULT_MAX_ITERATIONS,
  AnalysisComplexityOption,
  DEFAULT_ANALYSIS_COMPLEXITY_OPTIONS,
  DEFAULT_MAIN_LIMIT,
  DEFAULT_AVOID_KEYWORDS,
  DEFAULT_AVOID_SMARTS,
  DEFAULT_AVOID_SMILES,
  DEFAULT_AVOID_MOLECULE_SETS,
  DEFAULT_PROTECTING_GROUPS,
  DEFAULT_SEEK_KEYWORDS,
  DEFAULT_SEEK_SMARTS,
  DEFAULT_SEEK_SMILES,
  DEFAULT_SEEK_MOLECULE_SETS,
  DEFAULT_AVOID_SMARTS_SETS,
  DEFAULT_SEEK_SMARTS_SETS,
  DEFAULT_JSON_DEV_PARAMETERS,
  DEFAULT_NONSELECTIVE,
  DEFAULT_CUT_INTO_SMALLER_FRAGMENTS_MANUAL,
  DEFAULT_EXCLUDE_DIASTEREOSELECTIVE_REACTIONS_MANUAL,
  DEFAULT_MARK_NON_SELECTIVE_MANUAL,
  DEFAULT_MULTICUT_MANUAL,
} from './parameters-constants';
import { KnownReactionBases } from '../analysis/models/graph-reaction-node-entry';
import { AlgorithmType } from '../analysis';
import { AnalysisAlgorithm } from '../app-constants/app-constants.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RulesDataset } from './models/rules-dataset';
import { ReactionDataset } from './models/reaction-dataset';

export enum NewDesignPathWays {
  STEPBYSTEP = 'Step by step',
  COSTANDPOPULARITY = 'Optimized for cost and popularity',
}

const GetRulesDataset = APP_CONFIG.CHEMATICA_API_URL + `/api/v1/rules-dataset/`;
const GetReactionDataset = APP_CONFIG.CHEMATICA_API_URL + `/api/v1/reaction-dataset/`;

@Injectable()
export class ParametersStashService {
  // network parameters
  public newDesignPathways: BehaviorSubject<NewDesignPathWays>;
  public relativeLaborCost: BehaviorSubject<number>;
  public variance: BehaviorSubject<number>;
  public popularityThreshold: BehaviorSubject<number>;
  public popVCostValue: BehaviorSubject<number>;
  public maxYear: BehaviorSubject<number>;
  public minYear: BehaviorSubject<number>;
  public maxProducts: BehaviorSubject<number>;
  public numberOfPaths: BehaviorSubject<number>;
  public syntheticSteps: BehaviorSubject<number>;
  public buyableCostValue: BehaviorSubject<number>;
  public buyableMassValue: BehaviorSubject<number>;
  public knownMassValue: BehaviorSubject<number>;
  public knownPopularityValue: BehaviorSubject<number>;
  public solubilityEnabled: BehaviorSubject<boolean>;
  public solubilityMode: BehaviorSubject<'range' | 'threshold'>;
  public logPRange: BehaviorSubject<number>;
  public logPThreshold: BehaviorSubject<number>;
  public moleculeSets: BehaviorSubject<any>;
  public catalogs: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  // stepByStep parameters
  public travelAs: BehaviorSubject<'asProduct' | 'asReactant'>;
  public searchType: BehaviorSubject<'fullSearch' | 'customSearch'>;
  public showReactions: BehaviorSubject<number>;
  public reactionsPopular: BehaviorSubject<boolean>;
  public reactionsMakeRings: BehaviorSubject<boolean>;
  public reactionsFragments: BehaviorSubject<boolean>;
  public reactionsMakeStereo: BehaviorSubject<boolean>;
  // manual retro parameters
  public multicutManual: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cutIntoSmallerFragmentsManual: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public markNonSelectiveManual: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public excludeDiasteroselectiveReactionsManual: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public heterocyclesManual: BehaviorSubject<boolean>;
  public arenesManual: BehaviorSubject<boolean>;
  public specializedManual: BehaviorSubject<boolean>;

  // auto retro parameters
  // rules and filters
  public powerSearchFGI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public powerSearchTunnel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public powerSearchMultirx: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public promoteRobustReactionsAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showDifferentBondConnectionsAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public excludeGaseousSubstrates: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public excludeReactionsWithMetals: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ringsStrategiesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public stereoStrategiesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cutIntoSmallerStrategiesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public jsonDevParameters: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public multirxAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public tunnelsAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fgiAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fgiWithProtectionsAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public lowriskAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public multicutAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public strategiesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public cutAllHeterocyclesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public markNonSelectiveAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public macrocyclesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public strainAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public excludeDiasteroselectiveReactionsAuto: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // scoring functions
  public chemicalScoringFunction: BehaviorSubject<ScoringFunction> =
    new BehaviorSubject<ScoringFunction>(DEFAULT_CHEMICAL_SCORING_FUNCTION);
  public reactionScoringFunction: BehaviorSubject<ScoringFunction> =
    new BehaviorSubject<ScoringFunction>(DEFAULT_CHEMICAL_SCORING_FUNCTION);
  public beamWidth: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public fgiCoef: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public tunnelCoef: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public maxReactionsPerProduct: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public simpleScoringFunction: BehaviorSubject<SimpleScoringFunction> =
    new BehaviorSubject<SimpleScoringFunction>(null);
  public heterocyclesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public arenesAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public specializedAuto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public rulesAuto: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public currentSSFIsCustom: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public usingExpertSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public usingDevModeSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // path ranking
  public shorterPaths: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pathwaysType: BehaviorSubject<'CONVERGENT' | 'LINEAR' | 'COMBO'> =
    new BehaviorSubject<any>(null);
  public protectingGroups: BehaviorSubject<ProtectingGroupsType> =
    new BehaviorSubject<ProtectingGroupsType>(null);
  public nonselective: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public expertChemistry: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public spresi: BehaviorSubject<boolean>;
  public uspto: BehaviorSubject<boolean>;
  public enzymesCatalyzed: BehaviorSubject<boolean>;
  public reactionDatasets: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public rulesDataset: BehaviorSubject<RulesDataset[]> = new BehaviorSubject<RulesDataset[]>([]);
  public publishedReactionDataset: BehaviorSubject<ReactionDataset[]> = new BehaviorSubject<
    ReactionDataset[]
  >([]);
  public rulesDataBasesInitial: string[] = [];
  public reactionDataBasesInitial: string[] = [];
  // exclude and seek
  public avoidSmarts: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public avoidSmiles: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public avoidKeywords: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public avoidMoleculeSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public avoidSmartsSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public frozenAvoidMoleculeSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public frozenAvoidSmartsSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public frozenSeekMoleculeSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public frozenSeekSmartsSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public seekSmarts: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public seekSmiles: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public seekKeywords: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public seekMoleculeSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public seekSmartsSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public seekLabels: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public excludeLabels: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  // stop conditions
  public maxPricePerGram: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public buyableMaxMolecularMass: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public knownMaxMolecularMass: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public inventoryMaxMolecularMass: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public minPopularity: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public maxIterations: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public pathsReturned: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public automaticRetrosynthesisDurationSec: BehaviorSubject<number> = new BehaviorSubject<number>(
    0,
  );
  public enableBuyableStopConditions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public enableKnownStopConditions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public enableInventoryStopConditions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public analysisComplexityOption: BehaviorSubject<AnalysisComplexityOption> =
    new BehaviorSubject<AnalysisComplexityOption>(null);
  public customAnalysisComplexityActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public enforceCarbonFootprintAvailability: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public buyableAvailableLargeQuantitiesThreshold: BehaviorSubject<number> =
    new BehaviorSubject<number>(-1);
  public buyableAvailableLargeQuantities: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public batchFolderName: BehaviorSubject<string>;
  public mainLimit: BehaviorSubject<'iterations' | 'time'> = new BehaviorSubject<any>(null);

  public algorithm: string;
  public pristine: boolean = true;
  public isNotCenterSelected: boolean = false;
  public molecule: ChemicalEntry;
  public smiles: string;
  public folderId: BehaviorSubject<number>;
  public isPresetEdited: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSynthiaLiteEnabled: boolean = false;
  public synthiaPromoUrl: string;
  public isAnalysisStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isInvalidExcludeOrSeek: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.setDefaultValues();
  }

  public setDefaultValues() {
    // manual retro parameters
    this.multicutManual = new BehaviorSubject<boolean>(DEFAULT_MULTICUT_MANUAL);
    this.cutIntoSmallerFragmentsManual = new BehaviorSubject<boolean>(
      DEFAULT_CUT_INTO_SMALLER_FRAGMENTS_MANUAL,
    );
    this.markNonSelectiveManual = new BehaviorSubject<boolean>(DEFAULT_MARK_NON_SELECTIVE_MANUAL);
    this.excludeDiasteroselectiveReactionsManual = new BehaviorSubject<boolean>(
      DEFAULT_EXCLUDE_DIASTEREOSELECTIVE_REACTIONS_MANUAL,
    );
    this.isPresetEdited = new BehaviorSubject<boolean>(false);
    this.isSynthiaLiteEnabled = APP_CONFIG.SYNTHIA_LITE_ENABLED;
    this.synthiaPromoUrl = APP_CONFIG.SYNTHIA_PROMO_URL;
  }

  public setParametersForAuto(
    parameters: any,
    fixedDuration: number,
    isAnalysisRerun: boolean = false,
    isDeveloperUser: boolean = false,
  ) {
    this.arenesAuto.next(parameters.bases.indexOf(KnownReactionBases.ARENES) !== -1);
    this.heterocyclesAuto.next(parameters.bases.indexOf(KnownReactionBases.HETEROCYCLES) !== -1);
    this.specializedAuto.next(parameters.bases.indexOf(KnownReactionBases.SPECIALIZED) !== -1);
    this.rulesAuto.next(this.prepareRulesArrayForAuto(parameters.bases));
    this.powerSearchFGI.next(parameters.post_filters.indexOf('FGI') !== -1);
    this.powerSearchTunnel.next(parameters.post_filters.indexOf('TUNNELS') !== -1);
    this.powerSearchMultirx.next(parameters.post_filters.indexOf('MULTIRX') !== -1);
    this.promoteRobustReactionsAuto.next(
      parameters.post_filters.indexOf('PROMOTE_ROBUST_REACTIONS') !== -1,
    );
    this.showDifferentBondConnectionsAuto.next(
      parameters.post_filters.indexOf('SHOW_ONLY_DIFFERENT_BOND_DISCONNECTIONS') !== -1,
    );
    this.excludeGaseousSubstrates.next(
      parameters.post_filters.indexOf('EXCLUDE_GASEOUS_SUBSTRATES') !== -1,
    );
    this.excludeReactionsWithMetals.next(
      parameters.post_filters.indexOf('EXCLUDE_REACTIONS_WITH_METALS') !== -1,
    );
    this.multicutAuto.next(parameters.post_filters.indexOf('MULTICUT') !== -1);
    this.cutAllHeterocyclesAuto.next(
      parameters.post_filters.indexOf('CUT_ALL_HETEROCYCLES') !== -1,
    );
    this.strainAuto.next(parameters.post_filters.indexOf('STRAIN') !== -1);
    this.macrocyclesAuto.next(parameters.post_filters.indexOf('MACROCYCLES') !== -1);
    this.excludeDiasteroselectiveReactionsAuto.next(
      parameters.post_filters.indexOf('EXCLUDE_DIASTEREOSELECTIVE_REACTIONS') !== -1,
    );
    this.strategiesAuto.next(parameters.post_filters.indexOf('STRATEGIES') !== -1);
    if (parameters.buyable_stop_conditions) {
      this.buyableMaxMolecularMass.next(parameters.buyable_stop_conditions.max_molecular_mass);
      this.maxPricePerGram.next(parameters.buyable_stop_conditions.max_price_per_gram);
      this.enforceCarbonFootprintAvailability.next(
        parameters.buyable_stop_conditions.enforce_carbon_footprint_availability,
      );
      this.buyableAvailableLargeQuantities.next(
        parameters.buyable_stop_conditions.buyable_available_in_large_quantities_threshold !== 0,
      );
      this.buyableAvailableLargeQuantitiesThreshold.next(
        parameters.buyable_stop_conditions.buyable_available_in_large_quantities_threshold,
      );
      this.catalogs.next(
        parameters.buyable_stop_conditions.buyable_catalogs
          ? parameters.buyable_stop_conditions.buyable_catalogs
          : [],
      );
    } else {
      this.enableBuyableStopConditions.next(false);
    }

    if (parameters.known_stop_conditions) {
      this.knownMaxMolecularMass.next(
        parameters.known_stop_conditions
          ? parameters.known_stop_conditions.max_molecular_mass
          : null,
      );
      this.minPopularity.next(parameters.known_stop_conditions.min_popularity);
    } else {
      this.enableKnownStopConditions.next(false);
    }

    if (parameters.inventory_stop_conditions) {
      this.inventoryMaxMolecularMass.next(parameters.inventory_stop_conditions.max_molecular_mass);
    } else {
      this.enableInventoryStopConditions.next(false);
    }

    if (isDeveloperUser && !!parameters.developer_mode_parameters) {
      this.ringsStrategiesAuto.next(
        parameters.developer_mode_parameters.strategies?.indexOf(5) !== -1,
      );
      this.stereoStrategiesAuto.next(
        parameters.developer_mode_parameters.strategies?.indexOf(6) !== -1,
      );
      this.cutIntoSmallerStrategiesAuto.next(
        parameters.developer_mode_parameters.strategies?.indexOf(7) !== -1,
      );
      this.multirxAuto.next(parameters.developer_mode_parameters.multirx);
      this.tunnelsAuto.next(parameters.developer_mode_parameters.tunnels);
      this.fgiAuto.next(parameters.developer_mode_parameters.fgi);
      this.fgiWithProtectionsAuto.next(parameters.developer_mode_parameters.fgi_with_protections);
      this.lowriskAuto.next(parameters.developer_mode_parameters.lowrisk);
      this.fgiCoef.next(parameters.developer_mode_parameters.fgi_coef);
      this.tunnelCoef.next(parameters.developer_mode_parameters.tunnel_coef);
      if (parameters.developer_mode_parameters.additional_parameters) {
        this.jsonDevParameters.next(
          JSON.stringify(parameters.developer_mode_parameters.additional_parameters),
        );
      } else {
        this.jsonDevParameters.next(DEFAULT_JSON_DEV_PARAMETERS);
      }
      this.usingDevModeSettings.next(true);
    }

    this.chemicalScoringFunction.next(
      parameters.molecule_scoring_formula
        ? parameters.molecule_scoring_formula
        : DEFAULT_CHEMICAL_SCORING_FUNCTION,
    );
    this.reactionScoringFunction.next(
      parameters.reaction_scoring_formula
        ? parameters.reaction_scoring_formula
        : DEFAULT_CHEMICAL_SCORING_FUNCTION,
    );
    if (!isAnalysisRerun) {
      this.simpleScoringFunction.next(parameters.simplified_scoring_function);
    }
    this.beamWidth.next(parameters.beam_width);
    this.maxReactionsPerProduct.next(parameters.max_reactions_per_product);
    this.smiles = parameters.smiles;
    this.pathsReturned.next(parameters.max_paths);
    this.pristine = false;
    this.analysisComplexityOption.next(
      this.prepareAnalysisComplexityOption(
        parameters.max_iterations,
        fixedDuration,
        parameters.main_limit,
      ),
    );
    if (parameters.simplified_scoring_function) {
      this.avoidKeywords.next(
        parameters.simplified_scoring_function.avoided_keywords
          ? this.cleanKeywords(parameters.simplified_scoring_function.avoided_keywords)
          : DEFAULT_AVOID_KEYWORDS,
      );
      this.avoidMoleculeSets.next(
        parameters.simplified_scoring_function.avoided_molecule_sets
          ? parameters.simplified_scoring_function.avoided_molecule_sets
          : DEFAULT_AVOID_MOLECULE_SETS,
      );
      this.avoidSmarts.next(
        parameters.simplified_scoring_function.avoided_smarts
          ? this.cleanStructure(parameters.simplified_scoring_function.avoided_smarts)
          : DEFAULT_AVOID_SMARTS,
      );
      this.avoidSmartsSets.next(
        parameters.simplified_scoring_function.avoided_smarts_sets
          ? parameters.simplified_scoring_function.avoided_smarts_sets
          : DEFAULT_AVOID_SMARTS_SETS,
      );
      this.avoidSmiles.next(
        parameters.simplified_scoring_function.avoided_smiles
          ? this.cleanStructure(parameters.simplified_scoring_function.avoided_smiles)
          : DEFAULT_AVOID_SMILES,
      );
      this.seekKeywords.next(
        parameters.simplified_scoring_function.seek_keywords
          ? this.cleanKeywords(parameters.simplified_scoring_function.seek_keywords)
          : DEFAULT_SEEK_KEYWORDS,
      );
      this.seekMoleculeSets.next(
        parameters.simplified_scoring_function.seek_molecule_sets
          ? parameters.simplified_scoring_function.seek_molecule_sets
          : DEFAULT_SEEK_MOLECULE_SETS,
      );
      this.seekSmarts.next(
        parameters.simplified_scoring_function.seek_smarts
          ? this.cleanStructure(parameters.simplified_scoring_function.seek_smarts)
          : DEFAULT_SEEK_SMARTS,
      );
      this.seekSmartsSets.next(
        parameters.simplified_scoring_function.seek_smarts_sets
          ? parameters.simplified_scoring_function.seek_smarts_sets
          : DEFAULT_SEEK_SMARTS_SETS,
      );
      this.seekSmiles.next(
        parameters.simplified_scoring_function.seek_smiles
          ? this.cleanStructure(parameters.simplified_scoring_function.seek_smiles)
          : DEFAULT_SEEK_SMILES,
      );
      this.nonselective.next(
        parameters.simplified_scoring_function.non_selectivity
          ? parameters.simplified_scoring_function.non_selectivity
          : DEFAULT_NONSELECTIVE,
      );
      this.protectingGroups.next(
        parameters.simplified_scoring_function.protecting_groups
          ? parameters.simplified_scoring_function.protecting_groups
          : DEFAULT_PROTECTING_GROUPS,
      );
    }
  }

  public setParametersFromScoringFunction(
    selectedSSF: SimpleScoringFunction,
    isAnalysisRerun: boolean = false,
  ) {
    if (selectedSSF.enable_commercial_stop_conditions !== null) {
      this.enableBuyableStopConditions.next(selectedSSF.enable_commercial_stop_conditions);
    }

    if (selectedSSF.enable_published_stop_conditions !== null) {
      this.enableKnownStopConditions.next(selectedSSF.enable_published_stop_conditions);
    }

    if (selectedSSF.enable_stockroom_stop_conditions !== null) {
      this.enableInventoryStopConditions.next(selectedSSF.enable_stockroom_stop_conditions);
    }

    if (selectedSSF.starting_material_enforce_carbon_footprint_availability !== null) {
      this.enforceCarbonFootprintAvailability.next(
        selectedSSF.starting_material_enforce_carbon_footprint_availability,
      );
    }

    if (selectedSSF.starting_material_buyable_available_large_quantities_threshold !== null) {
      this.buyableAvailableLargeQuantitiesThreshold.next(
        selectedSSF.starting_material_buyable_available_large_quantities_threshold,
      );
      this.buyableAvailableLargeQuantities.next(
        selectedSSF.starting_material_buyable_available_large_quantities_threshold !== 0,
      );
    }

    if (selectedSSF.starting_material_commercial_price_per_gram !== null) {
      this.maxPricePerGram.next(selectedSSF.starting_material_commercial_price_per_gram);
    }

    if (selectedSSF.starting_material_commercial_molecular_mass !== null) {
      this.buyableMaxMolecularMass.next(selectedSSF.starting_material_commercial_molecular_mass);
    }

    if (selectedSSF.starting_material_published_popularity !== null) {
      this.minPopularity.next(selectedSSF.starting_material_published_popularity);
    }

    if (selectedSSF.starting_material_published_molecular_mass !== null) {
      this.knownMaxMolecularMass.next(selectedSSF.starting_material_published_molecular_mass);
    }

    if (selectedSSF.starting_material_stockroom_molecular_mass !== null) {
      this.inventoryMaxMolecularMass.next(selectedSSF.starting_material_stockroom_molecular_mass);
    }

    if (selectedSSF.paths_returned !== null) {
      this.pathsReturned.next(selectedSSF.paths_returned);
    }

    if (selectedSSF.min_search_width !== null) {
      this.beamWidth.next(selectedSSF.min_search_width);
    }

    if (selectedSSF.max_reactions_per_product !== null) {
      this.maxReactionsPerProduct.next(selectedSSF.max_reactions_per_product);
    }

    for (const _analysisComplexity of DEFAULT_ANALYSIS_COMPLEXITY_OPTIONS) {
      if (_analysisComplexity.value === selectedSSF.analysis_complexity) {
        this.analysisComplexityOption.next(_analysisComplexity);
        const customAnalysisComplexityActiveStatus =
          _analysisComplexity.name === 'Custom analysis (use your own limit)';
        this.customAnalysisComplexityActive.next(customAnalysisComplexityActiveStatus);
      }
    }

    this.arenesAuto.next(
      (selectedSSF.rules_database || []).indexOf(KnownReactionBases.ARENES) !== -1,
    );
    this.heterocyclesAuto.next(
      (selectedSSF.rules_database || []).indexOf(KnownReactionBases.HETEROCYCLES) !== -1,
    );
    this.specializedAuto.next(
      (selectedSSF.rules_database || []).indexOf(KnownReactionBases.SPECIALIZED) !== -1,
    );
    this.expertChemistry.next(
      (selectedSSF.rules_database || []).indexOf(KnownReactionBases.EXPERT) !== -1,
    );
    this.rulesAuto.next(this.prepareRulesArrayForAuto(selectedSSF.rules_database));
    this.powerSearchTunnel.next((selectedSSF.filters || []).indexOf('TUNNELS') !== -1);
    this.powerSearchFGI.next((selectedSSF.filters || []).indexOf('FGI') !== -1);
    this.powerSearchMultirx.next((selectedSSF.filters || []).indexOf('MULTIRX') !== -1);
    this.promoteRobustReactionsAuto.next(
      (selectedSSF.filters || []).indexOf('PROMOTE_ROBUST_REACTIONS') !== -1,
    );
    this.showDifferentBondConnectionsAuto.next(
      (selectedSSF.filters || []).indexOf('SHOW_ONLY_DIFFERENT_BOND_DISCONNECTIONS') !== -1,
    );
    this.excludeGaseousSubstrates.next(
      (selectedSSF.filters || []).indexOf('EXCLUDE_GASEOUS_SUBSTRATES') !== -1,
    );
    this.excludeReactionsWithMetals.next(
      (selectedSSF.filters || []).indexOf('EXCLUDE_REACTIONS_WITH_METALS') !== -1,
    );
    this.multicutAuto.next((selectedSSF.filters || []).indexOf('MULTICUT') !== -1);
    this.cutAllHeterocyclesAuto.next(
      (selectedSSF.filters || []).indexOf('CUT_ALL_HETEROCYCLES') !== -1,
    );
    this.strainAuto.next((selectedSSF.filters || []).indexOf('STRAIN') !== -1);
    this.macrocyclesAuto.next((selectedSSF.filters || []).indexOf('MACROCYCLES') !== -1);
    this.excludeDiasteroselectiveReactionsAuto.next(
      (selectedSSF.filters || []).indexOf('EXCLUDE_DIASTEREOSELECTIVE_REACTIONS') !== -1,
    );
    this.strategiesAuto.next((selectedSSF.filters || []).indexOf('STRATEGIES') !== -1);
    this.reactionDatasets.next(this.prepareReactionsArrayForAuto(selectedSSF.reaction_datasets));
    const avoided_keywords = selectedSSF.avoided_keywords
      ? this.cleanKeywords(selectedSSF.avoided_keywords)
      : '';
    const avoided_smiles = selectedSSF.avoided_smiles
      ? this.cleanStructure(selectedSSF.avoided_smiles)
      : '';
    const avoided_smarts = selectedSSF.avoided_smarts
      ? this.cleanStructure(selectedSSF.avoided_smarts)
      : '';
    const seek_keywords = selectedSSF.seek_keywords
      ? this.cleanKeywords(selectedSSF.seek_keywords)
      : '';
    const seek_smiles = selectedSSF.seek_smiles ? this.cleanStructure(selectedSSF.seek_smiles) : '';
    const seek_smarts = selectedSSF.seek_smarts ? this.cleanStructure(selectedSSF.seek_smarts) : '';

    this.avoidKeywords.next(avoided_keywords);
    this.avoidSmiles.next(avoided_smiles);
    this.avoidSmarts.next(avoided_smarts);
    this.frozenAvoidMoleculeSets.next(selectedSSF.avoided_molecule_sets);
    this.avoidMoleculeSets.next(selectedSSF.avoided_molecule_sets);
    this.seekKeywords.next(seek_keywords);
    this.seekSmiles.next(seek_smiles);
    this.seekSmarts.next(seek_smarts);
    this.frozenSeekMoleculeSets.next(selectedSSF.seek_molecule_sets);
    this.seekMoleculeSets.next(selectedSSF.seek_molecule_sets);
    this.pathwaysType.next(selectedSSF.pathway_linearity);
    this.protectingGroups.next(selectedSSF.protecting_groups);
    this.shorterPaths.next(selectedSSF.shorter_paths);
    this.nonselective.next(selectedSSF.non_selectivity);
    this.excludeLabels.next(selectedSSF.hide_labels);
    this.seekLabels.next(selectedSSF.seek_labels);
    if (!isAnalysisRerun) {
      this.catalogs.next(selectedSSF.starting_material_commercial_catalog);
    }
    this.isPresetEdited.next(false);
    switch (selectedSSF.custom_analysis_complexity_type) {
      case 'iterations':
        this.mainLimit.next('iterations');
        this.maxIterations.next(selectedSSF.custom_analysis_complexity_iterations);
        this.automaticRetrosynthesisDurationSec.next(3600);
        break;
      case 'duration':
        this.mainLimit.next('time');
        this.maxIterations.next(1000);
        this.automaticRetrosynthesisDurationSec.next(
          selectedSSF.custom_analysis_complexity_duration,
        );
        break;
      default:
        this.mainLimit.next('iterations');
        this.maxIterations.next(1000);
        this.automaticRetrosynthesisDurationSec.next(3600);
        break;
    }
  }

  public setParametersForManual(parameters: any, isDeveloperUser: boolean = false) {
    this.rulesAuto.next(this.prepareRulesArrayForManual(parameters.bases));
    this.multicutManual.next(!!parameters.post_filters.MULTICUT);
    this.excludeDiasteroselectiveReactionsManual.next(
      !!parameters.post_filters.EXCLUDE_DIASTEREOSELECTIVE_REACTIONS,
    );
    this.reactionDatasets.next(this.prepareReactionsArrayForManual(parameters.reaction_datasets));
    this.cutIntoSmallerFragmentsManual.next(!!parameters.post_filters.CUT_INTO_SMALLER_FRAGMENTS);
    this.markNonSelectiveManual.next(!!parameters.post_filters.MARK_NON_SELECTIVE);
    this.smiles = parameters.smiles;
    this.algorithm = AnalysisAlgorithm.MANUAL_RETROSYNTHESIS;
    this.pristine = false;
    if (isDeveloperUser && !!parameters.developer_mode_parameters) {
      if (parameters.developer_mode_parameters.additional_parameters) {
        this.jsonDevParameters.next(
          JSON.stringify(parameters.developer_mode_parameters.additional_parameters),
        );
      } else {
        this.jsonDevParameters.next(DEFAULT_JSON_DEV_PARAMETERS);
      }
      this.usingDevModeSettings.next(true);
    }
  }

  public setFolder(folderId: number) {
    this.folderId.next(folderId);
  }

  public getFilters(selectedAlgorithm: AlgorithmType) {
    switch (selectedAlgorithm) {
      case AlgorithmType.AutomaticRetrosynthesis:
      case AlgorithmType.LibraryMode:
        return this.getAutoRetroFilters();
      case AlgorithmType.ManualRetrosynthesis:
        return this.getManualRetroFilters();
      case AlgorithmType.AutomaticRetrosynthesisBatch:
        return this.getAutoRetroFilters();
      default:
    }
  }

  public getDatabaseRules(selectedAlgorithm: AlgorithmType) {
    switch (selectedAlgorithm) {
      case AlgorithmType.AutomaticRetrosynthesis:
      case AlgorithmType.LibraryMode:
        return this.getRulesForAutoRetro();
      case AlgorithmType.ManualRetrosynthesis:
        return this.getRulesForManualRetro();
      case AlgorithmType.AutomaticRetrosynthesisBatch:
        return this.getRulesForAutoRetro();
      default:
    }
  }

  public cleanKeywords(keywords: string) {
    if (keywords && keywords.length > 2) {
      return keywords
        .substring(1, keywords.length - 1)
        .split(`','`)
        .join(';');
    } else {
      return '';
    }
  }

  public cleanStructure(structure: string) {
    if (structure && structure.length > 2) {
      return structure
        .substring(1, structure.length - 1)
        .split(`','`)
        .join(' ');
    } else {
      return '';
    }
  }

  public getRulesDataset() {
    return this.http.get(GetRulesDataset, { observe: 'response', responseType: 'json' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const rulesDataset: RulesDataset[] = [...response.body];
          this.setRulesDataBases(response.body);
          this.rulesDataset.next(rulesDataset);
        } catch (e) {
          throw 'Unexpected format of response.'; /* tslint:disable-line:no-string-throw */
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public getReactionDataset() {
    return this.http.get(GetReactionDataset, { observe: 'response', responseType: 'json' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const reactionDataset: ReactionDataset[] = [...response.body];
          this.setReactionDataBases(response.body);
          this.publishedReactionDataset.next(reactionDataset);
        } catch (e) {
          throw 'Unexpected format of response.'; /* tslint:disable-line:no-string-throw */
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public prepareRulesArrayForAuto(bases) {
    const rulesArray = [];
    this.rulesDataset.value.forEach((item) => {
      if (bases.includes(item.name)) {
        item.selected = true;
        rulesArray.push(item.name);
      } else {
        item.selected = false;
      }
    });
    this.rulesAuto.next(rulesArray);
    return rulesArray;
  }

  public prepareReactionsArrayForAuto(bases) {
    if (bases !== null) {
      const reactionArray = [];
      this.publishedReactionDataset.value.forEach((item) => {
        if (bases.includes(item.name)) {
          item.selected = true;
          reactionArray.push(item.name);
        } else {
          item.selected = false;
        }
      });
      return reactionArray;
    }
  }

  private getAutoRetroFilters() {
    const filters: string[] = [];
    if (this.cutAllHeterocyclesAuto.value === true) {
      filters.push('CUT_ALL_HETEROCYCLES');
    }
    if (this.strategiesAuto.value === true) {
      filters.push('STRATEGIES');
    }
    if (this.multicutAuto.value === true) {
      filters.push('MULTICUT');
    }
    if (this.strainAuto.value) {
      filters.push('STRAIN');
    }
    if (this.macrocyclesAuto.value) {
      filters.push('MACROCYCLES');
    }
    if (this.excludeDiasteroselectiveReactionsAuto.value) {
      filters.push('EXCLUDE_DIASTEREOSELECTIVE_REACTIONS');
    }
    if (this.powerSearchFGI.value === true) {
      filters.push('FGI');
    }
    if (this.powerSearchTunnel.value === true) {
      filters.push('TUNNELS');
    }
    if (this.powerSearchMultirx.value === true) {
      filters.push('MULTIRX');
    }
    if (this.promoteRobustReactionsAuto.value) {
      filters.push('PROMOTE_ROBUST_REACTIONS');
    }
    if (this.showDifferentBondConnectionsAuto.value) {
      filters.push('SHOW_ONLY_DIFFERENT_BOND_DISCONNECTIONS');
    }
    if (this.excludeGaseousSubstrates.value) {
      filters.push('EXCLUDE_GASEOUS_SUBSTRATES');
    }
    if (this.excludeReactionsWithMetals.value) {
      filters.push('EXCLUDE_REACTIONS_WITH_METALS');
    }
    return filters;
  }

  private getManualRetroFilters() {
    const filters = {};
    if (this.multicutManual.value) {
      filters['MULTICUT'] = {};
    }
    if (this.excludeDiasteroselectiveReactionsManual.value) {
      filters['EXCLUDE_DIASTEREOSELECTIVE_REACTIONS'] = {};
    }
    if (this.cutIntoSmallerFragmentsManual.value) {
      filters['CUT_INTO_SMALLER_FRAGMENTS'] = {};
    }
    return filters;
  }

  private getRulesForManualRetro() {
    const rulesBases = this.rulesAuto.value;
    if (rulesBases.length > 0) {
      const chemBases = this.rulesAuto.value.reduce((obj, item) => {
        obj[item] = {};
        return obj;
      }, {});
      return chemBases;
    } else {
      return {};
    }
  }

  private getRulesForAutoRetro() {
    return this.rulesAuto.value;
  }

  public prepareRulesArrayForManual(bases) {
    const filteredArray = [];
    const rulesArray = Object.keys(bases).map((key) => key);
    this.rulesDataset.value.forEach((item) => {
      if (rulesArray.includes(item.name)) {
        item.selected = true;
        filteredArray.push(item.name);
      } else {
        item.selected = false;
      }
    });
    return filteredArray;
  }

  public prepareReactionsArrayForManual(bases) {
    if (bases !== null) {
      const reactionArray = [];
      this.publishedReactionDataset.value.forEach((item) => {
        if (bases.includes(item.name)) {
          item.selected = true;
          reactionArray.push(item.name);
        } else {
          item.selected = false;
        }
      });
      return reactionArray;
    }
  }

  private prepareAnalysisComplexityOption(
    maxIterations: number,
    fixedDuration: number,
    mainLimit: 'iterations' | 'time',
  ) {
    const selectedOption = DEFAULT_ANALYSIS_COMPLEXITY_OPTIONS.find((option) => {
      return option.max_iterations === maxIterations && option.fixed_duration === fixedDuration;
    });
    this.setCustomComplexityValues(selectedOption, maxIterations, fixedDuration, mainLimit);
    return selectedOption ? selectedOption : DEFAULT_ANALYSIS_COMPLEXITY_OPTIONS[3];
  }

  private setCustomComplexityValues(
    analysisComplexityOption: AnalysisComplexityOption,
    maxIterations: number,
    fixedDuration: number,
    mainLimit: 'iterations' | 'time',
  ) {
    this.maxIterations.next(DEFAULT_MAX_ITERATIONS);
    if (analysisComplexityOption) {
      this.automaticRetrosynthesisDurationSec.next(
        APP_CONFIG.AUTOMATIC_RETROSYNTHESIS_TIME_SEC_DEFAULT,
      );
      this.mainLimit.next(DEFAULT_MAIN_LIMIT);
    } else {
      if (mainLimit === 'iterations') {
        this.maxIterations.next(maxIterations);
      } else {
        this.automaticRetrosynthesisDurationSec.next(
          fixedDuration !== 86400
            ? fixedDuration
            : APP_CONFIG.AUTOMATIC_RETROSYNTHESIS_TIME_SEC_DEFAULT,
        );
      }
      this.customAnalysisComplexityActive.next(true);
      this.mainLimit.next(mainLimit);
    }
  }

  private setRulesDataBases(rulesData: RulesDataset[]) {
    this.rulesDataBasesInitial = [];
    rulesData.forEach((item) => {
      if (item.selected === true) {
        this.rulesDataBasesInitial.push(item.name);
      }
    });
    this.rulesAuto.next(this.rulesDataBasesInitial);
  }

  private setReactionDataBases(reactionData: ReactionDataset[]) {
    this.reactionDataBasesInitial = [];
    reactionData.forEach((item) => {
      if (item.selected === true) {
        this.reactionDataBasesInitial.push(item.name);
      }
    });
    this.reactionDatasets.next(this.reactionDataBasesInitial);
  }
}
