import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GRAPH_NODE_DATA } from '../../services/graph-node-popup/graph-node-popup.models';
import {
  AnalysisService,
  GraphNodePopupService,
  CopyToClipboardService,
  InfoService,
  ErrorsHandlerService,
  AnalysisResultsService,
} from '../../services';
import { AppConstantsService } from '../../services/app-constants/app-constants.service';
import { getTooltip } from '../../components/utils';
import { AnnotateReactionData } from 'src/app/shared/services/models/annotate-reaction';
import { GraphReactionNode } from '../../services/graph-builder';

interface NodeDetails {
  title: string;
  content: string;
  showCopyIcon: boolean;
  copyType: number;
  isCopied: boolean;
}

interface ReactionDialogData {
  node: GraphReactionNode;
  popupService: GraphNodePopupService;
}

@Component({
  selector: 'ch-reaction-node-menu',
  templateUrl: './reaction-node-menu.component.html',
  styleUrl: './reaction-node-menu.component.scss',
})
export class ReactionNodeMenuComponent implements OnInit, OnDestroy {
  public unsubscriberSubject: Subject<void> = new Subject<void>();
  public getTooltip = getTooltip;
  public isReactionNodeMenuOpen: boolean = false;
  public node: GraphReactionNode;
  public popupService: GraphNodePopupService;
  public searchFormControl: FormControl;
  public filteredRules: AnnotateReactionData[];
  public reactionRules: AnnotateReactionData[];
  public selectAllReactionRules: boolean = false;
  public isSearchValue: boolean = false;
  public showReactionPopup: boolean = false;
  public reactionNodeType: string = 'reaction';
  public isCopiedCondition: boolean = false;
  public rulesForPopup: AnnotateReactionData[];
  public nodeDetailsToDisplay: NodeDetails;
  public maxReactionNameLength: number = 45;
  public maxTypicalConditionLength: number = 25;
  public maxRuleLength: number = 30;
  public showPopup: boolean = true;

  @ViewChild('popupForReaction', { static: false }) popupElement: ElementRef;

  constructor(
    public elementRef: ElementRef,
    @Inject(GRAPH_NODE_DATA) private data: ReactionDialogData,
    public analysisService: AnalysisService,
    public appConstantsService: AppConstantsService,
    public copyToClipboardService: CopyToClipboardService,
    public analysisResultsService: AnalysisResultsService,
    private infoService: InfoService,
    private errorsHandler: ErrorsHandlerService,
  ) {
    this.popupService = this.data.popupService;
    this.node = data.node;
    this.searchFormControl = new UntypedFormControl('', [Validators.required.bind(this)]);
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public ngOnInit() {
    this.analysisService.isRerunDiversityLibrary
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isRerunDiversity: boolean) => {
        if (isRerunDiversity) {
          const reactionsToModify: string[] = [];
          this.node.chNodeAnalysisContext?.analysis?.initial_computation?.reactions_to_modify?.forEach(
            (reactionToModify: [attr: string]) => {
              const key = Object.keys(reactionToModify)[0];
              const value: [] = reactionToModify[key];
              reactionsToModify.push(...value);
            },
          );
          reactionsToModify.map((reaction) => reaction.toLowerCase());
          this.node.annotateReactionList.forEach((annotateReaction) => {
            if (reactionsToModify.includes(annotateReaction.name.toLowerCase())) {
              annotateReaction.checked = true;
            }
          });
        }
        this.getFilteredAnnotateReactionList();
      });
  }

  public setRuleName(rule: string) {
    if (rule) {
      return rule.length > this.maxRuleLength
        ? rule.substring(0, this.maxRuleLength) + '...'
        : rule;
    }
  }

  public getFilteredAnnotateReactionList() {
    if (this.node && this.node.annotateReactionList) {
      this.reactionRules = this.node.annotateReactionList;
      this.filteredRules = this.reactionRules;
      this.updateFilteredRulesCheckbox();
    }
  }

  public onChangeReactionRulesCheckbox(checked: boolean) {
    if (checked) {
      this.filteredRules = this.filteredRules.map((item) => {
        item.checked = true;
        return item;
      });
    } else {
      this.filteredRules = this.filteredRules.map((item) => {
        item.checked = false;
        this.infoService.showInfo('At least one reaction needs to be selected');
        return item;
      });
    }
  }

  public enterSearch() {
    const searchText: string = (this.searchFormControl.value as string)?.toLowerCase() || '';
    this.filteredRules = [];
    this.reactionRules.forEach((annotateReaction) => {
      if (annotateReaction.name.toLowerCase().indexOf(searchText) !== -1) {
        this.filteredRules.push(annotateReaction);
      }
    });
  }

  public isSearchInputValid() {
    if (this.searchFormControl.value) {
      this.isSearchValue = true;
    }
    return (this.searchFormControl.value as string) &&
      (this.searchFormControl.value as string).length > 0
      ? true
      : false;
  }

  public clearSearchField() {
    this.isSearchValue = false;
    this.searchFormControl.setValue('');
    this.filteredRules = this.reactionRules;
  }

  public updateFilteredRulesCheckbox() {
    if (this.filteredRules.filter((rule) => rule.checked).length === 0) {
      this.infoService.showInfo('At least one reaction needs to be selected');
    }
    if (this.filteredRules !== undefined) {
      this.selectAllReactionRules = this.filteredRules.every((item) => item.checked === true);
    }
  }

  public mouseOverIcon(rule: AnnotateReactionData[], event: MouseEvent) {
    this.nodeDetailsToDisplay = {
      title: 'Typical Conditions',
      content: rule['conditions'] as string,
      showCopyIcon: true,
      copyType: 5,
      isCopied: false,
    };
    this.showReactionPopup = true;
    this.rulesForPopup = rule;
    const timer = setTimeout(() => {
      const popup = document?.getElementById('popupForReaction');
      popup.style.display = 'block';
      if (event.clientX < 850) {
        popup.style.left = `${event.clientX + 10}px`;
      } else {
        popup.style.left = `${event.clientX - 400}px`;
      }
      if (event.clientY < 491) {
        popup.style.top = `${event.clientY + 10}px`;
      } else {
        popup.style.top = `${event.clientY - 250}px`;
      }
      clearTimeout(timer);
    }, 100);
  }

  public mouseLeaveIcon() {
    this.showReactionPopup = false;
  }

  public isArray<T>(data: T | T[]): data is T[] {
    return data instanceof Array;
  }

  public copyToClipboard(toBeCopied: string) {
    this.copyToClipboardService
      .copyContent(toBeCopied?.toString())
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((success: boolean) => {
        if (success) {
          this.isCopiedCondition = true;
          setTimeout(() => {
            this.isCopiedCondition = false;
          }, 2000);
        } else {
          this.errorsHandler.showGlobalError(
            'Copy failed',
            'Could not copy content due to an unknown error.',
          );
        }
      });
  }

  public getMaxLength(label: string, maxLength: number): string {
    return label?.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }

  public closePopup() {
    if (this.reactionRules.filter((rule) => rule.checked).length > 0) {
      this.showPopup = false;
      this.analysisResultsService.setAnnotateReactionRules.next(true);
    } else {
      this.infoService.showInfo('At least one reaction needs to be selected');
      this.showPopup = true;
      this.showReactionPopup = false;
    }
  }
}
