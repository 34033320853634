import { GraphMoleculeEntry } from './graph-molecule-entry';
import { GraphReactionNodeEntry } from './graph-reaction-node-entry';
import { GraphReactionEntry } from './graph-reaction-entry';
import { isString } from '../../../components/utils';
import { TargetSetDetailsEntry } from './target-set-details';
import { LibrarySyntheticPathEntry } from './library-synthetic-path-entry';

export class LibraryResources {
  public molecules: GraphMoleculeEntry[] = [];
  public reactions: GraphReactionEntry[] = [];
  public reaction_nodes: GraphReactionNodeEntry[] = [];
  public paths: LibrarySyntheticPathEntry[] = [];
  public target_set_details: TargetSetDetailsEntry[] = [];
  public total_pages: number = 0;
  public active_page_index: number = 0;

  constructor(data?) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        switch (field) {
          case 'molecules':
            this.molecules = jsonData['molecules'].map(
              (molecule) => new GraphMoleculeEntry(molecule),
            );
            break;
          case 'reactions':
            this.reactions = jsonData['reactions'].map(
              (reaction) => new GraphReactionEntry(reaction),
            );
            break;
          case 'reaction_nodes':
            this.reaction_nodes = jsonData['reaction_nodes'].map(
              (reactionNode) => new GraphReactionNodeEntry(reactionNode),
            );
            break;
          case 'paths':
            this.paths = jsonData['paths'].map((path) => new LibrarySyntheticPathEntry(path));
            break;
          case 'total_pages':
            this.total_pages = jsonData['total_pages'];
            break;
          case 'active_page_index':
            this.active_page_index = jsonData['active_page_index'];
            break;
          case 'target_set_details':
            this.target_set_details = jsonData['target_set_details'];
            break;
          default:
            console.error(`Unexpected field '${field}' in data provided to LibraryResources:ctor.`);
            break;
        }
      }
    }
  }

  public isEmpty(): boolean {
    return (
      this.molecules.length === 0 &&
      this.reaction_nodes.length === 0 &&
      this.reactions.length === 0 &&
      this.paths.length === 0
    );
  }
}
