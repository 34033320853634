import { throwError, empty, from, Observable, BehaviorSubject } from 'rxjs';
import { catchError, share, mergeAll, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChemicalEntry } from './models/chemical-entry';
import { ErrorsHandlerService, ParsedConnectionError } from '../errors-handler';
import { InfoService } from '../info.service';
import { RDKitLoaderService } from '../rdkit-loader/rdkit-loader.service';
import { JSMol, RDKitModule } from '@rdkit/rdkit/dist';
import { isNullOrUndefined } from '../../../shared/components/utils';
import { svgToImageDataURI } from 'src/app/shared/components/graph-utils';
import { DomSanitizer } from '@angular/platform-browser';

/* tslint:disable*/
export const backendEntryPoint_GetChemicalEntries =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/molecule-search/';
export const backendEntryPoint_GetSVG = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/svg-for-reaction/';
export const backendEntryPoint_VerifyMolFile =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/convert-molfile/';
export const backendEntryPoint_VerifySmiles =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/canonicalize-smiles/';
export const backendEntryPoint_MolfileMapping =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/external-integration/user-molfile-mapping/';
export const backendEntryPoint_GetSVGFromMolFile =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/svg-from-molfile/';
export const backendEntryPoint_GetImageForProtectionSmarts =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/svg-from-smarts/';

/* tslint:enable*/

export enum DisplayType {
  Molecule,
  Substructure,
}

@Injectable({
  providedIn: 'root',
})
export class MoleculeSearchService {
  private static resolveChemicalEntries(res): Observable<ChemicalEntry> {
    try {
      const jsonResult = res.body;
      if (jsonResult.length !== 0) {
        const resolvedMolecules: ChemicalEntry[] = jsonResult.map(
          (molecule) => new ChemicalEntry(molecule),
        );
        return from(resolvedMolecules);
      } else {
        return empty();
      }
    } catch (error) {
      return throwError('Unexpected format of response.');
    }
  }
  public moleculeSearchErrorText = new BehaviorSubject(null);
  private rdkit?: RDKitModule;

  constructor(
    private http: HttpClient,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
    private rdkitService: RDKitLoaderService,
    private domSanitizer: DomSanitizer,
  ) {
    this.rdkitService.getRDKit().subscribe((rdkit: RDKitModule) => {
      this.rdkit = rdkit;
    });
  }

  public getMoleculesByPrompt(
    prompt: string | string[] | number | number[],
    analsis_from_node: boolean = false,
  ): Observable<ChemicalEntry> {
    if (prompt instanceof Array) {
      return this.getMolecules({ prompt, analsis_from_node });
    } else {
      return this.getMolecules({ prompt: [prompt], analsis_from_node: analsis_from_node });
    }
  }

  public getMolecules(json): Observable<ChemicalEntry> {
    return this.http
      .post(backendEntryPoint_GetChemicalEntries, JSON.stringify(json), { observe: 'response' })
      .pipe(
        map(MoleculeSearchService.resolveChemicalEntries),
        mergeAll(),
        share(),
        catchError(this.getChemicalEntryError.bind(this)),
      ) as Observable<ChemicalEntry>;
    // Note: Due to the throw operator there and there in the above Observable chain it's inferred type
    //       becomes annoying Observable<ChemicalEntry|{}> which makes it unusable in constructs as
    //       getMolecule(...).subscribe((entry)=> ..., (error)=> ..., ...) as compiler finds only
    //       .subscribe() method available as common for both observables types. This way or another
    //       it makes no sense. throw operator do not produce any 'next' output but only talks to error stream
    //       therefore it do not change the expectation that the type of next element is ChemicalEntry.
    //       This is why we enforce Observable<ChemicalEntry> type above.
  }

  public verifySmiles(smiles: string[]): Observable<{ [key: string]: string }> {
    const params = {
      smiles: smiles,
    };
    return this.http
      .post(backendEntryPoint_VerifySmiles, JSON.stringify(params), { observe: 'response' })
      .pipe(
        map((response: any) => {
          return response.body;
        }),
        catchError(this.verifyStructureError.bind(this)),
      ) as Observable<{ [key: string]: string }>;
  }

  public verifyMolFile(molfile: FileList) {
    const formData = new FormData();
    for (let i = 0; i < molfile.length; i++) {
      formData.append('files', molfile[i]);
    }
    return this.http.post(backendEntryPoint_VerifyMolFile, formData, { observe: 'response' }).pipe(
      map((response: any) => response.body.smiles_list),
      catchError(this.verifyStructureError.bind(this)),
    );
  }

  public isFileSizeBiggerThanLimit(file: File, maxSize: number) {
    if (file.size / 1024 / 1024 > maxSize) {
      return true;
    }
    return false;
  }

  public structureToSvg(structure: string, width: number, height: number) {
    const params = {
      smiles: structure,
      height: height, // return SVG with this size
      width: width,
    };
    return this.http
      .post(backendEntryPoint_GetSVG, JSON.stringify(params), { observe: 'response' })
      .pipe(
        map((response: any) => response.body.structure),
        catchError(this.verifyStructureError.bind(this)),
      ) as Observable<string>;
  }

  public getMoleculeFromMolFileMapping() {
    return this.http.get(backendEntryPoint_MolfileMapping, { observe: 'response' }).pipe(
      map((response: any) => {
        if (response.status === 200) {
          return response.body.molfile;
        }
        return {};
      }),
      catchError(this.verifyStructureError.bind(this)),
    );
  }

  private getChemicalEntryError(error: Response | any) {
    this.moleculeSearchErrorText.next(error.error.message.toString());
    const parsedError = new ParsedConnectionError(error);
    this.parseConnectionError(parsedError);
    if (parsedError.status === 400) {
      return throwError(parsedError.error);
    } else if (parsedError.isRecognized() && parsedError.isGlobal()) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      return throwError(parsedError);
    } else {
      return throwError('Unsuccessful access to molecules database. ' + error);
    }
  }

  private verifyStructureError(error: Response | any) {
    const parsedError = new ParsedConnectionError(error);
    this.parseConnectionError(parsedError);
    if (parsedError.status === 404) {
      // 404 in this case may mean that SMILES couldn't be found due to being from invalid molfile
      // let components handle this kind of error
      return throwError(parsedError);
    } else if (parsedError.isRecognized() && parsedError.isGlobal()) {
      this.errorsHandler.showGlobalError(parsedError.promptMessage);
      return throwError(parsedError);
    } else {
      return throwError('Unsuccessful access to structure validation. ' + error);
    }
  }

  private parseConnectionError(parsedError: ParsedConnectionError) {
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
      return throwError(parsedError);
    }
  }

  public smilesToSmartsWithRdkit(smiles: string) {
    let smartsList = [];
    smiles.split('.').forEach((value) => {
      let mol_obj: JSMol;
      try {
        mol_obj = this.rdkit.get_mol(value);
        smartsList.push(mol_obj.get_smarts());
      } catch (error) {
        smartsList = [];
      } finally {
        if (mol_obj && mol_obj.delete) {
          mol_obj.delete();
        }
      }
    });
    return smartsList;
  }

  public smartsToMolWithRdkit(smarts: string) {
    let mol_obj: JSMol;
    let molFileBlock: string;
    try {
      mol_obj = this.rdkit.get_mol(smarts);
      molFileBlock = mol_obj.get_molblock();
    } catch (error) {
      molFileBlock = '';
    } finally {
      if (mol_obj && mol_obj.delete) {
        mol_obj.delete();
      }
    }
    const molBlockObservable = Observable.create((observer) => {
      observer.next(molFileBlock);
      observer.complete();
    });
    return molBlockObservable;
  }

  public MolToSmartsWithRdkit(molBlock: string) {
    const smartsList = [];
    molBlock.split('$$$$').forEach((molFile: string) => {
      if (molFile.trim() === '') {
        return;
      }
      const smarts = this.getMolFile(molFile);
      let mol_obj: JSMol;
      let smartsString: string;
      try {
        mol_obj = this.rdkit.get_mol(smarts);
        smartsString = mol_obj.get_smarts();
      } catch (error) {
        smartsString = '';
      } finally {
        if (mol_obj && mol_obj.delete) {
          mol_obj.delete();
        }
      }
      this.isSmartContainsDot(smartsString)
        ? smartsString.split('.').forEach((smart: string) => {
            smartsList.push(smart);
          })
        : smartsList.push(smartsString);
    });
    const smartsObservable = Observable.create((observer) => {
      observer.next(smartsList);
      observer.complete();
    });
    return smartsObservable;
  }

  public getRdkitObject(): Observable<RDKitModule> {
    if (isNullOrUndefined(this.rdkit)) {
      return this.rdkitService.getRDKit();
    } else {
      const rdkitObservable = Observable.create((observer) => {
        observer.next(this.rdkit);
        observer.complete();
      });
      return rdkitObservable;
    }
  }

  public structureToSvgWithRdkit(
    structure: string,
    width: number,
    height: number,
    rdkit: RDKitModule,
    displayType: DisplayType = DisplayType.Molecule,
  ) {
    if (isNullOrUndefined(this.rdkit)) {
      this.rdkit = rdkit;
    }
    let molFile: JSMol;
    if (displayType === DisplayType.Substructure) {
      molFile = this.rdkit.get_qmol(structure);
    } else {
      molFile = this.rdkit.get_mol(structure);
    }
    try {
      if (molFile.is_valid()) {
        const bondLength = height * 0.13;
        const atomLabelSize = height / 350;
        const params = {
          bondLineWidth: 1,
          width: width,
          height: height,
          fixedBondLength: bondLength,
          baseFontSize: atomLabelSize,
          atomLabelFontFace: 'Arial',
          clearBackground: false,
          unspecifiedStereoIsUnknown: true,
        };
        if (displayType === DisplayType.Substructure) {
          params['kekulize'] = false;
        }
        const svg = molFile.get_svg_with_highlights(JSON.stringify(params));
        return svg;
      } else {
        return '';
      }
    } catch (e) {
      return '';
    } finally {
      molFile.delete();
    }
  }

  public canonicalSmilesWithRdkit(smiles: string) {
    let smileStr: string;
    let mol_obj: JSMol;
    if (!isNullOrUndefined(this.rdkit)) {
      try {
        mol_obj = this.rdkit?.get_mol(smiles);
        smileStr = mol_obj?.get_smiles();
      } catch (error) {
        smileStr = '';
      } finally {
        if (mol_obj && mol_obj?.delete) {
          mol_obj?.delete();
        }
      }
    }
    return smileStr;
  }

  public verifySmilesWithRdkit(smiles: string) {
    let validity: boolean;
    let mol_obj: JSMol;
    try {
      mol_obj = this.rdkit.get_mol(smiles);
      if (mol_obj && mol_obj.is_valid) {
        validity = mol_obj.is_valid();
      } else {
        validity = false;
      }
    } catch (error) {
      validity = false;
    } finally {
      if (mol_obj && mol_obj.delete) {
        mol_obj.delete();
      }
    }

    return validity;
  }

  public verifySmartsWithRdkit(smarts: string) {
    let validity: boolean;
    let mol_obj: JSMol;
    try {
      mol_obj = this.rdkit.get_qmol(smarts);
      if (mol_obj && mol_obj.is_valid) {
        validity = mol_obj.is_valid();
      } else {
        validity = false;
      }
    } catch (error) {
      validity = false;
    } finally {
      if (mol_obj && mol_obj.delete) {
        mol_obj.delete();
      }
    }

    return validity;
  }

  public smilesToMolWithRdkit(smiles: string) {
    const moleculeObject = this.rdkit.get_mol(smiles);
    moleculeObject.set_prop('_MolFileChiralFlag', '1');
    const molFileBlock = moleculeObject.get_molblock();
    moleculeObject.delete();
    const molBlockObservable = Observable.create((observer) => {
      observer.next(molFileBlock);
      observer.complete();
    });
    return molBlockObservable;
  }

  public molToSmileWithRdkit(mol: string) {
    let smileStr: string;
    let mol_obj: JSMol;
    try {
      mol_obj = this.rdkit.get_mol(mol);
      smileStr = mol_obj.get_smiles();
    } catch (error) {
      smileStr = '';
    } finally {
      if (mol_obj && mol_obj.delete) {
        mol_obj.delete();
      }
    }
    return smileStr;
  }

  public smartsToSmileWithRdkit(smarts: string) {
    let smileStr: string = '';
    const qmol_obj = this.rdkit.get_qmol(smarts);
    try {
      smileStr = qmol_obj.get_smiles();
    } finally {
      qmol_obj.delete();
    }
    return smileStr;
  }

  public drawSvgWithRdkit(
    smiles: string,
    displayType: DisplayType = DisplayType.Molecule,
    rdkit: RDKitModule,
  ) {
    const params = {};
    if (isNullOrUndefined(this.rdkit)) {
      this.rdkit = rdkit;
    }
    let mol: JSMol;
    if (displayType === DisplayType.Substructure) {
      mol = this.rdkit.get_qmol(smiles);
      params['kekulize'] = false;
    } else {
      mol = this.rdkit.get_mol(smiles);
    }
    try {
      if (mol.is_valid()) {
        params['explicitMethyl'] = true;
        const svg = this.domSanitizer.bypassSecurityTrustResourceUrl(
          svgToImageDataURI(mol.get_svg_with_highlights(JSON.stringify(params))),
        );
        return svg;
      } else {
        return '';
      }
    } catch (e) {
      return '';
    } finally {
      mol.delete();
    }
  }

  public getSvgFromMolFile(molFileBlock: string, width: number, height: number) {
    const params = {
      molfileblock: molFileBlock,
      height: height,
      width: width,
    };
    return this.http
      .post(backendEntryPoint_GetSVGFromMolFile, JSON.stringify(params), { observe: 'response' })
      .pipe(
        map((response: any) => response.body.structure),
        catchError(this.verifyStructureError.bind(this)),
      );
  }
  private isSmartContainsDot(smartString: string) {
    return new RegExp(/[.]/).test(smartString);
  }

  private getMolFile(molFile: string) {
    molFile = this.filterMolString(molFile);
    if (!molFile.includes('(M  ALS[^\n]+( w{1}  | w{2} ))\n')) {
      molFile = molFile.replace(/(M {2}ALS[^\n]+( \w{1} {2}| \w{2} ))\n/gi, '$1 \n');
    }
    if (!molFile.includes('\r\n')) {
      molFile = molFile.replace(/\r\n|\r/gi, '\n');
    }
    molFile = '\n'.concat(molFile);
    return molFile;
  }

  private filterMolString(molFile: string) {
    const fileExtension = ['.sdf', '.mol'];
    let index;
    if (molFile.includes(fileExtension[0])) {
      index = molFile.indexOf(fileExtension[0]);
      return molFile.slice(index + fileExtension[0].length).trim();
    } else if (molFile.includes(fileExtension[1])) {
      index = molFile.indexOf(fileExtension[1]);
      return molFile.slice(index + fileExtension[1].length).trim();
    } else {
      return molFile.trim();
    }
  }

  public getImageForProtectionSmarts(smarts: string, width: number, height: number) {
    const params = {
      smarts: smarts,
      height: height,
      width: width,
    };
    return this.http
      .post(backendEntryPoint_GetImageForProtectionSmarts, JSON.stringify(params), {
        observe: 'response',
      })
      .pipe(
        map((response: any) => response.body.structure),
        catchError(this.verifyStructureError.bind(this)),
      );
  }

  public canonicalSmilesArrayWithRdkit(smiles: string) {
    let smilesList = [];
    let mol_obj: JSMol;
    smiles.split('.').forEach((value: string) => {
      try {
        mol_obj = this.rdkit.get_mol(value);
        smilesList.push(mol_obj.get_smiles());
      } catch (error) {
        smilesList = [];
      } finally {
        if (mol_obj && mol_obj.delete) {
          mol_obj.delete();
        }
      }
    });
    return smilesList;
  }
}
