import { GraphMoleculeNode } from '../services/graph-builder';
import { latexEncoder } from './utils';

/**
 * Check all available shop links and return decision to show or hide "Buy" button
 * @param {any} molecule node object
 * @param {boolean} linksRequired optional - strict checking whether shop links or product IDs really exist in molecule
 */
export function moleculeVendorsExist(
  graphMoleculeNode: GraphMoleculeNode | any,
  linksRequired: boolean = true,
) {
  if (graphMoleculeNode.molecule && graphMoleculeNode.molecule.vendors) {
    if (linksRequired) {
      return graphMoleculeNode.molecule.vendors.some(
        (vendor: any) => !!vendor.data.product_id || !!vendor.data.link,
      );
    }
    return graphMoleculeNode.molecule.vendors.length > 0;
  }
  return false;
}

/**
 * Allows to scan all available shop links and create readable list with Merck vendor only
 * @param {GraphMoleculeNode | any} molecule molecule node object
 */
export function scanSigmaAldrichShopLinks(graphMoleculeNode: GraphMoleculeNode | any) {
  const shopLinks: any[] = [];
  if (moleculeVendorsExist(graphMoleculeNode)) {
    const sigmaAldrichVendor = graphMoleculeNode.molecule.vendors.find(
      (vendor) => vendor.vendor === 'Sigma-Aldrich',
    );

    if (sigmaAldrichVendor) {
      const link = {
        name: '',
        url: '',
        productId: '',
      };

      if (sigmaAldrichVendor.data.link) {
        link.url = sigmaAldrichVendor.data.link;
      } else if (sigmaAldrichVendor.data.product_id && !sigmaAldrichVendor.data.link) {
        link.productId = sigmaAldrichVendor.data.product_id;
      }

      link.name = 'Sigma-Aldrich';
      shopLinks.push(link);
    }
  }
  return shopLinks;
}

/**
 * Allows to scan all available vendor links and create readable list of them
 * @param {GraphMoleculeNode | any} molecule molecule node object
 */
export function scanAllVendorLinks(graphMoleculeNode: GraphMoleculeNode | any) {
  const vendorLinks: any[] = [];
  if (moleculeVendorsExist(graphMoleculeNode)) {
    for (const vendor of graphMoleculeNode.molecule.vendors) {
      const vendorData: any = {
        linkText: undefined,
        linkUrl: undefined,
      };
      if (vendor.data.link) {
        vendorData.linkUrl = vendor.data.link;
        if (vendor.vendor === 'Sigma-Aldrich') {
          vendorData.linkText = 'available at Sigma-Aldrich';
        } else {
          vendorData.linkText = latexEncoder(
            vendor.data.VENDOR_NAME ? vendor.data.VENDOR_NAME : vendor.vendor,
          );
        }
        vendorLinks.push(vendorData);
      }
    }
  }
  return vendorLinks;
}
