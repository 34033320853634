import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AppConstantsService } from '../../../services';
import { FilterData } from '../../new-graph-node-menu/new-graph-node-menu.component';

@Component({
  selector: 'ch-add-filter-popup',
  templateUrl: './add-filter-popup.component.html',
  styleUrls: ['./add-filter-popup.component.scss'],
})
export class AddFilterPopupComponent {
  public lastReaction: boolean = false;
  public filterType: string = this.appConstantsService.exclude;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddFilterPopupComponent>,
    public appConstantsService: AppConstantsService,
  ) {}

  public addFilter() {
    const filterData: FilterData = { lastReaction: this.lastReaction, filterType: this.filterType };
    this.dialogRef.close(filterData);
  }

  public closeDialog() {
    this.dialogRef.close(null);
  }
}
