<div class="ch-intro-tour-dialog">
  <div class="intro-title">
    <strong>Would you like to learn how to set up {{ tourName }} Analysis?</strong>
  </div>

  <img src="../assets/img/auto-retro-img.png" />

  <div class="intro-controls">
    <button mat-raised-button color="primary" (click)="startTutorial()">START TUTORIAL</button>
    <button mat-button class="ch-outline-btn" [buttonOutline]="'primary'" (click)="remindLater()">
      Remind me later</button
    ><br />
    <p>
      <a (click)="hideTutorial()"> No, thanks (Please don’t show this message again) </a>
    </p>
  </div>
</div>
