import { isString } from '../../../components/utils';

/*
 Wraps a "dihedral strain" from the list returned by v1/molecular-strain/report entry point on backend
 */
export class DihedralStrain {
  /* tslint:disable:variable-name */
  public atoms: number[] = [];
  public angle: number = 0;
  public angle_deviation: number = 0;
  public deviation_limit: number = 0;
  public angle_above_threshold: boolean = false;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        this[field] = jsonData[field];
      } else {
        console.error(`Unexpeced field '${field}' in data provided to DihedralStrain:ctor.`);
      }
    }
  }
}
