import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { isNullOrUndefined } from '../../components/utils';
import { AnalysisEntry } from '../analysis';

export interface BreadCrumb {
  label: string;
  url?: string;
  svgIcon?: string;
  icon?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  public breadCrumbSubject: Subject<BreadCrumb[]> = new Subject<BreadCrumb[]>();
  public analysis: BehaviorSubject<AnalysisEntry> = new BehaviorSubject<AnalysisEntry>(null);
  public breadCrumbDiversity: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public createBreadcrumbs(
    route: ActivatedRouteSnapshot,
    breadcrumb: BreadCrumb[] = [],
  ): BreadCrumb[] {
    const children: ActivatedRouteSnapshot[] = route.children[0]?.children;

    if (children.length === 0) {
      return breadcrumb;
    }
    for (const child of children) {
      const breadCrumb: BreadCrumb = child.data[this.ROUTE_DATA_BREADCRUMB];
      if (breadCrumb !== undefined && !isNullOrUndefined(breadCrumb.label)) {
        breadcrumb.push(breadCrumb);
      }
    }
    return breadcrumb;
  }

  public dynamicUpdateBreadCrumbs(dynammicCrumbs: BreadCrumb[]) {
    this.breadCrumbSubject.next(dynammicCrumbs);
  }
}
