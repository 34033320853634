import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '../../components/utils';
import { InfoService } from '../info.service';
import { MatDialog } from '@angular/material/dialog';

/* tslint:disable:max-classes-per-file */

/**
 * A class that constructs itself from paramters of .catch method of Http connection observables.
 * If isRecognized() returns true it is guaranteed to have at least promptMessage set and can be used to
 * display error information with use of ErrorHandlerService.showGlobalError method.
 *
 * For example:
 *
 *  authHttp.post(...)
 *    .
 *    .catch(
 *       function(error) {
 *         let parsedError = new ParsedConnectionError(error);
 *         if (parsedError.isRecognized() && parsedError.isGlobal()) {
 *           this.errorsHandlerService.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
 *         }
 *    })
 *
 * @member status -1 for not recognized error (error passed to constructor looks not related to Http connection)
 *                 otherwise equal to Responce.status. Note that it could be 0 if internet connection isn't available.
 */
export class ParsedConnectionError {
  public promptMessage: string = 'Connection error.';
  public shouldRedirect: boolean = false;
  public detailsMessage: string = null;
  public status: number = -1;
  public bodyJson: any = null;
  public response: HttpErrorResponse = null;
  public connectionLost: boolean = false;
  public error: any;
  public errorsHandlerService: ErrorsHandlerService;

  constructor(error: HttpErrorResponse | any) {
    this.error = error;
    this.tryResponse(error);
    if (!this.isRecognized() && !this.isTokenError()) {
      let errJson: any;
      let errStr: string;
      try {
        errJson = JSON.stringify(error, null, 2);
      } catch (e) {
        errJson = '';
      }
      try {
        errStr = `${error}`;
      } catch (e) {
        errStr = '';
      }
      console.error(
        `\nCONNECTION ERROR\n` + `AS STRING:\n` + `${errStr}\n` + `AS JSON:\n` + `${errJson}\n\n`,
      );
    }
  }

  public isRecognized(): boolean {
    return this.status !== -1;
  }

  public isGlobal(): boolean {
    return this.isRecognized(); // for now all connection errors are global
  }

  public isTokenError(): boolean {
    try {
      return (
        this.error.message.indexOf('JWT') !== -1 && this.error.message.indexOf('expired') !== -1
      );
    } catch (err) {
      return false;
    }
  }

  public tryResponse(error: HttpErrorResponse | any) {
    if (error instanceof HttpErrorResponse) {
      this.status = error.status;
      this.response = error;
      try {
        this.bodyJson = error.error;
      } catch (e) {
        this.bodyJson = null;
      }
      switch (this.status) {
        case 0:
          this.promptMessage = 'Connection not available';
          this.connectionLost = true;
          break;
        case 400:
          if (
            this.bodyJson.non_field_errors &&
            this.bodyJson.non_field_errors &&
            this.bodyJson.non_field_errors[0].indexOf('has expired') !== -1
          ) {
            this.promptMessage = 'Your session has expired. Please login again.';
            this.shouldRedirect = true;
          } else if (
            this.bodyJson.non_field_errors &&
            this.bodyJson.non_field_errors &&
            this.bodyJson.non_field_errors[0].indexOf('be null') !== -1
          ) {
            this.promptMessage = 'Your session has expired. Please login again.';
          } else if (
            this.bodyJson.errors &&
            this.bodyJson.errors.length === 1 &&
            this.bodyJson.errors[0].message
          ) {
            this.promptMessage = this.bodyJson.errors[0].message;
          } else {
            this.promptMessage = 'Invalid input.';
          }
          break;
        case 401:
          this.promptMessage = 'Authorization required';
          this.shouldRedirect = true;
          break;
        case 403:
          if (this.bodyJson.code === 'compliance-statement-not-accepted') {
            this.promptMessage = this.bodyJson.message;
          } else if (
            this.bodyJson.message &&
            this.bodyJson.message.indexOf('old_password is missing') > 0
          ) {
            this.promptMessage = 'Current password is invalid.';
            this.shouldRedirect = true;
          } else if (
            this.bodyJson.message &&
            (this.bodyJson.message.indexOf('user has to change password') > 0 ||
              this.bodyJson.message.indexOf('password has expired and needs to be changed') > 0)
          ) {
            this.promptMessage = 'Password change is required.';
            this.shouldRedirect = true;
          } else if (
            this.bodyJson.message &&
            this.bodyJson.message.indexOf('password has expired') > 0
          ) {
            this.promptMessage = 'Password change is required.';
            this.shouldRedirect = true;
          } else if (
            this.bodyJson.message &&
            this.bodyJson.message.indexOf('does not belong to the user') > 0
          ) {
            this.promptMessage = 'Analysis does not belong to your user.';
          } else if (
            this.bodyJson.message &&
            this.bodyJson.message.indexOf('user has no rights to perform this action') > 0
          ) {
            this.promptMessage = 'Insufficient access to perform this action.';
          } else if (
            this.bodyJson.message &&
            this.bodyJson.message.indexOf('have reached autoretro computations limit') > 0
          ) {
            this.promptMessage =
              'You have reached autoretro computations limit for simultaneous computations.';
          } else if (
            this.bodyJson.message &&
            this.bodyJson.message.indexOf('You are not allowed to run analysis') > 0
          ) {
            this.promptMessage =
              'Your plan status does not allow you to run new analyses. To continue using Synthia™ Lite for new analyses, renew or upgrade your subscription plan';
          }
          break;
        case 404:
          this.promptMessage = 'Resource not found';
          break;
        case 405:
        case 414:
        case 501:
          this.promptMessage = 'Incompatible backend request';
          break;
        case 500:
          this.promptMessage = 'Server error.';
          break;
        case 502:
          this.promptMessage = 'External server error.';
          try {
            const errorObj = JSON.parse(this.bodyJson);
            if (errorObj.errors && errorObj.errors.length === 1 && errorObj.errors[0].message) {
              this.promptMessage = errorObj.errors[0].message;
            }
          } catch (e) {
            return;
          }
          break;
        case 504:
          this.promptMessage = 'Server error, try again later';
          break;
        default:
          this.promptMessage = `Connection error (${this.status})`;
          this.connectionLost = true;
      }
      let statusCode = '';
      let statusText = '';
      let body = '';
      try {
        statusCode += this.response.status;
        statusText += this.response.statusText;
        body += this.response.message;
        /* eslint-disable-next-line no-empty */
      } catch (e) {}
      if (statusCode !== '' || statusText !== '' || body !== '') {
        body = body.replace(/^\s*[\r\n]/gm, '');
        console.error(
          `HTTP CONNECTION ERROR\n` +
            `URL: ${this.response.url ? this.response.url : '--not available--'}\n` +
            `STATUS: (${statusCode}) ${statusText}\n` +
            `BODY (empty lines stripped):\n${body}\n\n`,
        );

        const MAX_BODY = 1500;
        if (body.length >= MAX_BODY) {
          body = body.substr(0, MAX_BODY) + '(...)';
        }
        this.detailsMessage =
          `<h4>Response status:</h4><pre>(${statusCode}) ${statusText}</pre>` +
          `<h4>Response body:</h4><small><code>${body}</code></small>`;
      }
    } else if (this.isTokenError()) {
      this.promptMessage = 'Session has expired.';
      this.shouldRedirect = true;
    }
  }

  public isCustomerPolicyNotAcceptedError(): boolean {
    if (
      this.status === 403 &&
      this.bodyJson &&
      this.bodyJson.code === 'compliance-statement-not-accepted'
    ) {
      this.errorsHandlerService.showGlobalError(this.promptMessage);
      return true;
    }
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ErrorsHandlerService {
  constructor(
    public dialogRef: MatDialog,
    public router: Router,
    private infoService: InfoService,
  ) {}

  /**
   * Display a single line errorPrompt message at the bottom of the screen. Previous error (if any) is hidden.
   * When detailsMessage is not empty the prompt includes also [More] link to show detailsMessage in an popup dialog.
   *
   * @param errorPrompt Terse, single line message to display at the bottom of screen.
   * @param detailsMessage Error details to show in an InfoDialog popup if the user wants to. Supported simple html
   *                       formating as well as a regular text.
   */
  public showGlobalError(errorPrompt?: string, detailsMessage?: string) {
    if (isNullOrUndefined(errorPrompt)) {
      errorPrompt = 'Connection error.';
    }

    this.infoService.showError(errorPrompt, undefined, detailsMessage);
  }

  public logout() {
    if (!this.router.url.startsWith('/sign-in')) {
      this.dialogRef.closeAll();
    }
    this.router.navigate(['sign-out']);
  }

  public goToHomePage() {
    this.router.navigate(['/home']);
  }
}
