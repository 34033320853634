import { isString } from '../../shared/components/utils';
import { PlanItem } from './plan-item';

export enum EMD_PLAN_DIMENSIONS {
  SEARCH_LIMIT_IDENTIFIER = 'PLAN_SEARCH_LIMIT',
  COST_LIMIT_IDENTIFIER = 'PLAN_COST',
}

export class Plan {
  /* tslint:disable:variable-name */
  public plan_id: string = '';
  public tier_id: string = '';
  public name: string = '';
  public items: PlanItem[] = [];
  public searchItem: PlanItem = null;
  public costItem: PlanItem = null;
  public is_paid: boolean = false;
  public plan_descriptions: string[] = [];

  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (field === 'items' && jsonData[field] !== null) {
        try {
          this.items = jsonData['items'].map((item) => new PlanItem(item));
          this.searchItem = this.items.find(
            (item) => item.dimension_identifier === EMD_PLAN_DIMENSIONS.SEARCH_LIMIT_IDENTIFIER,
          );
          this.costItem = this.items.find(
            (item) => item.dimension_identifier === EMD_PLAN_DIMENSIONS.COST_LIMIT_IDENTIFIER,
          );
        } catch (error) {
          console.warn(this.getInvalidFieldTypeError(field, jsonData[field]));
        }
      } else if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];

          if (field === 'tier_id') {
            this.is_paid = !!(jsonData[field] === APP_CONFIG.EMD_DIGITAL_PAID_PLANS_TIER_ID);
          }
        } else {
          console.warn(this.getInvalidFieldTypeError(field, jsonData[field]));
        }
      } else {
        console.warn(`Unexpected type of '${field}' in data provided to Plan:ctor.`);
      }
    }
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to Plan:ctor.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
