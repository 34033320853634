import { ResultsFilterModels } from '../../components/results-filter/results-filter-base/results-filter-models';
import {
  KeywordsFilter,
  StructureSectionCategory,
} from '../../components/results-filter/results-filter-default/results-default-filter.models';
import {
  GraphMoleculeEntry,
  GraphReactionEntry,
  GraphReactionNodeEntry,
  GraphResources,
} from '../analysis/models';

export enum KeywordFilterAttributes {
  Name = 'name',
  Conditions = 'conditions',
  RetroSynthesis = 'retroSynthesis',
}

export function excludeGraphReactionNodeEntryList(
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
  filteredGraphReactionNodeEntryList: GraphReactionNodeEntry[],
  exclude: boolean,
) {
  if (exclude) {
    const excludeList: GraphReactionNodeEntry[] = filteredGraphReactionNodeEntryList;
    filteredGraphReactionNodeEntryList = [];

    for (const excludedReactionNode of excludeList) {
      const expandedParentNodes = graphReactionNodeEntryList.filter(
        (reactionNodes: GraphReactionNodeEntry) => {
          return excludedReactionNode.id === reactionNodes.parent_reaction_node;
        },
      );
      const uniqueNodes = removeDuplicateGraphReactionNodeEntry(excludeList, expandedParentNodes);
      excludeList.push(...uniqueNodes);
    }

    filteredGraphReactionNodeEntryList = graphReactionNodeEntryList.filter(
      (filteredGraphReactionNodeEntry: GraphReactionNodeEntry) => {
        return !excludeList.find((excludeGraphReactionNodeEntry: GraphReactionNodeEntry) => {
          return excludeGraphReactionNodeEntry.id === filteredGraphReactionNodeEntry.id;
        });
      },
    );
  }

  return filteredGraphReactionNodeEntryList;
}

export function getReactionNodesFilteredMolecule(
  filteredGraphApiResult: GraphResources,
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
  molecule: GraphMoleculeEntry,
): GraphReactionNodeEntry[] {
  let graphReactionEntry: GraphReactionEntry[] = [];
  graphReactionEntry = filteredGraphApiResult.reactions.filter((reaction) => {
    return reaction.substrates.filter((sub) => {
      return molecule.id === sub;
    }).length;
  });
  if (!graphReactionEntry.length) {
    graphReactionEntry = filteredGraphApiResult.reactions.filter((reaction) => {
      return reaction.products.filter((product) => {
        return molecule.id === product;
      }).length;
    });
  }
  graphReactionNodeEntryList = getGraphReactionNodes(
    graphReactionNodeEntryList,
    filteredGraphApiResult.reaction_nodes,
    graphReactionEntry,
  );
  return graphReactionNodeEntryList;
}

export function getAllReactionNodesToBuildGraph(
  filteredGraphApiResult: GraphResources,
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
): GraphReactionNodeEntry[] {
  for (const filteredReactionNode of graphReactionNodeEntryList) {
    const expandedParentNodes = filteredGraphApiResult.reaction_nodes.filter(
      (reactionNodes: GraphReactionNodeEntry) => {
        return (
          filteredReactionNode.parent_reaction_node !== null &&
          filteredReactionNode.parent_reaction_node === reactionNodes.id
        );
      },
    );
    const uniqueExpandedParentNodes = removeDuplicateGraphReactionNodeEntry(
      graphReactionNodeEntryList,
      expandedParentNodes,
    );
    graphReactionNodeEntryList.push(...uniqueExpandedParentNodes);
  }
  return graphReactionNodeEntryList;
}

export function isKeywordIncluded(
  keyword: string,
  moleculeOrReaction: GraphMoleculeEntry | GraphReactionEntry,
  attribute: string,
) {
  if (attribute === KeywordFilterAttributes.RetroSynthesis) {
    const reaction: GraphReactionEntry = moleculeOrReaction as GraphReactionEntry;
    if (reaction.rxids && reaction.rxids.length > 0) {
      return reaction.rxids[0].toString().toLowerCase().includes(keyword.toLowerCase());
    } else if (reaction.reaction_id) {
      return reaction.reaction_id.toString().toLowerCase().includes(keyword.toLowerCase());
    }
  } else {
    return (
      moleculeOrReaction[attribute] &&
      moleculeOrReaction[attribute].toLowerCase().includes(keyword.trim().toLowerCase())
    );
  }
}

export function excludeFilteredPathList(
  filteredPathList: any[],
  filteredList: any[],
  exclude: boolean = false,
) {
  if (exclude) {
    const excludeList = filteredList;
    filteredList = [];
    if (filteredPathList) {
      filteredList = filteredPathList.filter((path: any) => {
        return !excludeList.find((excludePath: any) => {
          return excludePath.syntheticPath === path.syntheticPath;
        });
      });
    }
  }
  return filteredList;
}
export function isStructureFilterEmpty(resultFilters: ResultsFilterModels, isDiversity: boolean) {
  const filter = isDiversity ? resultFilters.diversityFilter : resultFilters.structureFilter;
  if (!filter) {
    return true;
  } else {
    const { limitTo, exclude } = filter;
    const isLimitTo = isAllStructureFilterRemoved(limitTo);
    const isExclude = isAllStructureFilterRemoved(exclude);
    return isLimitTo && isExclude;
  }
}

export function isKeywordFilterEmpty(resultFilters: ResultsFilterModels) {
  if (!resultFilters.keywordFilter) {
    return true;
  } else {
    const { limitTo, exclude }: KeywordsFilter = resultFilters.keywordFilter;
    return limitTo && limitTo.keywords.length === 0 && exclude && exclude.keywords.length === 0;
  }
}

export function isReactionFilterEmpty(resultFilters: ResultsFilterModels) {
  if (!resultFilters.reactionFilter) {
    return true;
  } else {
    const { limitTo, exclude } = resultFilters.reactionFilter;
    const { lastDisconnected } = resultFilters.reactionFilter;
    return (
      limitTo.reactions.length === 0 &&
      exclude.reactions.length === 0 &&
      lastDisconnected.limitTo.reactions.length === 0 &&
      lastDisconnected.exclude.reactions.length === 0
    );
  }
}

export function isAllStructureFilterRemoved(section: StructureSectionCategory) {
  return (
    section.exact.criteria.length === 0 &&
    section.structure.criteria.length === 0 &&
    section.similar.criteria.length === 0
  );
}

export function graphKeywordFilterByMoleculeName(
  filteredGraphApiResult: GraphResources,
  keywords: string,
  exclude: boolean = false,
) {
  let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
  const keywordFilteredMolecules = filteredGraphApiResult.molecules.filter(
    (molecule: GraphMoleculeEntry) => {
      return isKeywordIncluded(keywords, molecule, KeywordFilterAttributes.Name);
    },
  );

  for (const molecule of keywordFilteredMolecules) {
    const reactionNodes = getReactionNodesFilteredMolecule(
      filteredGraphApiResult,
      graphReactionNodeEntryList,
      molecule,
    );
    const uniqueNodes = removeDuplicateGraphReactionNodeEntry(
      graphReactionNodeEntryList,
      reactionNodes,
    );
    graphReactionNodeEntryList.push(...uniqueNodes);
  }
  if (graphReactionNodeEntryList) {
    graphReactionNodeEntryList = excludeGraphReactionNodeEntryList(
      filteredGraphApiResult.reaction_nodes,
      graphReactionNodeEntryList,
      exclude,
    );
  }
  graphReactionNodeEntryList = getAllReactionNodesToBuildGraph(
    filteredGraphApiResult,
    graphReactionNodeEntryList,
  );

  return {
    graphReactionNodeEntryList,
    isEmpty: graphReactionNodeEntryList.length === 0,
  };
}

export function graphFilterKeywordByReactionName(
  filteredGraphApiResult: GraphResources,
  keywords: string,
  exclude: boolean = false,
) {
  let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
  const keywordFilteredReactions = filteredGraphApiResult.reactions.filter(
    (reaction: GraphReactionEntry) => {
      return isKeywordIncluded(keywords, reaction, KeywordFilterAttributes.Name);
    },
  );
  graphReactionNodeEntryList = getReactionNodesAfterFiltering(
    graphReactionNodeEntryList,
    filteredGraphApiResult,
    keywordFilteredReactions,
    exclude,
  );
  return {
    graphReactionNodeEntryList,
    isEmpty: graphReactionNodeEntryList.length === 0,
  };
}

export function graphkeywordFilterByReactionRetroSynthesisId(
  filteredGraphApiResult: GraphResources,
  keywords: string,
  exclude: boolean = false,
) {
  let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
  const keywordFilteredReactions = filteredGraphApiResult.reactions.filter(
    (reaction: GraphReactionEntry) => {
      return isKeywordIncluded(keywords, reaction, KeywordFilterAttributes.RetroSynthesis);
    },
  );
  graphReactionNodeEntryList = getReactionNodesAfterFiltering(
    graphReactionNodeEntryList,
    filteredGraphApiResult,
    keywordFilteredReactions,
    exclude,
  );
  return {
    graphReactionNodeEntryList,
    isEmpty: graphReactionNodeEntryList.length === 0,
  };
}

export function graphKeywordFilterByReactionConditions(
  filteredGraphApiResult: GraphResources,
  keywords: string,
  exclude: boolean = false,
) {
  let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
  const keywordFilteredReactions = filteredGraphApiResult.reactions.filter(
    (reaction: GraphReactionEntry) => {
      return isKeywordIncluded(keywords, reaction, KeywordFilterAttributes.Conditions);
    },
  );
  graphReactionNodeEntryList = getReactionNodesAfterFiltering(
    graphReactionNodeEntryList,
    filteredGraphApiResult,
    keywordFilteredReactions,
    exclude,
  );
  return {
    graphReactionNodeEntryList,
    isEmpty: graphReactionNodeEntryList.length === 0,
  };
}

export function graphFilterByReactionSmiles(
  filteredGraphApiResult: GraphResources,
  smiles: string,
  exclude: boolean = false,
) {
  let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
  const keywordFilteredReactions = filteredGraphApiResult.reactions.filter(
    (reaction: GraphReactionEntry) => {
      return reaction.smiles === smiles;
    },
  );
  graphReactionNodeEntryList = getReactionNodesAfterFiltering(
    graphReactionNodeEntryList,
    filteredGraphApiResult,
    keywordFilteredReactions,
    exclude,
  );

  filteredGraphApiResult.reaction_nodes = graphReactionNodeEntryList;
  return filteredGraphApiResult;
}

export function removeDuplicateGraphReactionNodeEntry(
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
  reactionNodeEntries: GraphReactionNodeEntry[],
) {
  return reactionNodeEntries.filter((reactionNode: GraphReactionNodeEntry) => {
    return !graphReactionNodeEntryList.includes(reactionNode);
  });
}

export function sortGraphReactionNodeEntries(graphReactionNodeEntryList: GraphReactionNodeEntry[]) {
  return graphReactionNodeEntryList.sort((a, b) => {
    return a.id - b.id;
  });
}

function getGraphReactionNodes(
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
  apiResultReactionNodes: GraphReactionNodeEntry[],
  filteredReactions: GraphReactionEntry[],
) {
  for (const node of apiResultReactionNodes) {
    for (const react of filteredReactions) {
      if (node.reaction === react.id) {
        const isNodeExist: boolean = isGraphReactionNodeExist(graphReactionNodeEntryList, node);
        if (!isNodeExist) {
          graphReactionNodeEntryList.push(node);
        }
      }
    }
  }
  return graphReactionNodeEntryList;
}

function isGraphReactionNodeExist(
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
  node: GraphReactionNodeEntry,
) {
  return graphReactionNodeEntryList.some((reactionNode: GraphReactionNodeEntry) => {
    return reactionNode.id === node.id;
  });
}

function getReactionNodesAfterFiltering(
  graphReactionNodeEntryList: GraphReactionNodeEntry[],
  filteredGraphApiResult: GraphResources,
  filteredReactions: GraphReactionEntry[],
  exclude: boolean,
) {
  graphReactionNodeEntryList = getGraphReactionNodes(
    graphReactionNodeEntryList,
    filteredGraphApiResult.reaction_nodes,
    filteredReactions,
  );
  if (graphReactionNodeEntryList.length) {
    graphReactionNodeEntryList = excludeGraphReactionNodeEntryList(
      filteredGraphApiResult.reaction_nodes,
      graphReactionNodeEntryList,
      exclude,
    );
  }

  graphReactionNodeEntryList = getAllReactionNodesToBuildGraph(
    filteredGraphApiResult,
    graphReactionNodeEntryList,
  );

  return graphReactionNodeEntryList;
}
