import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ErrorsHandlerService,
  ParsedConnectionError,
} from './errors-handler/errors-handler.service';
import * as _ from 'underscore';

/* tslint:disable:max-classes-per-file */
export interface IBackendPrintToPdfContent {
  text: string;
  images: Array<{ name: string; imageDataUrl: string }>;
}
/* tslint:disable-next-line:variable-name */
export const backendEntryPoint_PrintToPdf = APP_CONFIG.CHEMATICA_PDF_SERVICE_URL + '/from-json';

@Injectable()
export class BackendPrintService {
  public static parseTemplate(template: string, context: object = {}) {
    return _.template(template)(context);
  }

  constructor(
    private http: HttpClient,
    private errorsHandlerService: ErrorsHandlerService,
  ) {}

  public printToPdf(
    content: IBackendPrintToPdfContent,
    handleErrors: boolean = true,
  ): Observable<any> {
    const printRequest = this.http
      .post(backendEntryPoint_PrintToPdf, JSON.stringify(content), {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));

    if (handleErrors) {
      const handlerBuilder = (startTime) => (err) => {
        const parsedError = new ParsedConnectionError(err);

        const handleDefault = () =>
          this.errorsHandlerService.showGlobalError(
            'PDF creation fail. Connection error',
            parsedError.isRecognized()
              ? `<h4>Error:</h4><span>${parsedError.promptMessage}</span><br/>${parsedError.detailsMessage}`
              : `<h4>Error:</h4><br>${err}`,
          );

        switch (parsedError.status) {
          case 400:
            this.errorsHandlerService.showGlobalError('PDF creation fail', 'Malformed content');
            break;

          case 502: {
            const hostTimeoutConfiguration = APP_CONFIG.PDF_EXPORT_SERVER_TIMEOUT;
            const requestTime = Date.now() - startTime;
            if (requestTime > hostTimeoutConfiguration) {
              this.errorsHandlerService.showGlobalError(
                'PDF aborted. Server configuration disallows so long tasks',
                `<p>Your PDF export request was aborted after ${(requestTime * 1e-3).toFixed(
                  0,
                )} seconds.</p>` +
                  (parsedError.isRecognized()
                    ? `<h4>Error:</h4><span>${parsedError.promptMessage}</span><br>${parsedError.detailsMessage}`
                    : `<h4>Error:</h4><br>${err}`),
              );
              break;
            } else {
              handleDefault();
            }
            break;
          }
          default:
            handleDefault();
        }

        return throwError(err);
      };

      return printRequest.pipe(catchError(handlerBuilder(Date.now())));
    } else {
      return printRequest;
    }
  }
}
