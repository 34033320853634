import { isString } from '../../../components/utils';

/*
 A class wrapping a single json from the list returned from get-chemical-entries entry point on backend.
 */
export class ChemicalEntry {
  /* tslint:disable:variable-name */
  public name: string = '';
  public formula: string = '';
  public smiles: string = '';
  public cas: string[] = [];
  public brn: number[] = [];
  public cost: number = 0;
  public in_degree: number = 0;
  public out_degree: number = 0;
  public log_p: number = 0;
  public log_p_cmx: number = 0;
  public mol_weight: number = 0;
  public mushroom: number = 0;
  public real_mass: number = 0;
  public functional_groups: { [group: string]: number } = {};
  public added_to_db_by_chemists: boolean = false;
  public id: string = '';
  public vendors: any[] = [];
  public molfile: string = '';
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else {
          // FIXME: Fallback to default is temporal only -- change to exception throwing when backend data become robust
          console.error(
            `Unexpected type of field '${field}' in data provided to ChemicalEntry:ctor.` +
              `\nUsed default value instead `,
            this[field],
          );
        }
      } else {
        console.error(`Unexpeced field '${field}' in data provided to ChemicalEntry:ctor.`);
      }
    }
  }
}
