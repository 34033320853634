type anyType = number | string | { key: string; value: number | string };

export class FilteredPathList {
  nodes: FilteredPathListNode[];
  edges: Edge[];
  toDisplay: Array<boolean | null>;
  score: number;
  syntheticPath: number;
  target_score: string;
  rank: number;
  isFavorite: boolean;
  is_diversifiable: boolean;
  building_blocks: BuildingBlocks;
  isDisplayed: boolean;
  set_rank: number;
}

export interface BuildingBlocks {}

export interface Edge {
  id: number;
  color: string;
  defaultColor: string;
  source: number;
  target: number;
  size: number;
  type: string;
  reactionNodeId: number;
  markedForPrint: boolean;
  paths: number[];
  colorByNonSelectiveOrDiasteroselectiveOnly: string;
  colorByTunnelsAndStrategy: string;
  edgeLabels: anyType[];
  colorByYear: string;
  repeatedReactionCount: number;
  repeatedReactionColor: string;
}

export interface FilteredPathListNode {
  id: number;
  type: string;
  molecule?: NodeMolecule;
  parentReactionNodeId?: number | null;
  score?: null;
  massPerGramOfTarget?: null;
  childReactionNodeId?: number | string;
  paths?: number[];
  fromLastModifications?: boolean;
  borderSize: number;
  availableAtSA?: boolean;
  defaultColor: string;
  color: string;
  icon?: string;
  primaryProduct?: boolean;
  borderColor?: string;
  target?: boolean;
  isStartingMolecule?: boolean;
  defaultBorderColor?: string;
  borderWidth?: number;
  labelPosition: number;
  labelSize: number;
  labelColor: string;
  iconVerticalPositionOffset?: number;
  iconRelativeSize?: number;
  iconColor?: string;
  size: number;
  markedForPrint: boolean;
  isDiversityLibrary?: boolean;
  isCheckedForDiverisity?: boolean;
  isCheckClicked?: boolean;
  isDiversify: boolean;
  repeatedMoleculeCount?: number;
  repeatedMoleculeColor?: string;
  x: number;
  y: number;
  count?: number;
  chNodeAnalysisContext?: ChNodeAnalysisContext;
  isLastSubstrate?: boolean;
  reaction_node?: ReactionNode;
  reaction?: Reaction;
  colorByYear?: string;
  defaultLabel?: string;
  colorByBase?: string;
  colorByNonSelective?: string;
  colorByTunnelsAndStrategy?: string;
  nodes?: SubstrateNodeElement[];
  targets?: ProductNode[];
  graphId?: number;
  substrateNodes?: SubstrateNodeElement[];
  productNodes?: ProductNode[];
  patents?: anyType[];
}

export interface ChNodeAnalysisContext {
  analysis: Analysis;
  graphId: number;
  mode: Mode;
  algorithm: number;
}

export interface Analysis {
  id: number;
  user: number;
  name: AnalysisName;
  is_favorite: boolean;
  graph_type: GraphType;
  target_name: null;
  created_at: Date;
  updated_at: Date;
  last_fetched: Date;
  num_active_computations: number;
  smiles: Smiles;
  initial_computation: InitialComputation;
  user_settings: UserSettings;
  copied: boolean;
  copied_from: string;
  removed_at: null;
  folder: null;
  batch: null;
  graph_hash: GraphHash;
  shared_at: null;
  shared_with: number | string | { key: string; value: number | string }[];
  owner: null;
  total_pages: number;
  total_no_paths: number;
  rerun: boolean;
  share_count: number;
}

export enum GraphHash {
  The497139E178Bdf0Eefd50975507C730B0 = '497139e178bdf0eefd50975507c730b0',
}

export enum GraphType {
  Standard = 'STANDARD',
}

export interface InitialComputation {
  id: number;
  user: number;
  analysis: number;
  algorithm: Algorithm;
  name: InitialComputationName;
  state: State;
  computation_time: number;
  status_code: string;
  status_message: string;
  country_code: string;
  progress_item: ProgressItem;
  created_at: Date;
  started_at: Date;
  updated_at: Date;
  finished_at: Date;
  parent_molecule: string;
  parent_reaction_node: number;
  permission_token: null;
  input: Input;
  sort: number;
  end_date: Date;
  fixed_duration: number;
  stop_requested: boolean;
  traversed_nodes: number;
  traversed_paths: number;
  warnings: anyType[];
}

export enum Algorithm {
  LibraryMode = 'LIBRARY_MODE',
}

export interface Input {
  smiles: Smiles;
  max_paths: number;
  max_depth: number;
  max_iterations: number;
  main_limit: MainLimit;
  buyable_stop_conditions: BuyableStopConditions;
  known_stop_conditions: KnownStopConditions;
  inventory_stop_conditions: InventoryStopConditions;
  reaction_scoring_formula: null;
  molecule_scoring_formula: null;
  simplified_scoring_function: SimplifiedScoringFunction;
  beam_width: number;
  max_reactions_per_product: number;
  reaction_datasets: ReactionDatasetElement[];
  post_filters: anyType[];
  molfile: string;
  customer_inventories: anyType[];
  developer_mode_parameters: null;
  bases: RulesDatabaseElement[];
}

export enum RulesDatabaseElement {
  Expert = 'expert',
  ExpertEnzymatic = 'expert_enzymatic',
}

export interface BuyableStopConditions {
  max_molecular_mass: number;
  max_price_per_gram: number;
  buyable_catalogs: Catalog[];
  buyable_available_in_large_quantities_threshold: number;
  enforce_carbon_footprint_availability: boolean;
}

export enum Catalog {
  AldrichMarketSelectCatalog = 'Aldrich Market Select catalog',
  BuildingBlockExplorerCatalog = 'Building Block Explorer catalog',
  CoreSigmaAldrichCatalog = 'Core Sigma Aldrich catalog',
}

export interface InventoryStopConditions {
  max_molecular_mass: number;
}

export interface KnownStopConditions {
  max_molecular_mass: number;
  min_popularity: number;
}

export enum MainLimit {
  Iterations = 'iterations',
}

export enum ReactionDatasetElement {
  Enzymatic = 'enzymatic',
  ScienceOfSynthesis = 'science_of_synthesis',
  Spresi = 'spresi',
  Uspto = 'uspto',
}

export interface SimplifiedScoringFunction {
  id: number;
  name: SimplifiedScoringFunctionName;
  shorter_paths: boolean;
  avoided_smiles: null;
  avoided_smarts: null;
  avoided_keywords: null;
  avoided_molecule_sets: anyType[];
  avoided_smarts_sets: anyType[];
  pathway_linearity: PathwayLinearity;
  protecting_groups: ProtectingGroups;
  molecule_scoring_formula: MoleculeScoringFormula;
  reaction_scoring_formula: string;
  group: Group;
  hide_labels: anyType[];
  seek_labels: anyType[];
  is_global_default: boolean;
  is_public: boolean;
  non_selectivity: boolean;
  seek_molecule_sets: anyType[];
  seek_smiles: null;
  seek_smarts: null;
  seek_keywords: string;
  seek_smarts_sets: anyType[];
  filters: anyType[];
  rules_database: RulesDatabaseElement[];
  reaction_datasets: ReactionDatasetElement[];
  enable_commercial_stop_conditions: boolean;
  enable_published_stop_conditions: boolean;
  enable_stockroom_stop_conditions: boolean;
  starting_material_commercial_catalog: Catalog[];
  starting_material_commercial_molecular_mass: number;
  starting_material_commercial_price_per_gram: number;
  starting_material_published_popularity: number;
  starting_material_published_molecular_mass: number;
  starting_material_stockroom_molecular_mass: number;
  starting_material_buyable_available_large_quantities_threshold: null;
  starting_material_enforce_carbon_footprint_availability: null;
  analysis_complexity: AnalysisComplexity;
  custom_analysis_complexity_type: null;
  custom_analysis_complexity_iterations: null;
  custom_analysis_complexity_duration: null;
  paths_returned: number;
  min_search_width: number;
  max_reactions_per_product: number;
  is_default: boolean;
}

export enum AnalysisComplexity {
  Standard = 'standard',
}

export enum Group {
  Main = 'main',
}

export enum MoleculeScoringFormula {
  Smaller3Smaller15 = 'SMALLER^3,SMALLER^1.5',
}

export enum SimplifiedScoringFunctionName {
  General = 'General',
}

export enum PathwayLinearity {
  Combo = 'COMBO',
}

export enum ProtectingGroups {
  Balanced = 'BALANCED',
}

export enum Smiles {
  C1CCCC1C1CCCC1C1Ccccc1 = 'C1=CCC=C1.C1CCCC1.c1ccccc1',
}

export enum InitialComputationName {
  InitialComputation = 'Initial computation',
}

export enum ProgressItem {
  Iterations2001PathsFound3 = 'iterations: 2001 paths found: 3+',
}

export enum State {
  Success = 'SUCCESS',
}

export enum AnalysisName {
  ReSharedPathLibrary = '(re)Shared Path Library',
}

export interface UserSettings {
  mode: Mode;
  layout: Layout;
  filters: Filters;
  modeIsDefault: boolean;
  moleculeLabel: MoleculeLabel;
  nodeTypeIcons: boolean;
  pathDirection: PathDirection;
  reactionLabel: MoleculeLabel;
  layoutIsDefault: boolean;
  moleculeScaling: MoleculeLabel;
  shopAvailability: boolean;
  colorByReactivity: boolean;
  colorReactionArrows: ColorReactionArrows;
  colorReactionDiamonds: ColorReactionDiamonds;
}

export enum ColorReactionArrows {
  All = 'all',
}

export enum ColorReactionDiamonds {
  Base = 'base',
}

export interface Filters {
  conflicts: Conflicts;
  regulated: Conflicts;
  protections: Conflicts;
  pathScoreMax: number;
  pathScoreMin: number;
  selectedOnly: boolean;
  groupByFamily: number;
  maximumReactions: number;
  minimumReactions: number;
  anyMaximumReactions: boolean;
  pathScoreMaxInfinite: boolean;
  analysisResultFilters: AnalysisResultFilters;
}

export interface AnalysisResultFilters {
  priceFilter: null;
  keywordFilter: null;
  reactionFilter: null;
  diversityFilter: null;
  structureFilter: null;
  pathwaySimilarity: number;
  numberOfProtectiveSteps: null;
  numberOfReactionSliderFilter: null;
}

export enum Conflicts {
  Any = 'any',
}

export enum Layout {
  Force = 'force',
}

export enum Mode {
  Path = 'path',
}

export enum MoleculeLabel {
  None = 'none',
}

export enum PathDirection {
  Lr = 'LR',
}

export interface NodeMolecule {
  id: string;
  mushroom: number;
  name: string;
  formula: string;
  smiles: string;
  mapped_smiles: string;
  in_degree: number;
  out_degree: number;
  cas: string[];
  brn: number[];
  cost: number | null;
  log_p: null;
  log_p_cmx: null;
  mol_weight: number;
  real_mass: number | null;
  functional_groups: BuildingBlocks;
  regulatory_databases: anyType[];
  vendors: PurpleVendor[];
  customer_inventory_data: anyType[];
  compound_metadata: { [key: string]: CompoundMetadatum };
  dois: string[];
  patents: string[];
  references: string[];
}

export interface CompoundMetadatum {
  pubchem_cid: number;
  labels: null;
  carbon_footprint: number | null;
}

export interface PurpleVendor {
  vendor: string;
  data: PurpleData;
}

export interface PurpleData {
  mw?: string;
  ppg: string;
  link: string;
  ppkg: string;
  mat_id?: string;
  empform?: string;
  vendor_id?: string;
  vendor_regid?: string;
}

export interface SubstrateNodeElement {
  id: number;
  type: string;
  molecule: NodeMolecule;
  parentReactionNodeId: number;
  paths: number[];
  fromLastModifications: boolean;
  isLastSubstrate?: boolean;
  borderSize: number;
  availableAtSA?: boolean;
  defaultColor: string;
  color: string;
  icon: string;
  primaryProduct: boolean;
  borderColor: string;
  target: boolean;
  isStartingMolecule: boolean;
  defaultBorderColor: string;
  borderWidth: number;
  labelPosition: number;
  labelSize: number;
  labelColor: string;
  iconVerticalPositionOffset: number;
  iconRelativeSize: number;
  iconColor: string;
  size: number;
  markedForPrint: boolean;
  count: number;
  chNodeAnalysisContext: ChNodeAnalysisContext;
  isDiversityLibrary: boolean;
  isCheckedForDiverisity: boolean;
  isCheckClicked: boolean;
  isDiversify: boolean;
  repeatedMoleculeCount: number;
  repeatedMoleculeColor: string;
  x: number;
  y: number;
  childReactionNodeId?: number;
}

export interface ProductNode {
  id: number;
  type: string;
  molecule: ProductNodeMolecule;
  parentReactionNodeId: number | null;
  childReactionNodeId: number | string;
  paths: number[];
  fromLastModifications: boolean;
  borderSize: number;
  defaultColor: string;
  color: string;
  icon: string;
  primaryProduct: boolean;
  borderColor: string;
  target: boolean;
  isStartingMolecule: boolean;
  defaultBorderColor: string;
  borderWidth: number;
  labelPosition: number;
  labelSize: number;
  labelColor: string;
  iconVerticalPositionOffset: number;
  iconRelativeSize: number;
  iconColor: string;
  size: number;
  markedForPrint: boolean;
  count?: number;
  chNodeAnalysisContext?: ChNodeAnalysisContext;
  isDiversityLibrary: boolean;
  isCheckedForDiverisity: boolean;
  isCheckClicked: boolean;
  isDiversify: boolean;
  repeatedMoleculeCount: number;
  repeatedMoleculeColor: string;
  x: number;
  y: number;
  score?: null;
  massPerGramOfTarget?: null;
  availableAtSA?: boolean;
}

export interface ProductNodeMolecule {
  id: string;
  mushroom: number;
  name: string;
  formula: string;
  smiles: string;
  mapped_smiles: string;
  in_degree: number;
  out_degree: number;
  cas: string[];
  brn: number[];
  cost: number | null;
  log_p: null;
  log_p_cmx: null;
  mol_weight: number;
  real_mass: null;
  functional_groups: BuildingBlocks;
  regulatory_databases: anyType[];
  vendors: FluffyVendor[];
  customer_inventory_data: anyType[];
  compound_metadata: CompoundMetadata;
  dois: anyType[];
  patents: anyType[];
  references: string[];
}

export interface CompoundMetadata {
  '12079-65-1'?: CompoundMetadatum;
}

export interface FluffyVendor {
  vendor: string;
  data: FluffyData;
}

export interface FluffyData {
  mw: string;
  ppg: string;
  link: string;
  ppkg: string;
  mat_id: string;
}

export interface Reaction {
  id: string;
  name: string;
  smiles: string;
  yield: null;
  yield_level: string;
  normalized_yield_level: string;
  publication_year: null;
  rxids: number[];
  reaction_id: null;
  reference: string;
  reference_doi: anyType[];
  patent_number: string;
  procedure: string;
  conditions: null;
  reaction_cost: null;
  substrates: string[];
  substrates_with_unprotected_groups: BuildingBlocks;
  products: string[];
  reactions_data: { [key: string]: ReactionsDatum };
  reaction_type: string;
  doi: string;
  similarity_score: number;
}

export interface ReactionsDatum {
  name: string;
  conditions: null;
  reference: string;
  db_id: null;
  mapped_reaction_smiles: string;
  main_retron_disconnections: Array<number[]>;
  reaction_example: null;
  reaction_node: number;
  synthetic_path: number;
  patents: null;
  dois: null;
  reference_doi: anyType[];
}

export interface ReactionNode {
  id: number;
  analysis: number;
  base: ReactionDatasetElement;
  child_molecule_masses_per_gram_of_target: { [key: string]: number };
  child_molecule_scores: BuildingBlocks;
  parent_molecule: string;
  parent_reaction_node: number | null;
  paths: number[];
  reaction: string;
  dynamic_strategies: anyType[];
  strategies: anyType[];
  tunnels: anyType[];
  non_selective: boolean;
  diastereoselective: null;
}
