import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { map, takeUntil, timeout } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class VersionService implements OnDestroy {
  private static checkVersionAndHandle(
    oldTag: string,
    newTag: string,
    oldVersionHandler: (oldTag: string) => void,
    newVersionHandler: (newTag: string) => void,
  ) {
    if (VersionService.isNewVersionAvailable(oldTag, newTag)) {
      newVersionHandler(newTag);
    } else {
      oldVersionHandler(oldTag);
    }
  }

  private static getOldVersionTag(): string {
    return sessionStorage.getItem('eTag');
  }

  private static isNewVersionAvailable(oldTag: string, newTag: string): boolean {
    return !oldTag || oldTag !== newTag;
  }
  private versionDetectionInterval: any;
  private applicationReloadingTimeout: any;
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private zone: NgZone,
  ) {}

  public checkForNewVersion(
    oldVersionHandler: (oldTag: string) => void,
    newVersionHandler: (newTag: string) => void,
  ) {
    this.getVersionTags((oldTag: string, newTag: string) => {
      VersionService.checkVersionAndHandle(oldTag, newTag, oldVersionHandler, newVersionHandler);
    });
  }

  public updateIfRequired() {
    this.checkForNewVersion(
      () => {},
      (newTag) => {
        this.updateTagAndReloadApplication(newTag);
      },
    );
  }

  public startAutoUpdate(autoUpdateCheckPeriod: number) {
    this.getNewVersionTag()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((newTag) => {
        sessionStorage.setItem('eTag', newTag);
        this.versionDetectionInterval = setInterval(() => {
          this.updateIfRequired();
        }, autoUpdateCheckPeriod);
      });
  }

  public stopAutoUpdate() {
    if (this.versionDetectionInterval) {
      clearInterval(this.versionDetectionInterval);
    }
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
  }

  private getNewVersionTag() {
    return this.http.get('/index.html', { observe: 'response', responseType: 'text' }).pipe(
      timeout(5000),
      map((response: HttpResponse<any>) => response.headers.get('etag')),
    );
  }

  private getVersionTags(tagHandler: (oldTag: string, newTag: string) => void) {
    this.http
      .get('/index.html', { observe: 'response', responseType: 'text' })
      .pipe(
        timeout(5000),
        map((response: HttpResponse<any>) => response.headers.get('etag')),
      )
      .subscribe((newTag) => {
        const oldTag = VersionService.getOldVersionTag();
        tagHandler(oldTag, newTag);
      });
  }

  private updateTagAndReloadApplication(newTag) {
    sessionStorage.setItem('eTag', newTag);
    this.startReloading();
  }

  private startReloading() {
    this.stopAutoUpdate();
    // Wait 5 minutes after new version detection to ensure that midend woke up after deploy
    // FIXME: may be removed when the nginx will wait for the midend itself
    this.applicationReloadingTimeout = setTimeout(() => {
      this.reloadApplication();
    }, 300000);
  }

  private reloadApplication() {
    if (this.applicationReloadingTimeout) {
      clearTimeout(this.applicationReloadingTimeout);
    }
    this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }
}
