<div class="prompt">
  <div class="icon icon-position">
    <mat-icon [color]="iconColor" class="info-type-icon">
      {{ icon }}
    </mat-icon>
  </div>
  <div class="prompt-msg message-position">
    {{ message }}
  </div>
  <div class="actions icon-position">
    <button
      *ngIf="buttonText"
      mat-button
      color="accent"
      class="action-button"
      (click)="barAction()"
    >
      {{ buttonText }}
    </button>
    <mat-icon (click)="closeSnackBar()" class="close-icon"> close </mat-icon>
  </div>
</div>
