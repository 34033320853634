import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChemicalEntry } from '../molecule-search';
import { AlgorithmType } from '../analysis/analysis.service';
import { SimpleScoringFunction } from '..';
import { DEFAULT_SIMPLE_SCORING_FUNCTION } from '../parameters-stash/parameters-constants';

export enum TargetViewType {
  DRAW = 'draw',
  NAME = 'name',
  SMILES = 'smiles',
  CAS = 'cas',
  BRN = 'brn',
  ID = 'id',
}

@Injectable({
  providedIn: 'root',
})
export class TargetMoleculeSelectionService {
  public showAnimation: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public defaultScoringFunction: BehaviorSubject<SimpleScoringFunction> =
    new BehaviorSubject<SimpleScoringFunction>(DEFAULT_SIMPLE_SCORING_FUNCTION);
  public editorSmiles: string = '';
  public getAnimationState = this.showAnimation.asObservable();
  public algorithm: AlgorithmType;
  public selectedMolecule: ChemicalEntry;
  public molFile: string = '';
  public analysisRerun: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectMoleculeSubject: BehaviorSubject<ChemicalEntry> = new BehaviorSubject<ChemicalEntry>(
    null,
  );
  public searchMoleculeSubject: BehaviorSubject<ChemicalEntry> = new BehaviorSubject<ChemicalEntry>(
    null,
  );
  public isComingFromSearchTarget: boolean = false;
  public isReloadMoleculeInEditor: boolean = false;
  public isTargetSelectedOnNonBatchType: boolean = false;
  public isMoleculeEdited: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isValidSmilesInput: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isTargetMoleculeLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMoleculeEditorLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMoleculeLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMolFileUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isIframeLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isSearchInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public resetToAutomaticRetrosynthesis: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isAnalysisFromNode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isStartAnalysisInitiated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public onNewAnalysisClick: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  public setValueOfAnimationState(value: boolean) {
    this.showAnimation.next(value);
  }

  public setMolecule(molecule: ChemicalEntry) {
    this.selectMoleculeSubject.next(molecule);
  }

  public setDefaultScoringFunction(defaultSSF: SimpleScoringFunction) {
    this.defaultScoringFunction.next(defaultSSF);
  }

  public getDefaultScoringFunction() {
    return this.defaultScoringFunction.asObservable();
  }
}
