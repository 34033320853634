import { isString } from '../../components/utils';

export class MidendConfiguration {
  /* tslint:disable:variable-name */
  public TRAINING_AND_SUPPORT_URL: string = '';
  public LANDING_PAGE_NAMES_AND_URLS: string = '';
  public DEFAULT_LANDING_PAGE_URL: string = '';
  public ANALYSIS_PARAMETER_SETUP_INFO_URL: string = '';
  public ENABLE_CUSTOMER_POLICY_ACCEPTANCE: boolean = false;
  public ENFORCE_TWO_FACTOR_AUTHENTICATION: boolean = false;
  public EMD_AUTHENTICATION_ENABLED: boolean = false;
  public PENDO_ANALYTICS_ENABLED: boolean = false;
  public ALLOW_EMD_PAID_PLANS: boolean = false;
  public PLAN_EXPIRATION_WARNING_BEFORE_DAYS: number = 0;
  public CONTACT_US_URL: string = '';
  public ENABLE_ECOMM_INTEGRATION: boolean = false;
  public MAX_FILE_SIZE_LIMIT: number = 0;
  public TARGET_LIBRARY_MAXIMUM_SMILES_LIMIT: number = 0;
  public TARGET_LIBRARY_MINIMUM_SMILES_LIMIT: number = 0;
  public MINIMUM_PASSWORD_LENGTH: number = 0;
  public MAXIMUM_MAIL_ATTACHMENTS_SIZE: number = 0;
  public NON_PROD_INSTANCE: boolean = true;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          console.warn(`Unexpected type of '${field}' in data provided to MidendConfiguration.`);
        }
      } else {
        console.warn(`Unexpected field '${field}' in data provided to MidendConfiguration.`);
      }
    }
  }
}
