import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserInventoryComponent } from '../../components/user-inventory/user-inventory.component';
import { ShareDialogComponent } from '../../components/share-dialog/share-dialog.component';
import { AboutApplicationDialogComponent } from '../../components/about-application-dialog/about-application-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private static userInventoryConfig = {
    width: '50%',
    minWidth: '400px',
    height: '225px',
    minHeight: '225px',
  };

  private static AnalysisSharingConfig = {
    width: '50%',
    minWidth: '400px',
    height: '450px',
    minHeight: '225px',
    data: {},
  };

  private static CredentialTokensDialogConfig = {
    width: '50%',
    minWidth: '500px',
    height: '585px',
    minHeight: '225px',
    data: {},
  };

  private static AboutApplicationDialogConfig = {
    width: '614px',
    height: '310px',
  };

  constructor(private matDialog: MatDialog) {}

  public openUserInventory(config?: MatDialogConfig) {
    if (!config) {
      config = DialogService.userInventoryConfig;
    }
    return this.matDialog.open(UserInventoryComponent, config).beforeClosed();
  }

  public openAnalysisSharing(analysisId?: number, config?: MatDialogConfig) {
    if (!config) {
      config = DialogService.AnalysisSharingConfig;
    }
    if (analysisId) {
      config.data = analysisId;
    }
    return this.matDialog.open(ShareDialogComponent, config).beforeClosed();
  }

  public openAboutApplicationDialog() {
    const config = DialogService.AboutApplicationDialogConfig;
    return this.matDialog.open(AboutApplicationDialogComponent, config).beforeClosed();
  }
}
