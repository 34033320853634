import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { autodownload, isString } from '../../shared/components/utils';
import { GraphMoleculeNode, GraphReactionNode } from 'src/app/shared/services/graph-builder';
import { StorageHandlerService } from 'src/app/shared/services/storage-handler.service';
import { ECommUserAuthDialogComponent } from '../components/ecomm-user-auth-dialog/ecomm-user-auth-dialog.component';
import { PricingAvailabilityPopupComponent } from '../components/pricing-availability-popup/pricing-availability-popup.component';
import { ECommUserAuthConfirmDialogConfiguration } from '../models';
import { ActiveCartItem } from '../models/ActiveCartItem';
import { CartItem } from '../models/CartItem';
import { CasNumberSearchResponse } from '../models/CasNumberSearchResponse';
import { CasNumSearchProductParam } from '../models/CasNumSearchProductParam';
import { ProductDetailInfo } from '../models/ProductDetailInfo';
import { ProductInfo } from '../models/ProductInfo';
import { SkuInfo } from '../models/SkuInfo';
import * as csvTemplate from '!raw-loader!./template.csv';
import { BackendPrintService, InfoService } from 'src/app/shared/services';
import { SubstanceSearchResponse } from '../models/SubstanceSearchResponse';
import { Substance } from '../models/Substance';
import { SubstanceProductDetailInfo } from '../models/SubstanceProductDetailInfo';
import { BBESkuInfo } from '../models/BBESkuInfo';

const midendApiVersion: string = 'v1';
export const backendECommAuth: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/ecomm/auth`;
export const casSearchProductAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/ecomm/product_search`;
const ecommAPIUrl: string = APP_CONFIG.ECOMM_API_URL;
export const materialSearchSkuAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/ecomm/product_search`;
export const materialSearchBBESkuAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/ecomm/product_search_bbe`;
export const addItemsWithoutCartIdAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL +
  `/api/${midendApiVersion}/ecomm/carts?operation_type=add_item_without_cartid`;
export const getActiveCartMiniAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL +
  `/api/${midendApiVersion}/ecomm/carts?operation_type=carts_active_mini`;
export const deleteCartItemAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL +
  `/api/${midendApiVersion}/ecomm/carts?operation_type=del_cart_item`;
export const clearCartAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/ecomm/carts?operation_type=del_cart`;
export const checkOutAPIUrl: string =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/ecomm/checkout`;
const eCommProductLinkPrefix: string = APP_CONFIG.ECOMM_PRODUCT_LINK_PREFIX;
export const prepareImageUrl = (partialImageUrl: string, ecommAPIAddress: string): string => {
  if (!partialImageUrl) {
    return '';
  }
  const lastDotIndex = partialImageUrl.toString().lastIndexOf('.');
  return (
    ecommAPIAddress +
    partialImageUrl.toString().substring(0, lastDotIndex) +
    '-medium.' +
    partialImageUrl.toString().substring(lastDotIndex + 1)
  );
};

@Injectable({
  providedIn: 'root',
})
export class ECommHandlerService implements OnDestroy {
  public dialogRef: MatDialogRef<ECommUserAuthDialogComponent> = null;
  public openedCartWidget: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateCart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public activeCartItemListSubject: BehaviorSubject<Array<ActiveCartItem>> = new BehaviorSubject<
    Array<ActiveCartItem>
  >([]);
  public isUserAuthenticatedOnECommSite: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private unsubscribeFromAll: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private pricingAvailabilityDialog: MatDialog,
    private storageService: StorageHandlerService,
    private infoService: InfoService,
  ) {}

  public ngOnDestroy() {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }

  public isECommUserAuth(isNeedToOpenECommAuthConfirmDialog: boolean = true): Observable<boolean> {
    return this.http.get(backendECommAuth, { observe: 'response' }).pipe(
      map((response: any) => {
        if (response?.status === 200 && !response?.body) {
          this.getActiveCartData();
          return true;
        } else {
          if (response?.body) {
            return response.body;
          } else {
            return false;
          }
        }
      }),
      switchMap((getAuthResult) => {
        if (isString(getAuthResult)) {
          if (isNeedToOpenECommAuthConfirmDialog) {
            const dialogConfiguration: ECommUserAuthConfirmDialogConfiguration = {
              url: getAuthResult,
            };
            this.confirmECommAuthorizationDialog(dialogConfiguration);
            this.setECommUserIsValidated('null');
            this.storageService.eCommUserValidated.next('null');
            this.activeCartItemListSubject.next([]);
            return this.storageService.eCommUserValidated.pipe(
              takeUntil(this.unsubscribeFromAll),
              filter((authResult) => authResult === 'true' || authResult === 'false'),
              map((result: string) => {
                if (result === 'true') {
                  this.getActiveCartData();
                  this.isUserAuthenticatedOnECommSite.next(true);
                  return true;
                } else {
                  this.isUserAuthenticatedOnECommSite.next(false);
                  return false;
                }
              }),
            );
          } else {
            return of(false);
          }
        } else {
          return of(getAuthResult);
        }
      }),
      catchError((error) => throwError(error)),
    );
  }

  public setECommUserIsValidated(value: string): void {
    localStorage.setItem('ecomm_user_validated', value);
  }

  public closeECommAuthorizationDialog() {
    if (this.dialogRef !== null) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  public confirmECommAuthorizationDialog(
    dialogConfiguration: ECommUserAuthConfirmDialogConfiguration,
  ): MatDialogRef<ECommUserAuthDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = 200;
    dialogConfig.minWidth = 550;
    dialogConfig.panelClass = 'dialog-container-new';
    dialogConfig.data = { url: dialogConfiguration.url };

    if (this.dialogRef !== null) {
      this.dialogRef = null;
    }

    this.dialogRef = this.dialog.open(ECommUserAuthDialogComponent, dialogConfig);
    return this.dialogRef;
  }

  public postAuthorizedCodeForECommUser(code: string): Observable<any> {
    return this.http.post(backendECommAuth, { authorization_code: code }, { observe: 'response' });
  }

  public openPricingAvailabilityDialog(
    nodeDataList: Array<GraphMoleculeNode | GraphReactionNode>,
  ): MatDialogRef<PricingAvailabilityPopupComponent> {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: nodeDataList,
      panelClass: 'pricing-availability-dialog',
    };
    return this.pricingAvailabilityDialog?.open(PricingAvailabilityPopupComponent, dialogConfig);
  }

  public getCasSearchResponse(casNumberList: string[]): Observable<CasNumberSearchResponse> {
    if (casNumberList.length === 0) {
      return of(new CasNumberSearchResponse());
    }
    const queryParamListObs = this.prepareProductCountForEachCasNumber(casNumberList);
    return queryParamListObs.pipe(
      concatMap((queryParamList) => this.prepareProductInfoList(queryParamList)),
      map((result) => result),
      catchError((error) => throwError(error)),
    );
  }

  public getCasSearchDataForGroupBySubstance(
    casNumberList: string[],
  ): Observable<SubstanceSearchResponse> {
    if (casNumberList.length === 0) {
      return of(new SubstanceSearchResponse());
    }
    const queryParamListObs = this.prepareProductCountForEachCasNumber(casNumberList);
    return queryParamListObs.pipe(
      concatMap((queryParamList) => this.prepareSubstanceInfoList(queryParamList)),
      map((result) => result),
      catchError((error) => throwError(error)),
    );
  }

  public getSkuInfoList(
    productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo,
  ): Observable<Array<SkuInfo> | Array<BBESkuInfo>> {
    if (productDetailInfo.is_sial === '0') {
      return this.getBBESkuInfoList(productDetailInfo);
    }
    return this.getECommSkuInfoList(productDetailInfo);
  }

  public addItemsToCart(items: Array<CartItem>): Observable<boolean> {
    const itemList = items.filter((item) => item.quantity > 0);
    return this.http
      .post(addItemsWithoutCartIdAPIUrl, { activeCartItems: itemList }, { observe: 'response' })
      .pipe(
        map((response: any) => {
          if (response?.status === 201) {
            this.getActiveCartData();
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => throwError(error)),
      );
  }

  public getActiveCartData(): void {
    this.http.get(getActiveCartMiniAPIUrl, { observe: 'response' }).subscribe((response: any) => {
      if (response?.status === 200) {
        const activeCartItemList = new Array<ActiveCartItem>();
        for (const activeCartItem of response?.body?.activeCartItems) {
          activeCartItemList.push(new ActiveCartItem(activeCartItem, ecommAPIUrl));
        }
        this.activeCartItemListSubject.next(activeCartItemList);
      }
    });
  }

  public tryToGetActiveCartDataWithoutBotherUserAuthenticateECommSite(): void {
    this.isECommUserAuth(false)
      .pipe(
        switchMap((authResult: boolean) => {
          if (authResult) {
            return this.http.get(getActiveCartMiniAPIUrl, { observe: 'response' });
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((response: any) => {
        if (response?.status === 200) {
          const activeCartItemList = new Array<ActiveCartItem>();
          for (const activeCartItem of response?.body?.activeCartItems) {
            activeCartItemList.push(new ActiveCartItem(activeCartItem, ecommAPIUrl));
          }
          this.activeCartItemListSubject.next(activeCartItemList);
        }
      });
  }

  public deleteCartItems(removedItemList: ActiveCartItem[]): Observable<boolean> {
    if (removedItemList.length === 0) {
      return of(true);
    }
    const httpCalls = removedItemList.map((activeCartItem) => {
      const params = new HttpParams()
        .set('cart_id', activeCartItem.cart_id)
        .set('cart_item_id', activeCartItem.cart_item_id);
      return this.http.delete(deleteCartItemAPIUrl, { observe: 'response', params: params }).pipe(
        map((response: any) => {
          if (response?.status === 200) {
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => throwError(error)),
      );
    });
    const combinedProductListObs = forkJoin(httpCalls);
    return combinedProductListObs.pipe(
      map((responses: boolean[]) => {
        let removeSuccessCount = 0;
        for (const response of responses) {
          if (response) {
            removeSuccessCount++;
          }
        }
        if (removeSuccessCount === removedItemList.length) {
          const newCartItemList = this.activeCartItemListSubject.value.filter((item) => {
            return !removedItemList.find(
              (removedItem) => removedItem.cart_item_id === item.cart_item_id,
            );
          });
          this.activeCartItemListSubject.next(newCartItemList);
          return true;
        } else {
          this.getActiveCartData();
          return false;
        }
      }),
      catchError((error) => throwError(error)),
    );
  }

  public clearCart(cartId: string): Observable<boolean> {
    const params = new HttpParams().set('cart_id', cartId);
    return this.http.delete(clearCartAPIUrl, { observe: 'response', params: params }).pipe(
      map((response: any) => {
        if (response?.status === 200) {
          this.activeCartItemListSubject.next([]);
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public getCheckOutUrl(): Observable<string> {
    return this.http.get(checkOutAPIUrl, { observe: 'response' }).pipe(
      map((response: any) => {
        if (response?.status === 200) {
          return response?.body;
        } else {
          return null;
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public prepareCompareFunction =
    <T>(key: any, isAsc: boolean = true) =>
    (objectA: T, objectB: T) => {
      const upperA = objectA[key].toUpperCase();
      const upperB = objectB[key].toUpperCase();
      if (upperA < upperB) {
        return isAsc ? -1 : 1;
      }
      if (upperA > upperB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    };

  public getCsvContent(cartItemList: ActiveCartItem[]): Observable<string> {
    const csvTemplateContext = {
      Data: Array<any>(),
    };
    for (const cartItem of cartItemList) {
      csvTemplateContext.Data.push({
        name: this.convertHtmlToPlainText(cartItem.material?.material_name ?? ''),
        desc: this.convertHtmlToPlainText(cartItem.description ?? ''),
        brand: this.convertHtmlToPlainText(cartItem.brand ?? cartItem.material?.brand ?? ''),
        sku: cartItem.material_number ?? '',
        cas: cartItem.material?.cas_number ?? '',
        uom: cartItem.material?.material_uom ?? '',
        qty: cartItem.quantity,
        price: cartItem.price ?? '',
        total: cartItem.price ? cartItem.price * cartItem.quantity : '',
        link:
          cartItem.material?.is_sial === '0'
            ? cartItem.brand && cartItem.product
              ? `${eCommProductLinkPrefix}/US/en/search/${cartItem.product}?focus=buildingblocks&term=${cartItem.product}&type=product`
              : ''
            : cartItem.brand && cartItem.product
              ? `${eCommProductLinkPrefix}/US/en/product/${cartItem.brand}/${cartItem.product}`
              : '',
      });
    }
    csvTemplateContext.Data = csvTemplateContext.Data.map((dataLine) => {
      return {
        ...dataLine,
        name: dataLine.name.split(',').join(';'),
        desc: dataLine.desc.split(',').join(';'),
        brand: dataLine.brand.split(',').join(';'),
      };
    });
    const template = BackendPrintService.parseTemplate(csvTemplate, csvTemplateContext);
    return of(template);
  }

  public getFile(cartItemList: ActiveCartItem[]): void {
    this.getCsvContent(cartItemList)
      .pipe(
        tap(
          (csvContent) => {
            this.infoService.showInfo('Data collected, creating CSV...');
          },
          (error) => {
            this.infoService.showError('Data collecting fail. CSV export aborted');
          },
        ),
      )
      .subscribe(
        (csvDataBlob: any) => {
          const data = [];
          data.push(csvDataBlob);
          const csvDataUrl = window.URL.createObjectURL(new Blob(data));
          const fileName = `Synthia shopping list.csv`;
          autodownload(csvDataUrl, fileName);
          return true;
        },
        (err) => {
          console.log(err);
        },
      );
  }

  public logOutECommUser(): Observable<boolean> {
    return this.http.delete(backendECommAuth, { observe: 'response' }).pipe(
      map((response: any) => {
        if (response?.status === 200) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  private getBBESkuInfoList(
    productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo,
  ): Observable<Array<BBESkuInfo>> {
    const httpParams = new HttpParams().set('term', productDetailInfo.product_key);
    const httpCallObs = this.http.get(materialSearchBBESkuAPIUrl, {
      params: httpParams,
      observe: 'response',
    });
    return httpCallObs.pipe(
      map((data: any) => {
        const result = new Array<BBESkuInfo>();
        for (const paData of data?.body?.material) {
          const newBBESkuInfo = new BBESkuInfo(productDetailInfo, paData);
          const existingSkuInfo = result.find(
            (skuInfo) => skuInfo.country === newBBESkuInfo.country,
          );
          if (existingSkuInfo) {
            existingSkuInfo.skuDetailList.push(...newBBESkuInfo.skuDetailList);
          } else {
            result.push(newBBESkuInfo);
          }
        }

        return result;
      }),
      catchError((error) => of(error)),
    );
  }

  private getECommSkuInfoList(
    productDetailInfo: ProductDetailInfo | SubstanceProductDetailInfo,
  ): Observable<Array<SkuInfo>> {
    const requestBody = [];
    requestBody.push({
      product: productDetailInfo.product_key,
      quantity: 1,
      check_availability: true,
    });

    const httpCallObs = this.http.post(materialSearchSkuAPIUrl, requestBody);
    return httpCallObs.pipe(
      map((data: any) => {
        const result = new Array<SkuInfo>();
        for (const paData of data) {
          const paList = paData.pricing_and_availability_models;
          for (const pa of paList) {
            if (pa.error_msg) {
              continue;
            }
            const newSkuInfo = new SkuInfo(productDetailInfo, pa);
            const existingSkuInfo = result.find(
              (skuInfo) => skuInfo.country === newSkuInfo.country,
            );
            if (existingSkuInfo) {
              existingSkuInfo.skuDetailList.push(...newSkuInfo.skuDetailList);
            } else {
              result.push(newSkuInfo);
            }
          }
        }
        return result;
      }),
      catchError((error) => throwError(error)),
    );
  }

  private prepareProductCountForEachCasNumber(
    casNumberList: string[],
  ): Observable<CasNumSearchProductParam[]> {
    const queryParamList = new Array<CasNumSearchProductParam>();
    for (const casNum of casNumberList) {
      queryParamList.push({
        cas_number: casNum,
        page: 1,
        page_size: 1,
      });
    }
    return this.prepareProductInfoList(queryParamList).pipe(
      map((response) => {
        if (response.apiCallFailedCount > 0 && response.productInfoList.length === 0) {
          throw new Error('cas search api error');
        }
        const result = new Array<CasNumSearchProductParam>();
        for (const pInfo of response.productInfoList) {
          result.push({
            cas_number: pInfo.cas_number,
            page: 1,
            page_size: pInfo.count,
          });
        }
        return result;
      }),
    );
  }

  private prepareProductInfoList(
    casNumSearchProductParamList: CasNumSearchProductParam[],
  ): Observable<CasNumberSearchResponse> {
    if (casNumSearchProductParamList.length === 0) {
      return of(new CasNumberSearchResponse());
    }
    const httpCalls = casNumSearchProductParamList.map((param) =>
      this.getProductInfoForSingleCasNumber(param.cas_number, param.page, param.page_size, false),
    );
    const combinedProductListObs = forkJoin(httpCalls);
    return combinedProductListObs.pipe(
      map((responses) => {
        const result = new CasNumberSearchResponse();
        for (const response of responses) {
          if (response?.status !== 200) {
            result.apiCallFailedCount++;
          } else if (response?.body?.count > 0 && response?.body?.term) {
            result.productInfoList.push(new ProductInfo(response.body, ecommAPIUrl));
          }
        }
        return result;
      }),
    );
  }

  private prepareSubstanceInfoList(
    casNumSearchProductParamList: CasNumSearchProductParam[],
  ): Observable<SubstanceSearchResponse> {
    if (casNumSearchProductParamList.length === 0) {
      return of(new SubstanceSearchResponse());
    }
    const httpCalls = casNumSearchProductParamList.map((param) =>
      this.getProductInfoForSingleCasNumber(param.cas_number, param.page, param.page_size, true),
    );
    const combinedProductListObs = forkJoin(httpCalls);
    return combinedProductListObs.pipe(
      map((responses) => {
        const result = new SubstanceSearchResponse();
        for (const response of responses) {
          if (response?.status !== 200) {
            result.apiCallFailedCount++;
          } else if (response?.body?.count > 0 && response?.body?.term) {
            for (const substanceData of response.body.substance) {
              if (substanceData.cas_number) {
                result.substance.push(new Substance(substanceData, ecommAPIUrl));
              }
            }
          }
        }
        return result;
      }),
    );
  }

  private getProductInfoForSingleCasNumber(
    casNumber: string,
    page: number,
    pageSize: number,
    isGroupBySubstance: boolean,
  ): Observable<HttpResponse<any>> {
    const queryParams = this.prepareGetProductListRequestParams(
      casNumber,
      page,
      pageSize,
      isGroupBySubstance,
    );
    return this.http.get(casSearchProductAPIUrl, { params: queryParams, observe: 'response' }).pipe(
      map((res) => res),
      catchError((error) => of(error)),
    );
  }

  private prepareGetProductListRequestParams(
    casNumber: string,
    page: number,
    pageSize: number,
    isGroupBySubstance: boolean,
  ): HttpParams {
    let httpParams = new HttpParams()
      .set('term', casNumber)
      .set('count', pageSize)
      .set('page', page)
      .set('type', 'cas_number');
    if (isGroupBySubstance) {
      httpParams = httpParams.set('group', 'substance');
    }
    return httpParams;
  }

  private convertHtmlToPlainText(html: string): string {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }
}
