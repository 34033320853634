import { stringFormat, isString } from '../../../components/utils';

export class ThumbsDownAnnotation {
  // public favorite: boolean = false; // deprecated

  /* tslint:disable:variable-name */
  public steric_or_strain_problem: boolean = false;
  public reactivity_conflict: boolean = false;
  public other_problems: boolean = false;
  public not_selective: boolean = false;
  public additional_comment: string = '';
  public problem_explanation: string = '';
  public smarts: string = '';
  /* tslint:enable:variable-name */

  constructor(data?: any) {
    const invalidFieldMessage: string =
      'Unexpected field {0} in data provided to ThumbsDownAnnotation';
    const invalidFieldValueMessage: string = `
      Unexpected value of field '{0}' in data provided to ThumbsDownAnnotation.
      \nUsed default value of '{0}' instead of provided '{1}'.
    `;

    const isValidType = (jsonDataField: any, field: any) => {
      if (typeof jsonDataField === typeof this[field]) {
        return true;
      } else {
        console.error(stringFormat(invalidFieldValueMessage, field, jsonDataField));
        return false;
      }
    };

    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (field === 'created_at' || field === 'updated_at') {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else if (isValidType(jsonData[field], field)) {
          this[field] = jsonData[field];
        }
      } else {
        console.error(stringFormat(invalidFieldMessage, field));
      }
    }
  }
}
