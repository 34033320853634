import { isString } from '../../../components';

export class SimilarReactionsEntry {
  /* tslint:disable:variable-name */
  public smiles: string = '';
  public similarity: number = 0;
  public reaction_id: number = 0;
  public name: string = '';
  public yield_value: string = '';
  public conditions: string = '';
  public reference: string = '';
  public doi: string = '';
  public patent_number: string = '';
  public reaction_type: string = '';
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (this.isValidType(jsonData[field], field)) {
          this[field] = jsonData[field];
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to SimilarReactionsEntry:ctor.`,
        );
      }
    }
  }

  private isValidType(jsonDataField: any, field: any) {
    if (typeof jsonDataField === typeof this[field] || jsonDataField === null) {
      return true;
    } else {
      // FIXME: Fallback to default is temporal only -- change to exception throwing when backend data become robust
      console.error(
        `Unexpected type of field '${field}' in data provided to ComputationEntry:ctor.` +
          `\nUsed default value instead `,
        jsonDataField,
      );
      return false;
    }
  }
}
