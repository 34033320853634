import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { AuthorizationService } from '../../../authorization/authorization.service';
import { UserEntry } from '../../../authorization/models/user-entry';
import { createSHA256Hash, replaceStringCharacters } from '../../components';
import { AppState } from 'src/app/app-state.service';

@Injectable({ providedIn: 'root' })
export class PendoAnalyticsService {
  private isAuthorized: boolean;
  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private loggedInUser: UserEntry;

  constructor(
    private authService: AuthorizationService,
    private appStateService: AppState,
  ) {
    this.authService
      .isAuthorized()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((authorized: boolean) => {
        this.isAuthorized = authorized;
        if (
          !!this.appStateService.midendConfiguration?.value &&
          this.appStateService.midendConfiguration?.value?.PENDO_ANALYTICS_ENABLED
        ) {
          this.identify();
        }
      });

    this.authService.userInformation
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((user: UserEntry) => {
        this.loggedInUser = user;
      });
  }

  public initialize() {
    pendo.initialize({
      visitor: this.getVisitor(),
      account: this.getAccount(),
    });
  }

  public update() {
    pendo.updateOptions({
      visitor: this.getVisitor(),
      account: this.getAccount(),
    });
  }

  public identify() {
    pendo.identify({
      visitor: this.getVisitor(),
      account: this.getAccount(),
    });
  }

  private getVisitor() {
    if (this.isAuthorized && this.loggedInUser.id !== 0) {
      return {
        id: this.createUserIdHash(),
        email: replaceStringCharacters(this.loggedInUser.email, '*', 3),
        full_name: this.getVisitorFullName(),
      };
    } else {
      return { id: this.getInsanceId() + '-anonymous' };
    }
  }

  private createUserIdHash() {
    return createSHA256Hash(this.loggedInUser.email).toString();
  }

  private getVisitorFullName() {
    return `${replaceStringCharacters(
      this.loggedInUser.first_name,
      '*',
      3,
    )} ${replaceStringCharacters(this.loggedInUser.last_name, '*', 3)}`;
  }

  private getAccount() {
    return {
      id: this.getAccountId(),
    };
  }

  private getAccountId() {
    const permissionGroupName: string = this.loggedInUser.customer_account_name;
    return `${this.getInsanceId()}-${permissionGroupName ? permissionGroupName : 'unknown'}`;
  }

  private getInsanceId() {
    return APP_CONFIG.CHEMATICA_API_URL.split('.')[0].split('//')[1];
  }
}
