<div class="ch-scoring-function-filter" [style.height.px]="isTextInput ? 200 : 450">
  <div class="ch-scoring-function-filter-body">
    <p>Use {{ separator }} to separate multiple SMILES, ID or names.</p>
    <mat-form-field
      class="ch-scoring-function-filter-input-form-field"
      (keyup.enter)="applyFilter(true)"
    >
      <textarea
        [(ngModel)]="userInput"
        name="filter-input"
        matInput
        [placeholder]="placeholder"
        type="text"
        class="ch-scoring-function-filter-input"
      >
      </textarea>
    </mat-form-field>
  </div>

  <div class="ch-scoring-function-filter-bottom">
    <div class="ch-scoring-function-filter-buttons">
      <button
        (click)="applyFilter(false)"
        mat-button
        class="ch-outline-btn"
        [buttonOutline]="'primary'"
      >
        CANCEL
      </button>
      <button (click)="applyFilter(true)" mat-raised-button color="primary">OK</button>
    </div>
  </div>
</div>
