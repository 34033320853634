import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AssociatedUser } from './models';
import { ParsedConnectionError, ErrorsHandlerService } from '../errors-handler';
import { AnalysisEntry } from '../analysis';

export const backendEntryPointUsers = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/users/';
export const getShareAnalysisEntryPoint = (analysis_id: number) => {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/analyses/${analysis_id}/share/`;
};
export const getUnshareAnalysisEntryPoint = (analysis_id: number) => {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/analyses/${analysis_id}/unshare/`;
};

@Injectable()
export class SocialService {
  private parsingErrorMessage: string = 'Unexpected format of response';

  constructor(
    private http: HttpClient,
    private errorsHandlerService: ErrorsHandlerService,
  ) {}

  public getAssociatedUsers(searchText: string): Observable<AssociatedUser[]> {
    const queryParams: HttpParams = new HttpParams().set('search', String(searchText));
    return this.http.get(backendEntryPointUsers, { params: queryParams, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.resolveGetAssociatedUsers(response.body)),
      catchError(this.handleSocialServiceErrors.bind(this)),
    );
  }

  public shareAnalysis(analysisId: number, shareWith: string[]): Observable<AnalysisEntry> {
    const options = { emails: shareWith };
    return this.http
      .post(getShareAnalysisEntryPoint(analysisId), options, { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => this.resolveShareAnalysisResponse(response.body)),
        catchError(this.handleSocialServiceErrors.bind(this)),
      );
  }

  public unshareAnalysisAsOwner(
    analysisId: number,
    shareWith: string[],
  ): Observable<AnalysisEntry> {
    const options = { emails: shareWith };
    const body = { body: options };
    return this.http.request('delete', getShareAnalysisEntryPoint(analysisId), body).pipe(
      map((response: any) => this.resolveShareAnalysisResponse(response)),
      catchError(this.handleSocialServiceErrors.bind(this)),
    );
  }

  public unshareAnalysisAsRecipient(analysisId: number) {
    return this.http.post(getUnshareAnalysisEntryPoint(analysisId), { observe: 'response' }).pipe(
      map((response: any) => response),
      catchError(this.handleSocialServiceErrors.bind(this)),
    );
  }

  private resolveShareAnalysisResponse(analysisEntry: any): AnalysisEntry {
    try {
      return new AnalysisEntry(analysisEntry);
    } catch (error) {
      throw new Error(this.parsingErrorMessage);
    }
  }

  private resolveGetAssociatedUsers(userList: any[]): AssociatedUser[] {
    try {
      return userList.map((user) => new AssociatedUser(user));
    } catch (error) {
      throw new Error(this.parsingErrorMessage);
    }
  }

  private handleSocialServiceErrors(error: HttpErrorResponse) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.status === 400 && parsedError.bodyJson.code === 'users-duplication') {
      this.errorsHandlerService.showGlobalError(
        `A selected user has access to this analysis already.`,
      );
      return throwError(parsedError);
    } else if (parsedError.isRecognized() && parsedError.isGlobal()) {
      this.errorsHandlerService.showGlobalError(
        parsedError.promptMessage,
        parsedError.detailsMessage,
      );
      return throwError(parsedError);
    } else {
      return throwError('Could not retrieve list of users. ' + error);
    }
  }
}
