import { ScoringFunction, SimpleScoringFunction } from '../scoring-functions';
import { ScoringFunctionCategoryType } from '../../components/scoring-function-utils';

export type AnalysisComplexityOption = {
  name: string;
  max_iterations: number;
  fixed_duration: number;
  value: string;
  disabledMode: boolean;
};

// Autoretro parameters
// Filters
export const DEFAULT_POWER_SEARCH_MULTIRX: boolean = false;
export const DEFAULT_POWER_SEARCH_FGI: boolean = false;
export const DEFAULT_POWER_SEARCH_TUNNEL: boolean = false;
export const DEFAULT_PROMOTE_ROBUST_REACTIONS_AUTO: boolean = false;
export const DEFAULT_DIFFERENT_BOND_DISCONNECTIONS: boolean = false;
export const DEFAULT_EXCLUDE_GASEOUS_SUBSTRATES: boolean = false;
export const DEFAULT_EXCLUDE_REACTIONS_WITH_METALS: boolean = false;
export const DEFAULT_MULTICUT_AUTO: boolean = false;
export const DEFAULT_CUT_ALL_HETEROCYCLES_AUTO: boolean = false;
export const DEFAULT_STRATEGIES_AUTO: boolean = false;
export const DEFAULT_MARK_NON_SELECTIVE_AUTO: boolean = false;
export const DEFAULT_MACROCYCLES_AUTO: boolean = false;
export const DEFAULT_STRAIN_AUTO: boolean = false;
export const DEFAULT_EXCLUDE_DIASTEREOSELECTIVE_REACTIONS_AUTO: boolean = false;
export const DEFAULT_JSON_DEV_PARAMETERS: string = '';

// Manualretro parameters
export const DEFAULT_RULES_MANUAL: string[] = [];
export const DEFAULT_MULTICUT_MANUAL: boolean = false;
export const DEFAULT_CUT_INTO_SMALLER_FRAGMENTS_MANUAL: boolean = false;
export const DEFAULT_MARK_NON_SELECTIVE_MANUAL: boolean = false;
export const DEFAULT_EXCLUDE_DIASTEREOSELECTIVE_REACTIONS_MANUAL: boolean = false;

// Scoring functions
export const DEFAULT_SPECIALIZED_AUTO: boolean = false;
export const DEFAULT_ARENES_AUTO: boolean = false;
export const DEFAULT_HETEROCYCLES_AUTO: boolean = false;
export const DEFAULT_REACTION_DATASETS: string[] = [];
export const DEFAULT_RULES_AUTO: string[] = [];
export const DEFAULT_BEAM_WIDTH: number = 100;
export const DEFAULT_MAX_REACTIONS_PER_PRODUCT: number = 20;
export const DEFAULT_REACTION_SCORING_FUNCTION: ScoringFunction = new ScoringFunction({
  id: 0,
  owner: 0,
  source_code: '',
  rpn_source_code: '',
  category: ScoringFunctionCategoryType.REACTION_SCORE,
  name: '',
});
export const DEFAULT_CHEMICAL_SCORING_FUNCTION: ScoringFunction = new ScoringFunction({
  id: 0,
  owner: 0,
  source_code: '',
  rpn_source_code: '',
  category: ScoringFunctionCategoryType.MOLECULE_SCORE,
  name: '',
});
export const DEFAULT_SIMPLE_SCORING_FUNCTION: SimpleScoringFunction = new SimpleScoringFunction({
  id: 0,
  owner: 0,
  avoided_smiles: '',
  avoided_molecule_sets: [],
  avoided_keywords: '',
  pathway_linearity: 'COMBO',
  protecting_groups: 'BALANCED',
});

// Stop conditions
export const DEFAULT_MAX_PRICE_PER_GRAM: number = 1000;
export const DEFAULT_BUYABLE_MAX_MOLECULAR_MASS: number = 1000;
export const DEFAULT_INVENTORY_MAX_MOLECULAR_MASS: number = 1000;
export const DEFAULT_KNOWN_MAX_MOLECULAR_MASS: number = 1000;
export const DEFAULT_MIN_POPULARITY: number = 15;
export const DEFAULT_MAX_ITERATIONS: number = 1000;
export const REGULAR_MAX_ITERATIONS: number = 50000;
export const DEVELOPER_MAX_ITERATIONS: number = 1000000;
// FIXME: why it is not used in the new UI?
export const DEVELOPER_AUTOMATIC_RETROSYNTHESIS_TIME_SEC_MAX: number = 604800;
export const DEFAULT_AUTOMATIC_RETROSYNTHESIS_TIME_SEC_MAX: number = 43200;
export const DEVELOPER_AUTOMATIC_RETROSYNTHESIS_MAX_HOURS: number = 167;
export const DEFAULT_AUTOMATIC_RETROSYNTHESIS_MAX_HOURS: number = 23;
export const DEFAULT_PATHS_RETURNED: number = 50;
export const DEFAULT_FOLDER_ID: number = undefined;
export const DEFAULT_ENABLE_BUYABLE_STOP_CONDITIONS: boolean = true;
export const DEFAULT_ENABLE_KNOWN_STOP_CONDITIONS: boolean = true;
export const DEFAULT_ENABLE_INVENTORY_STOP_CONDITIONS: boolean = true;
export const DEFAULT_ANALYSIS_COMPLEXITY_OPTIONS: AnalysisComplexityOption[] = [
  {
    name: 'Quick analysis (stops at 300 iterations)',
    fixed_duration: 3600,
    max_iterations: 300,
    value: 'quick',
    disabledMode: false,
  },
  {
    name: 'Standard analysis (stops at 2000 iterations)',
    fixed_duration: 14400,
    max_iterations: 2000,
    value: 'standard',
    disabledMode: false,
  },
  {
    name: 'Long analysis (stops at 10000 iterations)',
    fixed_duration: 43200,
    max_iterations: 10000,
    value: 'long',
    disabledMode: false,
  },
  {
    name: 'Custom analysis (use your own limit)',
    fixed_duration: 86400,
    max_iterations: 0,
    value: 'custom',
    disabledMode: false,
  },
];
export const DEFAULT_ANALYSIS_COMPLEXITY_OPTION: AnalysisComplexityOption =
  DEFAULT_ANALYSIS_COMPLEXITY_OPTIONS[1];
export const DEFAULT_MAIN_LIMIT: 'iterations' | 'time' = 'iterations';
export const DEFAULT_BATCH_FOLDER_NAME: string = '';

// path ranking
export const DEFAULT_SHORTER_PATHS = false;
export const DEFAULT_PATHWAYS_TYPE = 'COMBO';
export const DEFAULT_PROTECTING_GROUPS = 'BALANCED';
export const DEFAULT_NONSELECTIVE = false;

// exclude and seek
export const DEFAULT_AVOID_SMILES = '';
export const DEFAULT_AVOID_SMARTS = '';
export const DEFAULT_AVOID_KEYWORDS = '';
export const DEFAULT_AVOID_MOLECULE_SETS = [];
export const DEFAULT_AVOID_SMARTS_SETS = [];
export const DEFAULT_SEEK_SMILES = '';
export const DEFAULT_SEEK_SMARTS = '';
export const DEFAULT_SEEK_KEYWORDS = '';
export const DEFAULT_SEEK_MOLECULE_SETS = [];
export const DEFAULT_SEEK_SMARTS_SETS = [];

// Additional strategies values (developer mode)
export const RINGS_STRATEGY = 5;
export const STEREO_STRATEGY = 6;
export const CUT_INTO_SMALLER_STRATEGY = 7;
