import { isString } from '../../../components/utils';

/*
 Wrapps a strain conformer from the list returned by v1/molecular-strain/report entry point on backend
 */
export class StrainConformer {
  /* tslint:disable:variable-name */
  public molfile: string = '';
  public energy: number = 0;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        this[field] = jsonData[field];

        // make molfile address backend related
        if (field === 'molfile' && this[field]) {
          this[field] = APP_CONFIG.CHEMATICA_API_URL + this[field];
        }
      } else {
        console.error(`Unexpeced field '${field}' in data provided to StrainConformer:ctor.`);
      }
    }
  }
}
