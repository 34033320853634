<div *ngIf="showPopup" id="overlay" class="overlay">
  <div class="overlay-viewport" (click)="closePopup()"></div>
  <div class="ch-graph-menu-popup" id="popup" (click)="mouseLeaveIcon()">
    <div class="header-container">
      <div class="main-header">
        <h4 class="section-title">
          Reaction Rules
          <mat-icon
            class="grey-icon rules-icon"
            [matTooltip]="appConstantsService.diversityReactionRulesTooltip"
            matTooltipPosition="above"
            matTooltipClass="ch-tooltip"
          >
            info_outline</mat-icon
          >
        </h4>
      </div>
      <div class="search-bar">
        <mat-form-field class="ch-form-field search-bar-field" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input
            matInput
            type="search"
            autocomplete="off"
            class="search-input"
            [ngClass]="!isSearchValue ? 'search-input-placeholder' : ''"
            placeholder="Search Keyword"
            [formControl]="searchFormControl"
            (keyup)="enterSearch()"
          />
          <button
            class="ch-action-icon-hover clear-button"
            mat-button
            type="button"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearSearchField()"
            *ngIf="isSearchInputValid()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <mat-divider class="div-search-header"></mat-divider>
    <div class="checkbox-section-title">
      <mat-checkbox
        color="primary"
        (change)="onChangeReactionRulesCheckbox($event.checked)"
        [(ngModel)]="selectAllReactionRules"
      >
        Select All
      </mat-checkbox>
    </div>
    <div class="reaction-list">
      <div class="checkbox-section" *ngFor="let rules of filteredRules; let i = index">
        <mat-checkbox
          class="checkbox-reaction"
          color="primary"
          [(ngModel)]="rules.checked"
          [matTooltip]="getTooltip(rules?.name, maxRuleLength)"
          matTooltipPosition="below"
          matTooltipClass="ch-tooltip"
          (change)="updateFilteredRulesCheckbox()"
          >{{ setRuleName(rules.name) }}
        </mat-checkbox>
        <mat-icon (mouseenter)="mouseOverIcon(rules, $event)" class="grey-icon reaction-list-icon">
          info_outline</mat-icon
        >
      </div>
    </div>
  </div>
  <div *ngIf="showReactionPopup" class="ch-icon-popup" id="popupForReaction">
    <div class="header">
      <p class="rule-name" [matTooltip]="getTooltip(rulesForPopup?.name, maxReactionNameLength)">
        {{ getMaxLength(rulesForPopup?.name, maxReactionNameLength) }}
      </p>
    </div>
    <div class="section-typical-condition-container">
      <div class="typical-contidition-container">
        <ng-container>
          <ng-container
            [ngTemplateOutlet]="rowDiv"
            [ngTemplateOutletContext]="nodeDetailsToDisplay"
          >
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="reaction-container">
      <div class="config">
        <ch-molecule-image
          #moleculeImage
          [format]="'svg'"
          [lazyLoad]="true"
          [formula]="rulesForPopup?.reaction_smiles"
          [width]="350"
          [height]="130"
          [formulaType]="reactionNodeType"
        >
        </ch-molecule-image>
      </div>
    </div>
  </div>
</div>

<ng-template
  #rowDiv
  let-title="title"
  let-content="content"
  let-copyType="copyType"
  let-isCopied="isCopiedCondition"
  let-showCasData="showCasData"
>
  <div class="row">
    <div class="row-header" *ngIf="title">{{ title }}:</div>
    <div
      class="row-content"
      [ngClass]="{ 'cas-contents': showCasData }"
      [class.multiple-content]="isArray(content)"
    >
      <div class="content-item" *ngIf="!isArray(content); else arrayContentDiv">
        <span
          class="truncate-on-overflow"
          [matTooltip]="getTooltip(content, maxTypicalConditionLength)"
          matTooltipClass="ch-tooltip full-width-tooltip"
          >{{ getMaxLength(content, maxTypicalConditionLength) }}</span
        >
        <ng-container
          [ngTemplateOutlet]="copyIconDiv"
          [ngTemplateOutletContext]="{
            toBeCopied: content,
            copyType: copyType,
            isCopied: isCopiedCondition,
          }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #copyIconDiv
  let-toBeCopied="toBeCopied"
  let-copyType="copyType"
  let-itemIndex="itemIndex"
  let-isCopied="isCopied"
>
  <mat-icon
    class="ch-icon-hover action-icon grey-icon"
    svgIcon="ch:file-copy-outline"
    (click)="copyToClipboard(toBeCopied)"
    [matTooltip]="appConstantsService.copyContentTooltip"
    matTooltipPosition="below"
    matTooltipClass="ch-tooltip"
    *ngIf="!isCopiedCondition"
  >
  </mat-icon>
  <mat-icon
    class="ch-clr-ok action-icon circle-icon"
    *ngIf="isCopiedCondition"
    [matTooltip]="appConstantsService.copyContentTooltip"
    matTooltipPosition="below"
    matTooltipClass="ch-tooltip"
  >
    check_circle
  </mat-icon>
</ng-template>
