<div class="filter-container" [ngClass]="data?.isDiversity ? 'filter-container-diversity' : ''">
  <div class="header" *ngIf="!data?.isDiversity">
    <button mat-icon-button class="close-btn ch-action-icon-hover" (click)="closeDialog()">
      <mat-icon class="action-icon grey-icon">close</mat-icon>
    </button>
  </div>
  <div class="last-reaction" *ngIf="data?.isLastReaction">
    <mat-checkbox [(ngModel)]="lastReaction" class="last-reaction-checkbox" color="primary"
      >Last reaction only
    </mat-checkbox>
  </div>
  <div
    class="select-filter-container"
    [ngClass]="
      data?.isDiversity ? 'select-filter-container-diversity' : 'select-filter-container-node'
    "
  >
    <mat-form-field class="ch-form-field ch-select-field select-filter" appearance="outline">
      <mat-select [(ngModel)]="filterType">
        <mat-option value="Limit To">Limit To</mat-option>
        <mat-option value="Exclude">Exclude</mat-option>
      </mat-select>
    </mat-form-field>
    <button class="ch-action-icon-hover add-icon-btn" mat-icon-button (click)="addFilter()">
      <mat-icon class="grey-icon">add</mat-icon>
    </button>
  </div>
</div>
