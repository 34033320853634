<div class="container">
  <div class="remove-btn-container">
    <div class="remove-btn" (click)="deleteCartItem(activeCartItem)">
      <mat-spinner
        [strokeWidth]="2"
        [diameter]="20"
        *ngIf="isRemovingCartItem === true"
        class="spinner"
      ></mat-spinner>
      <mat-icon *ngIf="isRemovingCartItem === false">clear</mat-icon>
      Remove
    </div>
  </div>
  <div class="cart-item-content">
    <mat-checkbox
      class="check-box"
      [(ngModel)]="activeCartItem.is_selected"
      (change)="onCheckBoxChange()"
    ></mat-checkbox>
    <div class="image">
      <img
        [src]="activeCartItem.material.image.mediumUrl"
        [ngStyle]="{ visibility: isImageLoading ? 'hidden' : '' }"
        (load)="hideImageLoader($event)"
        (error)="hideImageLoader($event)"
      />
      <div *ngIf="isImageLoading" class="spinner">
        <mat-spinner [strokeWidth]="2" [diameter]="50" color="primary"></mat-spinner>
      </div>
    </div>
    <div class="content">
      <div class="first-row">{{ activeCartItem.material_number }}</div>
      <div class="second-row">
        <span
          [innerHTML]="activeCartItem?.material?.material_name || activeCartItem?.description"
        ></span>
      </div>
      <div class="third-row"><span [innerHTML]="activeCartItem.material.name_suffix"></span></div>
      <div class="fourth-row">
        Qty: <span class="quantity">{{ activeCartItem.quantity }}</span>
      </div>
    </div>
  </div>
</div>
