<div>
  <div class="ch-reaction-list">
    <div
      class="ch-reaction-list-parsing-error"
      [ngClass]="parsingError ? 'parsing-error-display ch-bg-warn' : ''"
    >
      <span class="ch-reaction-list-parsing-error-content">{{ thrownError }}</span>
    </div>
    <h3 class="ch-title" *ngIf="isAllPathwaysLoaded">
      REACTION REPORT <span *ngIf="shortSmiles.length > 0">for {{ shortSmiles }}</span>
    </h3>
    <h3 class="ch-title title-text" *ngIf="!isAllPathwaysLoaded">REACTION REPORT</h3>
    <p
      *ngIf="!reactions || (reactions.length === 0 && !isReactionReportLoading)"
      [style.margin-left.px]="10"
    >
      No reactions in the graph.
    </p>
    <p
      class="title-text"
      *ngIf="reactions.length === 0 && isReactionReportLoading"
      [style.margin-left.px]="10"
    >
      Reaction report loading
    </p>
    <div *ngIf="reactions.length > 0">
      <div class="ch-reaction-list-top">
        <mat-form-field
          class="ch-form-field ch-text-field reaction-search-field"
          appearance="outline"
        >
          <mat-icon matPrefix>search</mat-icon>
          <input
            [formControl]="searchFormControl"
            matInput
            ch-autofocus
            #searchField
            type="search"
            [placeholder]="appConstantsService.homeSearchShortPlaceHolder"
            class="search-input"
          />
        </mat-form-field>
        <div
          class="ch-reaction-list-parameter reaction-list-header"
          *ngIf="sortingFunctions.length > 0"
        >
          <div class="sort-by">Sort by:</div>
          <mat-form-field>
            <mat-select
              [(ngModel)]="sort"
              [disabled]="isSortAndOrderBeDisabled"
              placeholder="Select sorting filter..."
              (selectionChange)="onSortingSelectionChange($event)"
            >
              <mat-option
                *ngFor="let sorting of sortingFunctions"
                value="{{ sorting.source_code }}"
              >
                <ng-container *ngIf="sorting.owner === null"
                  ><strong>{{ sorting.name }}</strong></ng-container
                >
                <ng-container *ngIf="sorting.owner !== null">{{ sorting.name }}</ng-container>
              </mat-option>

              <mat-option
                *ngIf="
                  customSortingFunction &&
                  customSortingFunction.source_code !== '' &&
                  sort !== 'NOSORTING' &&
                  sort !== 'NAME' &&
                  sort !== 'YEAR'
                "
                [value]="customSortingFunction.source_code"
              >
                Custom sort: {{ customSortingFunction.source_code }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            mat-icon-button
            class="ch-action-icon-hover"
            [disabled]="isSortAndOrderBeDisabled"
            (click)="editSortingFunction()"
          >
            <mat-icon class="action-icon grey-icon" svgIcon="ch:edit"> </mat-icon>
          </button>
          <button
            mat-icon-button
            class="ch-action-icon-hover"
            (click)="changeSortDirection()"
            [disabled]="isSortAndOrderBeDisabled"
          >
            <mat-icon
              class="action-icon grey-icon"
              [class.ascending-icon]="direction === 'ascending'"
              [matTooltip]="getSortOrderTooltip()"
              matTooltipPosition="above"
              matTooltipClass="ch-tooltip"
            >
              sort
            </mat-icon>
          </button>
        </div>

        <div class="ch-reaction-list-parameter">
          <mat-radio-group [(ngModel)]="filter" (ngModelChange)="changeFilter($event)">
            <mat-radio-button
              [value]="ReactionListMode.GRAPH"
              class="ch-reaction-list-radio-button"
              color="primary"
              [matTooltip]="appConstantsService.allReactionTooltip"
              matTooltipClass="ch-tooltip"
            >
              All Reactions
            </mat-radio-button>
            <mat-radio-button
              *ngIf="viewMode === ResultsView.GRAPH"
              [disabled]="markedNodesCount === 0"
              [value]="ReactionListMode.SELECTION"
              class="ch-reaction-list-radio-button"
              color="primary"
              [matTooltip]="appConstantsService.highlightedSelectionTooltip"
              matTooltipClass="ch-tooltip"
            >
              Reactions from highlighted selection
            </mat-radio-button>
            <mat-radio-button
              [disabled]="!moleculeReactionsList || moleculeReactionsList.length === 0"
              [value]="ReactionListMode.MOLECULE"
              class="ch-reaction-list-radio-button"
              color="primary"
              [matTooltip]="appConstantsService.specifiedMoleculeTooltip"
              matTooltipClass="ch-tooltip"
            >
              Reactions leading to specified molecule
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <button
        mat-raised-button
        color="primary"
        (click)="deselectAllReactions()"
        [disabled]="!isAnyReactionSelected() || viewMode === ResultsView.PATHWAYS"
        class="ch-reaction-list-deselect-button"
      >
        DESELECT ALL
      </button>

      <div class="ch-reaction-list-reactions">
        <ng-template [ngIf]="filter === ReactionListMode.GRAPH">
          <ng-container
            *ngFor="let reaction of filteredReactions; trackBy: reactionId; let i = index"
          >
            <ch-reaction-details
              #reactionItem
              class="animate-stagger"
              *ngIf="i < reactionLoadingLimiter"
              [reactionFilter]="ReactionListMode.GRAPH"
              [displayMode]="'normal'"
              [reactionIndex]="filteredReactions.length - i"
              [reaction]="reaction"
              [algorithm]="analysis.initial_computation.algorithm"
              [viewMode]="viewMode"
              (onDetails)="onDetails.emit($event)"
              (onMouseDown)="onReactionClick($event, true)"
              (onProtectionInformation)="onProtectionInformation.emit($event)"
              (onSimilarReactions)="onSimilarReactions.emit($event)"
              (onSideReactions)="onSideReactions.emit($event)"
            >
            </ch-reaction-details>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="filter === ReactionListMode.SELECTION">
          <ng-container
            *ngFor="let reaction of filteredSelectionReactions; trackBy: reactionId; let i = index"
          >
            <ch-reaction-details
              #reactionItem
              class="animate-stagger"
              *ngIf="i < reactionLoadingLimiter"
              [displayMode]="'normal'"
              [reactionIndex]="filteredSelectionReactions.length - i"
              [reaction]="reaction"
              [algorithm]="analysis.initial_computation.algorithm"
              [viewMode]="viewMode"
              (onDetails)="onDetails.emit($event)"
              (onMouseDown)="onReactionClick($event)"
              (onProtectionInformation)="onProtectionInformation.emit($event)"
              (onSimilarReactions)="onSimilarReactions.emit($event)"
              (onSideReactions)="onSideReactions.emit($event)"
            >
            </ch-reaction-details>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="filter === ReactionListMode.MOLECULE">
          <ng-container
            *ngFor="
              let reaction of filteredMoleculeReactionsList;
              trackBy: reactionId;
              let i = index
            "
          >
            <ch-reaction-details
              #reactionItem
              class="animate-stagger"
              *ngIf="i < reactionLoadingLimiter"
              [displayMode]="'normal'"
              [reactionIndex]="filteredMoleculeReactionsList.length - i"
              [reaction]="reaction"
              [algorithm]="analysis.initial_computation.algorithm"
              [viewMode]="viewMode"
              (onDetails)="onDetails.emit($event)"
              (onMouseDown)="onReactionClick($event)"
              (onProtectionInformation)="onProtectionInformation.emit($event)"
              (onSimilarReactions)="onSimilarReactions.emit($event)"
              (onSideReactions)="onSideReactions.emit($event)"
            >
            </ch-reaction-details>
          </ng-container>
        </ng-template>
        <ng-template [ngIf]="isSearchReactionResult">
          <div class="ch-reaction-list-empty ch-clr-grey">
            <p>No reactions found for applied filter</p>
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="more-loader"
      ch-viewport-visibility
      *ngIf="isLoadMoreReactions"
      (visibilityChange)="loadMoreReactions($event.value)"
    >
      <mat-spinner
        [diameter]="30"
        [strokeWidth]="2"
        *ngIf="loadingMoreReactionsTimeout"
      ></mat-spinner>
      <p>Loading more reactions...</p>
    </div>
  </div>
</div>
