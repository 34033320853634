import { Injectable } from '@angular/core';

export enum AnalysisAlgorithm {
  MANUAL_RETROSYNTHESIS = 'MANUAL_RETROSYNTHESIS',
  AUTOMATIC_RETROSYNTHESIS = 'AUTOMATIC_RETROSYNTHESIS',
  LIBRARY_MODE = 'LIBRARY_MODE',
  MINIMAL_COST = 'MINIMAL_COST',
  GREEDY_POPULARITY = 'GREEDY_POPULARITY',
  COST_AND_POPULARITY = 'COST_AND_POPULARITY',
  REVERSE_REACTION_NETWORK = 'REVERSE_REACTION_NETWORK',
  REACTION_NETWORK = 'REACTION_NETWORK',
  DIVERSITY_LIBRARY = 'DIVERSITY_LIBRARY',
}

export enum ComputationState {
  PENDING = 'PENDING',
  IN_QUEUE = 'IN_QUEUE',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INTERRUPTED_BY_USER = 'INTERRUPTED_BY_USER',
  STOPPED = 'STOPPED',
}

export enum AnalysisAlgorithmName {
  MANUAL_RETROSYNTHESIS = 'Manual Retrosynthesis',
  AUTOMATIC_RETROSYNTHESIS = 'Automatic Retrosynthesis',
  AUTOMATIC_BATCH_RETROSYNTHESIS = 'Automatic Batch Retrosynthesis',
  DIVERSITY_LIBRARY = 'Diversity Library',
  NOC = 'Network of Organic Chemistry',
}

@Injectable({
  providedIn: 'root',
})
export class AppConstantsService {
  // Common Constants
  public readonly ABOUT_CHEMATICA_LINK: string =
    'https://www.sigmaaldrich.com/services/software-and-digital-platforms/synthia-retrosynthesis-software?utm_source=redirect&utm_medium=promotional&utm_campaign=synthia';
  public readonly ABOUT_CHEMATICA_LINK_CHINA: string =
    'https://www.sigmaaldrich.cn/CN/zh/services/software-and-digital-platforms/synthia-retrosynthesis-software';
  public readonly SynthiaLitePrivacyLink: string =
    'https://www.sigmaaldrich.com/US/en/life-science/legal/privacy-statement';

  // Graph View Mark Node
  public readonly markedForPrint: string = 'markedForPrint';

  // Target Selection Tab bar title
  public readonly moleculeNameWrapLength: number = 30;

  // New analysis steps Tab bar title
  public readonly analysisTypeTabTitle: string = '1. ANALYSIS TYPE';
  public readonly targetTabTitle: string = '2. TARGET';
  public readonly batchTargetTabTitle: string = '2. CREATE BATCH';

  // Target Selection Tab bar title
  public readonly targetDrawLabel: string = 'DRAW';
  public readonly targetNameLabel: string = 'NAME';
  public readonly targetSmilesLabel: string = 'SMILES';
  public readonly targetCASLabel: string = 'CAS';

  // Placeholders
  public readonly targetSearchPlaceHolder: string = 'Search for a known molecule in our database';
  public readonly targetMoleculeSearchPlaceHolder: string =
    'Search for known molecules in the database by entering valid name, SMILES or CAS here';
  public readonly drawSearchPlaceHolder: string = 'Enter valid SMILES here';
  public readonly homeSearchShortPlaceHolder: string = 'Search';
  public readonly homeSearchFullPlaceHolder: string =
    'Search by keywords (SMILES, analysis name, etc.)';
  public readonly moleculeSearchPlaceHolder: string = 'Filter';
  public readonly noneApplied: string = 'Custom (not saved)';
  public readonly savedConfigurationTitle: string = 'My Saved Configurations';
  public readonly mainConfigurationTitle: string = 'Main';
  public readonly specializedConfigurationTitle: string = 'Specialized';

  // Tool tip messages
  public readonly drawTabTooltip: string = 'Draw target molecule in the chemical editor';
  public readonly nameTabTooltip: string = this.getSearchForMoleculeTooltipText('name');
  public readonly smilesTabTooltip: string = this.getSearchForMoleculeTooltipText('SMILES');
  public readonly casTabTooltip: string = this.getSearchForMoleculeTooltipText('CAS number');
  public readonly presetInfoTooltip: string = 'Draw target molecule in the chemical editor';
  public readonly similaritySearchTooltip: string =
    'Search for similar commercially available/known molecules';
  public readonly editConfigureInfoTooltip: string =
    'View and edit analysis configuration settings';
  public readonly popularityIconTooltip: string =
    "Minimum number of times a compound has been described in a reaction, as found in SYNTHIA™'s database of Known Compounds (range 0-9999)";
  public readonly shopIconTooltip: string =
    'Click on a molecule structure to display Vendor information';

  // Node details dialog
  public readonly moleculeDBOcccurence: string = 'Database occurrences';
  public readonly moleculeCASTitle: string = 'CAS';
  public readonly moleculeBRNTitle: string = 'BRN';
  public readonly moleculeSmiles: string = 'SMILES';
  public readonly unknownReactionTitle: string = 'Reaction details';
  public readonly unknownMoleculeTitle: string = 'Unknown Molecule';
  public readonly nocReactionConditionTitle: string = 'Reaction Conditions';
  public readonly retrosynthesisReactionConditionTitle: string = 'Typical Conditions';
  public readonly retrosynthesisIdTitle: string = 'Retrosynthesis ID';
  public readonly yieldTitle: string = 'Yield';
  public readonly reactionTypeTitle: string = 'Reaction Type';
  public readonly publicatonYearTitle: string = 'Publication year';
  public readonly regulatoryDatabasesTitle: string = 'Regulatory databases';
  public readonly costTitle: string = 'Cost';
  public readonly logPTitle: string = 'LogP';
  public readonly molecularWeightTitle: string = 'Molecular Weight';
  public readonly molecularFormulaTitle: string = 'Molecular Formula';
  public readonly moleculeNameTitle: string = 'Molecule Name';
  public readonly nocReactionTitle: string = 'Published Reaction';
  public readonly noFunctionalGroupSmartsInfo: string = 'No SMARTS available';
  public readonly noProtectionGroupInfo: string = 'No information available';
  public readonly moleculePopularityTitle: string = 'Popularity';

  // Path download menu tooltip
  public readonly downloadTooltip: string = 'Download';

  // Path toggle tooltip
  public readonly toggleTooltip: string =
    'Switch between the Building Blocks view and Pathway view';

  // Path View Options tooltip
  public readonly pathViewOptionsTooltip: string =
    'Pathway Options are available only when at least one path is switched to the Path view';
  public readonly globalPathwaysDisabledTooltip: string =
    'Global path view will be available after all results are loaded';

  // Configure Selection Tab bar title
  public readonly configureStartingLabel: string = 'STARTING MATERIALS';
  public readonly configurePreferenceLabel: string = 'ANALYSIS PREFERENCES';
  public readonly configureExcludeLabel: string = 'EXCLUDE & SEEK';
  public readonly configureExpertLabel: string = 'EXPERT MODE';

  // Configure - Path Analysis Tab ToolTip Messages
  public readonly shorterPathTooltip: string =
    'When this modifier is selected, shorter paths with potentially more expensive ' +
    'starting materials will be favored over those with low-cost starting ' +
    'materials, essentially eliminating the bias toward low-cost starting materials';
  public readonly stratsTooltip: string =
    'Enables the algorithm to plan strategic two-step reaction sequences, ' +
    'for which the product of the first step is more complex than the product of the second step, ' +
    'but overall makes significant advances towards the scaffold of your desired molecule';
  public readonly multicutTooltip: string =
    'Allows multiple identical retrosynthetic disconnections in one step. ' +
    'Select when a molecule presents symmetry';
  public readonly cutAllHeterocyclesTooltip: string =
    'Forces the algorithm to build all heterocycles from scratch, ' +
    'even if the heterocyclic building block is commercially available';
  public readonly heteroAutomaticTooltip: string =
    'About 30,000 transformation for aromatic heterocycles';
  public readonly arenesAutomaticTooltip: string = 'Over 10,000 transformation of arenes';
  public readonly specialAutomaticTooltip: string =
    'About 120,000 rarely used or specialized reaction types';
  public readonly heteroManualTooltip: string = 'About 30,000 heterocycle chemistries';
  public readonly arenesManualTooltip: string = 'About 20,000 specialized arenes chemistries';
  public readonly specialManualTooltip: string = 'About 119,000 non-stereoselective chemistries';
  public readonly expertTooltip: string = 'Reaction rule coded by expert chemists';
  public readonly spresiTooltip: string = 'Reaction from commercial database SPRESI';
  public readonly usptoTooltip: string = 'Reaction from free database USPTO';
  public readonly enzymesCatalyzedTooltip: string = 'Reaction from free database USPTO';
  public readonly comingSoon: string = 'Coming Soon';
  public readonly diastereoselectiveTooltip: string =
    'Eliminate any proposed reactions that are only ' +
    'diastereoselective, not enantioselective (i.e. Diels-Alder reaction)';
  public readonly strainTooltip: string =
    'Filters out highly strained intermediates. Use only if unwanted strained ' +
    'intermediates are seen in the results set';
  public readonly macroTooltip: string =
    'Prevents ring contractions from macrocycles. Use only if unwanted macrocyclic ' +
    'intermediates are seen in the results set';
  public readonly ruleDatabaseNote: string =
    '<strong>Warning:</strong> While these rules may provide useful results, ' +
    'they are <strong>NOT expert-coded.</strong>';
  public readonly nonselectiveTooltip: string =
    'Show pathways with non-selective reactions only if' +
    ' they help finding pathways or keeping them short. May be used for natural products and uncommon rings systems';
  public readonly powerSearchTooltipSimultaneous: string =
    'Algorithm designed to combine reactions that can be performed under ' +
    'the same reaction conditions';
  public readonly powerSearchTooltipFunctionalGroup: string =
    'Two or three step reaction sequences that stabilize ' +
    'highly reactive functional groups along synthetic pathways. ' +
    'Generated based on the analysis of several thousand reports of classical total syntheses';
  public readonly powerSearchTooltipDynamic: string =
    'Allow SYNTHIA^TM to strategize over multiple steps. ' +
    "Use with 'Simultaneous protection/deprotection steps' and " +
    "'Promote function group interconversions' for more complex molecules";
  public readonly promoteRobustReactionsTooltip: string =
    'Promote reactions that are considered robust. Less popular reactions still can be proposed' +
    ' if they offer molecule simplifications that could not be achieved otherwise';
  public readonly showDifferentBondConnectionsTooltip: string =
    'Only show pathways with unique bond disconnections. This may limit the total number of results';
  public readonly excludeGaseousSubstratesTooltip: string =
    'Exclude all reactions that utilize gaseous substrates';
  public readonly excludeReactionsWithMetalsTooltip: string =
    'Exclude all reactions that utilize metals as catalysts/reagents e.g., Pd(II)';
  public readonly savedConfigurationInfoTooltip: string =
    'Saved Configurations are sets of saved analysis settings. They allow you to adjust priorities ' +
    'for your results, such as cost, popularity, or length of analysis. To customize your settings, ' +
    'click the ';
  public readonly configurePresetInfoTooltip: string = 'Learn more about configuration settings';
  public readonly enableDoneInfoTooltip: string =
    'To run a search, please select at least one Database';
  public readonly invalidDoneButtonInfoTooltip: string =
    'SYNTHIA identified an invalid structure in Exclude/Seek. Please correct your structure before closing the settings';
  // Search Molecule Error Message & Info Message
  public readonly emptyMoleculeMessage: string = 'No molecule matches criteria';
  public readonly inValidSmilesMesage: string = 'Invalid SMILES';

  // Manual Configure options
  public readonly manualMulticutTooltip: string = 'Select when a molecule presents symmetry';
  public readonly manualStartsTooltip: string =
    'Eliminates precursors with higher molecular weights than the target compound.' +
    ' Could be used to avoid ring contraction.' +
    ' Use carefully as some halide or boronic acid precursors could be heavier than the target compound';
  public readonly manualDiastereoSelectiveTooltip: string =
    'Eliminate any proposed reactions that are diastereoselective,' +
    ' but not enantioselective (i.e. catalytic hydrogenation of double bonds or cyclopropanations)';

  // NOC configuration
  public readonly stepbyStepNote: string =
    ' See all the reactions for which the target compound has been published,' +
    'either as a product or as a reactant. Results can only be viewed in graph view';
  public readonly costAndPopularityNote: string =
    'Get a customized pathway based on cost of synthesis and popularity of published compounds. ' +
    'Results can be viewed in both path view and graph view.';
  public readonly reactionCountToolTip: string =
    'Maximum number of reactions returned. Range 1 - 200';
  public readonly popularStartingMaterialToolTip: string =
    'Favor reactions with starting materials that have a higher popularity';
  public readonly reactionRingToolTip: string = 'Favor reactions that form rings';
  public readonly reactionStereocentersToolTip: string =
    'Favor reactions that create stereocenters';
  public readonly smallerPrecursorsToolTip: string =
    'Eliminate precursors with higher molecular weights than the target compound.' +
    ' Could be used to avoid ring contraction.' +
    ' Use carefully as some halide or boronic acid precursors could be heavier than the target compound';
  public readonly nonSelectiveReactionToolTip: string =
    'Include reactions that have been reported to generate side products';
  public readonly avoidedChemicalsToolTip: string =
    'Molecule sets displayed in bold font are global, others are created by you';
  public readonly startingMaterialsPublishedToolTip: string =
    'Stop the search when published compounds that have been published in the literature' +
    ' as many or more times than the specified limit are found. Range 0 - 9999';
  public readonly startingMaterialsCommercialToolTip: string =
    'Stop the search when commercial compounds with a price lower than the specified limit are found. Range 0 - 9999';
  public readonly numberOfSyntheticStepsToolTip: string =
    'Set the limit for the maximum number of synthetic steps allowed in the returned pathway. Range 1 -50';
  public readonly startingMaterialsToolTip: string = '';
  public readonly popularityToolTip: string =
    'Count how many times a compound was published as the product of a reaction,' +
    ' or as a reactant in a reaction, or as both ';
  public readonly relativeLaborCostToolTip: string =
    'Assign a value in $ per hour for your labor cost. Range 0.001 - 1000' +
    '\n' +
    ' 0.001 $/h: Cost of chemicals is the major consideration' +
    '\n' +
    ' 1000 $/h: Labor cost is very high, cost of chemicals is insignificant';
  public readonly preferredStartingMaterialsToolTip: string =
    ' Favor either commercial or published compounds as starting materials. Range 0 - 1' +
    '\n' +
    ' 0: mostly published compounds' +
    '\n' +
    ' 1: mostly commercial compounds';

  public readonly NOCSelectTargetDisabled = `This molecule is not available in Network of Organic Chemistry analysis
  because there are no documented reactions leading to it`;
  public readonly pathViewTooltip =
    'Display Pathway view instead of Building Blocks view by default for all analyses';
  public readonly pathScoreTooltip = 'Display the path score for all analyses';
  public readonly similarityScoreTooltip = 'Display the similarity score for all targets';

  // Stop Conditions Tab Tooltips
  public readonly startingMaterialsTooltip: string =
    'Continue the search until starting materials that satisfy the set limits are found';
  public readonly startingMaterialsEmissionAllMoleculesTooltip: string = `Limit starting materials to compounds with available Spend-Based Emission Factors information according to the Environmentally Extended Input-Output (EEIO) model (reported in kgCO\u2082eq/EUR)`;
  public readonly startingMaterialsAvailableQuantityTooltip: string =
    'Select desired minimum available quantity to consider commercial compounds as starting materials';
  public readonly maxPricePerGramTooltip: string =
    'Maximum price in dollar per gram allowed for commercially available compounds (range 0-9999)';
  public readonly diversityMenuCommercial =
    'Commercially Available Starting Materials. Select parameters below';
  public readonly diversityMenuPublished = 'Published Starting Materials. Select parameters below';
  public readonly diversityMenuLibrarySize = 'Library size';
  public readonly diversityInfoTooltip = 'Select one or more framed molecules to start a library';
  public readonly diversityMenuMaximize = 'Click to see more settings';
  public readonly diversityMenuMinimize = 'Minimize';
  public readonly diversityExpand = 'Open Expanded Pathway view';
  public readonly diversityFavorite = 'Favorite this molecule';
  public readonly diversityUnFavorite = 'Unfavorite this molecule';
  public readonly diversityFilter = 'Filter this molecule';
  public readonly diversityAddtoClipboard = 'Add molecule to clipboard';
  public readonly diversityAddtoClipboardDisabled =
    'This molecule was already added to the clipboard';
  public readonly diversityAddtoShop =
    'View shopping options for starting materials used in a synthetic pathway';
  public readonly diversityCopySmile = 'Copy molecule SMILES';
  public readonly diversityExport = 'Export molecule or pathway';
  public readonly buyableMaxMolecularMasstip: string =
    'Maximum MW allowed for commercially available compounds (range 0-9999)';
  public readonly minPopularityTooltip: string =
    'Minimum number of times a compound has been described in a reaction, ' +
    "as found in SYNTHIA™'s database of Known Compounds (range 0-9999)";
  public readonly knownMaxMolecularMassTooltip: string =
    "Maximum MW allowed for compounds from SYNTHIA™'s database of Known Compounds (range 0-9999)";
  public readonly inventoryMaxMolecularMassTooltip: string =
    'Maximum MW allowed for compounds available in customer inventory (range 0-9999)';
  public readonly maxPathsTooltip: string =
    'Maximum number of pathways returned in results (range 1-50)';
  public readonly beamSearchTooltip: string =
    'Adjust how much diversity is required for pathways returned in results';
  public readonly beamWidthTooltip: string =
    'Number of potential pathways kept in consideration after each iteration (range 0-1000)';
  public readonly maxReactionsPerProductTooltip: string =
    'Upper limit of distinct reactions that may be selected for further expansion ' +
    'for the target molecule and each intermediate (range 0-500)';
  public readonly analysisComplexityTooltip: string =
    'Limit the time spent looking for pathways based on number of iterations. ' +
    'Each iteration explores one expansion of synthetic possibilities';
  public readonly quickAnalysisOptionTooltip: string =
    'Use to quickly check results for a simple molecule';
  public readonly standardAnalysisOptionTooltip: string =
    'Use for simple molecules with two or fewer chiral centers';
  public readonly longAnalysisOptionTooltip: string =
    'Use for complex molecules with several chiral centers and/or fused rings, natural products, ' +
    'or if beam search is expanded or power search is selected';
  public readonly customAnalysisOptionTooltip: string = '';
  public readonly resetTooltip: string = 'Undo changes';
  public readonly renameTooltip: string = 'Rename this configuration';
  public readonly deleteTooltip: string = 'Delete this configuration';
  public readonly defaultTooltip: string = 'Set this configuration as default';
  public readonly notSavedConfiguration: string = 'Unsaved configurations cannot be set as default';
  public readonly saveTooltip: string = 'Save this configuration';
  public readonly renameDisableTooltip: string = 'System configurations cannot be renamed';
  public readonly disableSystemConfigurationTooltip: string = 'No saved configuration selected';
  public readonly saveEnableTooltip: string = 'Save this configuration';
  public readonly saveEnableWithModificationTooltip: string = 'Save current configuration';
  public readonly deleteDisableTooltip: string = 'System configurations cannot be deleted';
  public readonly resetDisableTooltip: string = 'No changes have been made';
  public readonly cancelTooltip: string = 'Cancel';
  public readonly iterationsTooltip: string =
    'Number of iterations explored before the analysis stops. ' +
    'Higher number of iterations may increase the chances of finding more diverse or better pathways ' +
    '(range 1-<maxIterations>)';
  public readonly timerTooltip: string =
    'Time allotted to analysis before it stops. Format is hh:mm (range 00:01-<maxTimeLimit>)';
  public readonly analysisRenameTooltip: string = 'Rename Analysis';
  public readonly parameterSetDuplicateNameErrorMessage: string = `'<setName>' cannot be modified. Please select a different name.`;
  public readonly parameterSetReplaceMessage: string = `'<setName>' already exists. Do you want to replace it?`;
  public readonly alreadySetAsDefaultTooltip: string = 'Default configuration';

  // Exclude and Seek Tab Tooltip
  public readonly excludeLabel = 'EXCLUDE';
  public readonly seekLabel = 'SEEK';
  public readonly excludeSmartLabel = 'excludeSmart';
  public readonly seekSmartLabel = 'seekSmart';
  public readonly excludeStructureTooltip: string =
    'Eliminate pathways containing listed molecules';
  public readonly excludeSubstructureTooltip: string =
    'Eliminate pathways containing listed substructures';
  public readonly excludeKeywordTooltip: string =
    'Eliminate pathways containing the listed keywords as part of the reaction name or conditions';
  public readonly excludeBuildingBlockTooltip: string =
    'Eliminate pathways containing chemicals from the EPA Safer Chemical Ingredients List that may have potential toxicological effects';
  public readonly benzeneRingTooltip: string = 'Open Molecule Editor';
  public readonly excludeEpaLabels: string[] = ['EPAYellow', 'EPAGrey'];
  public readonly excludePredefinedMoleculeTooltip: string =
    'Eliminate pathways containing any molecules from the selected list(s)';
  public readonly excludePredefinedSubstructreTooltip: string =
    'Eliminate pathways containing any substructure from the selected list(s)';
  public readonly seekStructureTooltip: string =
    'Find only pathways containing at least one of the listed molecules';
  public readonly seekSubstructureTooltip: string =
    'Find only pathways containing at least one of the listed substructures';
  public readonly seekKeywordTooltip: string =
    'Find only pathways containing at least one of the listed keywords as part of the reaction name or conditions';
  public readonly seekBuildingBlockTooltip: string =
    'Find pathways containing low concern chemicals from the EPA Safer Chemical Ingredients List';
  public readonly seekEpaLabels: string[] = ['EPAGreen', 'EPAHalfGreen'];
  public readonly seekPredefinedMoleculeTooltip: string =
    'Find pathways containing one or more of the molecules from the selected list(s)';
  public readonly seekPredefinedSubstructreTooltip: string =
    'Find pathways containing one or more of the substructures from the selected list(s)';
  public readonly archivedVersionTooltip: string =
    'SYNTHIA™ makes a copy of each set you use. If a ' +
    'molecule set changes, its previous versions will still be available ' +
    '(as archived sets) for analyses that formerly used them';

  // Validation error messages
  public readonly emptyMoleculeErrorMessage: string = 'Please choose a molecule';
  public readonly invalidSmilesErorrMessage: string = 'Some SMILES you entered may be invalid';
  public readonly invalidBatchNameErorrMessage: string =
    'You must specify name of the folder' + ' in which this batch will be created.';
  public readonly invalidJsonField: string = 'JSON is invalid. Please enter a valid JSON';
  public readonly invalidTokenErrorMessage: string =
    'Your <algorithmName> license is inactive. ' +
    'If you have purchased the license, try refreshing the page.';
  public readonly scoringFnWithSameNameError: string =
    "<type> scoring function with name ' <newName> ' cannot be modified. Please select a different name.";
  public readonly invalidErrorCodeMessage: string =
    'Provided function has invalid name or parameters';

  // Analysis related messages
  public readonly deleteAnalysisConfimationMessage: string =
    'Are you sure you want to delete analysis <analysisName> permanently?';
  public readonly deleteMoleculeConfimationMessage: string =
    'Are you sure you want to delete molecule';
  public readonly deletedAnalysisInfoMessage: string = 'analysis has been deleted';
  public readonly restoredAnalysisInfoMessage: string = 'analysis has been restored';
  public readonly inventoryAffectingDialogTitle: string = 'Inventory Upload in Progress';
  public readonly inventoryAffectingDialogMessage: string =
    'A Custom Inventory is being processed. It may affect results of your analysis. Do you want to continue?';
  public readonly targetModifiedOnRerunDialogTitle: string = 'Target Modified';
  public readonly targetModifiedOnRerunDialogMessage: string =
    'Please note that modifying the structure of your target may use your last available molecule';

  // Tag related messages
  public readonly deleteTagConfimationMessage: string =
    'Are you sure you want to delete #<tag> tag from analysis <analysisName>?';
  public readonly analysisTagsUpdateMessage: string = 'Analysis tags saved successfully';
  public readonly analysisTagsDeleteMessage: string = 'Tag removed from analysis';
  public readonly editUserTagConfimationMessage: string =
    'Are you sure you want to rename the tag #<oldTag> to #<newTag>?';
  public readonly deleteUserTagConfimationMessage: string =
    'Are you sure you want to permanently delete #<tag> tag?' +
    '<br>Please note that the tag will be permanently deleted from' +
    '<br>&emsp;&emsp;&emsp;&emsp;&emsp; all analyses in which it was used.';
  public readonly userTagsUpdateMessage: string = 'Tags updated successfully';
  // Folder related messages
  public readonly duplicateFolderNameErrorMessage: string = 'Folder with this name already exists';
  // Home left bar folders
  public readonly filterFolders = [
    { name: 'Favorites', icon: 'star_outline', url: 'favorites', disabledInSynthiaLite: false },
    { name: 'Pending', icon: 'hourglass_empty', url: 'pending', disabledInSynthiaLite: false },
    {
      name: 'Automatic Retrosynthesis',
      url: 'autoretro',
      svgIcon: 'ch:automatic-retro-home',
      disabledInSynthiaLite: false,
    },
    {
      name: 'Manual Retrosynthesis',
      url: 'manual',
      svgIcon: 'ch:manual-retro-home',
      disabledInSynthiaLite: false,
    },
  ];
  public readonly recycleBinFolder = {
    name: 'Recycle bin',
    svgIcon: 'ch:delete',
    url: 'recycle_bin',
    disabledInSynthiaLite: true,
  };
  public readonly defaultFolder = {
    name: 'Default',
    svgIcon: 'ch:folder',
  };
  public readonly batchFolder = {
    name: 'Batch',
    svgIcon: 'ch:batch-folder',
  };
  public readonly sharedWithMeFolder = {
    name: 'Shared With Me',
    url: 'shared-with-me',
    disabledInSynthiaLite: true,
  };
  public readonly single_analysis_title = 'Single Analyses';
  public readonly batch_analysis_title = 'Batch Analyses';
  public readonly analysisFolders = [
    { name: this.single_analysis_title, url: 'all' },
    { name: this.batch_analysis_title, url: 'batch' },
  ];

  // Home tooltip
  public readonly diversityView: string = 'Create Diversity Library';
  public readonly diversityViewDisabled: string =
    'Diversity Library is not available for this pathway';
  public readonly diversityTextHeader: string = 'Exciting news ahead!';
  public readonly diversityText: string =
    "We're thrilled to announce the upcoming launch of our next release featuring a brand-new addition called the";
  public readonly diversityTextEnd: string = 'Diversity Library';
  public readonly infoTooltip: string = 'Analysis Parameters';
  public readonly favoriteTooltip: string = 'Favorite this analysis';
  public readonly unfavoriteTooltip: string = 'Unfavorite this analysis';
  public readonly favoritePathTooltip: string = 'Favorite this pathway';
  public readonly unfavoritePathTooltip: string = 'Unfavorite this pathway';
  public readonly rerunTooltip: string = 'Rerun analysis';
  public readonly rerunDisableTooltip: string = 'Cannot rerun shared analysis';
  public readonly invalidNocTokenErrorMessage: string =
    'Your NOC license is inactive. If you have purchased the license, try refreshing the page';
  public readonly shareTooltip: string = 'Share analysis';
  public readonly rerunCustomMoleculeTooltip: string =
    'Analyses using custom molecule/structure lists cannot be rerun';
  public readonly diversityRerunDisabledTooltip: string =
    'Rerunning is not available for this analysis as the parent analysis was removed';
  public readonly shareDisableTooltip: string = 'Cannot share shared analysis';
  public readonly editTooltip: string = 'Edit name';
  public readonly editDisableTooltip: string = 'Cannot rename shared analysis';
  public readonly commentTooltip: string = 'Comment';
  public readonly deleteAnalysisTooltip: string = 'Delete analysis';
  public readonly deleteAnalysisDisableTooltip: string =
    'Cannot delete analysis while it has computations in progress';
  public readonly copyTooltip: string = 'Copy SMILES';
  public readonly copyDisabledTooltip: string =
    'Copy action is not available for Diversity Library';
  public readonly copySmartsTooltip: string = 'Copy SMARTS';
  public readonly shareDeletedTooltip: string = 'Cannot share analysis from recycle bin';
  public readonly favoriteDeletedTooltip: string = 'Cannot favorite deleted analysis';
  public readonly restoreAnalysisTooltip: string = 'Restore this analysis';
  public readonly openInNewTabTooltip: string = 'Open analysis in new tab';
  public readonly noResultsTooltip: string = 'This analysis yielded no results';
  public readonly errorTooltip: string = 'Finished with errors';
  public readonly inventoryWarningTooltip: string =
    'Results of this analysis may have been affected by inventory upload';
  public readonly userInterruptTooltip: string = 'Interrupted by user';
  public readonly analysisSuccessTooltip: string = 'Successfully completed';
  public readonly computationStoppedTooltip: string = 'Stopped automatically';
  public readonly rerunDisableTooltipNoc: string = 'Running NOC Analysis is no longer supported';
  public readonly addAnalysisTagTooltip: string = 'Click to add new tag';
  public readonly overallTagTooltip: string = 'Click to manage tags';
  // Analysis Parameters
  public readonly savedConfigurationNameInfo: string =
    'This is the name of the saved configuration at the time the analysis was initially run.' +
    ' It does not reflect any subsequent changes to the saved configuration.' +
    ' If this analysis is rerun, it will use the same parameters as the initial run';
  public readonly savedConfigurationNameLabel: string = 'Saved Configuration';
  public readonly helpCenter: string = 'Learning Center and Help Form';
  public readonly shopingList: string = 'Shopping List';
  public readonly newsTutorials: string = 'News and Tutorials';
  public readonly userMenu: string = 'User Menu';

  // Home error messages
  public readonly pageNotFound: string = 'Page not found';
  public readonly noFavorites: string = 'You have not favorited any analyses yet';
  public readonly noPending: string = 'You have no pending analyses at the moment';
  public readonly noRecent: string = 'You have no recently open analyses at the moment';
  public readonly noSearchResult: string =
    'Your search criteria did not match any analysis' + ' Please try different criteria';
  public readonly noAnalysis: string =
    'Select New Analysis in the upper left hand corner to create your first results';
  public readonly noAnalysisForSharedFolder: string = 'No analysis was shared';
  public readonly noAnalysisInRecycleBinFolder: string = `No analyses Analyses in the recycle bin are permanently deleted after 30 days`;
  public readonly rerunError: string =
    'An error occurred when attempting to rerun analysis. Try again.';
  public readonly analysisMoveToBatchError: string =
    'Analysis <analysisName> cannot be moved in to a batch folder';
  public readonly analysisMoveToSameFolderError: string =
    'Analysis <analysisName> is already in the selected folder';
  public readonly batchAnalysisMoveError: string =
    'Batch analysis can not be moved between folders';
  public readonly deleteRunningAnalysisError: string = 'Running analysis can not be deleted';
  public readonly somethingWentWrongError: string = 'Sorry! Something went wrong, please try again';
  public readonly tagListingError: string = 'An error occurred while listing the tags';

  // Analysis error messages
  public readonly stopComputationPermissionErrorMessage: string =
    "You don't have permission to stop this computation.";
  public readonly computationErrorTitleMessage: string = 'Computation finished with errors';
  public readonly computationErrorDetailMessage: string = `<h4>The results may be not available because the computation finished with errors</h4>
  <h4>Error code:</h4><pre> <statusCode> </pre>
  <h4>Error message:</h4><code> <statusMessage> </code>`;
  public readonly analysisGenericWarningMessageTitle: string =
    'Computation may have encountered problems';
  public readonly searchMoleculeErrorMessage: string = 'Enter at least 3 characters';

  // Batch tooltip
  public readonly editMoleculeTooltip: string = 'Edit molecule';
  public readonly deleteMoleculeTooltip: string = 'Delete molecule';
  public readonly addMoleculeInfoMessage: string = 'All molecule(s) have been added';
  public readonly allowSingleFileError: string = 'Only one file at a time is supported';
  public readonly incorrectFileTypeError: string = 'Incorrect file type provided';
  public readonly incorrectFileTypeInListError: string =
    'SYNTHIA™ does not support this file format. Only files with the following extensions are accepted: .mol, .sdf, .smiles';
  public readonly maxFileSizeLimitError: string =
    'The size of the file has exceeded the limit of <value> MB';

  // Folder Batch Name
  public readonly maxDefaultItemNumber: number = 100;

  // Batch Error Message
  public readonly batchMoleculeDeleteError: string =
    'Please add at least one target molecule before starting an analysis';
  public readonly emptyBatchNameError: string =
    'You must specify name of the folder in which this batch will be created';
  public readonly complexityOptionError: string =
    'The maximum number of iterations allowed for a batch analysis is 2000.' +
    '<br>Please modify your settings accordingly';
  public readonly batchAnalysisReactionCenterWarning: string =
    'Reaction center/not center information is not saved for batch analyses. ' +
    'This feature is only available for single Automatic Retrosynthesis analyses';

  // Overlay Action Message
  public readonly homeOverlayActionMessage: string = 'New Website Version Available';
  public readonly homeOverlayActionButtonTitle: string = 'RELOAD PAGE';

  // Molecule and SMARTS sets
  public readonly undoTooltip: string = 'Undo last action';
  public readonly redoTooltip: string = 'Redo last action';
  public readonly filterTooltip: string = 'Filter';
  public readonly expandTooltip: string = 'Expand';
  public readonly collapseTooltip: string = 'Collapse';
  public readonly selectTooltip: string = 'Select All';
  public readonly unselectTooltip: string = 'Unselect All';
  public readonly deleteMoleculesTooltip: string = 'Delete';
  public readonly defaultMessage: string =
    'Select an existing list <br>or select <strong>New Molecule List</strong>';
  public readonly defaultSmartsetsMessage: string =
    'Select an existing list <br>' + 'or select <strong>New Substructure List</strong>';
  public readonly noMoleculeMessage: string =
    'This list is empty.<br>Molecules haven’t been added yet';
  public readonly moleculeSetDeleteError: string =
    'Unable to delete molecule set. Permission denied';
  public readonly smartsSetDeleteError: string = 'Unable to delete SMARTS set. Permission denied';
  public readonly moleculeSetNotExistError: string = `Molecule set '<itemName> does not exist'`;
  public readonly smartsSetNotExistError: string = `SMARTS set '<itemName> does not exist'`;
  public readonly setNotExistError: string = `Set '<itemName> does not exist'`;
  public readonly confirmDeleteSet: string = `Are you sure you want to delete set '<itemName>'? This action cannot be undone.`;
  public readonly confirmOverwriteSet: string = ` set with this name already exists. Do you want to overwrite set '<itemName>'?`;
  public readonly addMoleculeMessage: string = ' molecule has been added to the list';
  public readonly addMoleculesMessage: string = ' molecules have been added to the list';
  public readonly updateMoleculeMessage: string = 'Molecule has been been updated';
  public readonly moleculesAlreadyAddedMessage: string =
    'Selected molecules are present in the list';
  public readonly defaultSearchResultButtonTitle: string = 'Select Target';
  public readonly listSearchResultButtonTitle: string = 'Select Molecule';
  public readonly expandCardTooltip: string = 'Expand card';
  public readonly collapseCardTooltip: string = 'Collapse card';
  public readonly moleculeDeleteMessage: string = `Molecule '<name>' has been deleted`;
  public readonly moleculesDeleteMessage: string = `'<count>' molecules have been deleted`;
  public readonly noMyMoleculeListMessage: string = 'Your molecule list is empty';
  public readonly setDuplicateNameErrorMessage: string = `'<itemName>' cannot be modified. Please select a different name.`;
  public readonly uploadButtonTooltip: string =
    'You can upload your list of molecules as Molfile/SDF. Please note that the uploaded molecules should not be in the salt form, otherwise they will get uploaded as separate molecules';
  public readonly EmptyMolfileMessage: string = 'Provided file does not contain valid molecules';

  // Analysis result view
  public readonly resultErrorPrompt: string = 'Incorrect page address';
  public readonly resultErrorMessage: string = 'Wrong or missing ID of analysis';
  public readonly waitMessage: string = 'Please wait...';
  public readonly emptyAnalysisMessage: string = 'Empty analysis';
  public readonly layoutLoadingMessage: string = 'Layout is loading';
  public readonly settingsLoadingMessage: string = 'Loading settings...';
  public readonly graphLoadingMessage: string = 'Loading graph...';
  public readonly noResultMessage: string = 'There are no results for this analysis';
  public readonly noResultWithSetParameterMessage: string =
    'There are no results of the computation with set parameters';
  public readonly graphBuildingErrorMessage: string = 'Graph building error has occurred';
  public readonly analysisNotFound: string = 'Analysis not found';
  public readonly analysisNotExistMessage: string =
    'Analysis does not exist or not shared with you';
  public readonly notAvailable: string = 'not available';
  public readonly sortAscendingTooltip: string = 'Ascending Sort';
  public readonly sortDescendingTooltip: string = 'Descending Sort';
  public readonly processingNewResultsTooltip: string = 'Processing of new results';
  public readonly newResultsFoundTooltip: string =
    'New results were found. Click to refresh the path list';
  public readonly analysisActionMenuTooltip: string = 'Manage analysis actions, print pdf';
  public readonly editAnalysisTooltip: string = 'Edit analysis name';

  public computationStatusMap = {
    PENDING: 'Computation waiting in the queue...',
    IN_QUEUE: 'Computation awaiting resources to start...',
    IN_PROGRESS: 'Computation in progress. Please wait...',
    ERROR: undefined,
  };
  public readonly legendMenuTooltip: string = 'Legend';
  public readonly filterMenuTooltip: string = 'Filter';
  public readonly settingsMenuTooltip: string = 'Settings';
  public readonly clipboardMenuTooltip: string = 'Clipboard';
  public readonly computationsMenuTooltip: string = 'Computations';
  public readonly reactionReportMenuTooltip: string = 'Reaction report';
  public readonly moleculeListMenuTooltip: string = 'Molecule report';
  public readonly pathwaysViewTooltip: string = 'Pathways view';
  public readonly disabledPathwaysViewTooltip: string =
    'Pathways view is not available for Step-by-step retrosynthesis algorithm';
  public readonly graphViewTooltip: string = 'Graph view';
  public readonly backendConnectionTimeoutMessage: string =
    'Backend connection timeout. Trying again';
  public readonly parametersTooltip: string = 'Analysis parameters';
  public readonly copyParametersTooltip: string = 'Copy analysis parameters';
  public readonly analysisStartErrorMessage: string =
    'An error occurred when attempting to start analysis. Try again';
  public readonly expandGraphErrorMessage: string = 'Unable to start graph expanding computation';
  public readonly computationStartedMessage: string = 'Computation started';
  public readonly expandGraphPermissionErrorMessage: string =
    'Expanding the graph of a shared analysis is not possible';
  public readonly zoomInTooltip: string = 'Zoom in';
  public readonly zoomOutTooltip: string = 'Zoom out';
  public readonly resetZoomTooltip: string = 'Reset view';
  public readonly analysisRenamedInfo: string = 'Analysis renamed';
  public readonly referenceTitle: string = 'Reference';
  public readonly illustrativeReferenceTitle: string = 'Illustrative References';

  // PATH EXPORT TO PDF
  public readonly exportToPDFDisableTooltip: string = 'Select pathways to export first';
  public readonly exportToPDFMaxReactionsConfigName: string = 'PDF_EXPORT_MAX_REACTIONS';
  public readonly documentSizeLimitMessage: string = 'Document size limit exceeded';
  public readonly emptySelectedPathMessage: string =
    'No reactions to print. Select some paths and try again.';
  public readonly exportToPDFMaxLimitInfo: string =
    'Maximum number of reactions exceeded.Filter out some paths to reduce the number of reactions and try again.';
  public readonly exportTooltip: string = 'Export selected pathways as pdf';

  // Icons
  public readonly icons = {
    mat_icon: {
      ACCOUNT_CIRCLE: 'account_circle',
    },
  };

  // Analysis path view
  public readonly sharedAnalysisPathFavoriteTooltip: string =
    'Cannot favorite path of a shared analysis';
  public readonly expandedPathTooltip: string =
    'Show expanded pathway view with structures, reaction names, and reaction conditions';

  // Path view node popup
  public readonly closePopupTooltip: string = 'Close popup';
  public readonly nonSelectiveWarningTooltip: string = 'Non-selective';
  public readonly diastereoselectiveWarningTooltip: string = 'Diastereoselective only';
  public readonly noProtectionOnNodeMessage: string =
    'No functional groups need protection in this <nodeType>';
  public readonly nodePopupAppearDelay: number = 300;
  public readonly nodePopupDismissDelay: number = 3000;
  public readonly selectPathTooltip: string =
    'Select a pathway starting from this reaction node and ending on target molecule';
  public readonly deselectPathTooltip: string =
    'Deselect this reaction node and all preceding selected nodes';
  public readonly clearGraphSelectionTooltip: string = 'Deselect all pathways';
  public readonly protectionNeededTooltip: string = 'Protection needed';

  // Clipboard view
  public readonly deleteClipboardItem: string = 'Remove this molecule node from the clipboard';
  public readonly moleculeAppearances: string = 'Appearances in the paths';
  public readonly reactionAppearancesTooltip: string = 'Appearances in the results';

  // Analysis Results Filter
  public readonly filterInfoTooltip: string = `
  You may apply filters from many places within analysis results,
  such as a specific molecule or reaction.
  Other filters are available in this panel.
  As you apply or remove filters, results will be re-rendered.
  To see all results, remove all filters using the “Reset filters” button`;
  public readonly limitTo: string = 'Limit to';
  public readonly exclude: string = 'Exclude';
  public readonly filterMoleculeEditorTitle = {
    EditorTitle: 'Filter By Structure',
    EditorTitleForEdit: 'Edit Filter',
    ButtonTitle: 'Apply Filter',
    EditButtonTitle: 'Save',
  };

  public readonly filterSection = {
    limitTo: 'limitTo',
    exclude: 'exclude',
  };

  public readonly filteredPathsEmpty: string = 'No pathways found for applied filters';
  public readonly pathwaySimilarityInfo: string = `Adjust the ‘Pathway Similarity’ slider to filter out pathways with similar reactions.
  Setting the slider to 20% will display only pathways that share at most 20% similarity between reactions (sharing 2 reactions in a 10 step pathway, or 1 reaction in a 5 step pathway).
  The pathways shown are the lowest scoring of their respective cluster`;
  public readonly filterRangeTooltip: string = 'Range for filtered results: <min> to <max>';
  public readonly filteredPriceRangeTooltip: string = `Effective price range for starting materials in filtered results: <min> to <max>`;
  public readonly filtersDisabledTooltip: string = 'Filters not available';
  public readonly moleculeListDisabledTooltip: string = 'Molecule report not available';
  public readonly invalidSimilarity: string = `Similarity value can't be greater than 1 or less than 0`;
  public readonly structureFilterMaxWarning: string = `Multiple structure filters may slow down the filtering process`;

  // Path detail popup
  public readonly copyContentTooltip: string = 'Copy';
  public readonly thumbsUpTooltip: string =
    'Send us feedback if you think this reaction is interesting';
  public readonly thumbsDownTooltip: string =
    'Send us feedback if you think this reaction is not realistic';

  // Reaction Navigator
  public readonly rightArrowTooltip: string = 'View next reaction';
  public readonly leftArrowTooltip: string = 'View previous reaction';
  public readonly upArrowTooltip: string = 'View reaction on branch above (convergent synthesis)';
  public readonly downArrowTooltip: string = 'View reaction on branch below (convergent synthesis)';

  // Settings view
  public readonly algorithmScoreTooltip: string =
    'Algorithm score is available only for Network of Organic Chemistry and Manual retrosynthesis analysis types';
  public readonly massPerGramTooltip: string =
    'Mass per gram of target is available only for Automatic retrosynthesis analysis type';
  public readonly costOrPopularityTooltip: string =
    'Cost or popularity is available only for Manual and Automatic retrosynthesis analysis types';
  public readonly moleculeNodeSizeTooltip: string =
    'This option is available only for Automatic retrosynthesis algorithm';
  public readonly reactionArrowColorTooltip: string =
    'Non-selectivity and diastereoselective-only is displayed over tunnels or strategies in case of overlap';
  public readonly nonselectivityAndDiastereoselectiveOnlyTooltip: string =
    'Coloring by non-selectivity and diastereoselective-only is available only for Manual retrosynthesis and ' +
    'Automatic retrosynthesis analysis types';
  public readonly tunnelsAndStrategyTooltip: string =
    'Coloring by tunnels and strategy is available only for Manual retrosynthesis and Automatic retrosynthesis analysis types';
  public readonly allColorTooltip: string =
    'Coloring by non-selectivity, diastereoselective-only, tunnels and strategy is available only for Manual retrosynthesis and ' +
    'Automatic retrosynthesis analysis types';

  // Developer Mode
  public readonly invalidDeveloperModeJsonField: string =
    'JSON is invalid. Please enter a valid JSON ("Others" field in Developer Mode section)';
  public readonly maxIterationLimitTooltip: string =
    'Limit is increased to <developerMaxIterations> due to Developer Mode';
  public readonly developerModeMaxDurationTooltip: string =
    'Limit is increased to <developerModeMaxDuration> due to Developer Mode';
  public readonly multirxTooltip: string = 'Enable MultiRX';
  public readonly fgiTooltip: string = 'Enable FGI';
  public readonly fgiCoefTooltip: string = 'FGI coefficient';
  public readonly fgiWithProtectionsTooltip: string = 'Enable FGI with Protections';
  public readonly tunnelsTooltip: string = 'Enable Tunnels';
  public readonly tunnelCoefTooltip: string = 'Tunnels coefficient';
  public readonly lowriskTooltip: string = 'Enable Lowrisk';
  public readonly ringsStrategiesTooltip: string = 'Enables rings strategies';
  public readonly stereoStrategiesTooltip: string = 'Enables stereo strategies';
  public readonly cutIntoSmallerStrategiesTooltip: string = 'Enables cut into smaller strategies';

  // Expanded path view
  public readonly pathUpArrowTooltip: string = 'See previous pathway';
  public readonly pathDownArrowTooltip: string = 'See next pathway';

  // Target Detail Popup view
  public readonly targetDetailUpArrowTooltip: string = 'Previous result';
  public readonly targetDetailDownArrowTooltip: string = 'Next result';

  // Analysis result view
  public readonly groupByFamilyTooltip: string =
    'Adjust the `Group by Family` slider to group pathways with similar ' +
    'reactions together. Setting the slider to 20% will show only pathways that share at most 20% similarity of ' +
    'reactions (sharing 2 reactions in a 10 step pathway, or 1 reaction in a 5 step pathway). ' +
    'The returned pathways are the ones with the lowest score in their family';
  public readonly noResultComputationStatusCode: string = 'success-with-no-reactions';

  // Landing page settings
  public readonly defaultFolderLandingPageObject = {
    name: 'Default',
    url: 'dashboard/folders/0/1',
  };
  public readonly folderRoutingUrlFormat: string = 'home/dashboard/folders/<folderID>/1';
  public readonly landingPageResetTooltip: string = 'Reset to default';
  public readonly accountSettingsUpdateMessage: string = 'Account settings updated successfully';
  public readonly lastDashboardUrlKey: string = 'last-dashboard';
  public readonly defaultDashboardUrl: string = 'home/dashboard/analyses/all/1';
  public readonly hideTrainingMaterialInUserSession = 'hideTrainingMaterialInUserSession';

  // Node menu
  public readonly smilesCopyMessage: string = 'Structure copied as SMILES';

  // Sign In Component
  public readonly confirmationCodeResendTimer: number = 60;
  public readonly verificationMethodEmail: string = 'email';
  public readonly verificationMethodText: string = 'text_message';
  public readonly rememberMeCookie: string = 'synthia_remember_me';

  // Firebase dummy config to use if SSO disabled
  public readonly dummyFirebaseConfig = {
    apiKey: 'dummy_key',
    authDomain: 'dummy.firebaseapp.com',
  };

  // compliance section
  public readonly AcceptDisabledMessage: string = `Scroll to the bottom of the page to accept the terms`;

  public readonly illustrativeReferenceTooltip: string =
    'Illustrative references are not substrate specific';

  // Reaction Report
  public readonly allReactionTooltip: string =
    'Lists one card for each distinct reaction in displayed results. The number of pathways in which the reaction appears is displayed to the left of the reaction name';
  public readonly highlightedSelectionTooltip: string =
    'Lists every reaction in the selection highlighted in blue on the graph. Option only available when a reaction pathway has been highlighted by clicking on the selection icon on the reaction card';
  public readonly specifiedMoleculeTooltip: string =
    "Lists every reaction leading up to the molecule node for which the reaction report was generated. Option only available when clicking on 'Reaction Report' in the three-dot menu of a molecule node. Generate the report from center target to see all displayed reactions";

  // Synthia Lite Disabled feature popup message
  public readonly synthiaLiteDisabledFeatureDialogTitle: string =
    'SYNTHIA<sup class="ch-trade-mark fs-8-px">TM</sup> Enterprise Feature';
  public readonly synthiaLiteDisabledFeatureDialogMessage: string =
    'This feature is only available with a SYNTHIA<sup class="ch-trade-mark fs-8-px">TM</sup> Enterprise subscription';
  public readonly synthiaLiteDisabledFeatureDialogCloseButtonText: string = 'Remind me later';
  public readonly synthiaLiteDisabledAnalysisDialogTitle: string = 'Feature Disabled';
  public readonly synthiaLiteDisabledDialogMessage: string =
    'Your plan status does not allow you to run new analyses. To continue using Synthia<sup class="ch-trade-mark fs-8-px">TM</sup> Lite for new analyses, renew or upgrade your subscription plan';
  public readonly synthiaLiteDisabledDialogCloseButtonText: string = 'OK';

  // Marvin button tooltip
  public readonly doCutButtonName: string = 'Mark bonds as reaction center';
  public readonly doNotCutButtonName: string = 'Mark bonds as not reaction center';
  public readonly marvinButtonDisabledClass: string = 'gwt-PushButton-up-disabled';

  // Synthia Lite user registration screen
  public readonly registrationErrorDialogTitle: string = 'Error';
  public readonly registrationErrorDialogMessage: string =
    'Please ensure all required fields are complete and all information correctly formatted';
  public readonly emailExistError: string =
    'Registered user with this Email address already exists';
  public readonly valueNotAvailable: string = 'Not available';
  public readonly emailBlackListTitle: string = 'Impermissible Email Domain';
  public readonly emailBlackListError: string =
    'You entered an email with an impermissible domain. Please use an email address associated with <br> <companyName> partner';
  public readonly blacklistedDomainAPIFailureTitle: string = 'Registration Cannot Be Completed';
  public readonly blacklistedDomainAPIFailureMessage: string =
    'This registration cannot be processed now';

  // Synthia terms label
  public readonly synthiaTermsLabel: string = 'SYNTHIA™ terms of use have been updated';

  // Network is offLine
  public readonly offlineMessage: string =
    'There seems to be a network issue. Please check your internet connection';

  // Enterprise Registration
  public readonly twoWayAuthTooltip: string =
    'By default Email will be used for Two-factor Authentication unless phone number is specified';
  public readonly lettersOnlyErrorMessage: string = 'Please use only letters';
  public readonly registrationEmailSpecialCharactersErrorMessage: string =
    'Sorry, only letters, numbers and ., _, + or - characters are allowed';
  public readonly firstLastNameRegex: string = '^[a-zA-Z ]+$';
  public readonly emailRegex: string = "^(?:(?![?&||~^=*{}%#!$()`',/]).)+$";
  public readonly newPasswordPlaceholder: string = 'Create a new password';
  public readonly repeatNewPasswordPlaceholder: string = 'Repeat password';

  // User management
  public readonly userStatusChangeInfo: string =
    'Please, reach out to SYNTHIA™ Admin if you would like to change the user status ';
  public readonly noUsersFound: string = 'No records found';
  public readonly userSearchPlaceholder: string = 'Search';
  public readonly unlockUserMessage: string =
    'The user account has been successfully unlocked. An email notification has been sent to the user.';
  public readonly deleteMemberSuccessMessage: string =
    '<email> user SYNTHIA™ account has been deleted and all data has been removed permanently from the system.';

  // User account settings
  public readonly invalidPhoneNumberMessage: string =
    'Please provide a valid phone number in the international format';
  public readonly unsavedChangesDialogTitle: string = 'Unsaved Changes';
  public readonly unsavedChangesDialogMessage: string =
    'There are unsaved changes to account settings. Are you sure you <br />want to leave this screen and discard the changes?';
  public readonly deleteAccountConfirmationMessage: string = `<span class="font-style-italic ch-clr-warn">
    Deleting an account is permanent. You won’t be able to recover this account.
    </span>`;
  public readonly deleteAccountConfirmationCheckboxMessage: string =
    'Yes, I understand that this will completely remove my account and all my data';
  public readonly deleteAccountConfirmationDialogTitleAndButton: string = 'Delete Account';

  // Tech Support
  public readonly unknownUserEmailSuccess: string =
    'If your user account exists on the system, you will receive a copy of the posted error message via email.';
  public readonly knownUserEmailSuccess: string = 'Your email has been sent';

  // Subscription Plans
  public readonly freePlanGroupTitle: string = 'Free Limited Trial';
  public readonly freePlanDescriptions: string[] = [
    'Try SYNTHIA<sup class="ch-trade-mark">TM</sup> Lite for a <expireTime> Limited Free Trail. Retrosynthesis for <moleculeLimit> unique target molecules included ',
    'Unlimited re-runs with a variety of SYNTHIA<sup class="ch-trade-mark">TM</sup> Lite options',
    'Upgrade options after free trial',
  ];
  public readonly paidPlanGroupTitle: string = 'Premium Options';
  public readonly paidPlanGroupTitleWithOnePlan: string = 'Premium Option';
  public readonly paidPlanDescriptions: string[] = [
    'Retrosynthesis for <moleculeLimit> unique target molecules in <expireTime>',
    'Unlimited re-runs with a variety of SYNTHIA<sup class="ch-trade-mark">TM</sup> Lite options',
  ];
  public readonly paidUnlimitedPlanDescriptions: string[] = [
    'Unlimited usage for <expireTime>',
    'Great for testing many target molecules',
  ];
  public readonly enterprisePlanGroupTitle: string = 'Enterprise License';
  public readonly enterprisePlanDescriptions: string[] = [
    'Site licensing options available',
    'Collaborate and share results with colleagues',
    'Batch analysis, custom inventory',
    'Single Sign-on (SSO)',
  ];
  public readonly freePlanStartedInfo: string = ' Trial in progress';
  public readonly planExpiredInfo: string = 'Expired';
  public readonly freePlanUnavailableInfo: string = 'Unavailable';
  public readonly paidPlanUnavailableInfo: string =
    'Available when plan expires or target molecules used';
  public readonly planSubscriptionFailureMessage: string =
    'Plan subscription failed, please try later';
  public readonly paidPlanNotAllowedInfo: string = 'Coming Soon';

  // Flask icon tooltip in top toolbar
  public readonly flaskTooltipAnalysisCount: string = '<count> analyses remaining';
  public readonly flaskTooltipActivePlan: string =
    '<subscriptionTypeLabel> will expire on <expiryDate>';
  public readonly flaskTooltipExpirePlan: string = '<subscriptionTypeLabel> has expired';

  // warning pop up
  public readonly hideWarningDialogUserSession: string = 'hideWarningDialogUserSession';
  public readonly planExpiredWarning: string =
    'Your <subscriptionTypeLabel> has expired, but you can still access your completed analyses.';
  public readonly moleculeUsedWarning: string =
    'Your have used all the unique target molecules in your <subscriptionTypeLabel>, but you can still <br>access your completed analyses.';
  public readonly planExpiringWarning: string =
    'You have <count> unique target molecules remaining.<br>Your <subscriptionTypeLabel> expires on <expiryDate>.';
  public readonly unlimitedPlanExpiringWarning: string =
    'Your subscription will expire on <expiryDate>';

  // Terms and Conditions section
  public readonly continueButtonDisabledMessage: string = 'Scroll down to complete acceptance';

  // Branding
  public readonly merckLogoUrl: string = '/assets/img/merck-logo.svg';
  public readonly milliporeSigmaLogoUrl: string = '/assets/img/millipore-sigma-logo.svg';
  public readonly merckLogoCyanUrl: string = '/assets/img/merck-logo-cyan.svg';
  public readonly milliporeSigmaLogoCyanUrl: string = '/assets/img/millipore-sigma-logo-cyan.svg';
  public readonly milliporeSigmaTag: string =
    'MilliporeSigma is the U.S. and Canada Life Science business of Merck KGaA, Darmstadt, Germany.';
  public readonly synthiaLiteLogoText: string = 'SYNTHIA&trade; Lite Retrosynthesis Software';
  public readonly synthiaEnterpriseLogoText: string = 'SYNTHIA&trade; Retrosynthesis Software';
  public readonly copyrightInfo: string =
    '© 2022 Merck KGaA, Darmstadt, Germany and/or its affiliates. All rights reserved.';

  // EMD
  public readonly emdAccessUrl: string =
    'https://login.emddigital.com/<accessType>?scope=openid+email&response_type=code&client_id={CLIENT_ID}&redirect_uri={REDIRECT_URL}&code_challenge_method=S256&code_challenge={CHALLENGE}&prompt=login';
  public readonly emdSignInUrlPart: string = 'oauth2/authorize';
  public readonly emdRegisterUrlPart: string = 'signup';
  public readonly emdResetPasswordUrlPart: string = 'resetpassword';

  // Inventory Upload
  public readonly inventoryUploadError: string =
    'Custom Inventory could not be created due to stale information.';
  public readonly inventoryMaxSizeTooltip: string = 'Maximum allowed file size is 256MB';

  // Chemdraw
  public readonly chemdrawRedirectUrl: string =
    'com.perkinelmer.chemdraw.addin:///#access_token={ACCESS_TOKEN}&state={CALLBACK_KEY}';

  // EComm Integration
  public readonly removeSingleItemFromShoppingListSuccess: string =
    'Successfully removed item from the list.';
  public readonly removeMultipleItemFromShoppingListSuccess: string =
    'Successfully removed selected items from the list.';
  public readonly removeItemFromShoppingListFailed: string =
    'Unable to remove item from the list.Please try again later.';

  // Sign In
  public readonly twoFactorAuthCodeInfo: string =
    'A new confirmation code has been sent by email. Please note that codes expire after 60 minutes, and only the most recent code is valid.';

  // Pubchem Url
  public readonly pubChemUrl: string = 'https://pubchem.ncbi.nlm.nih.gov/compound/';

  // Carbon FootPrint
  public readonly carbonFootprintTooltip: string =
    'Spend-based emission factor according to the Environmentally Extended Input-Output (EEIO) model (reported in kgCO₂ eq/EUR)';

  // Start analysis error messages
  public readonly noStructureInEditorError: string = `No structure detected. Please add your target molecule to the Molecule Editor.`;
  public readonly multipleStructureInEditorError: string = `Multiple structures detected. Please remove extra structures or enable 'Multiple Targets' to run a batch or a library.`;
  public readonly invalidStructureInEditorError: string = `Invalid structure detected. Please correct the structure in the Molecule Editor.`;
  public readonly noStructureInMultipleTargetError: string = `No structure detected. Please add your target molecules using 'Draw' or 'Upload File'.`;
  public readonly minimumTargetLimitLibrary: string = `Minimal number of targets for the Shared Path Library is`;
  public readonly maximumTargetLimitLibrary: string = `Number of target molecules exceeds maximum limit of`;

  public readonly startBtnInfo: string = `Please draw or paste a structure into the drawer window or upload a molecule file to start the analysis`;

  public readonly targetMoleculesMessage: string = `See target molecules`;

  public readonly libraryNoResultsMainText: string = `SYNTHIA™ has not found a shared path for any of your targets.`;
  public readonly libraryNoResultsSubText: string = `Please modify your structures or select different search criteria.`;
  // Tokens for illustratuve reference
  public readonly token: string[] = [' and ', ' or '];

  // Diversity more icons
  public readonly diversityMoreDisabledTooltip: string =
    'Select at least one molecule by Ctrl+click/Cmd+click on a molecule tile, or use the Selection dropdown menu to select all molecules or favorites only';
  public readonly diversityFavoriteTooltip: string = 'Favorite selected molecules';
  public readonly diversityUnfavoriteTooltip: string = 'Unfavorite selected molecules';
  public readonly diversityFilterTooltip: string = 'Filter selected molecules';
  public readonly diversitySelectionCheckboxTooltip: string =
    'Select all molecules or favorites only, or deselect all molecules';
  public readonly diversityCmdOrCtrlTooltip: string =
    'For custom selection of multiple targets use Ctrl+click/Cmd+click';
  public readonly diversityClipboardTooltip: string = 'Add selected molecules to clipboard';
  public readonly diversityShopIconTooltip: string =
    'View shopping options for starting materials used in synthetic pathways of selected molecules';
  public readonly diversityshopIconTooltipDisabled: string =
    'Shopping list is available for maximum 10 molecules at once';
  public readonly diversityCopySmilesTooltip: string = 'Copy selected molecule SMILES';
  public readonly diversityDownloadTooltip: string = 'Export selected molecules';
  public readonly diversityReactionRulesTooltip: string =
    'Select one or more reactions from the list to create a library';

  public replaceVariableFromMessage(
    message: string,
    strToReplace: string,
    newStr: string | number = '',
  ): string {
    return message.replace(new RegExp(strToReplace, 'g'), newStr?.toString());
  }

  public getExportToPDFLimitMessage(count: number, isPlural: boolean, tip: string) {
    return (
      `No more than ${APP_CONFIG.PDF_EXPORT_MAX_REACTIONS} reaction` +
      `${isPlural ? 's' : ''} in a single PDF ${isPlural ? 'are' : 'is'}` +
      ` supported by the export service. You are trying to export ${count} of them. ` +
      tip
    );
  }

  private getSearchForMoleculeTooltipText(searchType: string): string {
    return `Search for target molecule by ${searchType} in our known database`;
  }

  public getAnalysisTagTooltip(tagName: string, tagLength: number) {
    if (tagName.length > tagLength) {
      return '#' + tagName;
    }
  }
}
