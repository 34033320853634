import { throwError, Observable } from 'rxjs';
import { catchError, share, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { MolecularStrainReport } from './models';
import { ParsedConnectionError } from '../errors-handler';
/* tslint:disable-next-line:no-unused-variable */
import { FixedQueryEncoderHelper } from '../../fixed-httpparams';

/* tslint:disable-next-line:variable-name */
export const backendEntryPoint_MolecularStrain_Report =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/molecular-strain/report/';

@Injectable()
export class MolecularStrainService {
  private static resolveStrainReport(res: HttpResponse<any>): MolecularStrainReport {
    try {
      return new MolecularStrainReport(res.body);
    } catch (e) {
      throw new Error('Unexpected format of response.');
    }
  }

  private static getStrainReportError(error: HttpResponse<any> | any): Observable<any> {
    const parsedError = new ParsedConnectionError(error);

    if (parsedError.isRecognized()) {
      return throwError(parsedError);
    } else {
      return throwError(`Unable to get a strains report from backend. ${error}`);
    }
  }

  constructor(private http: HttpClient) {}

  public getStrainReport(smiles: string): Observable<MolecularStrainReport> {
    const params: HttpParams = new HttpParams({ encoder: new FixedQueryEncoderHelper() }).append(
      'smiles',
      smiles,
    );

    return this.http
      .get(backendEntryPoint_MolecularStrain_Report, {
        params,
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map((res: HttpResponse<any>) => MolecularStrainService.resolveStrainReport(res)),
        share(),
        catchError((error) => MolecularStrainService.getStrainReportError(error)),
      );
  }
}
