<div class="reaction-filter-section">
  <div *ngIf="isNormalReaction">
    <div class="reaction-filter-label">
      <span class="reaction-filter-category"> Reactions </span>
    </div>
    <div *ngIf="reactionFilters.limitTo.reactions.length > 0">
      <div class="section-label"><span>Limit to</span></div>
      <div class="chips-section">
        <mat-chip-list>
          <mat-chip
            *ngFor="let reaction of reactionFilters.limitTo.reactions; let reactionIndex = index"
          >
            <div
              class="truncate-on-overflow reaction-chip ch-default-blue"
              (mouseenter)="onMouseEnter($event, reaction.smiles)"
              (mouseleave)="onMouseLeave()"
              [matTooltip]="getTooltip(reaction.reactionName, tooltipMaxLength)"
              matTooltipClass="ch-tooltip"
              matTooltipPosition="above"
            >
              {{ reaction.reactionName }}
            </div>
            <mat-icon matChipRemove (click)="removeReactions(reactionIndex, 'limitTo')"
              >clear</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div *ngIf="reactionFilters.exclude.reactions.length > 0">
      <div class="section-label"><span>Exclude</span></div>
      <div class="chips-section">
        <mat-chip-list>
          <mat-chip
            *ngFor="let reaction of reactionFilters.exclude.reactions; let reactionIndex = index"
          >
            <div
              class="truncate-on-overflow reaction-chip ch-default-blue"
              (mouseenter)="onMouseEnter($event, reaction.smiles)"
              (mouseleave)="onMouseLeave()"
              [matTooltip]="getTooltip(reaction.reactionName, tooltipMaxLength)"
              matTooltipClass="ch-tooltip"
              matTooltipPosition="above"
            >
              {{ reaction.reactionName }}
            </div>
            <mat-icon matChipRemove (click)="removeReactions(reactionIndex, 'exclude')"
              >clear</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
  <div *ngIf="isLastDisconnectedReaction">
    <div class="reaction-filter-label">
      <span class="reaction-filter-category"> Last Reaction </span>
    </div>
    <div *ngIf="reactionFilters.lastDisconnected.limitTo.reactions.length > 0">
      <div class="section-label"><span>Limit to</span></div>
      <div class="chips-section">
        <mat-chip-list>
          <mat-chip
            *ngFor="
              let reaction of reactionFilters.lastDisconnected.limitTo.reactions;
              let reactionIndex = index
            "
          >
            <div
              class="truncate-on-overflow reaction-chip ch-default-blue"
              (mouseenter)="onMouseEnter($event, reaction.smiles)"
              (mouseleave)="onMouseLeave()"
              [matTooltip]="getTooltip(reaction.reactionName, tooltipMaxLength)"
              matTooltipClass="ch-tooltip"
              matTooltipPosition="above"
            >
              {{ reaction.reactionName }}
            </div>
            <mat-icon matChipRemove (click)="removeReactions(reactionIndex, 'limitTo', true)"
              >clear</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div *ngIf="reactionFilters.lastDisconnected.exclude.reactions.length > 0">
      <div class="section-label"><span>Exclude</span></div>
      <div class="chips-section">
        <mat-chip-list>
          <mat-chip
            *ngFor="
              let reaction of reactionFilters.lastDisconnected.exclude.reactions;
              let reactionIndex = index
            "
          >
            <div
              class="truncate-on-overflow reaction-chip ch-default-blue"
              (mouseenter)="onMouseEnter($event, reaction.smiles)"
              (mouseleave)="onMouseLeave()"
              [matTooltip]="getTooltip(reaction.reactionName, tooltipMaxLength)"
              matTooltipClass="ch-tooltip"
              matTooltipPosition="above"
            >
              {{ reaction.reactionName }}
            </div>
            <mat-icon matChipRemove (click)="removeReactions(reactionIndex, 'exclude', true)"
              >clear</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>
