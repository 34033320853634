import { AnalysisResultsService } from './../analysis-results/analysis-results.service';
import {
  ReplaySubject,
  Subscription,
  Observable,
  BehaviorSubject,
  throwError,
  Subject,
} from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ComputationEntry, AnalysisEntry, AnalysisStateEntry, GraphResources } from './models';
import {
  AnalysisSettings,
  MoleculeLabelType,
  MoleculeScalingType,
  ReactionColorType,
  ReactionLabelType,
  EdgeColorType,
} from '..';
import { AnalysisComment } from '../models/comment';
import { AnalysisAlgorithm } from '../app-constants/app-constants.service';
import { getLocalCopy } from '../../components/utils';
import { isNullOrUndefined } from '../../components/utils';
import { Tag } from '../models/tag';
import { RDFDataReaction } from '../models/rxn-data-for-reaction';
import { SimilarityScore } from '../models/similarity-score';
import { AnnotateReactionData } from '../models/annotate-reaction';
export interface AnalysisTags {
  tags: Tag[];
  user: number;
  analysis: number;
}

export interface CommentsCount {
  unread: number;
  total: number;
}

export interface AnnotateReactionDetails {
  annotateReactions: AnnotateReactionData[];
  reactionId: string;
}

const midendApiVersion = 'v2';

export const backendEntryPointAnalysesComputations =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/analyses/computations/`;
export const backendEntryPointEmptyTrash =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/empty-trash/`;
export const BackendEntryPointBatchStart =
  APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/batches/`;
export const backendEntryPointLibraryMode =
  APP_CONFIG.CHEMATICA_API_URL + `/api/v1/analyses/library-computation/`;
export const backendEntryPointAnalysesDiversityComputations =
  APP_CONFIG.CHEMATICA_API_URL + `/api/v1/diversity-library/computation/`;
export const backendEntryPointGetAnnotateReaction =
  APP_CONFIG.CHEMATICA_API_URL + `/api/v1/annotate-reaction/`;

export enum AlgorithmType {
  AutomaticRetrosynthesis,
  ManualRetrosynthesis,
  AutomaticRetrosynthesisBatch,
  ReverseReactionNetwork,
  ReactionNetwork,
  LibraryMode,
  DiversityLibrary,
}

export enum ResultsView {
  GRAPH = 'graph',
  PATHWAYS = 'path',
}

export enum GraphLayout {
  FORCE = 'force',
  DAGRE = 'dagre',
  POLAR = 'polar',
}

export enum PathDirection {
  LeftToRight = 'LR',
  RightToLeft = 'RL',
}

export enum ReactionListMode {
  GRAPH = 'graph',
  SELECTION = 'selection',
  MOLECULE = 'molecule',
}

export const AlgorithmMap = {
  AUTOMATIC_RETROSYNTHESIS: AlgorithmType.AutomaticRetrosynthesis,
  MANUAL_RETROSYNTHESIS: AlgorithmType.ManualRetrosynthesis,
  LIBRARY_MODE: AlgorithmType.LibraryMode,
  DIVERSITY_LIBRARY: AlgorithmType.DiversityLibrary,
};

const cacheSizeLimit: number = APP_CONFIG.GRAPH_CACHE_SIZE_MAX;

export function getBackendEntryPointMarkAnalysisFavorite(): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/favorite-analysis/`;
}

export function getBackendEntryPointMarkSyntheticPathFavorite(): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/favorite-synthetic-path/`;
}

export function getBackendEntryPointUnmarkSyntheticPathFavorite(pathId: number[]): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/favorite-synthetic-path/${pathId}/`;
}

export function getBackendEntryPointUnmarkAnalysisFavorite(analysisId: number): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/favorite-analysis/${analysisId}/`;
}

export function getBackendEntryPointAnalysis(analysisId: number): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/analyses/${analysisId}/`;
}

export function getBackendEntryPointLibrary(analysisId: number): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/library/${analysisId}/result`;
}

export function getBackendEntryPointDiversity(analysisId: number): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/diversity-library/${analysisId}/result`;
}

export function getBackendEntryPointTags(): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/${midendApiVersion}/tag/`;
}
export function getBackendEntryPointAnalysisComputations(
  analysisId: number,
  computationId?: number,
): string {
  return (
    APP_CONFIG.CHEMATICA_API_URL +
    `/api/v1/analyses/${analysisId}/computations/${computationId ? `${computationId}/` : ''}`
  );
}

export function getRDKitDataReaction(): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/rxn-data-for-reaction/`;
}

export function getBackendEntrySimilarityScore(): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/reaction-similarity-score/`;
}

export function getBackendEntryPointSimilarChemicals(): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/get-similar-chemicals/`;
}

export function getBackendEntrySyntheticPathFavorite(analysisId: number): string {
  return APP_CONFIG.CHEMATICA_API_URL + `/api/v1/favorite-synthetic-paths/analysis/${analysisId}`;
}

export function getLibraryTargetDetailsUrl(analysisId: number): string {
  return `${backendEntryPointLibraryMode}${analysisId}/targets/`;
}

@Injectable({
  providedIn: 'root',
})
export class AnalysisService {
  private static resolveComputationEntry(response: HttpResponse<any>): ComputationEntry {
    try {
      return new ComputationEntry(response.body);
    } catch (e) {
      throw 'Unexpected format of response for ComputationEntry.'; /* tslint:disable-line:no-string-throw */
    }
  }

  private static resolveAnalysisEntry(response: HttpResponse<any>): AnalysisEntry {
    try {
      return new AnalysisEntry(response.body);
    } catch (e) {
      throw 'Unexpected format of response for AnalysisEntry.'; /* tslint:disable-line:no-string-throw */
    }
  }

  private static resolveAnalysisStateEntry(response: HttpResponse<any>): AnalysisStateEntry {
    try {
      return new AnalysisStateEntry(response.body);
    } catch (e) {
      throw 'Unexpected format of response for AnalysisStateEntry.'; /* tslint:disable-line:no-string-throw */
    }
  }

  private commentsCount: CommentsCount = {
    unread: 0,
    total: 0,
  };

  private annotateReactionDetails: AnnotateReactionDetails = {
    annotateReactions: [],
    reactionId: '',
  };

  public libraryApiResult: any;
  public analysisSettings: any;
  public progressItemSummary: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public targetDetailsSummary: BehaviorSubject<Object> = new BehaviorSubject<Object>({});
  public hasResult: BehaviorSubject<boolean>;
  public loadingFrontendStorage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public analysisSettingsBehaviorSubjects = {
    moleculeLabel: new BehaviorSubject<MoleculeLabelType>('costOrPopularity'),
    reactionLabel: new BehaviorSubject<ReactionLabelType>('none'),
    mode: new BehaviorSubject<ResultsView>(ResultsView.GRAPH),
    layout: new BehaviorSubject<GraphLayout>(GraphLayout.FORCE),
    algorithm: new BehaviorSubject<string>(''),
    colorReactionDiamonds: new BehaviorSubject<ReactionColorType>('base'),
    colorReactionArrows: new BehaviorSubject<EdgeColorType>('all'),
    colorByReactivity: new BehaviorSubject<boolean>(false),
    nodeTypeIcons: new BehaviorSubject<boolean>(false),
    moleculeScaling: new BehaviorSubject<MoleculeScalingType>('none'),
    pathDirection: new BehaviorSubject<PathDirection>(PathDirection.LeftToRight),
    shopAvailability: new BehaviorSubject<boolean>(true),
    settingsHaveChanged: new BehaviorSubject<boolean>(false),
    layoutIsDefault: true,
    modeIsDefault: true,
    showPathScore: new BehaviorSubject<boolean>(false),
    showSimilarityScore: new BehaviorSubject<boolean>(true),
    showPathWayView: new BehaviorSubject<boolean>(false),
    commentsCount: new BehaviorSubject<CommentsCount>(this.commentsCount),
    sharesCount: new BehaviorSubject<number>(0),
    filters: {
      selectedOnly: new BehaviorSubject<boolean>(false),
      groupByFamily: new BehaviorSubject<number>(100),
      minimumReactions: new BehaviorSubject<number>(1),
      maximumReactions: new BehaviorSubject<number>(0),
      anyMaximumReactions: new BehaviorSubject<boolean>(true),
      conflicts: new BehaviorSubject<string>('any'),
      protections: new BehaviorSubject<string>('any'),
      regulated: new BehaviorSubject<string>('any'),
      pathScoreMin: new BehaviorSubject<number>(0),
      pathScoreMax: new BehaviorSubject<number>(1000),
      pathScoreMaxInfinite: new BehaviorSubject<boolean>(true),
    },
  };
  public stoppingAnalyses: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public clearCache: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthorizedForRunAnalysis: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public tagManagerAnalysisId: Subject<number> = new Subject<number>();
  public isUserTagsChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPathwayViewOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pathwayOptions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public visiblePaths: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public currentPathIndex: Subject<number> = new Subject<number>();
  public currentPathIndexTarget: Subject<number> = new Subject<number>();
  public currentAnalysisPageIndex: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public isNavigatingBack: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLastPathResize: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isComputationActivePreviousValue: boolean;
  public isLibraryResultAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public analysis: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isDiversityLibrary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDiversityLibraryChecked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public diversityApiResult: any;
  public isDiversityResultView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public libraryTargetDetailsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public analysisStateStop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public annotateReactionListFiltered: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public diversityColoringOption: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public annotateReactionData: BehaviorSubject<AnnotateReactionDetails> =
    new BehaviorSubject<AnnotateReactionDetails>(this.annotateReactionDetails);
  public isRerunDiversityLibrary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAnnotatedReactionsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  private graphApiCache: {
    [key: string]: {
      graphHash: string;
      usedAt: number;
      graphResultsSubscription: Subscription;
      graphApiResults: ReplaySubject<GraphResources>;
    };
  } = {};

  constructor(
    private http: HttpClient,
    private analysisResultsService: AnalysisResultsService,
  ) {
    this.hasResult = new BehaviorSubject<boolean>(false);
  }

  public createAnalysisByComputation(params, analysisName?: string) {
    return this.http
      .post(backendEntryPointAnalysesComputations, params, { observe: 'response' })
      .pipe(
        map(AnalysisService.resolveComputationEntry),
        mergeMap((computation: ComputationEntry) => {
          if (analysisName === undefined) {
            analysisName = `Analysis ${computation.analysis}`;
          }

          return this.renameAnalysis(computation.analysis, analysisName);
        }),
        map((r) => r.body),
      );
  }

  public createDiverisityComputation(params: any) {
    return this.http
      .post(backendEntryPointAnalysesDiversityComputations, params, { observe: 'response' })
      .pipe(
        map(AnalysisService.resolveComputationEntry),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  public getAnnotateReaction(params: any) {
    return this.http
      .post(backendEntryPointGetAnnotateReaction, params, { observe: 'response' })
      .pipe(
        map((response: any) => response.body.results),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  public stopComputation(analysisId: number, computationId: number): Observable<ComputationEntry> {
    const computationUrl: string = getBackendEntryPointAnalysisComputations(
      analysisId,
      computationId,
    );
    const options = {
      stop_computation: true,
    };
    return this.http.patch(computationUrl, options, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => AnalysisService.resolveComputationEntry(response)),
      map((computation: any) => {
        const newStoppingAnalyses: number[] = this.stoppingAnalyses.value;
        newStoppingAnalyses.push(computation.analysis);
        this.stoppingAnalyses.next(newStoppingAnalyses);
        return computation;
      }),
      catchError((error) => {
        const newStoppingAnalyses: number[] = this.stoppingAnalyses.value.filter(
          (_analysisId) => _analysisId !== analysisId,
        );
        this.stoppingAnalyses.next(newStoppingAnalyses);
        return throwError(error);
      }),
    );
  }

  /**
   * Load analysis from server and optionally update the time of latest access.
   * @param {number} analysisId Id of analysis.
   * @param {boolean} touch If the time of latest access should be actualized.
   * @returns {Observable<AnalysisEntry>} Observable which emits analysis json.
   */
  public getAnalysis(analysisId: number): Observable<AnalysisEntry> {
    return this.http.get(getBackendEntryPointAnalysis(analysisId), { observe: 'response' }).pipe(
      map((response) => {
        return AnalysisService.resolveAnalysisEntry(response);
      }),
    );
  }

  /**
   * Load analysis state from server, it's graph hash and computations state hash.
   * @param {number} analysisId Id of analysis.
   * @returns {Observable<AnalysisStateEntry>} Observable which emits analysis json.
   */
  public getAnalysisState(analysisId: number): Observable<AnalysisStateEntry> {
    const analysisStateUrl: string = getBackendEntryPointAnalysis(analysisId) + 'analysis-state/';
    return this.http.get(analysisStateUrl, { observe: 'response' }).pipe(
      map((response) => {
        return AnalysisService.resolveAnalysisStateEntry(response);
      }),
    );
  }

  public createComputationOfAnalysis(
    analysisId: number,
    params: any,
  ): Observable<ComputationEntry> {
    return this.http
      .post(getBackendEntryPointAnalysisComputations(analysisId), params, { observe: 'response' })
      .pipe(map(AnalysisService.resolveComputationEntry));
  }

  public getComputationsOfAnalysis(
    analysisId: number,
    limit: number,
  ): Observable<ComputationEntry[]> {
    const queryParams: HttpParams = new HttpParams().set('limit', String(limit));

    return this.http
      .get(getBackendEntryPointAnalysisComputations(analysisId), {
        params: queryParams,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            const responseJson = response.body;
            return responseJson.map((computation) => new ComputationEntry(computation));
          } catch (e) {
            throw 'Unexpected format of response.'; /* tslint:disable-line:no-string-throw */
          }
        }),
      );
  }

  public getGraphApiResults(analysisId: number, graphHash: string, page: number) {
    this.activateCacheCleaning();

    let graphApiResult: ReplaySubject<GraphResources> = new ReplaySubject<GraphResources>(1);

    graphApiResult = this.loadAndCacheGraphApiResults(analysisId, graphHash, page);
    return graphApiResult;
  }

  public getLibraryApiResults(
    analysisId: number,
    graphHash: string,
    page: number,
    mode: ResultsView,
    isCacheEnabled: boolean,
  ) {
    let queryParams: HttpParams;
    const libraryApiUrl: string = `${getBackendEntryPointLibrary(analysisId)}`;
    if (isCacheEnabled) {
      queryParams = new HttpParams().set('state', 'completed');
    }
    return this.http.get(libraryApiUrl, { params: queryParams, observe: 'response' }).pipe(
      map((result: HttpResponse<Object>) => {
        this.libraryApiResult = result.body;
        return this.libraryApiResult;
      }),
    );
  }

  public removeAnalysisFromCache(analysisId: number) {
    if (analysisId in this.graphApiCache) {
      delete this.graphApiCache[analysisId];
    }
    this.clearCache.next(false);
  }

  public loadAndCacheGraphApiResults(
    analysisId: number,
    graphHash: string,
    page: number,
  ): ReplaySubject<GraphResources> {
    const graphApiResults: ReplaySubject<GraphResources> = new ReplaySubject<GraphResources>();
    let graphResultsSubscription: Subscription;

    graphResultsSubscription = this.getAnalysisGraphResources(analysisId, page).subscribe(
      (apiResult) => {
        graphApiResults.next(apiResult);
      },
      (error) => {
        graphApiResults.error(error);
      },
    );
    return graphApiResults;
  }

  public fromGraphApiCache(analysisId: number, graphHash: string): ReplaySubject<any> | undefined {
    const resultItem = this.graphApiCache[analysisId];
    if (resultItem && resultItem.graphHash === graphHash) {
      resultItem.usedAt = Date.now();
      return resultItem.graphApiResults;
    } else {
      if (analysisId in this.graphApiCache) {
        delete this.graphApiCache[analysisId];
      }
      return undefined;
    }
  }

  public getAnalysisGraphResources(analysisId: number, page: number): Observable<GraphResources> {
    let queryParams: HttpParams;
    if (this.analysisResultsService.isComputationActive.value) {
      queryParams = new HttpParams().set('mode', this.analysisSettingsBehaviorSubjects.mode.value);
    } else if (
      this.analysisSettingsBehaviorSubjects.mode.value === ResultsView.PATHWAYS &&
      !this.analysisResultsService.isComputationActive.value
    ) {
      queryParams = new HttpParams()
        .set('page', page.toString())
        .set('mode', this.analysisSettingsBehaviorSubjects.mode.value);
    } else {
      queryParams = new HttpParams().set('mode', this.analysisSettingsBehaviorSubjects.mode.value);
    }
    const graphResourcesUrl: string = getBackendEntryPointAnalysis(analysisId) + 'graph-resources/';
    return this.http.get(graphResourcesUrl, { params: queryParams, observe: 'response' }).pipe(
      map((result: HttpResponse<Object>) => {
        const jsonResult = result.body;
        return new GraphResources(jsonResult);
      }),
    );
  }

  public deleteAnalysis(analysisId: number) {
    const analysisUrl: string = getBackendEntryPointAnalysis(analysisId);
    return this.http.delete(analysisUrl, { observe: 'response' }).pipe(map((result) => result));
  }

  public emptyTrash() {
    return this.http
      .delete(backendEntryPointEmptyTrash, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public markAnalysisAsFavorite(analysisId: number) {
    const markFavoriteAnalysisUrl: string = getBackendEntryPointMarkAnalysisFavorite();
    const requestBody = {
      analysis_id: analysisId,
    };
    return this.http
      .post(markFavoriteAnalysisUrl, requestBody, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public unmarkAnalysisAsFavorite(analysisId: number) {
    const unmarkFavoriteAnalysisUrl: string =
      getBackendEntryPointUnmarkAnalysisFavorite(analysisId);
    return this.http
      .delete(unmarkFavoriteAnalysisUrl, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public moveAnalysisToFolder(analysisId: number, folderId: number) {
    const analysisUrl: string = getBackendEntryPointAnalysis(analysisId);
    const options = {
      folder: folderId,
    };
    return this.http
      .patch(analysisUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public commentAnalysis(analysisId: number, comment: string) {
    const analysisUrl: string = getBackendEntryPointAnalysis(analysisId) + 'comments/';
    const options = {
      content: comment,
    };
    return this.http
      .post(analysisUrl, options, { observe: 'response' })
      .pipe(map((result) => result.body));
  }

  public getAnalysisComments(analysisId: number): Observable<AnalysisComment[]> {
    const analysisCommentsUrl: string = getBackendEntryPointAnalysis(analysisId) + 'comments/';
    return this.http.get(analysisCommentsUrl, { observe: 'response', responseType: 'json' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.map((comment) => new AnalysisComment(comment));
        } catch (e) {
          throw 'Unexpected format of response.'; /* tslint:disable-line:no-string-throw */
        }
      }),
    );
  }

  public renameAnalysis(analysisId: number, _name: string) {
    const analysisUrl: string = getBackendEntryPointAnalysis(analysisId);
    const analysisName = _name.toString().replace(/\s+/g, ' ');
    const options = {
      name: analysisName.trim(),
    };
    return this.http
      .patch(analysisUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public restoreAnalysis(analysisId: number) {
    const analysisUrl: string = getBackendEntryPointAnalysis(analysisId);
    const options = {
      removed_at: null,
    };
    return this.http
      .patch(analysisUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public renameComputation(analysisId: number, computationId: number, _name: string) {
    const computationUrl: string = getBackendEntryPointAnalysisComputations(
      analysisId,
      computationId,
    );
    const computationName = _name.replace(/\s+/g, ' ');
    const options = {
      name: computationName.trim(),
    };
    return this.http
      .patch(computationUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public updateAnalysisSettings(analysisId: number) {
    this.analysisSettings = this.getSettingsValuesFromBehaviorSubject();
    const analysisSettingsUrl = getBackendEntryPointAnalysis(analysisId) + 'settings/';
    const options = {
      user_settings: this.analysisSettings,
    };
    return this.http
      .post(analysisSettingsUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public favoritePath(pathId: number[]) {
    const pathApiUrl: string = getBackendEntryPointMarkSyntheticPathFavorite();
    const options = {
      synthetic_path_ids: pathId,
    };
    return this.http
      .post(pathApiUrl, options, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public unfavoritePath(pathId: number[]) {
    const pathApiUrl: string = getBackendEntryPointMarkSyntheticPathFavorite();
    const options = {
      body: { synthetic_path_ids: pathId },
    };
    return this.http.delete(pathApiUrl, options).pipe(map((result) => result));
  }

  public startBatch(batchParams: any) {
    return this.http
      .post(BackendEntryPointBatchStart, JSON.stringify(batchParams), { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public startNewLibrary(libraryParams: any) {
    return this.http
      .post(backendEntryPointLibraryMode, JSON.stringify(libraryParams), { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public getLibraryTargetDetails(libraryId: number) {
    return this.http
      .get(getLibraryTargetDetailsUrl(libraryId), { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public getSettingsValuesFromBehaviorSubject() {
    const analysisSettings = {
      moleculeLabel: this.analysisSettingsBehaviorSubjects.moleculeLabel.value,
      reactionLabel: this.analysisSettingsBehaviorSubjects.reactionLabel.value,
      mode: this.analysisSettingsBehaviorSubjects.mode.value,
      layout: this.analysisSettingsBehaviorSubjects.layout.value,
      colorReactionDiamonds: this.analysisSettingsBehaviorSubjects.colorReactionDiamonds.value,
      colorReactionArrows: this.analysisSettingsBehaviorSubjects.colorReactionArrows.value,
      colorByReactivity: this.analysisSettingsBehaviorSubjects.colorByReactivity.value,
      nodeTypeIcons: this.analysisSettingsBehaviorSubjects.nodeTypeIcons.value,
      moleculeScaling: this.analysisSettingsBehaviorSubjects.moleculeScaling.value,
      pathDirection: this.analysisSettingsBehaviorSubjects.pathDirection.value,
      shopAvailability: this.analysisSettingsBehaviorSubjects.shopAvailability.value,
      modeIsDefault: this.analysisSettingsBehaviorSubjects.modeIsDefault,
      layoutIsDefault: this.analysisSettingsBehaviorSubjects.layoutIsDefault,
      filters: {
        selectedOnly: this.analysisSettingsBehaviorSubjects.filters.selectedOnly.value,
        groupByFamily: this.analysisSettingsBehaviorSubjects.filters.groupByFamily.value,
        minimumReactions: this.analysisSettingsBehaviorSubjects.filters.minimumReactions.value,
        maximumReactions: this.analysisSettingsBehaviorSubjects.filters.maximumReactions.value,
        anyMaximumReactions:
          this.analysisSettingsBehaviorSubjects.filters.anyMaximumReactions.value,
        conflicts: this.analysisSettingsBehaviorSubjects.filters.conflicts.value,
        protections: this.analysisSettingsBehaviorSubjects.filters.protections.value,
        regulated: this.analysisSettingsBehaviorSubjects.filters.regulated.value,
        pathScoreMin: this.analysisSettingsBehaviorSubjects.filters.pathScoreMin.value,
        pathScoreMax: this.analysisSettingsBehaviorSubjects.filters.pathScoreMax.value,
        pathScoreMaxInfinite:
          this.analysisSettingsBehaviorSubjects.filters.pathScoreMaxInfinite.value,
        analysisResultFilters: this.deleteSVGFromStructureFilters(
          this.analysisResultsService.resultsFilterSubject.value,
        ),
      },
    };
    return analysisSettings;
  }

  public deleteSVGFromStructureFilters(analysisResultFilters) {
    const deepCopiedFilters = getLocalCopy(analysisResultFilters);
    if (!deepCopiedFilters || isNullOrUndefined(deepCopiedFilters.structureFilter)) {
      return analysisResultFilters;
    }
    const { limitTo, exclude } = deepCopiedFilters.structureFilter;
    if (limitTo) {
      const { exact, similar, structure } = limitTo;
      if (exact && exact.criteria.length > 0) {
        this.deleteSVGProperty(exact.criteria);
      }
      if (similar && similar.criteria.length > 0) {
        this.deleteSVGProperty(similar.criteria);
      }
      if (structure && structure.criteria.length > 0) {
        this.deleteSVGProperty(structure.criteria);
      }
    }
    if (exclude) {
      const { exact, similar, structure } = exclude;
      if (exact && exact.criteria.length > 0) {
        this.deleteSVGProperty(exact.criteria);
      }
      if (similar && similar.criteria.length > 0) {
        this.deleteSVGProperty(similar.criteria);
      }
      if (structure && structure.criteria.length > 0) {
        this.deleteSVGProperty(structure.criteria);
      }
    }
    return deepCopiedFilters;
  }

  public deleteSVGProperty(structure) {
    for (const data of structure) {
      delete data.svg;
    }
  }

  public applyAnalysisVisualizationSettings(settings: AnalysisSettings) {
    for (const field of Object.keys(settings)) {
      if (
        this.analysisSettingsBehaviorSubjects[field] &&
        typeof this.analysisSettingsBehaviorSubjects[field] === 'boolean'
      ) {
        this.analysisSettingsBehaviorSubjects[field] = settings[field];
      } else if (this.analysisSettingsBehaviorSubjects[field] && field !== 'filters') {
        this.analysisSettingsBehaviorSubjects[field].next(settings[field]);
      } else {
        for (const filter of Object.keys(settings[field])) {
          if (settings.filters[filter]) {
            if (filter === 'analysisResultFilters') {
              this.analysisResultsService.resultsFilterSubject.next(settings.filters[filter]);
            } else {
              this.analysisSettingsBehaviorSubjects.filters[filter].next(settings.filters[filter]);
            }
          }
        }
      }
    }
  }

  public isMoleculeListActive() {
    return (
      this.analysisSettingsBehaviorSubjects.algorithm.value ===
        AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS ||
      this.analysisSettingsBehaviorSubjects.algorithm.value ===
        AnalysisAlgorithm.MANUAL_RETROSYNTHESIS ||
      this.analysisSettingsBehaviorSubjects.algorithm.value === AnalysisAlgorithm.LIBRARY_MODE ||
      this.analysisSettingsBehaviorSubjects.algorithm.value === AnalysisAlgorithm.DIVERSITY_LIBRARY
    );
  }

  private activateCacheCleaning() {
    if (Object.keys(this.graphApiCache).length > cacheSizeLimit) {
      this.cleanGraphApiCache();
    }
  }

  private cleanGraphApiCache() {
    const sortedCache = Object.keys(this.graphApiCache)
      .map((key) => Object.assign({}, this.graphApiCache[key], { analysisId: key }))
      .sort((a, b) => a.usedAt - b.usedAt);

    sortedCache.forEach((item, index) => {
      if (index < cacheSizeLimit / 2) {
        // clear ~half of cache
        if (this.graphApiCache[item.analysisId].usedAt === item.usedAt) {
          if (
            this.graphApiCache[item.analysisId].graphResultsSubscription &&
            !this.graphApiCache[item.analysisId].graphResultsSubscription.closed
          ) {
            this.graphApiCache[item.analysisId].graphResultsSubscription.unsubscribe();
          }
          delete this.graphApiCache[item.analysisId];
        }
      }
    });
  }

  public getTags(): Observable<Tag[]> {
    const tagsUrl: string = getBackendEntryPointTags();
    return this.http.get(tagsUrl, { observe: 'response', responseType: 'json' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          const responseJson = response.body;
          return responseJson.map((tag) => new Tag(tag));
        } catch (e) {
          throw 'Unexpected format of response.'; /* tslint:disable-line:no-string-throw */
        }
      }),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public saveAnalysisTags(analysis: AnalysisTags) {
    const analysisSettingsUrl = getBackendEntryPointTags();
    return this.http
      .post(analysisSettingsUrl, analysis, { observe: 'response' })
      .pipe(map((result) => result));
  }

  public setTagManagerId(analysisId: number) {
    this.tagManagerAnalysisId.next(analysisId);
  }

  public getDownloadPathRDKit(smiles: string): Observable<string> {
    const requestBody = {
      smiles: smiles,
    };
    return this.http
      .post(getRDKitDataReaction(), requestBody, { observe: 'response', responseType: 'json' })
      .pipe(
        map((result: HttpResponse<RDFDataReaction>) => {
          const reaction: RDFDataReaction = result.body;
          return reaction.structure;
        }),
      );
  }

  public getReactionSimilarityScore(reaction_ids: string[]): Observable<SimilarityScore[]> {
    const requestBody = {
      reaction_ids: reaction_ids,
    };
    return this.http
      .post(getBackendEntrySimilarityScore(), requestBody, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((result: HttpResponse<any>) => {
          const responseJson = result.body;
          return responseJson.map((similarity) => new SimilarityScore(similarity));
        }),
      );
  }

  public getSimilarChemicals(smiles: string) {
    const requestBody = { smiles };
    requestBody['dataset_filters'] = [];
    return this.http
      .post(getBackendEntryPointSimilarChemicals(), requestBody, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        map((result: HttpResponse<any>) => {
          const responseJson = result.body;
          return responseJson.map((similarity) => similarity);
        }),
      );
  }

  public getFavoriteSyntheticPath(analysisId: number) {
    return this.http
      .get(getBackendEntrySyntheticPathFavorite(analysisId), { observe: 'response' })
      .pipe(
        map((result: HttpResponse<any>) => {
          return result.body.favorite_synthetic_path_ids as any[];
        }),
      );
  }

  public getDiversityApiResults(analysisId: number, isCacheEnabled: boolean) {
    let queryParams: HttpParams;
    const diversityApiUrl: string = `${getBackendEntryPointDiversity(analysisId)}`;
    if (isCacheEnabled) {
      queryParams = new HttpParams().set('state', 'completed');
    }
    return this.http.get(diversityApiUrl, { params: queryParams, observe: 'response' }).pipe(
      map((result: HttpResponse<Object>) => {
        this.diversityApiResult = result.body;
        return this.diversityApiResult;
      }),
    );
  }
}
