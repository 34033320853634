type anyType = number | string | { key: string; value: number | string };

export class PathList {
  molecules: Molecule[];
  reactions: Reaction[];
  reaction_nodes: ReactionNode[];
  paths: Path[];
  total_pages: number;
  active_page_index: null;
  target_set_details: TargetSetDetail[];
}

export interface Molecule {
  id: string;
  mushroom: number;
  name: string;
  formula: string;
  smiles: string;
  mapped_smiles: null | string;
  cas: string[];
  in_degree: number;
  out_degree: number;
  mol_weight: number;
  real_mass: number | null;
  cost: number | null;
  log_p: number | null;
  log_p_cmx: null;
  brn: number[];
  regulatory_databases: anyType[];
  functional_groups: FunctionalGroups;
  vendors: Vendor[];
  customer_inventory_data: anyType[];
  compound_metadata: { [key: string]: CompoundMetadatum };
  references: string[];
  patents: string[];
  dois: string[];
}

export interface CompoundMetadatum {
  pubchem_cid: number | null;
  labels: string[] | null;
  carbon_footprint: number | null;
}

export interface FunctionalGroups {}

export interface Vendor {
  vendor: string;
  data: Data;
}

export interface Data {
  mw?: string;
  ppg: string;
  link: string;
  ppkg: string;
  mat_id?: string;
  empform?: string;
  vendor_id?: string;
  vendor_regid?: string;
  iupac?: string;
  syns?: string;
  brand?: string;
  density?: string;
  name_suffix?: string;
}

export interface Path {
  analysis: number;
  computation: number;
  created_at: Date;
  graph: number;
  id: number;
  path_hash: number;
  reaction_nodes: number[];
  score: number;
  target_score: string;
  updated_at: Date;
  building_blocks: FunctionalGroups;
  target_name: string;
  reactions_to_diversify: string[];
  nodes: anyType[];
  edges: anyType[];
}

export interface ReactionNode {
  id: number;
  base: Base;
  non_selective: boolean;
  diastereoselective: null;
  parent_molecule: string;
  reaction: string;
  analysis: number;
  parent_reaction_node: number | null;
  strategies: anyType[];
  dynamic_strategies: anyType[];
  tunnels: anyType[];
  child_molecule_masses_per_gram_of_target: { [key: string]: number };
  child_molecule_scores: FunctionalGroups;
  paths: number[];
}

export enum Base {
  Expert = 'expert',
  Spresi = 'spresi',
}

export interface Reaction {
  id: string;
  smiles: string;
  name: string;
  yield: null;
  doi: string;
  reaction_type: ReactionType;
  yield_level: YieldLevel;
  normalized_yield_level: string;
  reference: string;
  procedure: string;
  patent_number: string;
  conditions: null | string;
  publication_year: null;
  reaction_cost: null;
  substrates: string[];
  products: string[];
  substrates_with_unprotected_groups: FunctionalGroups;
  reference_doi: string[];
  reactions_data: { [key: string]: ReactionsDatum };
  rxids: number[];
  reaction_id: null;
  similarity_score: number;
}

export enum ReactionType {
  SingleReaction = 'Single Reaction',
  StaticReaction = 'Static Reaction',
}

export interface ReactionsDatum {
  name: string;
  conditions: null | string;
  reference: null | string;
  db_id: number | null;
  mapped_reaction_smiles: string;
  main_retron_disconnections: Array<number[]>;
  reaction_example: null;
  reaction_node: number;
  synthetic_path: number;
  patents: null;
  dois: string[] | null;
  reference_doi: string[];
}

export enum YieldLevel {
  Good = 'good',
  Moderate = 'moderate',
}

export interface TargetSetDetail {
  target_set_hash: number;
  unique_reactions: number;
  repeated_intermediates: number;
  shared_reaction_info: { [key: string]: SharedInfo }[];
  shared_molecule_info: { [key: string]: SharedInfo }[];
}

export interface SharedInfo {
  count: number;
  color: number[];
}
