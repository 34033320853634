import { isNull, isString } from '../../components/utils';
import { AssociatedUser } from '../social';
import { AnalysisAlgorithm } from '../app-constants/app-constants.service';
import { Tag } from './tag';
import { CommentsCount } from '../analysis';
import { ComputationEntryStatus } from '../analysis-results/analysis-results.service';

export class DashboardAnalysisEntry {
  private static algorithms = new Set([
    AnalysisAlgorithm.MINIMAL_COST,
    AnalysisAlgorithm.MANUAL_RETROSYNTHESIS,
    AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS,
    AnalysisAlgorithm.GREEDY_POPULARITY,
    AnalysisAlgorithm.COST_AND_POPULARITY,
    AnalysisAlgorithm.REVERSE_REACTION_NETWORK,
    AnalysisAlgorithm.REACTION_NETWORK,
    AnalysisAlgorithm.LIBRARY_MODE,
    AnalysisAlgorithm.DIVERSITY_LIBRARY,
  ]);

  private static dateFields = new Set(['created_at', 'removed_at', 'shared_at']);

  /* tslint:disable:variable-name */
  public id: number = 0;
  public name: string = '';
  public target_name: string = '';
  public is_favorite: boolean = false;
  public algorithm: string = '';
  public created_at: Date = null;
  public smiles: string = '';
  public computations: ComputationEntryStatus = {
    pending: 0,
    in_queue: 0,
    in_progress: 0,
    success: 0,
    error: 0,
    interrupted_by_user: 0,
    stopped: 0,
    failure: 0,
    started: 0,
    aborted: 0,
  };
  public empty: boolean = false;
  public copied: boolean = false;
  public copied_from: string = '';
  public folder: number = 0;
  public batch: number = 0;
  public removed_at: Date = null;
  public warnings: number = 0;
  public molfile: string = '';
  public shared_with: AssociatedUser[] = [];
  public owner: AssociatedUser = null;
  public shared_at: Date = null;
  public tags: Tag[] = [];
  public comments_count: CommentsCount = { unread: 0, total: 0 };
  public share_count: number = 0;
  public rerun: boolean = false;
  public number_of_molecules_generated_for_diversity_analysis: number = 0;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (field === 'algorithm') {
          if (DashboardAnalysisEntry.algorithms.has(jsonData[field])) {
            this[field] = jsonData[field];
          } else {
            this[field] = null;
          }
        } else if (DashboardAnalysisEntry.dateFields.has(field)) {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else if (field === 'shared_with' && Array.isArray(jsonData[field])) {
          try {
            this[field] = jsonData[field].map((user) => new AssociatedUser(user));
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field].toString()));
          }
        } else if (field === 'owner') {
          try {
            this[field] = new AssociatedUser(jsonData[field]);
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else {
          if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
            this[field] = jsonData[field];
          } else {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to DashboardAnalysisEntry:ctor.`,
        );
      }
    }
  }

  public isBatchAnalysis(): boolean {
    return !isNull(this.batch);
  }

  public isSingleAnalysis(): boolean {
    return isNull(this.batch) && isNull(this.shared_at);
  }

  public isFavoritingAllowed(): boolean {
    return !this.removed_at;
  }

  public isMovingAllowed(): boolean {
    return !this.removed_at && !this.shared_at && !this.batch;
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to DashboardAnalysisEntry.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
