<mat-sidenav-container>
  <mat-sidenav #sideNav>
    <div class="cart-widget-container">
      <div class="cart-header">
        <div class="cart-header-content">
          <div>YOUR LIST ({{ activeCartItemList.length }})</div>
          <div>
            <mat-icon class="clickable-icon" (click)="closeSideNav()">clear</mat-icon>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="authorized-content" *ngIf="isUserValidated === 'true'">
        <div class="functional-area">
          <div class="select-all">
            <mat-checkbox
              class="check-box"
              (change)="onCheckBoxChange($event)"
              [(ngModel)]="isAllItemSelected"
              [disabled]="this.activeCartItemList.length === 0"
            ></mat-checkbox>
            <span class="content">Select All</span>
          </div>
          <div class="buttons">
            <div class="icon-with-word" (click)="exportShoppingList()">
              <mat-icon>south</mat-icon>
              <div>Export</div>
            </div>
            <mat-divider [vertical]="true" class="divider-between-buttons"></mat-divider>
            <div class="icon-with-word" (click)="RemoveItems()">
              <mat-icon *ngIf="isRemovingItems !== true"> delete_outline </mat-icon>
              <mat-spinner
                [strokeWidth]="2"
                [diameter]="20"
                *ngIf="isRemovingItems"
                [color]="primary"
              ></mat-spinner>
              <div>Remove</div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="cart-item-list">
          <div *ngFor="let item of activeCartItemList">
            <ch-cart-item [activeCartItem]="item"></ch-cart-item>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="cart-footer">
          <button
            mat-raised-button
            class="btn"
            [disabled]="isGettingCheckoutUrl"
            (click)="checkOut()"
            *ngIf="activeCartItemList.length > 0"
          >
            <span [hidden]="isGettingCheckoutUrl">Buy Online</span>
            <span [hidden]="isGettingCheckoutUrl === false">
              <mat-spinner [strokeWidth]="2" [diameter]="20"></mat-spinner>
            </span>
          </button>
        </div>
      </div>
      <div *ngIf="isUserValidated !== 'true'">
        <div *ngIf="isWaitingUserAuth; else noAuthTemplate" class="wait-ecomm-auth">
          <span>Waiting Authentication from Sigma-Aldrich ...</span>
          <mat-spinner [strokeWidth]="2" [diameter]="15" color="primary"></mat-spinner>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
<ng-template #noAuthTemplate>
  <div class="wait-ecomm-auth">
    <span>You are not authorized by Sigma-Aldrich at this moment.</span>
  </div>
</ng-template>
