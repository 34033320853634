import { stringFormat } from '../../../components/utils';

export class HashingTool {
  /* tslint:disable:variable-name */
  public name: string = '';
  public os: string = '';
  public size: number = 0;
  public link: string = '';
  /* tslint:enable:variable-name */

  constructor(HashingToolData: any) {
    const invalidFieldMessage: string = 'Unexpected field {0} in data provided to HashingTool';
    const invalidFieldValueMessage: string = `
      Unexpected value of field '{0}' in data provided to HashingTool.
      \nUsed default value of '{0}' instead of provided '{1}'.
    `;

    for (const field of Object.keys(HashingToolData)) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof HashingToolData[field] === typeof this[field]) {
          this[field] = HashingToolData[field];
        } else {
          console.error(stringFormat(invalidFieldValueMessage, field, HashingToolData[field]));
        }
      } else {
        console.error(stringFormat(invalidFieldMessage, field, HashingToolData[field]));
      }
    }
  }
}
