import { ComputationEntry } from './computation-entry';
import { isNull, isString } from '../../../components/utils';
import { AssociatedUser } from '../../social/models/associated-user';
import { AnalysisAlgorithm } from '../../app-constants/app-constants.service';
import { AnalysisSettings } from '../../models/analysis-settings';
import { DiversityResources } from './diversity-resources';

export enum GraphType {
  STANDARD = 'STANDARD',
  REVERSED = 'REVERSED',
}

export class AnalysisEntry {
  private static graphTypes = new Set([GraphType.STANDARD, GraphType.REVERSED]);
  private static dateFields = new Set([
    'created_at',
    'updated_at',
    'last_fetched',
    'removed_at',
    'shared_at',
  ]);

  /* tslint:disable:variable-name */
  public id: number = 0;
  public user: number = 0;
  public name: string = '';
  public is_favorite: boolean = false;
  public graph_type: GraphType = GraphType.STANDARD;
  public target_name: string = '';
  public created_at: Date = null;
  public updated_at: Date = null;
  public last_fetched: Date = null;
  public num_active_computations: number = 0;
  public smiles: string = '';
  public initial_computation: ComputationEntry | null = null;
  public user_settings: AnalysisSettings = null;
  public copied: boolean = false;
  public copied_from: string = '';
  public removed_at: Date = null;
  public folder: number = 0;
  public batch: number = 0;
  public graph_hash: string = '';
  public shared_at: Date = null;
  public shared_with: AssociatedUser[] = [];
  public owner: AssociatedUser = null;
  public total_pages: number = 0;
  public total_no_paths: number = 0;
  public rerun: boolean = false;
  public share_count: number = 0;
  public number_of_molecules_generated_for_diversity_analysis?: number;
  public resources_for_diversity_rerun?: DiversityResources;
  /* tslint:enable:variable-name */

  constructor(data?: any) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      this.number_of_molecules_generated_for_diversity_analysis =
        jsonData['number_of_molecules_generated_for_diversity_analysis'];
      this.resources_for_diversity_rerun = jsonData['resources_for_diversity_rerun'];
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (field === 'graph_type') {
          if (AnalysisEntry.graphTypes.has(jsonData[field])) {
            this[field] = jsonData[field];
          } else {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else if (AnalysisEntry.dateFields.has(field)) {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else if (field === 'initial_computation' && jsonData[field] !== null) {
          try {
            this[field] = new ComputationEntry(jsonData[field]);
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else if (field === 'shared_with' && Array.isArray(jsonData[field])) {
          try {
            this[field] = jsonData[field].map((user) => new AssociatedUser(user));
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else if (field === 'owner') {
          try {
            this[field] = new AssociatedUser(jsonData[field]);
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else {
          if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
            this[field] = jsonData[field];
          } else {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to AnalysisEntry.`);
      }
    }
  }

  public isBatchAnalysis(): boolean {
    return !isNull(this.batch);
  }

  public isSingleAnalysis(): boolean {
    return isNull(this.batch) && isNull(this.shared_at);
  }

  public isFavoritingAllowed(): boolean {
    return !this.removed_at;
  }

  public isRemoved(): boolean {
    return this.removed_at !== null;
  }

  public isShared(): boolean {
    return this.shared_at !== null;
  }

  public isAlgorithmWithoutPathways() {
    if (this.initial_computation) {
      return (
        this.initial_computation.algorithm === AnalysisAlgorithm.MANUAL_RETROSYNTHESIS ||
        this.initial_computation.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK ||
        this.initial_computation.algorithm === AnalysisAlgorithm.REACTION_NETWORK
      );
    }
    return false;
  }

  public isAutomaticRetrosynthesis() {
    if (this.initial_computation) {
      return this.initial_computation.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS;
    }
    return false;
  }

  public isManualRetrosynthesis() {
    if (this.initial_computation) {
      return this.initial_computation.algorithm === AnalysisAlgorithm.MANUAL_RETROSYNTHESIS;
    }
    return false;
  }

  public isLibraryModeRetrosynthesis() {
    if (this.initial_computation) {
      return this.initial_computation.algorithm === AnalysisAlgorithm.LIBRARY_MODE;
    }
    return false;
  }

  public isDiversityModeRetrosynthesis() {
    if (this.initial_computation) {
      return this.initial_computation.algorithm === AnalysisAlgorithm.DIVERSITY_LIBRARY;
    }
    return false;
  }

  public isNOCAnalysis() {
    if (this.initial_computation) {
      return (
        this.initial_computation.algorithm === AnalysisAlgorithm.COST_AND_POPULARITY ||
        this.initial_computation.algorithm === AnalysisAlgorithm.REACTION_NETWORK ||
        this.initial_computation.algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK
      );
    }
    return false;
  }

  public isReactionNetwork() {
    if (this.initial_computation) {
      return this.initial_computation.algorithm === AnalysisAlgorithm.REACTION_NETWORK;
    }
    return false;
  }

  public isFavorited() {
    return this.is_favorite;
  }

  public hasPendingComputations() {
    return this.num_active_computations > 0;
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to AnalysisEntry.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
