<mat-card
  [class.ch-password-change-card]="!!passwordChangeToken"
  [class.ch-password-change-card-in-dialog]="!passwordChangeToken"
  [class.ch-password-change-card-registration-view]="isRegistrationView"
>
  <div *ngIf="!isRegistrationView" class="ch-dialog-top-new">
    <span>Change Password</span>
    <mat-icon *ngIf="!passwordChangeToken" (click)="dialogRef.close()" class="icon-18">
      close
    </mat-icon>
  </div>

  <form
    *ngIf="!success && !isTokenExpiredOrInvalid && !isPendingTokenVerification"
    (ngSubmit)="changePassword()"
    autocomplete="off"
    #passwordChangeForm="ngForm"
    name="passwordChangeForm"
    chEqualFields="newPasswordInput, newPasswordConfirmationInput"
  >
    <div
      [class.ch-dialog-body-new]="!isRegistrationView"
      [class.change-password-container]="isRegistrationView"
    >
      <div *ngIf="isRegistrationView" class="registration-view-message">
        Create a secure password for your SYNTHIA<sup class="ch-trade-mark">TM</sup> account.
      </div>
      <mat-progress-bar
        *ngIf="pendingApiCall"
        class="ch-password-change-progress"
        mode="indeterminate"
        color="primary"
      ></mat-progress-bar>
      <div class="input-row" [class.hidden-input-row]="!!passwordChangeToken">
        <mat-label class="value-label" [class.card-value-label]="isRegistrationView"
          >Present Password</mat-label
        >
        <mat-form-field
          class="value-field ch-form-field ch-text-field"
          [class.input-error]="
            (!presentPasswordInput.valid || !!presentPasswordError) &&
            !presentPasswordInput.pristine
          "
          appearance="outline"
        >
          <input
            matInput
            ch-autofocus
            [readonly]="!!passwordChangeToken"
            name="presentPasswordInput"
            #presentPasswordInput="ngModel"
            [(ngModel)]="presentPassword"
            type="password"
            placeholder="Enter Your Current Password"
            required
            [maxlength]="maxPasswordLength"
            (input)="onInputPassword($event)"
          />
          <mat-hint
            *ngIf="
              (!presentPasswordInput.valid || !!presentPasswordError) &&
              !presentPasswordInput.pristine
            "
            align="end"
            class="ch-clr-warn"
          >
            <span *ngIf="presentPasswordError">{{ presentPasswordError }}</span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="input-row">
        <mat-label class="value-label" [class.card-value-label]="isRegistrationView">
          New Password
          <mat-icon
            matSuffix
            matTooltip="Your password should be at least {{
              minPasswordLength
            }} characters long, contain upper and lower-case letters, and at least one number and one special characters (!@$#%^&*_)"
            matTooltipPosition="above"
            matTooltipClass="ch-tooltip"
          >
            info_outline
          </mat-icon>
        </mat-label>
        <mat-form-field
          class="value-field ch-form-field ch-text-field"
          [class.input-error]="
            (passwordChangeForm.hasError('chEqualFields') &&
              !(newPasswordInput.pristine || newPasswordConfirmationInput.pristine)) ||
            (!newPasswordInput.valid && !newPasswordInput.pristine)
          "
          appearance="outline"
        >
          <input
            matInput
            name="newPasswordInput"
            #newPasswordInput="ngModel"
            [(ngModel)]="newPassword"
            (input)="onInputPassword($event)"
            type="password"
            required
            [minlength]="minPasswordLength"
            [maxlength]="maxPasswordLength"
            pattern="{{ passwordPattern }}"
            [placeholder]="newPasswordPlaceholder"
          />
          <mat-hint *ngIf="!newPasswordInput.valid && !newPasswordInput.pristine" align="end">
            <span *ngIf="newPasswordInput.hasError('minlength')"
              >At least {{ minPasswordLength }} characters required</span
            >
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="input-row">
        <mat-label class="value-label" [class.card-value-label]="isRegistrationView">
          <span *ngIf="!isRegistrationView">Repeat</span>
          <span *ngIf="isRegistrationView">Confirm</span> New Password
        </mat-label>
        <mat-form-field
          class="value-field ch-form-field ch-text-field"
          [class.input-error]="
            (passwordChangeForm.hasError('chEqualFields') &&
              !(newPasswordInput.pristine || newPasswordConfirmationInput.pristine)) ||
            (!newPasswordConfirmationInput.valid && !newPasswordConfirmationInput.pristine)
          "
          appearance="outline"
        >
          <input
            matInput
            name="newPasswordConfirmationInput"
            #newPasswordConfirmationInput="ngModel"
            [(ngModel)]="newPasswordConfirmation"
            (input)="onInputPassword($event)"
            type="password"
            required
            [minlength]="minPasswordLength"
            [maxlength]="maxPasswordLength"
            pattern="{{ passwordPattern }}"
            [placeholder]="repeatNewPasswordPlaceholder"
          />
          <mat-hint
            *ngIf="!newPasswordConfirmationInput.valid && !newPasswordConfirmationInput.pristine"
            align="end"
            [class.error]="true"
          >
            <span *ngIf="newPasswordConfirmationInput.hasError('minlength')"
              >At least {{ minPasswordLength }} characters required</span
            >
          </mat-hint>
        </mat-form-field>
      </div>

      <mat-error
        *ngIf="
          passwordChangeForm.hasError('chEqualFields') &&
          !newPasswordInput.pristine &&
          !newPasswordConfirmationInput.pristine
        "
      >
        Passwords do not match
      </mat-error>

      <mat-error *ngIf="newPasswordInput.hasError('pattern')">
        Password does not meet security requirements
      </mat-error>

      <ng-container *ngIf="apiCallError.length">
        <mat-error *ngFor="let err of apiCallError">{{ err }}</mat-error>
      </ng-container>
    </div>

    <div class="ch-dialog-btn-section-new" [class.text-align-center]="isRegistrationView">
      <button
        *ngIf="!passwordChangeToken"
        mat-button
        class="ch-outline-btn"
        [buttonOutline]="primary"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="
          !passwordChangeForm.valid ||
          !presentPasswordInput.valid ||
          !newPasswordInput.valid ||
          !newPasswordConfirmationInput.valid ||
          pendingApiCall
        "
      >
        <span *ngIf="!isRegistrationView">Set New Password</span>
        <span *ngIf="isRegistrationView">Submit</span>
      </button>
    </div>
  </form>

  <div class="ch-dialog-body-new" *ngIf="isPendingTokenVerification">
    <mat-progress-bar
      class="ch-password-change-progress"
      mode="indeterminate"
      color="primary"
    ></mat-progress-bar>
  </div>

  <div class="ch-dialog-body-new" *ngIf="success">
    <p *ngIf="!isRegistrationView" class="registration-view-message">Your new password is set</p>
    <p *ngIf="isRegistrationView" class="registration-view-message">
      Your account has been successfully activated.
    </p>
  </div>

  <div class="ch-dialog-expired-msg" *ngIf="isTokenExpiredOrInvalid">
    <p *ngIf="!isNewTokenSent" class="registration-view-message">
      Your registration link expired. Click the button to get a new one.
    </p>

    <p *ngIf="isNewTokenSent" class="registration-view-message new-link-sent-message">
      We sent you a new email with instructions. Please try again.
    </p>
  </div>

  <div
    *ngIf="success"
    class="ch-dialog-btn-section-new"
    [ngClass]="isRegistrationView ? 'dialog-button-style' : 'ok-button-style'"
    [class.text-align-center]="isRegistrationView"
  >
    <button mat-raised-button (click)="onOkClick()" color="primary">
      <span *ngIf="!isRegistrationView">OK</span>
      <span *ngIf="isRegistrationView">Sign In</span>
    </button>
  </div>

  <div
    *ngIf="isTokenExpiredOrInvalid && !isNewTokenSent"
    class="ch-dialog-btn-section-new text-align-center"
  >
    <button
      mat-raised-button
      (click)="resendConfirmationEmail()"
      color="primary"
      [disabled]="pendingApiCall"
    >
      Resend Confirmation Email
    </button>
  </div>
</mat-card>
