<div class="ch-graph-key">
  <h3 class="ch-title" *ngIf="!showOnlyMoleculeRelatedTags">LEGEND</h3>
  <div *ngIf="isPathView; then pathView; else graphView"></div>
  <ng-template #graphView>
    <ul [@fadeInOut] *ngIf="enableLegendView" class="ch-graph-key-list">
      <li *ngIf="showTag(moleculeNodeTagType.NODE_TARGET)">
        <div class="ch-graph-key-icon graph-key-target"></div>
        <span class="ch-graph-key-label"> Retron / Target (◉) </span>
      </li>
      <li
        class="commercial-available-container"
        *ngIf="showTag(moleculeNodeTagType.NODE_COMMERCIALLY_AVAILABLE)"
      >
        <div class="icon-label-container">
          <div class="ch-graph-key-icon graph-key-available"></div>
          <span class="ch-graph-key-label"> Commercially available ($) </span>
        </div>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_UNKNOWN)">
        <div class="ch-graph-key-icon graph-key-unknown"></div>
        <span class="ch-graph-key-label"> Unknown chemical (?) </span>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_KNOWN)">
        <div class="ch-graph-key-icon graph-key-known"></div>
        <span class="ch-graph-key-label"> Known chemical (☼︎) </span>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_SA_AVAILABLE)">
        <div class="ch-graph-key-icon graph-key-shop-link">
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.59 0L4 6.99575L1.41 4.2569L0 5.75372L4 10L12 1.50743L10.59 0Z"
              fill="white"
            />
          </svg>
        </div>
        <span class="ch-graph-key-label"> Available at SigmaAldrich.com </span>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_CUSTOM_INVENTORY)">
        <div class="ch-graph-key-icon graph-key-user-inventory"></div>
        <span class="ch-graph-key-label"> Available in end-user inventory </span>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_SECONDARY)">
        <div class="ch-graph-key-icon graph-key-secondary"></div>
        <span class="ch-graph-key-label"> Secondary </span>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_REGULATED)">
        <div class="ch-graph-key-icon graph-key-regulated"></div>
        <span class="ch-graph-key-label"> Regulated Substance </span>
      </li>
      <li *ngIf="showTag(moleculeNodeTagType.NODE_PROTECTION_NEEDED)">
        <div class="ch-graph-key-icon graph-key-protection-needed">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="8.5" stroke="white" stroke-width="3" />
            <circle
              cx="10"
              cy="10"
              r="8.5"
              stroke="#009CE1"
              stroke-width="3"
              stroke-dasharray="4 4"
            />
          </svg>
        </div>
        <span class="ch-graph-key-label"> Protection needed </span>
      </li>
      <ng-container *ngIf="!showOnlyMoleculeRelatedTags">
        <li>
          <div class="ch-graph-key-reaction ch-graph-key-expert"></div>
          <span class="ch-graph-key-label"> Prediction based on expert-coded rule </span>
        </li>
        <li>
          <div class="ch-graph-key-reaction ch-graph-key-heterocycles"></div>
          <span class="ch-graph-key-label"> Prediction based on machine-extracted rule </span>
        </li>
        <li>
          <div class="ch-graph-key-reaction ch-graph-key-published"></div>
          <span class="ch-graph-key-label"> Published reaction </span>
        </li>
        <li class="ch-graph-key-reaction-arrow-container">
          <div class="ch-graph-key-reaction-arrow ch-graph-key-non-selective">
            <img src="../assets/img/non-selective-diasteroselective-only-arrow.svg" />
          </div>
          <span class="ch-graph-key-label">
            Non-selective or <br />
            Diasteroselective only
          </span>
        </li>
        <li class="ch-graph-key-reaction-arrow-container">
          <div class="ch-graph-key-reaction-arrow ch-graph-key-strategy-tunnels">
            <img class="logo" src="../assets/img/strategies-tunnels-arrow.svg" />
          </div>
          <span class="ch-graph-key-label"> Power Search or Strategies </span>
        </li>
        <li>
          <div class="warning-graph-key">
            <mat-icon>warning</mat-icon>
          </div>
          <span class="ch-graph-key-label"> Warning </span>
        </li>
      </ng-container>
    </ul>
  </ng-template>
  <ng-template #pathView>
    <ul [@fadeInOut] *ngIf="enableLegendView" class="ch-graph-key-list">
      <li>
        <div class="star-graph-key">
          <mat-icon svgIcon="ch:book100"></mat-icon>
        </div>
        <span class="ch-graph-key-label"> Published reaction </span>
      </li>
      <li>
        <div class="graph-key">
          <mat-icon svgIcon="ch:book3star"></mat-icon>
        </div>
        <span class="ch-graph-key-label">
          Highly similar published reactions<br />(top reaction similarity > 0.9)
        </span>
      </li>
      <li>
        <div class="graph-key">
          <mat-icon svgIcon="ch:book2star"></mat-icon>
        </div>
        <span class="ch-graph-key-label">
          Similar published reactions<br />(top reaction similarity > 0.5)
        </span>
      </li>
      <li>
        <div class="graph-key">
          <mat-icon svgIcon="ch:book1star"></mat-icon>
        </div>
        <span class="ch-graph-key-label">
          Similar published reactions<br />(any similar reactions)
        </span>
      </li>
      <li>
        <div class="star-graph-key">
          <mat-icon svgIcon="ch:molset-ind"></mat-icon>
        </div>
        <span class="ch-graph-key-label"> Add to Shopping List </span>
      </li>
      <li class="star-graph-key">
        <div class="ch-graph-key-reaction-arrow ch-graph-key-non-selective">
          <img src="../assets/img/non-selective-diasteroselective-only-arrow.svg" />
        </div>
        <span class="ch-graph-key-label"> Non-selective <br />or diasteroselective only </span>
      </li>
      <li class="ch-graph-key-reaction-arrow-container">
        <div class="ch-graph-key-reaction-arrow ch-graph-key-strategy-tunnels">
          <img class="logo" src="../assets/img/strategies-tunnels-arrow.svg" />
        </div>
        <span class="ch-graph-key-label"> Power Search or Strategies </span>
      </li>
      <li>
        <div class="star-graph-key">
          <mat-icon svgIcon="ch:known_molecule"></mat-icon>
        </div>
        <span class="ch-graph-key-label"> Published molecule (occurences as substrate) </span>
      </li>
      <li>
        <div class="graph-key">
          <mat-icon svgIcon="ch:protection"></mat-icon>
        </div>
        <span class="ch-graph-key-label"> Protecting group required </span>
      </li>
      <li>
        <div class="graph-key">
          <mat-icon svgIcon="ch:regulated"></mat-icon>
        </div>
        <span class="ch-graph-key-label"> Regulated substance </span>
      </li>
      <li>
        <div class="graph-key">
          <mat-icon svgIcon="ch:flask"></mat-icon>
        </div>
        <span class="ch-graph-key-label"> Inventory compound </span>
      </li>
    </ul>
  </ng-template>
</div>
