import { Subject } from 'rxjs';
import { takeUntil, flatMap, finalize } from 'rxjs/operators';
import {
  Component,
  Input,
  OnDestroy,
  OnChanges,
  OnInit,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  ImageCreatorType,
  MoleculeImageService,
  MoleculeImageZoomMode,
} from './molecule-image.service';
import { MoleculeSearchService } from '../../services/molecule-search/molecule-search.service';
import { CopyToClipboardService } from '../../services/copy-to-clipboard.service';
import { InfoService } from '../../services/info.service';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from '../../animations';
import { AnalysisService, GraphNodePopupService, ParsedConnectionError } from '../../services';
import { RDKitModule } from '@rdkit/rdkit/dist';
import { ViewType } from 'src/app/shared/services/molecule-sets';
import { DisplayType } from 'src/app/shared/services/molecule-search';
import { checkReactingCenterStatusIsCenterOrNotCenter } from '../utils';
import { PathwaySubstrates } from '../../services/analysis/models/pathway-substrates';
import { GraphMoleculeNode, GraphReactionNode } from '../../services/graph-builder';
@Component({
  selector: 'ch-molecule-image',
  templateUrl: './molecule-image.component.html',
  styleUrls: ['./molecule-image.component.scss'],
  animations: [FADE_IN_ANIMATION, FADE_OUT_ANIMATION],
})
export class MoleculeImageComponent implements OnDestroy, OnChanges, OnInit {
  @Input() public format: string = 'svg';
  @Input() public width: number = 100;
  @Input() public height: number = 100;
  @Input() public minWidth: number;
  @Input() public hideSpinner: boolean = false;
  @Input() public formula: string;
  @Input() public lazyLoad: boolean = false;
  @Input() public allowStructureCopying: boolean = true;
  @Input() public hostElementHovered: boolean = false;
  @Input() public inputFormat: 'smiles' | 'mol' | 'smarts' = 'smiles';
  @Input() public viewType: ViewType;
  // there is a guard for this union type in ngOnChanges
  @Input() public formulaType: 'molecule' | 'reaction' = 'molecule';
  @Input() public imageCreator: ImageCreatorType = ImageCreatorType.WEBSERVICES;
  @Input() public protectionSmarts: boolean = false;
  @Input() public buildingBlockTargetSvg: string;
  @Input() public substrates: PathwaySubstrates;
  @Input() public disabled: boolean;
  @Input() public node: GraphMoleculeNode | GraphReactionNode;
  @ViewChild('imageWrapper') public imageWrapper: ElementRef;

  public imageRendered: boolean = false;
  public imageInitialized: boolean = false;
  public loading: boolean = false;
  public error: boolean = false;
  public errorMessage: string = '';
  public molfile: string = '';
  public moleculeChanged: boolean = true;
  public copySuccessMessage: string = '';

  private visible: boolean = false;
  private lazyLoadTimer: number;
  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private errorsHandler: any;
  private timer: any;

  constructor(
    public popupService: GraphNodePopupService,
    private moleculeImageService: MoleculeImageService,
    private moleculeSearchService: MoleculeSearchService,
    private copyToClipboardService: CopyToClipboardService,
    private renderer: Renderer2,
    private infoService: InfoService,
    private analysisService: AnalysisService,
  ) {}

  public ngOnDestroy() {
    clearTimeout(this.timer);
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public onVisibilityChange(isVisible: boolean) {
    if (
      this.lazyLoad &&
      isVisible &&
      !this.visible &&
      !this.isImageAvailable() &&
      !this.lazyLoadTimer
    ) {
      this.lazyLoadTimer = window.setTimeout(() => {
        // window. to avoid return type ambiguity due to node.js types
        this.lazyLoadTimer = undefined;
        if (this.visible && !this.isImageAvailable()) {
          this.renderAndSetInit();
        }
      }, 250);
    }
    this.visible = isVisible;
  }

  public isImageAvailable() {
    return !this.loading && !this.error && this.imageRendered;
  }

  public renderImage() {
    this.loading = true;
    this.moleculeImageService
      .render(
        this.formula,
        this.inputFormat,
        this.format,
        this.width,
        this.height,
        MoleculeImageZoomMode.FIT,
        this.imageCreator,
      )
      .pipe(
        takeUntil(this.unsubscriberSubject),
        finalize(() => {
          this.loading = false;
          this.imageRendered = true;
        }),
      )
      .subscribe(
        (dataUrl: string) => {
          if (!dataUrl) {
            this.error = true;
            this.errorMessage = 'Could not load image.';
            return;
          }
          if (this.imageRendered) {
            const wrapperChildren: any[] = this.imageWrapper.nativeElement.children;
            for (const childElement of wrapperChildren) {
              this.renderer.removeChild(this.imageWrapper.nativeElement, childElement);
            }
          }
          this.error = false;
          let imageElement: HTMLElement;
          if (this.imageRendered) {
            const wrapperChildren: any[] = this.imageWrapper.nativeElement.children;
            for (const childElement of wrapperChildren) {
              this.renderer.removeChild(this.imageWrapper.nativeElement, childElement);
            }
          }
          if (this.format === 'svg') {
            // sanitize to reduce xss injection type attack vulnerability
            dataUrl = dataUrl.replace('<script>', '').replace('javascript:', '');
            imageElement = this.renderer.createElement('div');
            imageElement.style.display = 'flex';
            this.renderer.setStyle(imageElement, 'margin', `auto`);
            this.renderer.setProperty(imageElement, 'innerHTML', dataUrl);
          } else {
            imageElement = this.renderer.createElement('img');
            this.renderer.setProperty(imageElement, 'src', dataUrl);
          }
          this.renderer.appendChild(this.imageWrapper.nativeElement, imageElement);
          this.renderer.setStyle(imageElement, 'max-height', `${this.height}px`);
          this.renderer.setStyle(imageElement, 'max-width', `${this.width}px`);
        },
        (error) => {
          this.error = true;
          if (error.message.includes('Status code:400')) {
            this.errorMessage = 'Wrong SMILES';
          } else {
            this.errorMessage = 'Could not load image.';
          }
        },
      );
  }

  public getMolFile() {
    return this.moleculeImageService.getMolFile(this.formula);
  }

  public copyContent(content: string) {
    return this.copyToClipboardService.copyContent(content);
  }

  public getStructureEvent() {
    if (this.moleculeChanged) {
      if (this.formulaType === 'reaction') {
        this.getRxnfileFromRdkit();
      } else {
        this.getMolFile()
          .pipe(
            flatMap((molfile: string) => {
              this.molfile = molfile;
              this.moleculeChanged = false;
              return this.copyContent(molfile);
            }),
            takeUntil(this.unsubscriberSubject),
          )
          .subscribe((success) => {
            this.displayCopyStructureSuccessMessage(success);
          });
      }
    } else {
      this.copyContent(this.molfile)
        .pipe(takeUntil(this.unsubscriberSubject))
        .subscribe((success) => {
          this.displayCopyStructureSuccessMessage(success);
        });
    }
  }

  public displayCopyStructureSuccessMessage(success) {
    if (success) {
      this.copySuccessMessage = `${
        this.formulaType === 'molecule' ? 'Structure' : 'Reaction'
      } copied as ${this.formulaType === 'molecule' ? 'molfile' : 'rxnfile'} format.`;
      this.displayAndHideMolfileCopiedNotification();
    }
  }

  public getMatTooltip() {
    if (this.formulaType === 'molecule') {
      return 'Copy compound structure as MOLfile.';
    } else if (this.formulaType === 'reaction') {
      return 'Copy reaction structures as RXN file.';
    }
  }

  public ngOnInit() {
    if (this.lazyLoad) {
      this.onVisibilityChange(true);
    } else {
      this.imageInitialized = false;
      this.renderAndSetInit();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.lazyLoad) {
      if (this.lazyLoadTimer) {
        clearTimeout(this.lazyLoadTimer);
        this.lazyLoadTimer = undefined;
      }
      if (changes.formula) {
        this.moleculeChanged = true;
        if (!this.hideSpinner) {
          this.loading = true;
        }
        this.renderAndSetInit();
      }
    }
    if (changes.formulaType) {
      if (!['molecule', 'reaction'].includes(changes.formulaType.currentValue)) {
        this.formulaType = 'molecule';
      }
    }
    if (changes.width && changes.width.previousValue !== changes.width.currentValue) {
      this.renderAndSetInit();
    }
    if (
      changes.buildingBlockTargetSvg &&
      changes.buildingBlockTargetSvg.previousValue !== changes.buildingBlockTargetSvg.currentValue
    ) {
      this.renderAndSetInit();
    }
  }

  public renderAndSetInit() {
    // To avoid race condition, rendering will run after timeout
    this.loading = true;
    this.imageInitialized = true;
    cancelAnimationFrame(this.timer);
    this.timer = requestAnimationFrame(() => {
      // This condition is added for solving the issue with reaction report when rendering using rdkit
      // Can be removed once the functionality is acheived
      if (this.formulaType === 'reaction') {
        this.renderImage();
      } else {
        if (this.protectionSmarts) {
          this.renderImageForProtectionSmarts(this.formula, this.width, this.height);
        } else {
          if (this.buildingBlockTargetSvg) {
            this.renderSvgImage(this.buildingBlockTargetSvg);
          } else if (this.substrates) {
            this.renderSvgImage(this.substrates.svg);
          } else {
            this.renderImageWithRdkit();
          }
        }
      }
    });
  }

  public renderImageForProtectionSmarts(formula: string, width: number, height: number) {
    this.moleculeSearchService
      .getImageForProtectionSmarts(formula, width, height)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (svgImage) => {
          this.renderSvgImage(svgImage);
        },
        (error) => {
          this.apiCallError(error);
        },
      );
  }

  public renderImageWithRdkit() {
    this.loading = true;
    let displayType = DisplayType.Molecule;
    if (this.viewType === ViewType.SmartsSets) {
      displayType = DisplayType.Substructure;
    }
    if (this.inputFormat === 'smiles' || this.inputFormat === 'smarts') {
      this.getSvgFromRdkit(displayType);
    } else {
      if (!checkReactingCenterStatusIsCenterOrNotCenter(this.formula)) {
        this.getSvgFromRdkit(displayType);
      } else {
        this.getSvgFromMolFile(this.formula, this.width, this.height);
      }
    }
  }

  public dispatchNodeEvent() {
    if (this.substrates || this.popupService.isSimilarMoleculePopupInfo.value) {
      if (!this.disabled) {
        this.popupService.dispatchNodeEvent(this.node, 'details', true);
      }
    }
  }

  private displayAndHideMolfileCopiedNotification() {
    this.infoService.showInfo(this.copySuccessMessage);
  }

  private apiCallError(error: any) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
    } else if (parsedError.isRecognized()) {
      if (parsedError.status === 403) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage);
      } else {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      }
    }
  }

  private getRxnfileFromRdkit() {
    this.analysisService
      .getDownloadPathRDKit(this.formula)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (reaction: string) => {
          this.displayCopyStructureSuccessMessage(true);
          this.moleculeChanged = false;
          return this.copyContent(reaction);
        },
        (error) => {
          this.apiCallError(error);
        },
        () => {},
      );
  }

  private getSvgFromMolFile(molFileBlock: string, width: number, height: number) {
    this.moleculeSearchService
      .getSvgFromMolFile(molFileBlock, width, height)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (svgImage: string) => {
          this.renderSvgImage(svgImage);
        },
        (error) => {
          this.apiCallError(error);
        },
      );
  }

  private getSvgFromRdkit(displayType: number) {
    this.moleculeSearchService
      .getRdkitObject()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((rdkit: RDKitModule) => {
        if (!this.unsubscriberSubject.isStopped) {
          const svgImage = this.moleculeSearchService.structureToSvgWithRdkit(
            this.formula ? this.formula : '',
            this.width,
            this.height,
            rdkit,
            displayType,
          );
          this.renderSvgImage(svgImage);
        }
      });
  }

  private renderSvgImage(svgImage: any) {
    let imageElement: HTMLElement;
    this.imageRendered = true;
    if (svgImage === '' || typeof svgImage !== 'string') {
      this.loading = false;
      this.error = true;
      this.errorMessage = 'Could not load image.';
    } else {
      this.error = false;
      this.loading = false;
      if (this.imageRendered) {
        this.imageWrapper.nativeElement.innerHTML = null;
      }
      if (this.format === 'svg') {
        // sanitize to reduce xss injection type attack vulnerability
        svgImage = svgImage.replace('<script>', '').replace('javascript:', '');
        imageElement = this.renderer.createElement('div');
        this.renderer.setStyle(imageElement, 'margin', `auto`);
        this.renderer.setProperty(imageElement, 'innerHTML', svgImage);
      } else {
        imageElement = this.renderer.createElement('img');
        this.renderer.setProperty(imageElement, 'src', svgImage);
      }
      this.renderer.appendChild(this.imageWrapper.nativeElement, imageElement);
      this.renderer.setStyle(imageElement, 'max-height', `${this.height}px`);
      this.renderer.setStyle(imageElement, 'max-width', `${this.width}px`);
    }
  }
}
