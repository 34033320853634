import { isString } from '../../../components/utils';

export type PathwayLinearityType = 'COMBO' | 'LINEAR' | 'CONVERGENT';
export type ProtectingGroupsType = 'NONE' | 'FEWER' | 'BALANCED' | 'MORE' | 'UNLIMITED';

export class SimpleScoringFunction {
  private static pathwayLinearityOptions = new Set(['COMBO', 'LINEAR', 'CONVERGENT']);
  private static protectingGroupsOptions = new Set(['ANY', 'BALANCED', 'NONE']);
  private static nullableAvoidedTypeFields = new Set([
    'name',
    'avoided_smiles',
    'avoided_smarts',
    'avoided_keywords',
    'seek_smiles',
    'seek_smarts',
    'seek_keywords',
    'custom_analysis_complexity_type',
    'group',
  ]);
  private static stopConditionsDefaultValueThousandSet = new Set([
    'starting_material_commercial_molecular_mass',
    'starting_material_commercial_price_per_gram',
    'starting_material_published_molecular_mass',
    'starting_material_stockroom_molecular_mass',
  ]);
  private static stopConditionsDefaultValueSet = new Set([
    'analysis_complexity',
    'starting_material_published_popularity',
    'paths_returned',
    'min_search_width',
    'max_reactions_per_product',
    'starting_material_enforce_carbon_footprint_availability',
    'starting_material_buyable_available_large_quantities_threshold',
  ]);
  private static nullableAvoidedNumberFields = new Set([
    'owner',
    'custom_analysis_complexity_iterations',
    'custom_analysis_complexity_duration',
  ]);

  /* tslint:disable:variable-name */
  public id: number = 0;
  public name: string = 'Default';
  public owner: number | null = null;
  public shorter_paths: boolean = false;
  public pathway_linearity: PathwayLinearityType = 'COMBO';
  public protecting_groups: ProtectingGroupsType = 'BALANCED';
  public avoided_smiles: string = '';
  public avoided_smarts: string = '';
  public avoided_keywords: string = '';
  public avoided_molecule_sets: any[] = []; // it actually is MoleculeSets but we do not have model for it
  public avoided_smarts_sets: any[] = [];
  public molecule_scoring_formula: string = '';
  public reaction_scoring_formula: string = '';
  public is_public: boolean = false;
  public group: string | null = null;
  public seek_molecule_sets: any[] = [];
  public seek_smarts_sets: any[] = [];
  public seek_smiles: string = '';
  public seek_smarts: string = '';
  public seek_keywords: string = '';
  public filters: any[] = [];
  public rules_database: any[] = [];
  public reaction_datasets: any[] = [];
  public is_global_default: boolean = false;
  public starting_material_commercial_catalog: any[] = [];
  public starting_material_commercial_molecular_mass: number | null = null;
  public starting_material_commercial_price_per_gram: number | null = null;
  public starting_material_published_popularity: number | null = null;
  public starting_material_enforce_carbon_footprint_availability: boolean = false;
  public starting_material_buyable_available_large_quantities_threshold: number | null = 0;
  public starting_material_published_molecular_mass: number | null = null;
  public starting_material_stockroom_molecular_mass: number | null = null;
  public analysis_complexity: string | null = null;
  public paths_returned: number | null = null;
  public min_search_width: number | null = null;
  public max_reactions_per_product: number | null = null;
  public enable_commercial_stop_conditions: boolean = true;
  public enable_published_stop_conditions: boolean = true;
  public enable_stockroom_stop_conditions: boolean = true;
  public is_default: boolean = false;
  public custom_analysis_complexity_type: string = '';
  public custom_analysis_complexity_iterations: number | null = null;
  public custom_analysis_complexity_duration: number | null = null;
  public non_selectivity: boolean = false;
  public hide_labels: any[] = [];
  public seek_labels: any[] = [];

  /* tslint:enable:variable-name */

  constructor(data?) {
    if (data) {
      const jsonData = isString(data) ? JSON.parse(data) : data;
      for (const field in jsonData) {
        if (Object.prototype.hasOwnProperty.call(this, field)) {
          if (SimpleScoringFunction.nullableAvoidedTypeFields.has(field)) {
            this[field] = jsonData[field] !== null ? jsonData[field] : '';
          } else if (SimpleScoringFunction.stopConditionsDefaultValueThousandSet.has(field)) {
            this[field] = jsonData[field] !== null ? jsonData[field] : 1000;
          } else if (SimpleScoringFunction.stopConditionsDefaultValueSet.has(field)) {
            let defaultValue: any;
            switch (field) {
              case 'starting_material_published_popularity':
                defaultValue = 15;
                break;
              case 'analysis_complexity':
                defaultValue = 'standard';
                break;
              case 'paths_returned':
                defaultValue = 50;
                break;
              case 'min_search_width':
                defaultValue = 100;
                break;
              case 'max_reactions_per_product':
                defaultValue = 20;
                break;
              case 'starting_material_buyable_available_large_quantities_threshold':
                defaultValue = 0;
                break;
              case 'starting_material_enforce_carbon_footprint_availability':
                defaultValue = false;
                break;
              default:
                break;
            }
            this[field] = jsonData[field] !== null ? jsonData[field] : defaultValue;
          } else if (this.isValidType(jsonData[field], field)) {
            this[field] = jsonData[field];
          }
        } else {
          console.error(
            `Unexpected field '${field}' in data provided to SimpleScoringFunction:ctor.`,
          );
        }
      }
    }
  }

  private isValidType(jsonDataField: any, field: any): boolean {
    if (typeof jsonDataField === typeof this[field]) {
      return true;
    } else if (SimpleScoringFunction.nullableAvoidedNumberFields.has(field)) {
      return typeof jsonDataField === 'number' || jsonDataField === null;
    } else if (field === 'avoided_molecule_sets') {
      return jsonDataField.isArray();
    } else if (field === 'pathway_linearity' || field === 'protecting_groups') {
      if (
        SimpleScoringFunction.pathwayLinearityOptions.has(jsonDataField) ||
        SimpleScoringFunction.protectingGroupsOptions.has(jsonDataField)
      ) {
        return true;
      } else {
        console.error(
          `Unexpected value of field '${field}' in data provided to SimpleScoringFunction:ctor` +
            `\nUsed default value "${this[field]}" instead of provided "${jsonDataField}"`,
        );
      }
    } else {
      console.error(
        `Unexpected type of field '${field}' in data provided to SimpleScoringFunction:ctor.` +
          `\nUsed default value "${this[field]}" instead of provided "${jsonDataField}"`,
        jsonDataField,
      );
      return false;
    }
  }
}
