<div class="ch-molecule-editor-dialog">
  <div class="ch-dialog-top">
    <span>
      Molecule Editor - {{ excludeOrSeek }}
      <ng-container *ngIf="elementType === 'SMILES'; else notSmiles"
        >Specific Molecules</ng-container
      >
      <ng-template #notSmiles>{{ elementType }}</ng-template>
      <mat-icon (click)="dialogRef.close()" class="icon-18">close</mat-icon>
    </span>
  </div>
  <div class="ch-dialog-body">
    <ch-ketcher-editor
      #moleculeEditor
      [isAnotherKetcherInstance]="true"
      class="molecule-editor new-ui-editor"
    >
    </ch-ketcher-editor>
    <textarea
      matInput
      id="hidden-input-editor"
      class="hidden-field"
      [formControl]="hiddenFormControl"
    ></textarea>
    <div *ngIf="searchLoading" class="loading-indicator">
      <mat-spinner [strokeWidth]="3" [diameter]="40"></mat-spinner>
    </div>
    <div class="add-btn">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!isAuthorizedToStartAnalysis"
        (click)="addToElementList()"
      >
        {{ elementType === 'SMILES' ? 'ADD MOLECULE SMILES' : 'ADD SUBSTRUCTURE' }}
      </button>
    </div>
    <div class="smiles">
      <div
        class="selected-smiles"
        *ngFor="let elementListItem of elementList; let i = index"
        matRipple
        [ngClass]="highlightedItemIndex === i ? 'ch-bdr-primary' : 'ch-bdr-grey-steel'"
        (click)="onElementListItemClick(elementListItem)"
        (mouseenter)="highlightItemOn(i)"
        (mouseleave)="highlightItemOff()"
      >
        <button mat-icon-button (click)="removeElementListItem(i, $event)" color="warn">
          <mat-icon inline="true">close</mat-icon>
        </button>
        <span>{{ elementListItem }}</span>
      </div>
    </div>
    <div
      *ngIf="errorMessage"
      [@fadeInAnimation]
      [@fadeOutAnimation]
      class="error-message ch-bg-warn"
    >
      {{ errorMessage }}
    </div>
    <div
      *ngIf="isDuplicatesRemovedNoticeDisplayed"
      [@fadeInAnimation]
      [@fadeOutAnimation]
      class="error-message ch-bg-accent-strong ch-clr-grey"
    >
      Some SMILES were duplicated and have been removed
    </div>
  </div>

  <div class="ch-dialog-bottom">
    <div class="ch-dialog-controls">
      <button
        class="ch-outline-btn"
        mat-button
        [buttonOutline]="'primary'"
        (click)="dialogRef.close()"
      >
        CANCEL
      </button>
      <button mat-raised-button color="primary" (click)="onCloseClick()">SAVE AND CLOSE</button>
    </div>
  </div>
</div>
