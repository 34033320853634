import { isString, isNullOrUndefined } from '../../../components/utils';
import { stringFormat } from '../../../components/utils';

export class ManagedUser {
  /* tslint:disable:variable-name */
  public id: number = 0;
  public first_name: string = '';
  public last_name: string = '';
  public full_name: string = '';
  public username: string = '';
  public email: string = '';
  public date_joined: string = '';
  public last_login: string = '';
  public phone_number: string = '';
  public is_active: boolean = false;
  public account_locked: boolean = false;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    const invalidFieldMessage: string = 'Unexpected field {0} in data provided to ManagedUser';
    const invalidFieldValueMessage: string = `
      Unexpected value of field '{0}' in data provided to ManagedUser.
      \nUsed default value of '{0}' instead of provided '{1}'.
    `;
    for (const field of Object.keys(jsonData)) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof this[field] === 'string' && isNullOrUndefined(jsonData[field])) {
          jsonData[field] = '';
        }
        if (field === 'first_name') {
          this['full_name'] = jsonData['first_name'] + ' ' + jsonData['last_name'];
        }

        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        } else {
          console.error(stringFormat(invalidFieldValueMessage, field, jsonData[field]));
        }
      } else {
        console.error(stringFormat(invalidFieldMessage, field));
      }
    }
  }
}
