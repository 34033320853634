import { KeywordsFilter } from './../results-filter-default/results-default-filter.models';
import { IFilterCriteria } from './../results-filter-molecule-editor/results-filter-molecule-editor.component';
import { ISliderRange } from './../results-filter-slider/results-filter-slider.component';
import {
  Component,
  OnInit,
  AfterContentChecked,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { AnalysisResultsService, AnalysisAlgorithm } from 'src/app/shared/services';
import { AppConstantsService } from 'src/app/shared/services/app-constants/app-constants.service';
import {
  IReactionFilter,
  StructureFilters,
} from '../results-filter-default/results-default-filter.models';
import { ResultsFilterModels } from './results-filter-models';

enum FilterSection {
  DEFAULT = 'default',
  SLIDER = 'slider',
}

@Component({
  selector: 'ch-results-filter-base',
  templateUrl: './results-filter-base.component.html',
  styleUrls: ['./results-filter-base.component.scss'],
})
export class ResultsFilterBaseComponent
  implements OnInit, OnChanges, AfterContentChecked, OnDestroy
{
  @Input() public algorithm: AnalysisAlgorithm;
  public isSliderFilterDisabled: boolean = false;
  public isSliderFilterSection: boolean;
  public resultFilters: ResultsFilterModels = { pathwaySimilarity: 100 };
  public isResetDisabled: boolean;
  public resultsFilterInfo: string;
  public filtersFromUserSettings: boolean;
  public readonly filterSection = FilterSection;
  private unsubscribeSubject: Subject<void> = new Subject();
  private setTimeOutTimer: ReturnType<typeof setTimeout>;

  constructor(
    private analysisResultsService: AnalysisResultsService,
    private appConstantService: AppConstantsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}
  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'algorithm')) {
      this.isSliderFilterDisabled = this.algorithm
        ? this.algorithm !== AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS &&
          this.algorithm !== AnalysisAlgorithm.DIVERSITY_LIBRARY &&
          this.algorithm !== AnalysisAlgorithm.LIBRARY_MODE
        : false;
    }
  }

  public ngOnDestroy(): void {
    this.changeDetectorRef.detach();
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  public ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public ngOnInit() {
    this.filtersFromUserSettings = false;
    this.isResetDisabled = true;
    this.isSliderFilterSection = false;
    this.resultsFilterInfo = this.appConstantService.filterInfoTooltip;

    this.analysisResultsService.resultsFilterSubject
      .pipe(
        takeUntil(this.unsubscribeSubject),
        filter((value: ResultsFilterModels) => {
          return value && !this.filtersFromUserSettings;
        }),
      )
      .subscribe((value: ResultsFilterModels) => {
        if (value) {
          this.resultFilters = value;
          this.filtersFromUserSettings = true;
          this.checkResetDisabled();
        }
      });
    this.analysisResultsService.addStructureFilterFromPopup
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((value: IFilterCriteria) => {
        if (value) {
          this.isSliderFilterSection = false;
        }
      });

    this.analysisResultsService.addDiversityFilterFromPopup
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((value: IFilterCriteria) => {
        if (value) {
          this.isSliderFilterSection = false;
        }
      });
    this.analysisResultsService.addReactionFilterFromPopup
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe((value: IReactionFilter) => {
        if (value) {
          this.isSliderFilterSection = false;
        }
      });
  }

  public toggleFilterSection(sectionName: FilterSection) {
    this.isSliderFilterSection = sectionName === FilterSection.SLIDER;
  }

  public applyStructureFilters(filters: StructureFilters) {
    this.analysisResultsService.showLoader.next(true);
    this.resultFilters.structureFilter = filters;
    if (this.setTimeOutTimer) {
      clearTimeout(this.setTimeOutTimer);
    }
    this.setTimeOutTimer = setTimeout(() => {
      this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    }, 100);
    this.checkResetDisabled();
  }

  public applyDiversityFilters(filters: StructureFilters) {
    this.analysisResultsService.showLoader.next(true);
    this.resultFilters.diversityFilter = filters;
    if (this.setTimeOutTimer) {
      clearTimeout(this.setTimeOutTimer);
    }
    this.setTimeOutTimer = setTimeout(() => {
      this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    }, 100);
    this.checkResetDisabled();
  }

  public checkResetDisabled() {
    this.isResetDisabled = this.analysisResultsService.isResetDisabled();
    if (!this.isResetDisabled) {
      this.analysisResultsService.resetFilterSubject.next(false);
    }
  }

  public resetFilters() {
    this.analysisResultsService.selectedDiversityTargets.next([]);
    this.analysisResultsService.showLoader.next(true);
    this.resultFilters.keywordFilter = null;
    this.resultFilters.structureFilter = null;
    this.resultFilters.diversityFilter = null;
    this.resultFilters.reactionFilter = null;
    this.resultFilters.numberOfProtectiveSteps = null;
    this.resultFilters.numberOfReactionSliderFilter = null;
    this.resultFilters.priceFilter = null;
    this.resultFilters.pathwaySimilarity = 100;
    if (this.setTimeOutTimer) {
      clearTimeout(this.setTimeOutTimer);
    }
    this.setTimeOutTimer = setTimeout(() => {
      this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    }, 100);
    this.checkResetDisabled();
    this.analysisResultsService.addReactionFilterFromPopup.next(null);
    this.analysisResultsService.addStructureFilterFromPopup.next(null);
    this.analysisResultsService.addDiversityFilterFromPopup.next(null);
    this.analysisResultsService.resetFilterSubject.next(true);
  }

  public applyKeywordFilter(keywordFilters: KeywordsFilter) {
    this.resultFilters.keywordFilter = keywordFilters;
    this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    this.checkResetDisabled();
  }

  public applyReactionFilter(reactionFilter: IReactionFilter) {
    this.resultFilters.reactionFilter = reactionFilter;
    this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    this.checkResetDisabled();
  }

  public addPriceFilter(value: ISliderRange) {
    value ? (this.resultFilters.priceFilter = value) : (this.resultFilters.priceFilter = null);
    this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    this.checkResetDisabled();
  }

  public addNumberOfReactionFilter(value: ISliderRange) {
    value
      ? (this.resultFilters.numberOfReactionSliderFilter = value)
      : (this.resultFilters.numberOfReactionSliderFilter = null);
    this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    this.checkResetDisabled();
  }

  public addProtectiveStepsFilter(value: ISliderRange) {
    value
      ? (this.resultFilters.numberOfProtectiveSteps = value)
      : (this.resultFilters.numberOfProtectiveSteps = null);
    this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    this.checkResetDisabled();
  }

  public addPathwaySimilarity(value: number) {
    this.resultFilters.pathwaySimilarity = value;
    this.analysisResultsService.resultsFilterSubject.next(this.resultFilters);
    this.checkResetDisabled();
  }
}
