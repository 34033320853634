import { Observable, Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { isNullOrUndefined, isUndefined, isNumber, cloneObject } from '../../components/utils';
import { GraphResources, AnalysisAlgorithm, ParametersStashService } from '../../services';
import { AnalysisService } from '../../services/analysis/analysis.service';
import {
  GraphBuilderParameters,
  GraphBuilder,
  GraphReactionNode,
  GraphNodeType,
  GraphMoleculeNode,
  ChildReactionNodeType,
  MoleculeNodeTagType,
  Graph,
  createGraphObject,
  GraphEdgeLabelValues,
} from '../../services/graph-builder';
import {
  COLOR_MOLECULE_BORDER_REGULATED,
  COLOR_MOLECULE_BORDER_PROTECTION_NEEDED,
  COLOR_NODE_LABEL,
  COLOR_NODE_TARGET,
  COLOR_NODE_SECONDARY,
  COLOR_NODE_COMMERCIALLY_AVAILABLE,
  COLOR_NODE_CUSTOM_INVENTORY,
  COLOR_NODE_KNOWN,
  COLOR_NODE_UNKNOWN,
  COLOR_REACTION_DEFAULT,
  COLOR_REACTION_EXPERT_CHEMISTRY,
  COLOR_REACTION_HETEROCYCLES,
  COLOR_REACTION_NON_SELECTIVE_OR_DIASTEROSELECTIVE_ONLY,
  COLOR_REACTION_STRATEGY_OR_TUNNELS,
  COLOR_REACTION_PUBLISHED,
} from '../../../colors';
import { KnownReactionBases } from '../../services/analysis/models/graph-reaction-node-entry';

/* tslint:disable:prefer-for-of */
@Injectable()
export class GraphService {
  /* tslint:disable:no-empty */
  constructor(
    public analysisService: AnalysisService,
    public parametersStashService: ParametersStashService,
  ) {}
  /* tslint:enable:no-empty */

  public createGraph(graphParameters: GraphBuilderParameters) {
    return new Observable((observer: Subscriber<Graph>) => {
      try {
        let graph: Graph = createGraphObject();

        const graphBuildingParameters: GraphBuilderParameters = cloneObject(graphParameters);
        graphBuildingParameters.graphApiResult = new GraphResources(
          graphBuildingParameters.graphApiResult,
        );

        if (graphBuildingParameters.syntheticPath) {
          graphBuildingParameters.graph = graph;
          for (let p = 0; p < graphBuildingParameters.syntheticPath.reaction_nodes.length; ++p) {
            const reactionNode = graphBuildingParameters.graphApiResult.reaction_nodes.find(
              (_reactionNode) =>
                _reactionNode.id === graphBuildingParameters.syntheticPath.reaction_nodes[p],
            );
            if (reactionNode) {
              graphBuildingParameters.reactionNodes.push(reactionNode);
            }
          }
          graphBuildingParameters.graphApiResult.reaction_nodes =
            graphBuildingParameters.reactionNodes;

          graph.score = graphBuildingParameters.syntheticPath.score;
          graph.target_score = graphBuildingParameters.syntheticPath.target_score;
          graph.syntheticPath = graphBuildingParameters.syntheticPath.id;
          graph.rank = graphBuildingParameters.syntheticPath.rank;
          graph.isFavorite = graphBuildingParameters.syntheticPath.is_favorite;
        }

        const graphBuilder = new GraphBuilder();

        graph = graphBuilder.build(graphBuildingParameters);

        graph = this.addMoreAttributes(graph, graphBuildingParameters.algorithm);

        const expandedNodes: GraphMoleculeNode[] = graph.nodes.filter(
          (node: GraphMoleculeNode | GraphReactionNode) =>
            node.type !== GraphNodeType.REACTION &&
            node.childReactionNodeId === ChildReactionNodeType.EXPANDED &&
            node.isLastSubstrate,
        );

        for (const expandedNode of expandedNodes) {
          expandedNode.isLastSubstrate = false;
        }

        // Add a score value to starting molecule node from the merged graph
        // for some network algorithms. It is works if analysis has only one path
        if (
          !graphParameters.syntheticPath &&
          graphParameters.algorithm !== AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS &&
          graphParameters.algorithm !== AnalysisAlgorithm.REVERSE_REACTION_NETWORK &&
          graphParameters.algorithm !== AnalysisAlgorithm.REACTION_NETWORK &&
          graphParameters.algorithm !== AnalysisAlgorithm.MANUAL_RETROSYNTHESIS &&
          graphParameters.graphApiResult.paths.length === 1
        ) {
          const startingMolecule = graph.nodes.find(
            (node) => node.type !== GraphNodeType.REACTION && node.isStartingMolecule === true,
          );
          if (startingMolecule) {
            startingMolecule.score = graphParameters.graphApiResult.paths[0].target_score;
          }
        }

        // Add a mass per gram of target to starting molecule node
        // in automatic retrosynthesis paths ang graph
        if (graphParameters.algorithm === AnalysisAlgorithm.AUTOMATIC_RETROSYNTHESIS) {
          const startingMolecule = graph.nodes.find(
            (node) => node.type !== GraphNodeType.REACTION && node.isStartingMolecule === true,
          );

          if (startingMolecule) {
            startingMolecule.massPerGramOfTarget = 1;
          }
        }

        observer.next(graph);
        observer.complete();
      } catch (error) {
        observer.error(new Error(error.message));
      }
    });
  }

  public getAllMoleculeTags(node: GraphMoleculeNode | GraphReactionNode) {
    const allTags = [];

    switch (node.defaultColor) {
      case COLOR_NODE_KNOWN:
        allTags.push(MoleculeNodeTagType.NODE_KNOWN);
        break;
      case COLOR_NODE_UNKNOWN:
        allTags.push(MoleculeNodeTagType.NODE_UNKNOWN);
        break;
      case COLOR_NODE_COMMERCIALLY_AVAILABLE:
        allTags.push(MoleculeNodeTagType.NODE_COMMERCIALLY_AVAILABLE);
        break;
      case COLOR_NODE_CUSTOM_INVENTORY:
        allTags.push(MoleculeNodeTagType.NODE_CUSTOM_INVENTORY);
        break;
      case COLOR_NODE_TARGET:
        allTags.push(MoleculeNodeTagType.NODE_TARGET);
        break;
      case COLOR_NODE_SECONDARY:
        allTags.push(MoleculeNodeTagType.NODE_SECONDARY);
        break;
    }

    if (node.availableAtSA) {
      allTags.push(MoleculeNodeTagType.NODE_SA_AVAILABLE);
    }

    if (node.borderColor === COLOR_MOLECULE_BORDER_PROTECTION_NEEDED) {
      allTags.push(MoleculeNodeTagType.NODE_PROTECTION_NEEDED);
    } else if (node.borderColor === COLOR_MOLECULE_BORDER_REGULATED) {
      allTags.push(MoleculeNodeTagType.NODE_REGULATED);
    }
    return allTags;
  }

  private addMoreAttributes(graph: any, algorithm: string) {
    const nodeCount: number = graph.nodes.length;
    const nodeSize = this.calcNodeSize(nodeCount);

    for (let i = 0; i < nodeCount; ++i) {
      const node: GraphMoleculeNode | GraphReactionNode = graph.nodes[i];

      node.borderSize = 10;

      if (node.type !== GraphNodeType.REACTION) {
        [
          node.defaultColor,
          node.color,
          node.icon,
          node.label,
          node.defaultLabel,
          node.primaryProduct,
          node.borderColor,
          node.target,
          node.isStartingMolecule,
        ] = this.setMoleculeColorAndLabel(node, algorithm, graph);
        node.defaultBorderColor = node.borderColor;
        if (node.borderColor !== '#ffffff' && node.borderColor !== '#f2f2f2') {
          node.borderWidth = 2;
        }
        node.labelPosition = 0.375;
        node.labelSize = 1.5;
        node.labelColor = '#ECEFF1';
        node.iconVerticalPositionOffset = 0.375;
        node.iconRelativeSize = 1;
        node.iconColor = '#ECEFF1';
        node.size = nodeSize * 0.6;
        node.markedForPrint = false;

        // For molecules in this algorithms score is not predefined by workers as one stable value.
        // In dependence of information that we have, we can set the score attribute as a cost or popularity value.
        if (
          algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK ||
          algorithm === AnalysisAlgorithm.REACTION_NETWORK ||
          algorithm === AnalysisAlgorithm.MANUAL_RETROSYNTHESIS
        ) {
          if (node.molecule.cost) {
            node.score = node.molecule.cost;
          } else if (!node.molecule.cost && node.molecule.mushroom) {
            if (isNumber(node.molecule.out_degree)) {
              node.score = node.molecule.out_degree;
            }
          }
        }

        // Turn on the reaction report for "target" molecule in REVERSE_REACTION_NETWORK algorithm
        if (algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK && node.isStartingMolecule) {
          node.isLastSubstrate = false;
        }

        // Add score for target molecule in network algorithms except "TRAVEL" algorithms
        if (
          graph.target_score &&
          algorithm !== AnalysisAlgorithm.REVERSE_REACTION_NETWORK &&
          algorithm !== AnalysisAlgorithm.REACTION_NETWORK &&
          node.isStartingMolecule
        ) {
          node.score = graph.target_score;
        }
      } else {
        [
          node.defaultColor,
          node.color,
          node.label,
          node.defaultLabel,
          node.colorByBase,
          node.colorByNonSelective,
          node.colorByTunnelsAndStrategy,
        ] = this.setReactionNodeColorAndLabel(node, graph);
        node.labelPosition = 1.8;
        node.labelSize = 1.8;
        node.labelColor = COLOR_NODE_LABEL;
        node.size = nodeSize * 0.35;
        node.markedForPrint = false;
      }
    }

    return graph;
  }

  private setReactionNodeColorAndLabel(graphReactionNode: GraphReactionNode | any, graph: any) {
    const defaultLabel: string = '';
    let label: string;
    let colorByBase: string;
    let colorByNonSelectiveOrDiasteroselectiveOnly: string;
    let colorByTunnelsAndStrategy: string;
    if (graphReactionNode.reaction_node.base === KnownReactionBases.EXPERT) {
      colorByBase = COLOR_REACTION_EXPERT_CHEMISTRY;
    } else {
      colorByBase = this.getNodeColorForGraph(graphReactionNode.reaction_node.base);
    }
    const edgeLabels: GraphEdgeLabelValues[] = [];
    colorByNonSelectiveOrDiasteroselectiveOnly = COLOR_REACTION_DEFAULT;
    colorByTunnelsAndStrategy = COLOR_REACTION_DEFAULT;
    if (graph.syntheticPath) {
      if (
        graphReactionNode.reaction_node.isTunnelInPath(graph.syntheticPath) ||
        graphReactionNode.reaction_node.isStrategyInPath(graph.syntheticPath) ||
        graphReactionNode.reaction_node.isDynamicStrategyInPath(graph.syntheticPath)
      ) {
        if (graphReactionNode.reaction_node.isTunnelInPath(graph.syntheticPath)) {
          edgeLabels.push(GraphEdgeLabelValues.TUNNELS);
        }
        if (graphReactionNode.reaction_node.isDynamicStrategyInPath(graph.syntheticPath)) {
          edgeLabels.push(GraphEdgeLabelValues.DYNAMIC_STRATEGIES);
        } else if (graphReactionNode.reaction_node.isStrategyInPath(graph.syntheticPath)) {
          edgeLabels.push(GraphEdgeLabelValues.STRATEGIES);
        }
        colorByTunnelsAndStrategy = COLOR_REACTION_STRATEGY_OR_TUNNELS;
      }
    } else {
      if (
        graphReactionNode.reaction_node.isTunnelInGraph() ||
        graphReactionNode.reaction_node.isStrategyInGraph() ||
        graphReactionNode.reaction_node.isDynamicStrategyInGraph(graph.syntheticPath)
      ) {
        if (graphReactionNode.reaction_node.isTunnelInGraph()) {
          edgeLabels.push(GraphEdgeLabelValues.TUNNELS);
        }
        if (graphReactionNode.reaction_node.isDynamicStrategyInGraph()) {
          edgeLabels.push(GraphEdgeLabelValues.DYNAMIC_STRATEGIES);
        } else if (graphReactionNode.reaction_node.isStrategyInGraph()) {
          edgeLabels.push(GraphEdgeLabelValues.STRATEGIES);
        }
        colorByTunnelsAndStrategy = COLOR_REACTION_STRATEGY_OR_TUNNELS;
      }
    }

    if (
      !isNullOrUndefined(graphReactionNode.reaction_node.non_selective) &&
      graphReactionNode.reaction_node.non_selective
    ) {
      colorByNonSelectiveOrDiasteroselectiveOnly =
        COLOR_REACTION_NON_SELECTIVE_OR_DIASTEROSELECTIVE_ONLY;
      edgeLabels.push(GraphEdgeLabelValues.NONE_SELECTIVE);
    }

    if (
      !isNullOrUndefined(graphReactionNode.reaction_node.diastereoselective) &&
      graphReactionNode.reaction_node.diastereoselective
    ) {
      colorByNonSelectiveOrDiasteroselectiveOnly =
        COLOR_REACTION_NON_SELECTIVE_OR_DIASTEROSELECTIVE_ONLY;
      edgeLabels.push(GraphEdgeLabelValues.DIASTEREOSELECTIVE_ONLY);
    }

    graph.edges
      .filter(
        (arrow) => arrow.target === graphReactionNode.id || arrow.source === graphReactionNode.id,
      )
      .forEach((reactionNodeArrow) => {
        reactionNodeArrow.colorByNonSelectiveOrDiasteroselectiveOnly =
          colorByNonSelectiveOrDiasteroselectiveOnly;
        reactionNodeArrow.colorByTunnelsAndStrategy = colorByTunnelsAndStrategy;
        reactionNodeArrow.edgeLabels = edgeLabels;
      });

    const yearColor = this.getColorByPublicationYear(graphReactionNode.reaction.publication_year);
    graphReactionNode.colorByYear = yearColor;
    graph.edges
      .filter(
        (arrow) => arrow.target === graphReactionNode.id || arrow.source === graphReactionNode.id,
      )
      .forEach((reactionNodeArrow) => (reactionNodeArrow.colorByYear = yearColor));

    const color = COLOR_NODE_SECONDARY;
    const defaultColor = COLOR_NODE_SECONDARY;

    if (!isNullOrUndefined(graphReactionNode.reaction.publication_year)) {
      label = graphReactionNode.reaction.publication_year.toString();
    }

    return [
      defaultColor,
      color,
      label,
      defaultLabel,
      colorByBase,
      colorByNonSelectiveOrDiasteroselectiveOnly,
      colorByTunnelsAndStrategy,
    ];
  }

  private getColorByPublicationYear(publicationYear: number): string {
    let yearColor = COLOR_REACTION_DEFAULT;
    if (publicationYear) {
      const startingRgb = {
        r: 0,
        g: 255,
        b: 255,
      };
      const endRgb = {
        r: 255,
        g: 0,
        b: 0,
      };
      const arrowRgb = {
        r: 166,
        g: 166,
        b: 166,
      };
      const maxYear: number = 2017;
      const minYear: number = 1789;
      const lerp = (publicationYear - minYear) / (maxYear - minYear); // lerp - linear interpolation
      arrowRgb.r = Math.floor(startingRgb.r * (1 - lerp) + endRgb.r * lerp);
      arrowRgb.g = Math.floor(startingRgb.g * (1 - lerp) + endRgb.g * lerp);
      arrowRgb.b = Math.floor(startingRgb.b * (1 - lerp) + endRgb.b * lerp);
      yearColor = 'rgb(' + arrowRgb.r + ',' + arrowRgb.g + ',' + arrowRgb.b + ')';
    }
    return yearColor;
  }

  private setMoleculeColorAndLabel(node: any, algorithm: string, graph: any) {
    let color: string;
    let label: string;
    let defaultLabel: string;
    let defaultColor: string;
    let primaryProduct: boolean;
    let borderColor: string;
    let icon: string;
    let target: boolean;
    let isStartingMolecule: boolean;

    const setKnownColorAndLabel = () => {
      color = COLOR_NODE_KNOWN;
      defaultColor = COLOR_NODE_KNOWN;
      icon = '☀';
      label = undefined;
      defaultLabel = undefined;
      primaryProduct = !(
        algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK && node.childReactionNodeId
      );
      target = false;
      isStartingMolecule = false;
    };

    if (node.molecule.mushroom !== null) {
      // Known Chemical
      setKnownColorAndLabel();
    } else {
      // Unknown Chemical
      color = COLOR_NODE_UNKNOWN;
      defaultColor = COLOR_NODE_UNKNOWN;
      icon = '?';
      label = undefined;
      defaultLabel = undefined;
      primaryProduct = !(
        algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK && node.childReactionNodeId
      );
      target = false;
      isStartingMolecule = false;
    }

    if (
      node.molecule.cost !== null &&
      node.molecule.cost !== 0 &&
      node.molecule.mushroom !== null
    ) {
      // Commercially Available
      color = COLOR_NODE_COMMERCIALLY_AVAILABLE;
      defaultColor = COLOR_NODE_COMMERCIALLY_AVAILABLE;
      icon = '$';
      label = undefined;
      defaultLabel = undefined;
      primaryProduct = !(
        algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK && node.childReactionNodeId
      );
      target = false;
      isStartingMolecule = false;
    }
    if (Array.isArray(node.molecule.vendors)) {
      const sigmaAldrichVendor = node.molecule.vendors.find((v) => v.vendor === 'Sigma-Aldrich');
      node.availableAtSA =
        sigmaAldrichVendor &&
        !isNullOrUndefined(sigmaAldrichVendor.data.link) &&
        sigmaAldrichVendor.data.link !== '';
    }
    if (
      Array.isArray(node.molecule.customer_inventory_data) &&
      node.molecule.customer_inventory_data.length
    ) {
      color = COLOR_NODE_CUSTOM_INVENTORY;
      defaultColor = COLOR_NODE_CUSTOM_INVENTORY;
    }
    // protections, databases and incompatibilities
    if (node.needsProtection) {
      borderColor = COLOR_MOLECULE_BORDER_PROTECTION_NEEDED;
    } else if (node.molecule.regulatory_databases.length > 0 && !node.needsProtection) {
      borderColor = COLOR_MOLECULE_BORDER_REGULATED;
    } else {
      borderColor = color;
    }

    if (algorithm === AnalysisAlgorithm.REVERSE_REACTION_NETWORK) {
      const edge = graph.edges.find((_edge) => _edge.source === node.id);

      if (!isUndefined(edge)) {
        const reaction = graph.nodes.find((_node) => _node.id === edge.target);

        if (!isUndefined(reaction)) {
          if (
            reaction.reaction_node.parent_molecule === node.molecule.id &&
            reaction.reaction_node.parent_reaction_node === null
          ) {
            color = COLOR_NODE_TARGET;
            defaultColor = COLOR_NODE_TARGET;
            icon = '◉';
            label = undefined;
            defaultLabel = undefined;
            primaryProduct = false;
            target = false;
            isStartingMolecule = true;
            borderColor = color;
          }
        }
      }
    } else {
      const edge = graph.edges.find((_edge) => _edge.target === node.id);

      if (!isUndefined(edge)) {
        const reaction = graph.nodes.find((_node) => _node.id === edge.source);

        if (!isUndefined(reaction)) {
          if (
            reaction.reaction_node.parent_molecule === node.molecule.id &&
            reaction.reaction_node.parent_reaction_node === null
          ) {
            // Target
            color = COLOR_NODE_TARGET;
            defaultColor = COLOR_NODE_TARGET;
            icon = '◉';
            label = undefined;
            defaultLabel = undefined;
            primaryProduct = true;
            target = true;
            isStartingMolecule = true;
            borderColor = color;
          }
          if (reaction.reaction_node.parent_molecule !== node.molecule.id) {
            // Secondary
            color = COLOR_NODE_SECONDARY;
            defaultColor = COLOR_NODE_SECONDARY;
            icon = undefined;
            label = undefined;
            defaultLabel = undefined;
            primaryProduct = false;
            target = false;
            isStartingMolecule = false;
            borderColor = color;
          }
        }
      }
    }

    return [
      defaultColor,
      color,
      icon,
      label,
      defaultLabel,
      primaryProduct,
      borderColor,
      target,
      isStartingMolecule,
    ];
  }

  private calcNodeSize(nodeCount: number): number {
    if (nodeCount <= 599) {
      return Math.max(-1 * Math.sqrt(nodeCount) + 30, 5);
    } else if (nodeCount > 600) {
      return Math.max(-0.75 * Math.sqrt(nodeCount) + 30, 5);
    }
  }

  private getNodeColorForGraph(baseValue) {
    if (this.isReactionDataset(baseValue)) {
      return COLOR_REACTION_PUBLISHED;
    } else if (this.isRulesDataset(baseValue)) {
      return COLOR_REACTION_HETEROCYCLES;
    } else {
      return COLOR_REACTION_DEFAULT;
    }
  }

  private isRulesDataset(baseValue) {
    const hasRulesValue = this.parametersStashService.rulesDataset.value.some(
      (item) => item.name === baseValue && item.name !== KnownReactionBases.EXPERT,
    );
    return hasRulesValue;
  }

  private isReactionDataset(baseValue) {
    const hasReactionValue = this.parametersStashService.publishedReactionDataset.value.some(
      (item) => item.name === baseValue,
    );
    return hasReactionValue;
  }
}
/* tslint:enable:prefer-for-of */
