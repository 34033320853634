import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AnalysisResultsService, AppConstantsService } from 'src/app/shared/services';
import {
  EditStructureFilter,
  IFilterImagePopupData,
  RemoveStructuralFilter,
  StructureSectionCategory,
} from '../results-default-filter.models';

@Component({
  selector: 'ch-results-structure-filter',
  templateUrl: './results-structure-filter.component.html',
  styleUrls: ['./results-structure-filter.component.scss'],
})
export class ResultsStructureFilterComponent {
  @Input()
  public filterSection: StructureSectionCategory;
  @Input() public isDiversityFilter: boolean;
  @Output() public removeStructuralFilter: EventEmitter<RemoveStructuralFilter> =
    new EventEmitter();
  @Output() public removeDiversityFilter: EventEmitter<RemoveStructuralFilter> = new EventEmitter();
  @Output() public editStructuralFilter: EventEmitter<EditStructureFilter> = new EventEmitter();
  @Output() public editDiversityFilter: EventEmitter<EditStructureFilter> = new EventEmitter();
  public hoveredSvg: SafeResourceUrl;
  public isExactImagePopup: boolean = false;
  public isStructureImagePopup: boolean;
  public isSimilarImagePopup: boolean;

  constructor(
    private appConstantService: AppConstantsService,
    private analysisResultService: AnalysisResultsService,
  ) {}

  public removeItemFromFilters(index: number, categoryName: string, sectionName: string) {
    const removeFilter: RemoveStructuralFilter = {
      index,
      categoryName:
        categoryName.toLowerCase() === 'substructure' ? 'structure' : categoryName.toLowerCase(),
      sectionName:
        sectionName === this.appConstantService.limitTo
          ? this.appConstantService.filterSection.limitTo
          : this.appConstantService.filterSection.exclude,
    };
    if (this.isDiversityFilter) {
      this.removeDiversityFilter.emit(removeFilter);
    } else {
      this.removeStructuralFilter.emit(removeFilter);
    }
  }

  public editStructureFilter(
    index: number,
    categoryName: string,
    sectionName: string,
    smiles: string,
    smarts: string = '',
    similarity: number = 0,
  ) {
    const editFilter: EditStructureFilter = {
      index,
      categoryName:
        categoryName.toLowerCase() === 'substructure' ? 'structure' : categoryName.toLowerCase(),
      sectionName:
        sectionName === this.appConstantService.limitTo
          ? this.appConstantService.filterSection.limitTo
          : this.appConstantService.filterSection.exclude,
      smiles,
      smarts,
      similarity,
    };
    if (this.isDiversityFilter) {
      this.editDiversityFilter.emit(editFilter);
    } else {
      this.editStructuralFilter.emit(editFilter);
    }
  }

  public onMouseEnter(event: MouseEvent, svg: SafeResourceUrl) {
    const target: ElementRef = new ElementRef(event.currentTarget);
    const rect = target.nativeElement.getBoundingClientRect();
    const position = { left: `${rect.left + 110}px`, top: `${rect.bottom - 40}px` };
    const filterImagePopup: IFilterImagePopupData = { svg, position };
    this.analysisResultService.filterImagePopupDetails.next(filterImagePopup);
  }

  public onMouseLeave() {
    this.analysisResultService.filterImagePopupDetails.next(null);
  }
}
