<nav class="breadcrumb-nav" aria-label="breadcrumb" *ngIf="breadcrumb.length > 0">
  <div class="breadcrumb" (mouseleave)="breadcrumbMoreIcon(false)">
    <div
      class="home-icon ch-text-gray"
      [class.disabled-home]="routeStateService.stopRoutingToHome.value"
    >
      <a routerLink (click)="navigateHome()">
        <mat-icon class="ch-icon-hover" svgIcon="ch:home"></mat-icon>
      </a>
    </div>
    <span *ngIf="isDiversityResultView && !isFilterApplied" class="diversity-library">
      Targets Found: {{ diversityTarget }}</span
    >
    <span *ngIf="isDiversityResultView && isFilterApplied" class="diversity-library">
      Filtered Results: {{ diversityTarget }}</span
    >
    <div
      class="breadcrumb breadcrumb-text ch-text-gray"
      *ngFor="let item of breadcrumb; let i = index"
    >
      <div class="ch-text-gray" [class.has-url]="item.url" (click)="onMenuItemClick(item)">
        <span>/ &nbsp;</span>
        <span>
          <mat-icon
            class="ch-icon-hover grey-icon"
            *ngIf="item.svgIcon"
            [svgIcon]="item.svgIcon"
          ></mat-icon>
          <mat-icon class="ch-icon-hover grey-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        </span>
        <span
          *ngIf="!isRenameClicked || (isRenameClicked && item.label !== analysis.name)"
          [ngClass]="getBreadCrumbTitleClass(i)"
          [matTooltip]="
            isDiversityLibrary
              ? getTooltip(breadcrumbLabel, maxBreadCrumbLength)
              : getTooltip(item.label, maxBreadCrumbLength)
          "
          matTooltipClass="ch-tooltip full-width-tooltip"
        >
          &nbsp; {{ getBreadCrumbTitle(item.label) }}
        </span>
        <span
          (mouseenter)="breadcrumbMoreIcon(true)"
          class="breadcrumb-more-icons"
          *ngIf="!!analysis && !isRenameClicked && !isDiversityLibrary"
        >
          <mat-icon class="mat-icon-analysis-header"> more_vert</mat-icon>
        </span>
        <div
          *ngIf="!isRenameClicked && !!analysis && !isRerunDiversityLibrary"
          class="breadcrumb-results-right"
        >
          <button
            *ngIf="isNewResultsAvailable"
            class="vert-icon"
            mat-icon-button
            [matTooltip]="appConstantsService.newResultsFoundTooltip"
            matTooltipPosition="after"
            matTooltipClass="ch-tooltip"
            (click)="loadNewResults()"
          >
            <mat-icon
              class="bell-ring ch-bell-icon-hover"
              [@ringAnimation]="ringAnimationState"
              (@ringAnimation.done)="continueRingAnimation()"
              >notifications</mat-icon
            >
          </button>
          <mat-spinner
            *ngIf="
              (isComputationActive || analysisService.isComputationActivePreviousValue) &&
              processingOfNewResults
            "
            [strokeWidth]="2"
            [diameter]="18"
            color="accent"
            class="vert-icon processing-new-results-spinner"
            [matTooltip]="appConstantsService.processingNewResultsTooltip"
            matTooltipClass="ch-tooltip"
            matTooltipPosition="after"
          >
          </mat-spinner>
          <span
            class="loading label-container"
            *ngIf="mode === ResultsView.PATHWAYS && isComputationActive"
          >
            COMPUTATION IN PROGRESS
          </span>
          <button
            *ngIf="
              !stoppingComputation &&
              (mode === ResultsView.PATHWAYS && isComputationActive
                | chIsStopComputationButtonAvailable
                  : isComputationStopped
                  : isAutomaticRetrosynthesisAnalysis || libraryView || isDiversityMode)
            "
            class="ch-active-stop-button ch-clr-primary"
            [class.ch-clr-primary-mlight]="stoppingComputation"
            (click)="stopActiveComputation()"
            mat-stroked-button
            [disabled]="stoppingComputation"
          >
            {{ stopButtonMessage }}
          </button>
          <span
            class="stopping-btn"
            *ngIf="stoppingComputation && !isComputationStopped && isComputationActive"
          >
            Stopping
          </span>
          <button
            *ngIf="hasWarnings"
            class="ch-clr-warn"
            mat-icon-button
            [matTooltip]="appConstantsService.analysisGenericWarningMessageTitle"
            matTooltipShowDelay="250"
            matTooltipPosition="below"
            matTooltipClass="ch-tooltip"
            (click)="onClickShowWarnings()"
          >
            <mat-icon class="ch-clr-accent-strong ch-warn-icon">warning</mat-icon>
          </button>
          <button
            *ngIf="hasComputationError"
            class="ch-clr-warn"
            mat-icon-button
            [matTooltip]="appConstantsService.computationErrorTitleMessage"
            matTooltipShowDelay="250"
            matTooltipPosition="below"
            matTooltipClass="ch-tooltip"
            (click)="onClickComputationError()"
          >
            <mat-icon class="computation-error ch-error-icon-hover">error_outline</mat-icon>
          </button>
          <span
            class="ch-text-gray label-container"
            *ngIf="
              showPathsCount &&
              !isFilterApplied &&
              !libraryView &&
              !isDiversityLibrary &&
              !isDiversityResultView
            "
          >
            Paths Found: {{ progressItemSummary.paths_found }}
          </span>
          <span
            class="ch-text-gray label-container filter-number"
            *ngIf="showPathsCount && isFilterApplied && !isDiversityLibrary"
          >
            Filtered results: {{ filteredResultsLength }}
          </span>
          <span class="target-header targets-library" *ngIf="showTargetsCount && libraryView">
            Targets in the Library ({{ libraryTargets?.targets?.with_result?.length }}/{{
              libraryTargets?.targets?.with_result?.length +
                libraryTargets?.targets?.without_result?.length
            }})
          </span>
          <button
            mat-icon-button
            [matTooltip]="appConstantsService.targetMoleculesMessage"
            matTooltipPosition="below"
            matTooltipClass="ch-tooltip"
            [ngClass]="{ 'ch-action-icon-hover': true }"
            *ngIf="showTargetsCount && libraryView"
            (click)="viewTargetMolecules()"
          >
            <mat-icon
              class="action-icon grey-icon visibility-icon"
              matTooltipPosition="above"
              matTooltipClass="ch-tooltip"
              svgIcon="ch:visibility-outline"
            >
            </mat-icon>
          </button>
          <div
            class="breadcrumb-icon-container"
            *ngIf="showMoreIcons && !!analysis && !isRenameClicked"
          >
            <div class="breadcrumb-icon-list">
              <div class="button-group">
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="isShared"
                  (click)="renameAnalysis()"
                >
                  <mat-icon
                    [matTooltip]="
                      isShared
                        ? appConstantsService.editDisableTooltip
                        : appConstantsService.editAnalysisTooltip
                    "
                    matTooltipClass="ch-tooltip"
                    class="grey-icon default-icon edit-icon"
                    svgIcon="ch:edit"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="!analysis?.rerun || isRerunDisabledForNoc"
                  (click)="rerunAnalysis($event)"
                >
                  <mat-icon
                    [matTooltip]="rerunTooltip"
                    matTooltipClass="ch-tooltip"
                    class="grey-icon default-icon custom-icon rerun-icon"
                    svgIcon="ch:rerun"
                  >
                  </mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  (click)="commentAnalysis($event)"
                >
                  <p
                    *ngIf="commentsCount?.total > 0"
                    class="count-card"
                    [class.unread-count]="commentsCount?.unread > 0"
                  >
                    {{ getCommentsCount() }}
                  </p>
                  <mat-icon
                    class="grey-icon comment-icon"
                    [matTooltip]="appConstantsService.commentTooltip"
                    matTooltipPosition="below"
                    matTooltipClass="ch-tooltip"
                    svgIcon="ch:comment"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  synthiaLiteDisabledFeature
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="disableShareButton"
                  (click)="shareAnalysis($event)"
                >
                  <p *ngIf="shareCount > 0" class="count-card">
                    {{ shareCount > maxCount ? maxCount : shareCount }}
                  </p>
                  <mat-icon
                    class="grey-icon share-icon"
                    [matTooltip]="appConstantsService.shareTooltip"
                    matTooltipPosition="below"
                    matTooltipClass="ch-tooltip"
                    svgIcon="ch:share"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button"
                  [disabled]="!isFavoritingAllowed"
                  (click)="handleFavoriteAnalysis($event)"
                >
                  <mat-icon
                    class="grey-icon default-icon"
                    [matTooltip]="
                      isFavoritingAllowed
                        ? analysis.is_favorite
                          ? appConstantsService.unfavoriteTooltip
                          : appConstantsService.favoriteTooltip
                        : appConstantsService.favoriteDeletedTooltip
                    "
                    matTooltipClass="ch-tooltip"
                  >
                    {{ isFavorited ? 'star' : 'star_outline' }}
                  </mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ch-action-icon-hover mat-icon-button delete-analysis"
                  [disabled]="isDeleteButtonDisabled"
                  (click)="deleteOrRestoreAnalysis($event)"
                >
                  <mat-icon
                    [matTooltip]="
                      isDeleteButtonDisabled
                        ? appConstantsService.deleteAnalysisDisableTooltip
                        : appConstantsService.deleteAnalysisTooltip
                    "
                    matTooltipClass="ch-tooltip"
                    class="grey-icon default-icon"
                  >
                    {{ getDeleteRestoreAnalysisIcon() }}
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              mode === ResultsView.PATHWAYS &&
              !libraryView &&
              !isDiversityLibrary &&
              !isDiversityResultView
            "
            [ngClass]="{
              'dropdown-container': true,
              'computation-progress': isComputationActive,
            }"
          >
            <ng-container *ngTemplateOutlet="sortBy"></ng-container>
          </div>
        </div>
        <div *ngIf="isRenameClicked && item.label === analysis.name" class="analysis-rename">
          <mat-form-field class="value-rename-field">
            <input
              matInput
              [(ngModel)]="analysisRename"
              ch-select-on-autofocus
              (keydown)="handleKeyEvents($event, i)"
            />
          </mat-form-field>
          <div class="button-group">
            <button
              mat-icon-button
              class="ch-action-icon-hover"
              (click)="saveEditedAnalysisName(i)"
              [disabled]="!analysisRename"
            >
              <mat-icon
                class="action-icon grey-icon"
                matTooltip="Save"
                matTooltipPosition="above"
                matTooltipClass="ch-tooltip"
                svgIcon="ch:save-outline"
              ></mat-icon>
            </button>
            <button mat-icon-button class="ch-action-icon-hover" (click)="closeRenameDialog()">
              <mat-icon
                class="action-icon grey-icon"
                matTooltip="Close"
                matTooltipPosition="above"
                matTooltipClass="ch-tooltip"
                >close</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="right-side"
    *ngIf="!!analysis"
    [ngClass]="libraryView ? 'pathway-view-library' : 'pathway-view'"
  >
    <div
      class="diversity-sort-container"
      *ngIf="isDiversityResultView"
      [ngClass]="{
        'dropdown-container': true,
        'computation-progress': isComputationActive,
      }"
    >
      <ng-container *ngTemplateOutlet="sortBy"></ng-container>
    </div>
    <div
      *ngIf="
        mode === ResultsView.PATHWAYS &&
        !libraryView &&
        !isDiversityLibrary &&
        !isDiversityResultView
      "
      class="label-container"
      matTooltipPosition="below"
      matTooltipClass="ch-tooltip"
      [matTooltip]="
        !isAllPathLoaded
          ? appConstantsService.globalPathwaysDisabledTooltip
          : appConstantsService.toggleTooltip
      "
    >
      <mat-icon
        class="toggle-icon"
        svgIcon="ch:block_view"
        [class.slide-toggle]="!isAllPathLoaded"
      ></mat-icon>
      <mat-slide-toggle
        color="primary"
        [class.slide-toggle]="!isAllPathLoaded"
        (change)="pathwayViewEnabled($event)"
        [(ngModel)]="isPathwayViewOnly"
      >
      </mat-slide-toggle>
      <mat-icon
        class="toggle-icon"
        svgIcon="ch:path_view"
        [class.slide-toggle]="!isAllPathLoaded"
      ></mat-icon>
    </div>
    <button
      [ngClass]="
        isSinglePathwaySelected ||
        isPathwayViewOnly ||
        ((libraryView || isDiversityLibrary || isDiversityResultView) && isAllPathwaysLoaded)
          ? 'ch-action-icon-hover'
          : 'pathway-option-hidden'
      "
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="
        ((!libraryView &&
          mode === ResultsView.PATHWAYS &&
          (isPathwayViewOnly || isSinglePathwaySelected)) ||
          (mode === ResultsView.PATHWAYS && libraryView && isAllPathwaysLoaded) ||
          isDiversityLibrary) &&
        !isDiversityResultView
      "
      (click)="updateNodeLabelControlChange()"
    >
      <mat-icon class="grey-icon" svgIcon="ch:pathway_option"></mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="grid-menu">
      <div>
        <div class="option-header">Pathway Options</div>
        <mat-divider class="div-options-header"></mat-divider>
        <mat-checkbox
          [disabled]="isDiversityLibrary"
          class="control-checkbox"
          [class.control-checkbox-disabled]="isDiversityLibrary"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.repeatedReaction"
          (change)="nodeLabelControlChange(nodeLabelTypes.repeatedReaction, $event)"
          *ngIf="libraryView"
        >
          Highlight repeated reactions
        </mat-checkbox>
        <mat-checkbox
          [disabled]="isDiversityLibrary"
          class="control-checkbox"
          [class.control-checkbox-disabled]="isDiversityLibrary"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.repeatedMolecule"
          (change)="nodeLabelControlChange(nodeLabelTypes.repeatedMolecule, $event)"
          *ngIf="libraryView"
        >
          Highlight repeated molecules
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.reactionName"
          (change)="nodeLabelControlChange(nodeLabelTypes.reactionName, $event)"
        >
          Reaction Names
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.typicalConditions"
          (change)="nodeLabelControlChange(nodeLabelTypes.typicalConditions, $event)"
        >
          Typical Conditions
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.legendTags"
          (change)="nodeLabelControlChange(nodeLabelTypes.legendTags, $event)"
        >
          Legend Tags
        </mat-checkbox>
        <mat-checkbox
          class="control-checkbox"
          color="primary"
          (click)="$event.stopPropagation()"
          [(ngModel)]="nodeLabelControl.publishedReferences"
          (change)="nodeLabelControlChange(nodeLabelTypes.publishedReferences, $event)"
        >
          Published Reaction References
        </mat-checkbox>
      </div>
    </mat-menu>
    <button
      class="action-icon-hover-coloring"
      mat-icon-button
      [matMenuTriggerFor]="menuTargetColor"
      *ngIf="isDiversityResultView && isAllPathwaysLoaded && diversityTarget !== 0"
    >
      <mat-icon class="grey-icon" svgIcon="ch:pathway_option"></mat-icon>
    </button>
    <mat-menu #menuTargetColor="matMenu" class="color-menu">
      <div>
        <span class="text-substrate-color">Target coloring:</span>
        <mat-radio-group
          [(ngModel)]="selectedColoring"
          class="radio-group"
          (change)="updateTargetColoring($event)"
        >
          <mat-radio-button
            [value]="coloringOption.none"
            class="radio"
            (click)="$event.stopPropagation()"
            color="primary"
          >
            None
          </mat-radio-button>
          <mat-radio-button
            [value]="coloringOption.structuralDifference"
            class="radio"
            (click)="$event.stopPropagation()"
            color="primary"
          >
            Structural differences
          </mat-radio-button>
          <mat-radio-button
            [value]="coloringOption.buildingBlocks"
            class="radio"
            (click)="$event.stopPropagation()"
            color="primary"
          >
            Building blocks
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-menu>
    <span
      *ngIf="!libraryView && !isDiversityLibrary && !isDiversityResultView"
      [matTooltip]="getPathViewButtonTooltip()"
      matTooltipClass="ch-tooltip"
    >
      <button
        mat-button
        class="view-button"
        [class.active-button]="mode === ResultsView.PATHWAYS"
        (click)="changeMode(ResultsView.PATHWAYS)"
        [disabled]="loadingAnalysis || analysis?.isAlgorithmWithoutPathways()"
      >
        <mat-icon>linear_scale</mat-icon> PATH
      </button>
    </span>
    <button
      [matTooltip]="appConstantsService.graphViewTooltip"
      matTooltipClass="ch-tooltip"
      mat-button
      class="view-button"
      [class.active-button]="mode === ResultsView.GRAPH"
      (click)="changeMode(ResultsView.GRAPH)"
      [disabled]="loadingAnalysis || !isAllPathwaysLoaded"
      *ngIf="!libraryView && !isDiversityLibrary && !isDiversityResultView"
    >
      <mat-icon>bubble_chart</mat-icon> GRAPH
    </button>
    <span *ngIf="isDiversityResultView && isAllPathLoaded">
      <mat-checkbox
        *ngIf="isAllPathLoaded"
        class="example-margin"
        [checked]="checked"
        [indeterminate]="indeterminate"
        [disabled]="diversityResultMolecules?.length === 0"
        (change)="onCheckboxClick($event)"
        color="primary"
        [matTooltip]="diversityCheckboxTooltip"
        matTooltipPosition="before"
        matTooltipClass="ch-tooltip custom-tooltip"
      >
      </mat-checkbox>
      <mat-menu class="mat-menu" #dropdownMenu="matMenu">
        <button
          mat-menu-item
          (click)="selectOption('All')"
          [disabled]="
            analysisResultsService.selectedDiversityTargets.value.length ===
            diversityResultMolecules.length
          "
        >
          All
        </button>
        <button
          mat-menu-item
          (click)="selectOption('None')"
          [disabled]="analysisResultsService.selectedDiversityTargets.value.length === 0"
        >
          None
        </button>
        <button
          mat-menu-item
          (click)="selectOption('Favorite')"
          [disabled]="isAllFavorieSelected()"
          [matTooltip]="noFavorites() ? 'No items marked as favorite' : ''"
          matTooltipPosition="before"
          matTooltipClass="ch-tooltip"
        >
          <mat-icon [ngClass]="isAllFavorieSelected() ? 'favorite-icon-disabled' : 'favorite-icon'">
            {{ !isAllFavorieSelected() ? 'star' : 'star_outline' }}
          </mat-icon>
          Favorites
        </button>
      </mat-menu>
      <button class="selection-menu" mat-icon-button [matMenuTriggerFor]="dropdownMenu">
        <mat-icon class="material-symbols-outlined"> keyboard_arrow_down </mat-icon>
      </button>
      <p
        *ngIf="analysisResultsService.selectedDiversityTargets.value.length > 0"
        class="selected-count"
      >
        {{ analysisResultsService.selectedDiversityTargets.value.length }}
      </p>
    </span>
    <span
      class="more-info"
      (mouseenter)="diversityMoreIconMouseEnter(menuTrigger)"
      (mouseleave)="diversityMoreIconMouseLeave()"
      matTooltipPosition="before"
      matTooltipClass="ch-tooltip"
      [matTooltip]="showDiversityMoreIcons ? '' : appConstantsService.diversityMoreDisabledTooltip"
      *ngIf="isDiversityResultView"
    >
      <button
        mat-icon-button
        [disabled]="!showDiversityMoreIcons"
        [ngClass]="showDiversityMoreIcons ? 'more-menu-enabled' : 'more-menu-disabled'"
      >
        <mat-icon
          class="mat-icon-analysis-header"
          [matMenuTriggerFor]="diversityMenu"
          #menuTrigger="matMenuTrigger"
        >
          more_vert</mat-icon
        >
      </button>
    </span>
    <mat-menu
      #diversityMenu
      class="right-diversity-menu-container diversity-icon-container"
      (mouseup)="closeMenuInstance()"
    >
      <button
        mat-menu-item
        class="ch-action-icon-hover cursor menu-button"
        (click)="favoriteMultiplePaths($event)"
      >
        <mat-icon
          class="grey-icon ch-action-icon-hover"
          [matTooltip]="multipleFavoriteTooltip"
          matTooltipPosition="after"
          matTooltipClass="ch-tooltip"
        >
          {{ multipleFavorite ? 'star_outline' : 'star' }}</mat-icon
        >
      </button>
      <button
        mat-menu-item
        class="ch-action-icon-hover cursor menu-button"
        (click)="showFilterPopup($event)"
      >
        <mat-icon
          class="grey-icon ch-action-icon-hover"
          [matTooltip]="appConstantsService.diversityFilterTooltip"
          matTooltipPosition="after"
          matTooltipClass="ch-tooltip"
          >filter_list</mat-icon
        >
      </button>
      <button
        mat-menu-item
        class="ch-action-icon-hover cursor menu-button"
        [matTooltip]="appConstantsService.diversityClipboardTooltip"
        matTooltipPosition="after"
        matTooltipClass="ch-tooltip"
        (click)="addToClipboard()"
      >
        <mat-icon class="clipboard-icon" svgIcon="ch:new-pin"> </mat-icon>
      </button>
      <div
        class="button-wrapper"
        [matTooltip]="
          analysisResultsService.selectedDiversityTargets.value.length >
          selectedMoleculeLimitForShoppingList
            ? appConstantsService.diversityshopIconTooltipDisabled
            : appConstantsService.diversityShopIconTooltip
        "
        matTooltipPosition="after"
        matTooltipClass="ch-tooltip"
      >
        <button
          mat-menu-item
          class="button-count ch-action-icon-hover cursor menu-button"
          (click)="addToShopMultiplePaths()"
          *ngIf="isECommIntegrationEnabled"
          [disabled]="
            analysisResultsService.selectedDiversityTargets.value.length >
            selectedMoleculeLimitForShoppingList
          "
        >
          <mat-icon svgIcon="ch:molset-ind" class="shop-now-icon"></mat-icon>
        </button>
      </div>
      <button
        mat-menu-item
        class="ch-action-icon-hover cursor menu-button"
        (click)="copySmiles($event)"
      >
        <mat-icon
          class="grey-icon"
          [matTooltip]="appConstantsService.diversityCopySmilesTooltip"
          matTooltipPosition="after"
          matTooltipClass="ch-tooltip"
        >
          {{ isSourceSmilesCopied ? 'check_circle' : 'content_copy' }}
        </mat-icon>
      </button>
      <button
        mat-icon-button
        class="ch-action-icon-hover cursor menu-button save-icon"
        [matMenuTriggerFor]="menuDownload"
        #menuTriggerSource="matMenuTrigger"
        (click)="openSubMenu($event, menuTriggerSource)"
      >
        <mat-icon
          class="grey-icon"
          [matTooltip]="appConstantsService.diversityDownloadTooltip"
          matTooltipPosition="after"
          matTooltipClass="ch-tooltip"
        >
          save_alt
        </mat-icon>
      </button>
    </mat-menu>
    <mat-menu #menuDownload="matMenu" class="more-menu download-more-menu">
      <div class="menu-targets-text">Targets</div>
      <button mat-menu-item class="more-menu-item exclude-hover" (click)="getHtmlImage()">
        <span class="menu-item-text">Images (HTML)</span>
      </button>
      <button
        mat-menu-item
        class="more-menu-item menu-text-last exclude-hover"
        (click)="downloadCSV()"
      >
        <span class="menu-item-text">Structures (CSV)</span>
      </button>
    </mat-menu>
  </div>
</nav>

<ng-template #sortBy>
  <div class="sort-by-container">
    <span class="dropdown-label" [class.disabled-label]="isSortDisabled()">Sort By</span>
    <mat-form-field
      appearance="outline"
      class="ch-form-field ch-select-field pathway-field"
      [class.diversity-view]="isDiversityResultView"
    >
      <mat-select
        class="sort-select"
        [(ngModel)]="selectedSort"
        (selectionChange)="onSortChange($event.value)"
        [disabled]="isSortDisabled()"
      >
        <ng-container
          *ngFor="let sortOption of isDiversityResultView ? sortOptionsDiversity : sortOptions"
        >
          <mat-option [value]="sortOption" class="sort-by-dropdown-option">
            <span>{{ sortOption }}</span>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <button
    mat-icon-button
    [ngClass]="{
      'ch-action-icon-hover': true,
      'sort-icon': isComputationActive,
    }"
    [disabled]="isSortDisabled()"
    (click)="onSortOrderChange()"
  >
    <mat-icon
      class="action-icon grey-icon"
      [class.ascending-icon]="isAscending"
      matTooltipPosition="above"
      matTooltipClass="ch-tooltip"
    >
      sort
    </mat-icon>
  </button>
</ng-template>
