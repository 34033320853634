import { isString } from '../../shared/components/utils';

export class PlanItem {
  /* tslint:disable:variable-name */
  public plan_item_id: string = '';
  public name: string = '';
  public price: any = null;
  public dimension_identifier: string = '';
  public limit: any = null;
  public recurring_interval: string = '';
  public recurring_interval_count: number = 0;
  public total_amount: number = 0;
  public metering_resource_id: string = '';
  public usage: any = null;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (field === 'limit') {
          this.limit = jsonData[field] ? jsonData[field] : null;
        } else if (field === 'price' && jsonData[field] !== null) {
          this[field] = jsonData[field];
          if (jsonData[field].recurring) {
            this.recurring_interval = jsonData[field].recurring.interval;
            this.recurring_interval_count = jsonData[field].recurring.interval_count;
          }
          if (jsonData[field].tiers && jsonData[field].tiers.length > 0) {
            const tier = jsonData[field].tiers[0];
            this.total_amount = tier.flat_amount;
          }
        } else if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
          this[field] = jsonData[field];
        } else {
          console.warn(`Unexpected type of '${field}' in data provided to PlanItem:ctor.`);
        }
      } else {
        console.warn(`Unexpected type of '${field}' in data provided to PlanItem:ctor.`);
      }
    }
  }
}
