import { MoleculeSearchService } from './../../services/molecule-search/molecule-search.service';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  filterTypes,
  IFilterCriteria,
} from './../results-filter/results-filter-molecule-editor/results-filter-molecule-editor.component';
import {
  GraphMoleculeNode,
  GraphNodeType,
  GraphReactionNode,
} from 'src/app/shared/services/graph-builder';
import * as moleculeUtils from '../../../shared/components/molecule-utils';
import { ParsedConnectionError } from '../../../shared/services/errors-handler';
import { InfoDialogContent, InfoDialogService } from '../../../shared/services/info-dialog.service';
import { InfoService } from '../../../shared/services/info.service';
import { INodeEvent } from '../../../shared/services/models/node-event';
import { isNullOrUndefined } from '../../../shared/components/utils';
import { MoleculeSetsService } from '../../../shared/services/molecule-sets';
import { MoleculeImageComponent } from '../../components/molecule-image';
import {
  AnalysisResultsService,
  SidePanelViewType,
  AlgorithmType,
  NodesClipboardService,
} from '../../services';
import { GraphNodePopupService } from '../../services/graph-node-popup/graph-node-popup.service';
import {
  IReactionData,
  IReactionFilter,
} from '../results-filter/results-filter-default/results-default-filter.models';
import { AppConstantsService } from 'src/app/shared/services/app-constants/app-constants.service';
import { CopyToClipboardService } from '../../services/copy-to-clipboard.service';
import { StorageHandlerService } from '../../services/storage-handler.service';
import { ECommHandlerService } from 'src/app/ecomm-integration/services/ecomm-handler.service';
import { AppState } from 'src/app/app-state.service';

export enum NodeMenuParentClass {
  nodeDetailPopup = 'node-detail-popup',
  nodeDetailFromConfigure = 'node-detail-from-target',
  nodePopup = 'node-popup',
  clipboardItem = 'clipboard-item',
  moleculeList = 'molecule-report',
  reactionReport = 'reaction-report',
}

export interface FilterData {
  lastReaction: boolean;
  filterType: string;
}

@Component({
  selector: 'ch-new-graph-node-menu',
  templateUrl: './new-graph-node-menu.component.html',
  styleUrls: ['./new-graph-node-menu.component.scss'],
})
export class NewGraphNodeMenuComponent implements OnInit, OnChanges, OnDestroy {
  public shopLinks: any[] = [];
  public isReaction: boolean;
  public userMoleculeSets: any[] = [];
  public unsubscriberSubject: Subject<void> = new Subject<void>();
  public filterType: string = this.appConstantService.exclude;
  public isNodeDetailPopup = false;
  public isMoleculeDetailFromConfigure = false;
  public isFromMoleculeList = false;
  public isPinned: boolean = false;
  public lastReaction: boolean = false;
  public graphId: number;
  public isFilterEnabled: boolean = true;
  public isFeatureDisabled: boolean = false;
  public isECommIntegrationEnabled: boolean = false;
  public targetMoleculeForDiversity: boolean = false;

  @Input() public node: GraphMoleculeNode | GraphReactionNode;
  @Input() public parentClass: string;
  @Input() public moleculeImageComponent: MoleculeImageComponent;
  @Input() public algorithmType: AlgorithmType;
  @Output() public setShowMenuSection: EventEmitter<boolean> = new EventEmitter();
  @Output() public closeMenuInstance: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public appConstantService: AppConstantsService,
    public clipboardService: NodesClipboardService,
    public popupService: GraphNodePopupService,
    public infoDialogService: InfoDialogService,
    public moleculeSetsService: MoleculeSetsService,
    public storageHandlerService: StorageHandlerService,
    private infoService: InfoService,
    private analysisResultsService: AnalysisResultsService,
    private copyToClipboardService: CopyToClipboardService,
    private moleculeSearchService: MoleculeSearchService,
    private eCommHandlerService: ECommHandlerService,
    private appStateService: AppState,
  ) {}

  public ngOnInit() {
    this.listenToMoleculeSets();
    this.isNodeDetailPopup = !!(this.parentClass === NodeMenuParentClass.nodeDetailPopup);
    this.isMoleculeDetailFromConfigure = !!(
      this.parentClass === NodeMenuParentClass.nodeDetailFromConfigure
    );
    this.isFromMoleculeList = !!(this.parentClass === NodeMenuParentClass.moleculeList);
    this.listenToClipboardEvents();
    this.popupService.clipboard.requestPinnedStatus(this.node);
    this.storageHandlerService.remainingAnalysisCount
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((analysisRemaining) => {
        this.isFeatureDisabled = analysisRemaining === 0;
      });
    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((config) => {
        this.isECommIntegrationEnabled = !!config && config.ENABLE_ECOMM_INTEGRATION;
      });

    this.analysisResultsService.diversityTargetExpanded
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((diversityExpanded: boolean) => {
        this.targetMoleculeForDiversity = diversityExpanded;
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.node) {
      this.updateNodeDetails();
    }
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
  }

  public updateNodeDetails() {
    if (this.popupService.isSimilarMoleculePopupInfo.value) {
      if (this.node['molecule']) {
        if (Array.isArray(this.node['molecule'].vendors)) {
          this.shopLinks = moleculeUtils.scanSigmaAldrichShopLinks(this.node);
        } else {
          const convertedVendorObject = Object.keys(this.node['molecule'].vendors).map((vendor) => {
            return {
              data: this.node['molecule'].vendors[vendor],
              vendor: vendor,
            };
          });
          this.node['molecule'].vendors = convertedVendorObject;
          this.shopLinks = moleculeUtils.scanSigmaAldrichShopLinks(this.node);
        }
      }
    } else {
      this.shopLinks = moleculeUtils.scanSigmaAldrichShopLinks(this.node);
    }
    this.isReaction = this.isReactionNode();
    this.graphId = this.node.chNodeAnalysisContext ? this.node.chNodeAnalysisContext.graphId : null;
    this.checkAnyMenuToShow();
    this.popupService.clipboard.requestPinnedStatus(this.node);
    this.setIsFilterEnabled();
  }

  public shouldShowBuyButton() {
    return this.shopLinks.length > 0 && !this.isReaction;
  }

  public isReactionNode(): boolean {
    return (
      this.node.type === GraphNodeType.REACTION ||
      this.node.type === GraphNodeType.EXPANDED_REACTION
    );
  }

  public listenToMoleculeSets() {
    this.moleculeSetsService
      .getSortedMoleculeSetTemplates()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((moleculeSets: any[]) => {
        const setItemsGroup = this.moleculeSetsService.filterUserAndRegulatedList(moleculeSets);
        this.userMoleculeSets = setItemsGroup.userList;
      });
  }

  public getStructure() {
    this.moleculeImageComponent.getStructureEvent();
  }

  public copySmiles() {
    if (this.node && this.node.molecule) {
      const toBeCopied: string = this.node.molecule.smiles;
      this.copyToClipboardService
        .copyContent(toBeCopied.toString())
        .pipe(takeUntil(this.unsubscriberSubject))
        .subscribe((success: boolean) => {
          if (success) {
            this.infoService.showInfo(this.appConstantService.smilesCopyMessage);
          }
        });
    }
  }
  public startNewAnalysis() {
    if (this.isNodeDetailPopup) {
      this.closeMenuInstance.emit(true);
      this.popupService.isExpandedPathViewClosed.next(true);
    }
    this.popupService.dispatchNodeEvent(this.node, 'newanalysis', true);
  }

  public stopMenuClosing(event: MouseEvent) {
    if (!this.isNodeDetailPopup) {
      event.stopPropagation();
    }
  }

  public addFilter() {
    if (!this.isFilterEnabled) {
      return false;
    }

    this.closeMenuInstance.emit(true);
    this.popupService.isExpandedPathViewClosed.next(true);
    const filterCriteria: IFilterCriteria = {
      smiles: '',
      smarts: '',
      filterType: filterTypes.Drawn,
      similarity: 100,
      showCategory: this.filterType,
      isFilterToBeRemoved: false,
    };
    switch (this.node.type) {
      case GraphNodeType.EXPANDED_IMAGE:
      case GraphNodeType.MOLECULE:
      case GraphNodeType.STRUCTURE:
        filterCriteria.smiles = this.node.molecule.smiles;
        const smartsList = this.moleculeSearchService.smilesToSmartsWithRdkit(
          filterCriteria.smiles,
        );
        if (!isNullOrUndefined(smartsList) && smartsList.length) {
          filterCriteria.smarts = smartsList[0];
        }
        this.analysisResultsService.addStructureFilterFromPopup.next(filterCriteria);
        setTimeout(() => {
          this.analysisResultsService.selectedMenu.next(SidePanelViewType.Filter);
          this.analysisResultsService.isMenuOpen.next(true);
        }, 500);
        break;
      case GraphNodeType.EXPANDED_REACTION:
      case GraphNodeType.REACTION:
        const sectionName =
          this.filterType === this.appConstantService.exclude
            ? this.appConstantService.filterSection.exclude
            : this.appConstantService.filterSection.limitTo;
        let reactionFilter: IReactionFilter;
        const reactionData: IReactionData = {
          reactionName: this.node.reaction.name,
          smiles: this.node.reaction.smiles,
        };
        if (this.lastReaction) {
          reactionFilter = {
            lastDisconnected: {
              [sectionName]: {
                reactions: [reactionData],
              },
            },
          };
        } else {
          reactionFilter = {
            [sectionName]: {
              reactions: [reactionData],
            },
          };
        }
        this.analysisResultsService.addReactionFilterFromPopup.next(reactionFilter);
        setTimeout(() => {
          this.analysisResultsService.selectedMenu.next(SidePanelViewType.Filter);
          this.analysisResultsService.isMenuOpen.next(true);
        }, 500);
        break;
    }
  }

  public isLastReaction() {
    return this.isReaction && !this.node.reaction_node.parent_reaction_node;
  }

  // Mat menu content contains button with height equal to 0px.
  // This button is auto focussed after opening 'Add to molecule set...' sub-menu which causes an incorrect tooltip
  // to be displayed.
  public addMoleculeToSet(moleculeSet: any) {
    this.moleculeSetsService
      .addMoleculesToSet({ setId: moleculeSet.id, moleculeId: this.node.molecule.id })
      .subscribe(
        () => {
          this.infoService.showInfo(`Molecule added to set '${moleculeSet.name}'`);
          if (this.parentClass === NodeMenuParentClass.nodePopup) {
            this.popupService.disposeNodeOverlay();
          }
        },
        (error) => {
          const parsedError = new ParsedConnectionError(error);
          if (parsedError.bodyJson.code === 'non-unique-molecule-set-molecule') {
            this.infoService.showError(
              `Molecule set '${moleculeSet.name}' already contains this molecule`,
              3000,
            );
          }
          if (parsedError.bodyJson.code === 'molecule-set-not-found') {
            this.infoService.showError(`Molecule set: '${moleculeSet.name}' does not exist`, 3000);
          }
        },
      );
  }

  public openProductID(productId: string) {
    const dialogContent = new InfoDialogContent();
    dialogContent.title = 'User Inventory';
    dialogContent.message = `<h4>Product ID:</h4><pre> ${productId} </pre>`;
    this.infoDialogService.info(dialogContent);
  }

  public checkAnyMenuToShow() {
    if (this.isMoleculeDetailFromConfigure && !this.shouldShowBuyButton()) {
      this.setShowMenuSection.emit(false);
    }
  }

  public addToClipboard() {
    this.analysisResultsService.selectedMenu.next(SidePanelViewType.Clipboard);
    this.analysisResultsService.isMenuOpen.next(true);
    setTimeout(() => {
      if (this.isNodeDetailPopup) {
        this.closeMenuInstance.emit(true);
        this.popupService.pinExaminedFromNodeDetails(this.node);
      } else if (
        this.isFromMoleculeList ||
        this.parentClass === NodeMenuParentClass.reactionReport
      ) {
        this.popupService.pinExaminedFromNodeDetails(this.node);
      } else {
        this.popupService.pinExamined();
      }
    }, 100);
  }

  public listenToClipboardEvents() {
    this.popupService.clipboard.nodeEvents
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((nodeEvent: INodeEvent) => {
        const isEqualToNode: boolean = Object.keys(this.node).every(
          (key) => this.node[key] === nodeEvent.node[key],
        );
        if (nodeEvent.node && isEqualToNode) {
          this.isPinned = nodeEvent.eventType === 'pinned';
        }
      });
  }

  public isGraphExpansionPossible(): boolean {
    return (
      (this.algorithmType === AlgorithmType.ManualRetrosynthesis ||
        this.algorithmType === AlgorithmType.ReactionNetwork ||
        this.algorithmType === AlgorithmType.ReverseReactionNetwork) &&
      this.node.type !== GraphNodeType.REACTION &&
      this.node.primaryProduct &&
      !this.node.isStartingMolecule
    );
  }

  public expandGraph() {
    this.popupService.dispatchNodeEvent(this.node, 'expandgraph', true);
    if (this.isNodeDetailPopup) {
      this.closeMenuInstance.emit(true);
    }
  }

  public showReactionReport() {
    this.analysisResultsService.selectedMenu.next(SidePanelViewType.ReactionReport);
    this.analysisResultsService.isMenuOpen.next(true);
    const nodeObj = this.node;
    nodeObj.graphId = this.graphId;
    this.popupService.dispatchOtherNodeEvent(nodeObj, 'reactionreport');
    this.closeMenuInstance.emit(true);
    this.clipboardService.selectedReactionNodes.next([]);
  }

  public updateNodePresentFlag(isMouseEnter: boolean) {
    if (this.parentClass === NodeMenuParentClass.nodePopup) {
      this.popupService.isCursorWithinPopupBounds = isMouseEnter;
    }
  }

  public addFilterFromNodeDetailPopup(filterData: FilterData) {
    this.lastReaction = filterData.lastReaction;
    this.filterType = filterData.filterType;
    this.addFilter();
  }

  public setIsFilterEnabled() {
    this.isFilterEnabled = !!(
      this.algorithmType === AlgorithmType.AutomaticRetrosynthesis ||
      this.algorithmType === AlgorithmType.ManualRetrosynthesis ||
      this.algorithmType === AlgorithmType.LibraryMode ||
      this.algorithmType === AlgorithmType.DiversityLibrary
    );
  }

  public openPricingAvailabilityPopup() {
    this.eCommHandlerService.openPricingAvailabilityDialog([this.node]);
  }
}
