import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { NodeEventType, INodeEvent } from '../models/node-event';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NodesClipboardService } from '../nodes-clipboard.service';
import { pageDimensions } from '../../components/utils';
import { GRAPH_NODE_DATA } from './graph-node-popup.models';
import { GraphMoleculeNode, GraphReactionNode } from '../graph-builder';
import { NewGraphNodePopupComponent } from '../../components/new-graph-node-popup/new-graph-node-popup.component';
import { ReactionNodeMenuComponent } from '../../components/reaction-node-menu/reaction-node-menu.component';

@Injectable({
  providedIn: 'root',
})
export class GraphNodePopupService {
  // Subscribe to receive events sent from nodes
  public nodeEvents: Observable<INodeEvent>;
  public isNodePopupPresent: boolean = false;
  public isCursorWithinPopupBounds: boolean = false;
  public hidePopupOnMouseOut: boolean = true;
  public selectedNodes: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public isSimilarMoleculePopupInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isExpandedPathViewClosed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private reactionMenuOverlayRef: OverlayRef;
  private nodeEventsQueue = new Subject<INodeEvent>();
  private examinedNode: any;
  private reactionMenuNode: any;
  private examinedNodeOverlay: OverlayRef;
  private nodePopupMouseMoveEvent: any;
  private nodeMenuMouseMoveEvent: any;
  private backdropMouseOverEvent: any;
  private backdropMenuMouseOverEvent: any;

  constructor(
    public clipboard: NodesClipboardService,
    private overlay: Overlay,
    private injector: Injector,
  ) {
    this.nodeEvents = this.nodeEventsQueue.asObservable();
  }

  public dispatchNodeEvent(
    node: GraphMoleculeNode | GraphReactionNode,
    eventType: NodeEventType,
    disposePopup?: boolean,
  ) {
    this.nodeEventsQueue.next({ node, eventType });
    if (disposePopup) {
      this.disposeNodeOverlay();
    }
  }

  public disposeNodeOverlay() {
    if (this.examinedNode !== undefined) {
      this.removeEventListener();
      this.examinedNodeOverlay.dispose();
      this.examinedNodeOverlay = undefined;
      this.examinedNode = undefined;
      this.hidePopupOnMouseOut = true;
      this.isNodePopupPresent = false;
    }
  }

  public disposeMenuOverlay() {
    if (this.reactionMenuNode !== undefined) {
      this.removeEventListenerMenu();
      this.reactionMenuOverlayRef.dispose();
      this.reactionMenuOverlayRef = undefined;
      this.reactionMenuNode = undefined;
      this.hidePopupOnMouseOut = true;
      this.isNodePopupPresent = false;
    }
  }

  // FIXME: Switch to a normal node events sent trough regular event dispatching methods of service
  // This method is only a proxy to mark places to fix.
  // Node event consists of a node and event name. Unfortunately somebody started to use node property as a place for
  // objects having node as an another field such as {node, graphId}, {node, select} etc. The fix needed is to get
  // rid of such objects by moving additional contextual information to an additional INodeEvent field or directly to
  // the node if more appropriate.
  public dispatchOtherNodeEvent(
    dataWithNode: GraphMoleculeNode | GraphReactionNode | any,
    eventType: NodeEventType,
    disposePopup?: boolean,
  ) {
    this.dispatchNodeEvent(dataWithNode, eventType, disposePopup);
  }

  public isSameNodeOpened(node: any) {
    return (
      !!this.examinedNode &&
      Object.keys(this.examinedNode).every((key) => this.examinedNode[key] === node[key])
    );
  }

  public isSameMenuOpened(node: any) {
    return (
      !!this.reactionMenuNode &&
      Object.keys(this.reactionMenuNode).every((key) => this.reactionMenuNode[key] === node[key])
    );
  }

  public showMenuAtNode(node: any, userActionType) {
    const isEqualToNode = this.isSameMenuOpened(node);
    if (isEqualToNode) {
      return;
    }

    this.disposeMenuOverlay();

    const adjustCoordinate = (position, size, lowerLimit, upperLimit) =>
      upperLimit - lowerLimit < size || position < lowerLimit
        ? lowerLimit
        : position + size > upperLimit
          ? upperLimit - size
          : position;

    const pageSize = pageDimensions();
    const spacing = 0;
    const popupWidth = 292;
    const popupHeight = 254;
    const popupLeft = adjustCoordinate(
      node.chGraphViewRect.left + node.chPositionInGraphViewRect.x + spacing,
      popupWidth + 2 * spacing,
      0,
      pageSize.width || node.chGraphViewRect.right,
    );
    const popupTop = adjustCoordinate(
      node.chGraphViewRect.top + node.chPositionInGraphViewRect.y + spacing,
      popupHeight + 2 * spacing,
      0,
      pageSize.height || node.chGraphViewRect.bottom,
    );
    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.backdropClass = 'graph-node-menu-overlay';

    config.positionStrategy = this.overlay
      .position()
      .global()
      .left(`${popupLeft + spacing}px`)
      .top(`${popupTop + spacing}px`);

    this.reactionMenuOverlayRef = this.overlay.create(config);
    this.reactionMenuOverlayRef.backdropClick().subscribe(() => {
      this.disposeMenuOverlay();
    });
    this.reactionMenuNode = node;
    const resolverOb = Injector.create(
      [{ provide: GRAPH_NODE_DATA, useValue: { node, popupService: this } }],
      this.injector,
    );
    this.reactionMenuOverlayRef.attach(
      new ComponentPortal(ReactionNodeMenuComponent, undefined, resolverOb),
    );
    this.isNodePopupPresent = true;
    if (userActionType === 'click' && this.reactionMenuOverlayRef) {
      this.listenBackdropMouseOver();
      this.listenNodePopupMouseMove();
    }
  }

  public examineNode(node: any, userActionType: 'click' | 'hover' = 'click') {
    if (!node || !node.chNodeAnalysisContext) {
      throw new Error('Node with analysis context expected.');
    }

    const isEqualToNode = this.isSameNodeOpened(node);
    if (isEqualToNode) {
      return;
    }

    this.disposeNodeOverlay();

    // Assert that for proposed size and position element stays in limits
    const adjustCoordinate = (position, size, lowerLimit, upperLimit) =>
      upperLimit - lowerLimit < size || position < lowerLimit
        ? lowerLimit
        : position + size > upperLimit
          ? upperLimit - size
          : position;

    const popupWidth = 390;
    const popupHeight = 260;

    const pageSize = pageDimensions();

    const spacing = 5;
    const popupLeft = adjustCoordinate(
      node.chGraphViewRect.left + node.chPositionInGraphViewRect.x + spacing,
      popupWidth + 2 * spacing,
      0,
      pageSize.width || node.chGraphViewRect.right,
    );
    const popupTop = adjustCoordinate(
      node.chGraphViewRect.top + node.chPositionInGraphViewRect.y + spacing,
      popupHeight + 2 * spacing,
      0,
      pageSize.height || node.chGraphViewRect.bottom,
    );

    const config = new OverlayConfig();
    config.hasBackdrop = true;
    config.backdropClass = 'graph-node-popup-overlay';
    config.positionStrategy = this.overlay
      .position()
      .global()
      .left(`${popupLeft + spacing}px`)
      .top(`${popupTop + spacing}px`);

    this.examinedNodeOverlay = this.overlay.create(config);
    this.examinedNodeOverlay.backdropClick().subscribe(() => {
      this.disposeNodeOverlay();
    });

    this.examinedNode = node;
    const resolverOb = Injector.create(
      [{ provide: GRAPH_NODE_DATA, useValue: { node, popupService: this } }],
      this.injector,
    );

    this.examinedNodeOverlay.attach(
      new ComponentPortal(NewGraphNodePopupComponent, undefined, resolverOb),
    );

    this.isNodePopupPresent = true;

    if (userActionType === 'hover' && this.examinedNodeOverlay) {
      this.listenBackdropMouseOver();
      this.listenNodePopupMouseMove();
    }
  }

  public pinExamined() {
    this.clipboard.requestPinning(this.examinedNode);
    this.disposeNodeOverlay();
  }

  public pinExaminedFromNodeDetails(node: GraphMoleculeNode | GraphReactionNode) {
    this.clipboard.requestPinning(node);
  }

  public listenNodePopupMouseMove() {
    if (this.examinedNodeOverlay) {
      this.nodePopupMouseMoveEvent = this.examinedNodeOverlay.hostElement.addEventListener(
        'mousemove',
        () => {
          this.hidePopupOnMouseOut = true;
        },
      );
    }
  }

  public listenBackdropMouseOver() {
    if (this.examinedNodeOverlay) {
      this.backdropMouseOverEvent = this.examinedNodeOverlay.backdropElement.addEventListener(
        'mouseover',
        () => {
          if (this.hidePopupOnMouseOut) {
            this.disposeNodeOverlay();
          }
        },
      );
    }
  }

  public menuInPopupOpen() {
    this.hidePopupOnMouseOut = false;
  }

  public removeEventListener() {
    this.examinedNodeOverlay.hostElement.removeEventListener(
      'mousemove',
      this.nodePopupMouseMoveEvent,
    );
    this.examinedNodeOverlay.hostElement.removeEventListener(
      'mouseover',
      this.backdropMouseOverEvent,
    );
  }

  public removeEventListenerMenu() {
    this.reactionMenuOverlayRef.hostElement.removeEventListener(
      'mousemove',
      this.nodeMenuMouseMoveEvent,
    );
    this.reactionMenuOverlayRef.hostElement.removeEventListener(
      'mouseover',
      this.backdropMenuMouseOverEvent,
    );
  }
}
