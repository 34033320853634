import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  UserRegistrationService,
  SelfRegistrationFormFieldValidationProperties,
  RegistrationFormTypes,
} from '../../../shared/services/user-registration/user-registration.service';
import { CountryDetails } from '../../../shared/services/user-registration/models';
import {
  ParsedConnectionError,
  InfoService,
  ErrorsHandlerService,
  AppConstantsService,
} from '../../services';
import { AppState } from 'src/app/app-state.service';
import { isNullOrUndefined } from '../utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ch-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
})
export class SelectCountryComponent implements OnInit, OnDestroy {
  public countries: CountryDetails[] = [];
  public loadingValues: boolean = false;
  public unsubscriberSubject: Subject<void> = new Subject<void>();
  public readonly formTypes = RegistrationFormTypes;
  public isChinaRegion: boolean = APP_CONFIG.CHINA_REGION_INSTANCE;

  @Input() public formType: RegistrationFormTypes = RegistrationFormTypes.SelfRegistration;
  @Input() public countryFormControl: UntypedFormControl = new UntypedFormControl('');
  @Input() public isFieldInvalid: boolean = false;
  @Input()
  public countryFieldValidationProperties: SelfRegistrationFormFieldValidationProperties['index'] =
    {
      displayName: 'country',
      errorMessages: [],
      maxLength: undefined,
    };
  @Output() public onChangeCountry = new EventEmitter<CountryDetails>();

  constructor(
    public userRegistrationService: UserRegistrationService,
    public infoService: InfoService,
    public appStateService: AppState,
    private errorsHandler: ErrorsHandlerService,
    private appConstantsService: AppConstantsService,
  ) {}

  public ngOnInit() {
    if (this.isChinaRegion) {
      this.countries = [new CountryDetails({ name: '中国', code: 'CN', calling_code: 86 })];
    } else {
      this.getCountries();
    }
    this.appStateService.country
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((countryCode) => {
        if (countryCode !== this.appConstantsService.valueNotAvailable) {
          this.isFieldInvalid = false;
          this.selectCountryByIp();
        }
      });
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
  }

  public getCountries() {
    this.loadingValues = true;
    this.userRegistrationService
      .getCountries()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(
        (countries) => {
          this.loadingValues = false;
          this.countries = countries;
          if (!this.countryFormControl.value) {
            this.selectCountryByIp();
          } else {
            this.resetCountryFormValue();
          }
        },
        (error) => {
          this.loadingValues = false;
          this.showError(error);
        },
      );
  }

  public onCountryChange(value: CountryDetails) {
    if (!isNullOrUndefined(value)) {
      this.onChangeCountry.emit(value);
    }
  }

  public selectCountryByIp() {
    for (const country of this.countries) {
      if (country.code === this.appStateService.country.value) {
        this.countryFormControl.setValue(country);
        this.onChangeCountry.emit(country);
      }
    }
  }

  public showError(error: any) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.shouldRedirect) {
      this.infoService.showInfo(parsedError.promptMessage);
      this.errorsHandler.logout();
    } else if (parsedError.isRecognized()) {
      if (parsedError.status === 403) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage);
      } else {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      }
    }
  }

  private resetCountryFormValue() {
    for (const country of this.countries) {
      if (country.code === this.countryFormControl.value.code) {
        this.countryFormControl.setValue(country);
      }
    }
  }
}
