import { Component, DoCheck, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { AnalysisResultsService } from 'src/app/shared/services';
import { getTooltip } from '../../../utils';
import { IReactionFilter, IRemoveReactionFilter } from '../results-default-filter.models';
import { IFilterImagePopupData } from '../results-default-filter.models';

@Component({
  selector: 'ch-result-reaction-filter',
  templateUrl: './results-reaction-filter.component.html',
  styleUrls: ['./results-reaction-filter.component.scss'],
})
export class ResultReactionFilterComponent implements DoCheck {
  public isNormalReaction: boolean;
  public isLastDisconnectedReaction: boolean;
  public tooltipMaxLength = 36;
  public getTooltip = getTooltip;
  @Input() public reactionFilters: IReactionFilter;
  @Output() public removeReactionFilter: EventEmitter<IRemoveReactionFilter> = new EventEmitter();

  constructor(private analysisResultService: AnalysisResultsService) {}

  public ngDoCheck(): void {
    this.isNormalReaction = this.isNormalReactionsExist();
    this.isLastDisconnectedReaction = this.isLastDisconnectedReactionsExist();
  }

  public removeReactions(reactionIndex: number, sectionName: string, isDisconnected = false) {
    const deleteReactionFilter: IRemoveReactionFilter = {
      index: reactionIndex,
      sectionName,
      isDisconnected,
    };
    this.removeReactionFilter.emit(deleteReactionFilter);
  }

  public onMouseEnter(event: MouseEvent, smiles: string) {
    const target: ElementRef = new ElementRef(event.currentTarget);
    const rect = target.nativeElement.getBoundingClientRect();
    const position = { left: `${rect.left + 150}px`, top: `${rect.bottom + 15}px` };
    const filterImagePopup: IFilterImagePopupData = { position, smiles };
    this.analysisResultService.filterImagePopupDetails.next(filterImagePopup);
  }

  public onMouseLeave() {
    this.analysisResultService.filterImagePopupDetails.next(null);
  }

  private isNormalReactionsExist() {
    if (!this.reactionFilters) {
      return false;
    } else {
      const { limitTo, exclude } = this.reactionFilters;
      return limitTo && limitTo.reactions.length > 0
        ? true
        : exclude && exclude.reactions.length > 0
          ? true
          : false;
    }
  }

  private isLastDisconnectedReactionsExist() {
    if (!this.reactionFilters) {
      return false;
    } else {
      const { lastDisconnected } = this.reactionFilters;
      const { limitTo, exclude } = lastDisconnected;
      return limitTo && limitTo.reactions.length > 0
        ? true
        : exclude && exclude.reactions.length > 0
          ? true
          : false;
    }
  }
}
