import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AnalysisService,
  BackendPushService,
  ComputationState,
  AnalysisResultsService,
  InfoService,
  AppConstantsService,
  BreadcrumbService,
} from '../../services';
import { ComputationEntry } from '../../services/analysis/models/computation-entry';

@Component({
  selector: 'ch-computation-list',
  styleUrls: ['./computation-list.component.scss'],
  templateUrl: './computation-list.component.html',
})
export class ComputationListComponent implements OnChanges, OnDestroy {
  @Input() public analysis: any;
  @Input() public analysisId: number = 0;
  @Input() public algorithm: string = '';
  @Input() public visible: boolean = false;

  @Output() public pendingOrInProgress = new EventEmitter<boolean>();

  public showLoadingComputations: boolean = true;
  public computationList: ComputationEntry[] = [];

  private getComputationsSubscription: Subscription;
  private analysisChangeSubscription: Subscription;

  constructor(
    private backendPushService: BackendPushService,
    private analysisService: AnalysisService,
    private analysisResultsService: AnalysisResultsService,
    private infoService: InfoService,
    private appConstantService: AppConstantsService,
    private breadCrumbService: BreadcrumbService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('analysisId')) {
      if (this.analysisChangeSubscription) {
        this.backendPushService.unobserveAnalysis(this.analysisChangeSubscription);
        this.analysisChangeSubscription = undefined;
      }
      if (this.analysisId) {
        this.analysisChangeSubscription = this.backendPushService.observeAnalysis(
          this.analysisId,
          1000,
          () => {
            this.reloadComputations(50);
          },
        );
      }
    }
  }

  public ngOnDestroy() {
    if (this.getComputationsSubscription && !this.getComputationsSubscription.closed) {
      this.getComputationsSubscription.unsubscribe();
    }
    if (this.analysisChangeSubscription) {
      this.backendPushService.unobserveAnalysis(this.analysisChangeSubscription);
      this.analysisChangeSubscription = undefined;
    }
  }

  public refreshComputationList(force: boolean) {
    if (force || !this.showLoadingComputations) {
      this.showLoadingComputations = force;
      this.reloadComputations(50);
    }
  }

  public trackByComputationId(index: number, item: ComputationEntry) {
    if (!item) {
      return null;
    }
    return item.id;
  }

  private reloadComputations(limit?: number) {
    if (this.getComputationsSubscription && !this.getComputationsSubscription.closed) {
      this.getComputationsSubscription.unsubscribe();
    }
    this.getComputationsSubscription = this.analysisService
      .getComputationsOfAnalysis(this.analysisId, limit)
      .pipe(finalize(() => (this.showLoadingComputations = false)))
      .subscribe((computations) => {
        this.computationList = computations;
        const expandComputationIds: number[] =
          this.analysisResultsService.expandedComputationIds.value;
        for (const computation of this.computationList) {
          if (computation.state === ComputationState.IN_PROGRESS) {
            computation.sort = 2;
            this.pendingOrInProgress.emit(true);
          } else if (
            computation.state === ComputationState.PENDING ||
            computation.state === ComputationState.IN_QUEUE
          ) {
            computation.sort = 1;
            this.pendingOrInProgress.emit(false);
          } else {
            computation.sort = 0;
            this.pendingOrInProgress.emit(false);
          }
          if (
            expandComputationIds.includes(computation.id) &&
            (computation.status_code === this.appConstantService.noResultComputationStatusCode ||
              computation.state === ComputationState.SUCCESS)
          ) {
            expandComputationIds.splice(expandComputationIds.indexOf(computation.id), 1);
            this.analysisResultsService.expandedComputationIds.next(expandComputationIds);
            if (computation.status_code === this.appConstantService.noResultComputationStatusCode) {
              if (this.analysis.isManualRetrosynthesis()) {
                this.analysis.num_active_computations = this.analysis.num_active_computations - 1;
                this.breadCrumbService.analysis.next(this.analysis);
              }
              this.infoService.showInfo(computation.status_message);
            }
          }
        }
        this.computationList.sort((a, b) => {
          return b.sort - a.sort;
        });
      });
  }
}
