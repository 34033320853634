import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  RelatedReactionListService,
  ErrorsHandlerService,
  ParsedConnectionError,
} from '../../../services';
import { Subject } from 'rxjs';
import { delay, retryWhen, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from '../..';

export interface RelatedReactionsEvent {
  reactions: any[];
  isError: boolean;
  reactionSmiles?: string;
}

@Component({
  selector: 'ch-related-reaction-list',
  styleUrls: ['./related-reaction-list.component.scss'],
  templateUrl: './related-reaction-list.component.html',
})
export class RelatedReactionListComponent implements OnChanges, OnDestroy {
  private static handleLocalErrors(parsedError: ParsedConnectionError): string {
    return parsedError.status === 400 &&
      parsedError.bodyJson &&
      parsedError.bodyJson.code &&
      parsedError.bodyJson.code === 'rxid-not-exist'
      ? 'Not enough data to perform similarity search for this reaction.'
      : '';
  }
  private unsubscriberSubject: Subject<void> = new Subject<void>();

  public loadingReactions: boolean = true;
  public errorMessage: string = '';
  public reactionList: any[] = [];
  public isReactionListAvailable: boolean = false;
  public isSideReactionsShown: boolean = false;

  @Input() public similarReactionList: { [smiles: string]: any[] } = {};
  @Input() public sideReactionList: any[] = [];
  @Input() public reactionType: 'side' | 'similar' = 'side';
  @Input() public reactionSmiles: string = '';
  @Input() public reactionId: string = '';
  @Input() public reactionRxid: number;
  @Input() public isMultiRxReactionSelected: boolean = false;

  @Output() public onSimilarReactionsResult = new EventEmitter<RelatedReactionsEvent>();
  @Output() public onSideReactionsResult = new EventEmitter<RelatedReactionsEvent>();

  constructor(
    public relatedReactionListService: RelatedReactionListService,
    private errorsHandler: ErrorsHandlerService,
  ) {}

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
  }

  public ngOnChanges(changes: SimpleChanges) {
    let observable;
    switch (this.reactionType) {
      case 'side':
        observable = this.relatedReactionListService
          .getSideReactions(this.reactionId, 50)
          .pipe(retryWhen((obs) => obs.pipe(takeUntil(this.unsubscriberSubject), delay(2000))));
        break;
      case 'similar':
        if (this.reactionRxid) {
          observable = this.relatedReactionListService.getSimilarReactions(
            this.reactionSmiles,
            50,
            this.reactionRxid,
          );
        } else {
          this.reactionList = [];
          this.loadingReactions = false;
          this.isReactionListAvailable = this.isReactionListPresent();
          this.isSideReactionsShown = this.isSideReactionsAllowed();
        }
        break;
      default:
        console.error(`Unexpected type of related reactions`);
    }
    if (!isNullOrUndefined(observable)) {
      observable.pipe(takeUntil(this.unsubscriberSubject));
    }

    if (changes.hasOwnProperty('reactionSmiles')) {
      if (observable) {
        this.loadingReactions = true;
        this.isReactionListAvailable = this.isReactionListPresent();
        this.isSideReactionsShown = this.isSideReactionsAllowed();

        if (!this.similarReactionList[this.reactionSmiles]) {
          observable.subscribe(
            (similarReactions) => {
              this.loadingReactions = false;
              this.reactionList = similarReactions;
              this.isReactionListAvailable = this.isReactionListPresent();
              this.isSideReactionsShown = this.isSideReactionsAllowed();

              this.emitReactionsResult(this.reactionType, similarReactions, false);
            },
            (error) => {
              this.reactionList = [];
              this.handleError(error);
            },
          );
        } else {
          this.loadingReactions = false;
          this.reactionList = this.similarReactionList[this.reactionSmiles];
          this.isReactionListAvailable = this.isReactionListPresent();
          this.isSideReactionsShown = this.isSideReactionsAllowed();
        }
      }
    }

    if (changes.hasOwnProperty('reactionId')) {
      if (observable) {
        this.loadingReactions = true;
        this.isReactionListAvailable = this.isReactionListPresent();
        this.isSideReactionsShown = this.isSideReactionsAllowed();

        if (!this.sideReactionList.length) {
          observable.subscribe(
            (sideReactions) => {
              this.loadingReactions = false;
              this.reactionList = sideReactions;
              this.isReactionListAvailable = this.isReactionListPresent();
              this.isSideReactionsShown = this.isSideReactionsAllowed();

              this.emitReactionsResult(this.reactionType, sideReactions, false);
            },
            (error) => {
              this.reactionList = [];
              this.handleError(error);
            },
          );
        } else {
          this.loadingReactions = false;
          this.reactionList = this.sideReactionList;
          this.isReactionListAvailable = this.isReactionListPresent();
          this.isSideReactionsShown = this.isSideReactionsAllowed();
        }
      }
    }
    if (changes.hasOwnProperty('isMultiRxReactionSelected')) {
      this.isReactionListAvailable = this.isReactionListPresent();
      this.isSideReactionsShown = this.isSideReactionsAllowed();
    }
  }

  public isReactionListPresent() {
    return this.reactionList.length > 0 && !this.loadingReactions && this.isSideReactionsAllowed();
  }

  public isSideReactionsAllowed() {
    return this.reactionType === 'side' ? !this.isMultiRxReactionSelected : true;
  }

  private handleError(error: any) {
    this.loadingReactions = false;
    this.isReactionListAvailable = this.isReactionListPresent();
    this.isSideReactionsShown = this.isSideReactionsAllowed();
    this.handleSimilarReactionServiceError(error);
    this.emitReactionsResult(this.reactionType, error, true);
  }

  private handleSimilarReactionServiceError(error: any) {
    const parsedError = new ParsedConnectionError(error);

    if (parsedError.isRecognized()) {
      const localErrorMessage = RelatedReactionListComponent.handleLocalErrors(parsedError);
      if (localErrorMessage) {
        this.errorMessage = localErrorMessage;
      } else {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
        this.errorMessage = error.message;
      }
    } else {
      this.errorMessage = error.message;
    }
  }

  private emitReactionsResult(reactionType: string, response, isError: boolean) {
    if (reactionType === 'similar') {
      this.onSimilarReactionsResult.emit({
        reactions: response,
        isError: isError,
        reactionSmiles: this.reactionSmiles,
      });
    } else if (reactionType === 'side') {
      this.onSideReactionsResult.emit({ reactions: response, isError: isError });
    }
  }
}
