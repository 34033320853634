import { Observable, Subscription, BehaviorSubject, timer } from 'rxjs';
import { delayWhen, retryWhen, scan } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ParsedConnectionError, ErrorsHandlerService } from './errors-handler';
import { AnalysisService, AnalysisStateEntry } from './analysis';
import { InfoService } from './info.service';
import { AnalysisResultsService } from './analysis-results/analysis-results.service';
import { AnalysisAlgorithm } from './app-constants';

/* tslint:disable:max-classes-per-file */
export class AnalysisStateChange {
  constructor(
    public change: 'computations_state' | 'computations_name' | 'graph' | 'progress_item',
  ) {}
}
/* tslint:disable:max-classes-per-file */

class AnalysisComputationsObserver {
  public detectedChanges: Observable<AnalysisStateChange>;
  private detectedChangesSource: BehaviorSubject<AnalysisStateChange> = new BehaviorSubject(null);
  private getGraphHashSubscription: Subscription;
  private refreshTimer: any;
  private analysisState: AnalysisStateEntry = new AnalysisStateEntry({
    graph_hash: null,
    computations_state_hash: null,
    computations_name_hash: null,
    progress_item: null,
  });
  private refCount: number = 0;

  constructor(
    public analysisId: number,
    private analysisService: AnalysisService,
    private refreshInterval: number,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
    private analysisResultsService: AnalysisResultsService,
  ) {
    this.detectedChanges = this.detectedChangesSource.asObservable();
    this.acquire();
  }

  public acquire() {
    this.refCount += 1;
    if (this.refCount === 1) {
      // restart
      this.refreshTimer = setTimeout(() => this.updateGraphHash(), 1);
    }
  }

  public unaquire() {
    if (this.refCount > 0) {
      this.refCount -= 1;
      if (this.refCount === 0) {
        this.abortPendingSubscriptions(); // shut down until next acquire
      }
    } else {
      console.error('AnalysisComputationsObserver:release() called for more times than acquire().');
    }
  }

  public abortPendingSubscriptions() {
    if (this.getGraphHashSubscription && !this.getGraphHashSubscription.closed) {
      this.getGraphHashSubscription.unsubscribe();
    }
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = undefined;
    }
  }

  private updateGraphHash() {
    this.getGraphHashSubscription = this.analysisService
      .getAnalysisState(this.analysisId)
      .pipe(
        // stop updating after 5 consecutive errors
        retryWhen((error) => {
          return error.pipe(
            delayWhen(() => timer(1500, 3000)),
            scan((count, thrownError) => {
              const parsedError = new ParsedConnectionError(thrownError);
              if (parsedError.shouldRedirect) {
                this.infoService.showInfo(parsedError.promptMessage, 1000 * 60 * 60);
                this.errorsHandler.logout();
                this.getGraphHashSubscription.unsubscribe();
              } else if (parsedError.status === 404) {
                this.infoService.showError(
                  `Analysis ${this.analysisId} does not exist.`,
                  1000 * 60 * 60,
                );
                this.errorsHandler.goToHomePage();
                this.getGraphHashSubscription.unsubscribe();
              } else if (count < 5) {
                return (count += 1);
              } else {
                throw thrownError;
              }
            }, 1),
          );
        }),
      )
      .subscribe(
        (analysisState) => {
          if (
            !this.analysisState.can_stop_analysis_state_polling ||
            this.analysisService.analysisSettingsBehaviorSubjects.algorithm.getValue() ===
              AnalysisAlgorithm.MANUAL_RETROSYNTHESIS
          ) {
            this.detectChanges(analysisState),
              (this.refreshTimer = setTimeout(() => this.updateGraphHash(), this.refreshInterval));
          } else {
            this.analysisResultsService.isComputationActive.next(false);
          }
        },
        () =>
          console.error(
            `Error occurred when attempting to update status of analysis ${this.analysisId}.`,
          ),
      );
  }

  private detectChanges(newAnalysisState: AnalysisStateEntry) {
    if (this.analysisState.graph_hash !== newAnalysisState.graph_hash) {
      this.detectedChangesSource.next(new AnalysisStateChange('graph'));
      this.analysisResultsService.isGraphHashChanged.next(true);
    }

    if (this.analysisState.computations_state_hash !== newAnalysisState.computations_state_hash) {
      this.detectedChangesSource.next(new AnalysisStateChange('computations_state'));
    } else if (
      this.analysisState.computations_name_hash !== newAnalysisState.computations_name_hash
    ) {
      this.detectedChangesSource.next(new AnalysisStateChange('computations_name'));
    }

    if (this.analysisState.progress_item !== newAnalysisState.progress_item) {
      this.analysisService.progressItemSummary.next(newAnalysisState.progress_item);
    }
    this.analysisService.analysisStateStop.next(newAnalysisState.can_stop_analysis_state_polling);

    this.analysisState = newAnalysisState;
  }
}

@Injectable()
export class BackendPushService {
  private observers: AnalysisComputationsObserver[] = [];
  private subscriptions: Array<{
    subscription: Subscription;
    analysisId: number;
  }> = [];

  constructor(
    public analysisService: AnalysisService,
    private errorsHandler: ErrorsHandlerService,
    private infoService: InfoService,
    private analysisResultsService: AnalysisResultsService,
  ) {}

  public observeAnalysis(
    analysisId: number,
    refreshInterval: number,
    callback: (value: AnalysisStateChange) => void,
  ): Subscription {
    let subscription: Subscription;
    const existingObserver = this.observers.find((item) => item.analysisId === analysisId);
    if (existingObserver) {
      existingObserver.acquire();
      subscription = existingObserver.detectedChanges.subscribe(callback);
    } else {
      const newObserver = new AnalysisComputationsObserver(
        analysisId,
        this.analysisService,
        refreshInterval,
        this.errorsHandler,
        this.infoService,
        this.analysisResultsService,
      );
      this.observers.push(newObserver);
      subscription = newObserver.detectedChanges.subscribe(callback);
    }
    this.subscriptions.push({ subscription, analysisId });
    return subscription;
  }

  public unobserveAnalysis(subscription: Subscription) {
    const knownSubscriptionIdx = this.subscriptions.findIndex(
      (item) => item.subscription === subscription,
    );
    if (knownSubscriptionIdx >= 0) {
      const knownSubscription = this.subscriptions[knownSubscriptionIdx];
      const existingObserverIdx = this.observers.findIndex(
        (item) => item.analysisId === knownSubscription.analysisId,
      );
      if (existingObserverIdx >= 0) {
        this.observers[existingObserverIdx].unaquire();

        // FIXME: Find a valid solution for a real push service working on backend.
        // It is rather a complicated issue and this comment only scratch the surface. The goal of creating
        // this service was to provide a proxy for a real backend push service based on some push technology
        // such as websockets). It acts by synchronizing the data with backend via polling, and then calculating
        // changes in state of analysis which is sent to registered clients. For this reason once initialized
        // a change observer is never removed from memory to keep the state of analysis and to correctly detect
        // any further updates even after a long time of being passive (refCount === 0).
        // Unfortunately components which uses the service assumes -- for lack of a better solution! -- that it is
        // "frontend-local" and "somehow knows" when the component is created, and which analysis updates happened
        // ONLY AFTER component registration. This becomes a problem when observer is reactivated after hibernation
        // and calculate diff with respect to the last known state, where a component initializes with some state
        // in between and do not expect so "deep" diffs:
        //
        // time 0:    observer.refCount becomes 0
        //            hibernation with analysis state 1 in memory (S1)
        // ...
        //
        // time 1222: component is created read analysis in S23
        //            it register itself to backend push, backend push wakes up for this analysis
        // ...
        // time 1250: backend push pollig tick, downloaded analysis state is S23 (or higher!)
        //            send a diff S23(or higher) - S1 to registered components
        //            !CONFLICT!
        //                Component expected no diff (when S23 - S23) or at most S23+ - S23.
        //                What the heck S1 is? What kind of diff it is? What to do with it?
        //
        // For an actual implementation and use pattern, we can delete observer instead of hibernate it. In effect
        // it will be recreated each time when a fist component in batch registers to observe a given analysis.
        // This way observer and components batch will start with the same analysis state. However this solution
        // makes backendPush "frontned-local" and the problem of implementation a real backend push service remains
        // open.
        if ((this.observers[existingObserverIdx] as any).refCount === 0) {
          this.observers.splice(existingObserverIdx, 1);
        }
      } else {
        console.error('Unknown observer in BackendPushService:unobserveAnalysis');
      }
      subscription.unsubscribe();
      this.subscriptions.splice(knownSubscriptionIdx, 1);
    } else {
      console.error('Unknown subscription in BackendPushService:unobserveAnalysis');
    }
  }
}
