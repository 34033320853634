import { of, throwError, Observable } from 'rxjs';
import { mapTo, map, share, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export class ImageCacheItem {
  // Those two are initialized by parent service
  public static http: HttpClient;
  public static sanitizer: DomSanitizer;

  private authObservable: Observable<SafeUrl>;
  private _imageBlob: Blob;
  private imageBlobUrl: string;
  private safeUrl: SafeUrl;
  private expirationTime: number;

  constructor(imageUrl: string, serviceCatch: any) {
    const headers: HttpHeaders = new HttpHeaders().append('Accept', 'image/*,*/*;q=0.8');

    this.authObservable = ImageCacheItem.http
      .get(imageUrl, {
        headers,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res: HttpResponse<any>) => {
          let blob: Blob;

          try {
            blob = res.body; // works if response _body is a Blob or ArrayBuffer
          } catch (e) {
            try {
              console.warn(
                `Tricky code path used in ImageCacheItem::ctor('${res.url}').\n` +
                  `RECEIVED Content-Type: '${res.headers.get('Content-Type')}'`,
              );

              const resWithBody = res as any as { _body: any };
              blob = new Blob([resWithBody._body], { type: res.headers.get('Content-Type') });
            } catch (e) {
              throw 'Unable to convert received data.'; /* tslint:disable-line:no-string-throw */
            }
          }

          this._imageBlob = blob;
          this.imageBlobUrl = window.URL.createObjectURL(blob);
          this.safeUrl = ImageCacheItem.sanitizer.bypassSecurityTrustUrl(this.imageBlobUrl);

          return this.safeUrl;
        }),

        // No need to start another http request for subsequent subscriptions when the first one is pending
        share(),

        // Insert an url to the thrown error to identify the failing item in the parent service
        catchError((err: any) => {
          return throwError({
            url: imageUrl,
            originalError: err,
          });
        }),
        catchError(serviceCatch),
      );

    this.expirationTime = Date.now();
  }

  public get image(): Observable<SafeUrl> {
    this.expirationTime = Date.now() + 1000 * 60 * 10;
    // Skip http observable if it's done
    return this.safeUrl ? of(this.safeUrl) : this.authObservable;
  }

  public get imageBlob(): Observable<any> {
    this.expirationTime = Date.now() + 1000 * 60 * 10;
    // Skip http observable if it's done
    return this.safeUrl ? of(this._imageBlob) : this.authObservable.pipe(mapTo(this._imageBlob));
  }

  public expired(now: number): boolean {
    return this.expirationTime < now;
  }

  public release() {
    this.safeUrl = undefined;
    if (this.imageBlobUrl) {
      window.URL.revokeObjectURL(this.imageBlobUrl);
      this.imageBlobUrl = undefined;
    }
    this._imageBlob = undefined;
  }
}
