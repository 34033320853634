import { AppConstantsService } from 'src/app/shared/services/app-constants/app-constants.service';
import { Observable, BehaviorSubject, throwError, Subject, Subscription, interval } from 'rxjs';
import { share, map, catchError, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { ParsedConnectionError } from '../shared/services/errors-handler/errors-handler.service';
import { Location } from '@angular/common';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { InfoService } from '../shared/services/info.service';
import { UserEntry, UserSubscriptionType } from './models/user-entry';
import { isNullOrUndefined } from '../shared/components/utils';
import { SingleSignOnService } from './single-sign-on.service';
import { AppState } from '../app-state.service';
import { EmdToken } from './models/emd-token';
import { TermsAndConditions } from '../shared/services/user-registration/models';

/* tslint:disable:variable-name */
export const backendEntryPoint_SignIn = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/jwt/';
export const backendEntryPoint_Logout = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/logout/';
export const backendEntryPoint_TokenVerification =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/token-verification/';
export const backendEntryPoint_User = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/users/${id}/';
export const backendEntryPoint_PasswordChange =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/password-change/';
export const backendEntryPoint_TokenRefresh =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/token-refresh/';
export const backendEntryPoint_Announcements =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/announcements/';
export const backendEntryPoint_SSO = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/sso/';
export const backendEntryPoint_COMPLIANCE_DATA =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/compliance-data/';
export const backendEntryPoint_UpdateVerificationMethod =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/verification-method/';
export const backendEntryPoint_ValidateUser =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/emd-verification/';
export const backendEntryPoint_CheckUserSSOEnabled =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/auth/tenant/';
export const backendEntryPointTermsAndConditionsWithoutPlaceHolder =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/terms-and-conditions-without-placeholder/';
/* tslint:enable:variable-name */

export const AuthTokenStorageName = 'jwt';
export const RefreshTokenStorageName = 'refresh';
export const jwtHelperService: JwtHelperService = new JwtHelperService();

export enum ConfirmationCodeMethod {
  TEXT = 'text',
  EMAIL = 'email',
}

export enum EmdAccessType {
  SignIn = 'SignIn',
  Register = 'Register',
  ResetPassword = 'ResetPassword',
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  public enableCustomerPolicyAcceptance = false;
  public sessionTimeLeftSubject: Subject<number> = new Subject<number>();
  public authorized: BehaviorSubject<boolean>;
  public userInformation: BehaviorSubject<UserEntry> = new BehaviorSubject<UserEntry>(
    new UserEntry(),
  );
  public emdToken: BehaviorSubject<EmdToken> = new BehaviorSubject<EmdToken>(new EmdToken());
  public isComplianceStatementAccepted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public mustChangePasswordCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public isAnalysisApiResponseReceived: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public isUserApiCalled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mustChangePassword: BehaviorSubject<boolean>;
  private tokenExpirationDate: Date;
  private tokenWatchSubscription: Subscription;
  private unsubscribeFromAll: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private infoService: InfoService,
    private http: HttpClient,
    private singleSignOnService: SingleSignOnService,
    private appStateService: AppState,
    private appConstantsService: AppConstantsService,
  ) {
    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((config) => {
        if (config) {
          this.enableCustomerPolicyAcceptance = !!config.ENABLE_CUSTOMER_POLICY_ACCEPTANCE;
        }
      });

    window.addEventListener(
      'storage',
      (event) => {
        if (event.storageArea === localStorage) {
          const jwtToken = sessionStorage.getItem(AuthTokenStorageName);

          if (event.key === AuthTokenStorageName) {
            if (!this.authorized.getValue()) {
              this.authorized.next(this.isTokenValid(event.newValue));
            }
            this.startTokenWatching(event.newValue);
          }

          if (event.key === 'JWT_FLUSH' && jwtToken) {
            this.redirectSessionExpiry();
          }

          if (event.key === 'mustChangePassword') {
            const firstLoginPasswordChange = JSON.parse(localStorage.getItem('mustChangePassword'));

            if (firstLoginPasswordChange) {
              this.router.navigate(['/sign-in']);
              this.dialog.closeAll();
            }
          }
        }
      },
      false,
    );

    const existingToken = sessionStorage.getItem(AuthTokenStorageName);
    this.authorized = new BehaviorSubject<boolean>(this.isTokenValid(existingToken));
    this.startTokenWatching(existingToken);

    const localStorageMustChangePassword = localStorage.getItem('mustChangePassword');
    // in case local storage value is something different than 'false' or 'true' (strings)
    try {
      this.mustChangePassword = new BehaviorSubject<boolean>(
        JSON.parse(localStorageMustChangePassword),
      );
    } catch (error) {
      console.error(error);
      this.mustChangePassword = new BehaviorSubject<boolean>(false);
    }
  }

  public startTokenWatching(token: string) {
    if (this.tokenWatchSubscription) {
      this.tokenWatchSubscription.unsubscribe();
    }
    this.tokenWatchSubscription = interval(1000).subscribe(() => this.checkTokenExpiration());
    this.updateExpirationDate(token);
  }

  public beginAuthorization(username: string, password: string): Observable<any> {
    return this.http
      .post(backendEntryPoint_SignIn, JSON.stringify({ username, password }), {
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<any>) => this.signInCallSuccess(res)),
        share(),
      );
  }

  public updateVerificationMethod(userId: string, verificationMethod: string): Observable<any> {
    return this.http
      .post(
        backendEntryPoint_UpdateVerificationMethod,
        JSON.stringify({ user_id: parseInt(userId, 10), verification_method: verificationMethod }),
        {
          observe: 'response',
        },
      )
      .pipe(
        map((res: HttpResponse<any>) => this.signInCallSuccess(res)),
        share(),
      );
  }

  public confirmAuthorization(
    userId: string,
    token: string,
    confirmationCode: string,
  ): Observable<any> {
    return this.http
      .post(
        backendEntryPoint_TokenVerification,
        JSON.stringify({
          user_id: parseInt(userId, 10),
          verification_token: token,
          verification_code: parseInt(confirmationCode, 10),
        }),
        { observe: 'response' },
      )
      .pipe(
        map((res: HttpResponse<any>) => this.signInCallSuccess(res)),
        share(),
      );
  }

  public ssoLogout() {
    this.singleSignOnService
      .firebaseIsAuthorized()
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((user) => {
        if (user) {
          this.singleSignOnService.firebaseLogout();
        }
      });
  }

  public logout() {
    this.invalidateToken();

    if (APP_CONFIG.SSO_MODE) {
      this.ssoLogout();
    }
  }

  public invalidateToken() {
    this.http.get(backendEntryPoint_Logout, { observe: 'response' }).subscribe((response) => {
      if (response.status === 204) {
        this.removeTokenFromFrontend();
        this.redirectToSignIn();
      }
    });
  }

  public removeTokenFromFrontend() {
    sessionStorage.removeItem(AuthTokenStorageName);
    sessionStorage.removeItem(RefreshTokenStorageName);
    sessionStorage.removeItem(this.appConstantsService.hideTrainingMaterialInUserSession);
    sessionStorage.removeItem(this.appConstantsService.hideWarningDialogUserSession);
    localStorage.removeItem('mustChangePassword');
    localStorage.setItem('JWT_FLUSH', Date.now().toString());
    localStorage.removeItem('JWT_FLUSH');
    localStorage.removeItem('masked_phone');
    localStorage.removeItem('masked_email');
    this.updateExpirationDate(null);
    this.userInformation.next(new UserEntry());
    this.authorized.next(false);
    this.mustChangePassword.next(false);
    this.dialog.closeAll();
    this.tokenWatchSubscription.unsubscribe();
  }

  public isAuthorized(): Observable<boolean> {
    return this.authorized.asObservable();
  }

  public userMustChangePassword(): Observable<boolean> {
    return this.mustChangePassword.asObservable();
  }

  public getCurrentUserUrl(): string {
    let userId: number;

    try {
      userId = this.getUserId();
    } catch (e) {
      console.log('Exception when accessing current user id: ' + e);
      return null;
    }
    return backendEntryPoint_User.replace(/\$\{id\}/, userId.toString());
  }

  public getUserId(): number {
    return jwtHelperService.decodeToken(sessionStorage.getItem(AuthTokenStorageName)).user_id;
  }

  public getUserInformation() {
    const userUrl = this.getCurrentUserUrl();
    this.isUserApiCalled.next(true);
    if (this.userInformation.value['email'].length !== 0) {
      return this.userInformation;
    }
    return this.http.get(userUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.resolveUserEntry(response)),
      catchError(this.getUserInformationError.bind(this)),
    );
  }

  public updateRemainingAnalysisCount() {
    this.userInformation.next(new UserEntry());
    this.getUserInformation().pipe(takeUntil(this.unsubscribeFromAll)).subscribe();
  }

  public changePassword(oldPass: string, newPass: string) {
    const userUrl = this.getCurrentUserUrl();
    return this.http
      .patch(userUrl, JSON.stringify({ old_password: oldPass, password: newPass }), {
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<any>) => this.resolvePasswordChangeResponse(res)),
        catchError(this.getPasswordChangeError.bind(this)),
      );
  }

  public changeSettings(settings: any) {
    const userUrl = this.getCurrentUserUrl();
    return this.http.patch(userUrl, settings, { observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => this.resolveUserEntry(res)),
      catchError(this.changeSettingsError.bind(this)),
    );
  }

  public resolvePasswordChangeResponse(res: HttpResponse<any>) {
    const resBody = res.body;
    if (res.status === 200) {
      localStorage.setItem('mustChangePassword', 'false');
      if (this.mustChangePassword.value) {
        this.mustChangePasswordCompleted.next(true);
      }
      this.mustChangePassword.next(false);
      this.infoService.showInfo('Password changed');
    }
    return resBody;
  }

  public changeSettingsError(error: ParsedConnectionError) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.isRecognized() && parsedError.isGlobal()) {
      return throwError(parsedError);
    } else {
      return throwError('Failed to update user account settings. ' + error);
    }
  }

  public getPasswordChangeError(error: ParsedConnectionError) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.isRecognized() && parsedError.isGlobal()) {
      return throwError(parsedError);
    } else {
      return throwError('Failed to change password. ' + error);
    }
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // FIXME: There is a good place to small refactoring (repeated calls)
    if (!this.authorized.getValue() && !this.mustChangePassword.getValue()) {
      this.dialog.closeAll();
      this.logout();
      this.redirectToSignIn(state);
    } else if (
      !sessionStorage.getItem(AuthTokenStorageName) &&
      !this.mustChangePassword.getValue()
    ) {
      this.logoutAndRedirectToSignIn();
    } else if (this.mustChangePassword.getValue()) {
      this.dialog.closeAll();
      this.redirectToSignIn(state);
    }
    return this.isAuthorized();
  }

  public refreshJwtToken(): Observable<any> {
    return this.http
      .post(
        backendEntryPoint_TokenRefresh,
        JSON.stringify({ refresh: sessionStorage.getItem(RefreshTokenStorageName) }),
        { observe: 'response' },
      )
      .pipe(
        map((response: HttpResponse<any>) => this.resolveJwtTokenRefreshResponse(response)),
        catchError(this.jwtTokenRefreshError.bind(this)),
      );
  }

  public logoutAndRedirectToSignIn() {
    this.logout();
    this.redirectToSignIn();
  }

  public getAnnouncements() {
    return this.http.get(backendEntryPoint_Announcements, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return response.body;
        }
        return { message: '' };
      }),
    );
  }

  public beginSSOAuthorization(token: string): Observable<any> {
    return this.http
      .post(backendEntryPoint_SSO, JSON.stringify({ token }), { observe: 'response' })
      .pipe(
        map((res: HttpResponse<any>) => this.signInCallSuccess(res)),
        share(),
      );
  }

  public getComplianceData() {
    return this.http.get(backendEntryPoint_COMPLIANCE_DATA, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return response.body;
        }
        return { message: '' };
      }),
    );
  }

  public acceptComplianceStatement() {
    return this.http
      .post(
        backendEntryPoint_COMPLIANCE_DATA,
        JSON.stringify({ is_compliance_data_accepted: true }),
        { observe: 'response' },
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200 || response.status === 201) {
            return response.body;
          }
          return { message: '' };
        }),
      );
  }

  public navigateToCustomerPolicy(nextUrl?: string) {
    this.navigateToComplianceStatement(nextUrl);
  }

  public navigateToComplianceStatement(nextUrl?: string) {
    if (nextUrl) {
      this.router.navigate(['/home', 'compliance-statement'], {
        replaceUrl: true,
      });
    } else {
      this.router.navigate(['/home', 'compliance-statement'], { replaceUrl: true });
    }
  }

  public redirectToEmdAccess(accessType: EmdAccessType = EmdAccessType.SignIn) {
    let redirectUrl = this.getEmdAccessUrl(accessType);

    const toCharCodes = (arr: Uint8Array) => {
      const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      return arr.map((x) => validChars.charCodeAt(x % validChars.length));
    };
    const randomStr = (len: number) => {
      const arr = new Uint8Array(len);
      window.crypto.getRandomValues(arr);
      return String.fromCharCode(...toCharCodes(arr));
    };
    const sha256 = (message: string) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(message);
      return window.crypto.subtle.digest('SHA-256', data);
    };
    const urlEncodeBase64 = (input: string) => {
      const chars = { '+': '-', '/': '_', '=': '' };
      return input.replace(/[\+\/=]/g, (m) => chars[m]);
    };
    const bufferToBase64UrlEncoded = (input: ArrayBuffer) => {
      const bytes = new Uint8Array(input);
      return urlEncodeBase64(window.btoa(String.fromCharCode(...bytes)));
    };
    (async () => {
      const newKey = randomStr(16);
      const codeVerifier = urlEncodeBase64(newKey);
      localStorage.setItem('code_verifier', codeVerifier);
      const shaBuffer = await sha256(codeVerifier);
      const challenge = bufferToBase64UrlEncoded(shaBuffer);
      redirectUrl = redirectUrl.replace('{CLIENT_ID}', APP_CONFIG.EMD_DIGITAL_CLIENT_ID);
      redirectUrl = redirectUrl.replace('{REDIRECT_URL}', APP_CONFIG.CHEMATICA_API_URL + '/');
      window.location.href = redirectUrl.replace('{CHALLENGE}', challenge);
    })();
  }

  public getEmdToken(authorizationCode: string): Observable<any> {
    const tokenEndPoint: string = 'https://login.emddigital.com/oauth2/token';
    const redirectURL: string = APP_CONFIG.CHEMATICA_API_URL + '/';
    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', APP_CONFIG.EMD_DIGITAL_CLIENT_ID);
    body.set('code_verifier', localStorage.getItem('code_verifier'));
    body.set('code', authorizationCode);
    body.set('redirect_uri', redirectURL);
    return this.http
      .post(tokenEndPoint, body.toString(), {
        observe: 'response',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .pipe(
        map((response: HttpResponse<any>) => this.resolveEmdTokenResponse(response)),
        catchError(this.jwtTokenRefreshError.bind(this)),
      );
  }

  public validateUser() {
    const emdToken: EmdToken = this.emdToken.value;
    return this.http
      .post(
        backendEntryPoint_ValidateUser,
        JSON.stringify({
          access_token: emdToken.access_token,
          refresh_token: emdToken.refresh_token,
          expires_in: emdToken.expires_in,
        }),
        { observe: 'response' },
      )
      .pipe(map((response: HttpResponse<any>) => this.signInCallSuccess(response)));
  }

  public isCustomerPolicyAccepted(userInfo: UserEntry) {
    return (
      !this.enableCustomerPolicyAcceptance ||
      (!APP_CONFIG.SYNTHIA_LITE_ENABLED && userInfo.is_compliance_data_accepted) ||
      APP_CONFIG.SYNTHIA_LITE_ENABLED
    );
  }

  public isPlanSubscribed(userInfo: UserEntry) {
    return (
      !APP_CONFIG.SYNTHIA_LITE_ENABLED ||
      (APP_CONFIG.SYNTHIA_LITE_ENABLED && userInfo.subscription_type !== UserSubscriptionType.NULL)
    );
  }

  public navigateToSubscriptionPlans() {
    this.router.navigate(['/subscription-plans'], { replaceUrl: true });
  }

  public checkUserSSOEnabled(email: string) {
    const queryParams: HttpParams = new HttpParams().set('email', String(email));

    return this.http
      .get(backendEntryPoint_CheckUserSSOEnabled, { params: queryParams, observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return response.body;
          }
          return {};
        }),
      );
  }

  public getTermsAndConditions() {
    return this.http
      .get(backendEntryPointTermsAndConditionsWithoutPlaceHolder, { observe: 'response' })
      .pipe(
        map((response: any) => {
          const jsonResult = response.body;
          return new TermsAndConditions(jsonResult);
        }),
      );
  }

  public isComingFromChemDraw() {
    return localStorage.getItem('is_coming_from_chemdraw') === 'true';
  }

  public isNewRegistration() {
    return localStorage.getItem('is_new_registration') === 'true';
  }

  public openChemDraw() {
    this.getUserInformation()
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((user) => {
        if (!this.isPlanSubscribed(user)) {
          if (user.subscription_type === UserSubscriptionType.NULL) {
            localStorage.setItem('is_new_registration', 'true');
          }
          this.navigateToSubscriptionPlans();
        } else {
          this.router.navigateByUrl('/user-authorized');
          const jwtToken: string = sessionStorage.getItem(AuthTokenStorageName);
          const stateKey: string = localStorage.getItem('chemdraw_callback_key');
          let redirectUrl = this.appConstantsService.replaceVariableFromMessage(
            this.appConstantsService.chemdrawRedirectUrl,
            '{ACCESS_TOKEN}',
            jwtToken,
          );
          redirectUrl = this.appConstantsService.replaceVariableFromMessage(
            redirectUrl,
            '{CALLBACK_KEY}',
            stateKey,
          );
          window.location.href = redirectUrl;
          localStorage.removeItem('chemdraw_callback_key');
        }
      });
  }

  private getEmdAccessUrl(accessType: EmdAccessType) {
    let accessUrl: string = this.appConstantsService.emdAccessUrl;
    let accessTypeUrlPart: string = '';
    switch (accessType) {
      case EmdAccessType.Register:
        accessTypeUrlPart = this.appConstantsService.emdRegisterUrlPart;
        break;
      case EmdAccessType.ResetPassword:
        accessTypeUrlPart = this.appConstantsService.emdResetPasswordUrlPart;
        break;
      default:
        accessTypeUrlPart = this.appConstantsService.emdSignInUrlPart;
        break;
    }
    accessUrl = this.appConstantsService.replaceVariableFromMessage(
      accessUrl,
      '<accessType>',
      accessTypeUrlPart,
    );
    return accessUrl;
  }

  private redirectToSignIn(state?: RouterStateSnapshot) {
    if (state) {
      this.router.navigate(['/sign-in', { next: state.url }], { replaceUrl: true });
    } else {
      this.router.navigate(['/sign-in'], { replaceUrl: true });
    }
  }

  private getUserInformationError(error: ParsedConnectionError) {
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.isRecognized() && parsedError.isGlobal()) {
      return throwError(parsedError);
    } else {
      return throwError(
        `
        Cannot load user data.
        Some account settings and permissions may be unavailable.
        Please try to reload application.
      ` + error,
      );
    }
  }

  private jwtTokenRefreshError(refreshError) {
    const parsedError = new ParsedConnectionError(refreshError);
    return throwError(parsedError);
  }

  private resolveJwtTokenRefreshResponse(response: HttpResponse<any>) {
    try {
      const resBody = response.body;
      localStorage.setItem('JWT_UPDATE', resBody.token);
      localStorage.removeItem('JWT_UPDATE');
      sessionStorage.setItem(AuthTokenStorageName, resBody.token);
      sessionStorage.setItem(RefreshTokenStorageName, resBody.refresh);
      this.checkTokenExpiration();
      return resBody;
    } catch (error) {
      console.log(`Unexpected format of response from ${backendEntryPoint_TokenRefresh}.`);
    }
  }

  private signInCallSuccess(res: HttpResponse<any>) {
    const authorizationStatus = {
      authorized: false,
      multifactor: false,
      showMultifactorMethods: false,
      username: null,
    };
    const resBody = res.body;
    if (res.status !== 201 && res.status !== 204 && res.status !== 200) {
      throw new Error(`Unable to finish authorization. Unexpected backend response ${res.status}.`);
    } else if (res.status === 201) {
      authorizationStatus.multifactor = true;
      authorizationStatus.showMultifactorMethods = false;
      localStorage.setItem('user_id', resBody.user_id);
      localStorage.setItem('verification_token', resBody.verification_token);
    } else if (res.status === 200) {
      if (!isNullOrUndefined(resBody.verification_methods)) {
        authorizationStatus.multifactor = true;
        authorizationStatus.showMultifactorMethods = true;
        localStorage.setItem('masked_phone', resBody.phone_number);
        localStorage.setItem('masked_email', resBody.email);
        localStorage.setItem('user_id', resBody.user_id);
      } else {
        sessionStorage.setItem(AuthTokenStorageName, resBody.token);
        sessionStorage.setItem(RefreshTokenStorageName, resBody.refresh);
        localStorage.setItem('JWT_UPDATE', resBody.token);
        localStorage.removeItem('JWT_UPDATE');
        localStorage.setItem(
          'mustChangePassword',
          !isNullOrUndefined(resBody.must_change_password) ? resBody.must_change_password : false,
        );
        this.updateExpirationDate(resBody.token);
        this.authorized.next(true);
        this.startTokenWatching(resBody.token);
        this.mustChangePassword.next(resBody.must_change_password);
        authorizationStatus.authorized = true;
        authorizationStatus.username = jwtHelperService.decodeToken(resBody.token).username;
      }
    }

    return authorizationStatus;
  }

  private updateExpirationDate(token: string) {
    if (token) {
      this.tokenExpirationDate = jwtHelperService.getTokenExpirationDate(token);
    } else {
      this.tokenExpirationDate = null;
    }
  }

  private checkTokenExpiration() {
    this.updateExpirationDate(sessionStorage.getItem(AuthTokenStorageName));
    if (this.tokenExpirationDate) {
      const timeLeft = this.tokenExpirationDate.getTime() - Date.now();
      if (timeLeft <= 0) {
        if (this.authorized.getValue()) {
          this.infoService.showInfo('Session expired', 1000 * 60 * 60);
          this.dialog.closeAll();
          this.redirectSessionExpiry();
        }
      } else {
        this.sessionTimeLeftSubject.next(timeLeft);
      }
    }
  }

  private isTokenValid(token: string): boolean {
    return !!token && !jwtHelperService.isTokenExpired(token);
  }

  private resolveUserEntry(response: HttpResponse<any>): UserEntry {
    try {
      const userEntry: UserEntry = new UserEntry(response.body);
      this.userInformation.next(userEntry);
      return userEntry;
    } catch (e) {
      throw new Error('Unexpected format of response');
    }
  }

  private resolveEmdTokenResponse(response: HttpResponse<any>): EmdToken {
    try {
      const emdToken: EmdToken = new EmdToken(response.body);
      this.emdToken.next(emdToken);
      return emdToken;
    } catch (e) {
      throw new Error('Unexpected format of response');
    }
  }

  private redirectSessionExpiry() {
    this.router.navigate(['sign-out']).then(() => {
      this.logout();
      this.infoService.showInfo('Session expired', 1000 * 60 * 60);
    });
  }
}
