import { BehaviorSubject } from 'rxjs';
import { ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { Injectable } from '@angular/core';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { inRange } from 'lodash';
import { isNullOrUndefined } from '../components/utils';

@Injectable()
export class FolderDragService {
  public dragging: BehaviorSubject<boolean>;
  public dropped: BehaviorSubject<boolean>;
  public previousId;
  public listElementPositions;
  public customList: BehaviorSubject<ElementRef>;
  public hoveredItem: BehaviorSubject<any>;
  public intTopHandler: number = null;
  public intBottomHandler: number = null;
  public scrollThreshold: number = 40;
  public scrollInterval: number = 50;
  public scrollStep: number = 10;
  public origin: string = '';
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.dragging = new BehaviorSubject<boolean>(false);
    this.dropped = new BehaviorSubject<boolean>(true);
    this.hoveredItem = new BehaviorSubject<any>(null);
    this.customList = new BehaviorSubject<ElementRef>(null);
  }

  public dragStarted(item: any) {
    if (this.origin === 'Home') {
      this.previousId = item.folder;
    }
    this.dragging.next(true);
    this.dropped.next(false);
    this.listElementPositions = this.customList.value.nativeElement.getBoundingClientRect();
    this.removeHoveredFolder();
  }

  public dragReleased() {
    this.dropped.next(true);
    this.clearIntervals();
  }

  public dragMoved(event: CdkDragMove) {
    let isMoving = false;
    // Moving top
    if (
      inRange(
        this.listElementPositions.top - event.pointerPosition.y,
        -this.scrollThreshold,
        this.scrollThreshold,
      ) &&
      this.isCursorPositionOutsideFoldersList(event.pointerPosition.x)
    ) {
      isMoving = true;
      if (isNullOrUndefined(this.intTopHandler)) {
        this.intTopHandler = window.setInterval(() => {
          this.renderer.setProperty(
            this.customList.value.nativeElement,
            'scrollTop',
            this.customList.value.nativeElement.scrollTop - this.scrollStep,
          );
        }, this.scrollInterval);
      }
    }

    // Moving bottom
    if (
      inRange(
        event.pointerPosition.y - this.listElementPositions.bottom,
        -this.scrollThreshold,
        this.scrollThreshold,
      ) &&
      this.isCursorPositionOutsideFoldersList(event.pointerPosition.x)
    ) {
      isMoving = true;
      if (isNullOrUndefined(this.intBottomHandler)) {
        this.intBottomHandler = window.setInterval(() => {
          this.renderer.setProperty(
            this.customList.value.nativeElement,
            'scrollTop',
            this.customList.value.nativeElement.scrollTop + this.scrollStep,
          );
        }, this.scrollInterval);
      }
    }

    if (!isMoving) {
      this.clearIntervals();
    }
  }

  public clearIntervals() {
    clearInterval(this.intTopHandler);
    clearInterval(this.intBottomHandler);
    this.intBottomHandler = null;
    this.intTopHandler = null;
  }

  public removeHoveredFolder() {
    if (!this.dropped) {
      this.hoveredItem = null;
    }
  }

  private isCursorPositionOutsideFoldersList(positionX: number) {
    return (
      positionX < this.listElementPositions.width &&
      positionX > this.customList.value.nativeElement.offsetLeft
    );
  }
}
