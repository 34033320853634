import { DecimalPipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IFilterCriteria, ISliderRange } from '../../components/results-filter';
import {
  IFilterImagePopupData,
  IReactionData,
  IReactionFilter,
  StructureFilters,
  StructureSectionCategory,
  SVGSmilesSimilarity,
  KeywordsFilter,
} from '../../components/results-filter/results-filter-default/results-default-filter.models';
import { Graph, GraphMoleculeNode, GraphNodeType, GraphReactionNode } from '../graph-builder';
import { RdKitBridgeService } from '../rdkit/rdkit-bridge.service';
import { ResultsFilterModels } from './../../components/results-filter/results-filter-base/results-filter-models';
import { isMoleculeNode, isReactionNode } from 'src/app/shared/components/graph-utils';
import { GraphReactionNodeEntry, GraphSyntheticPathEntry } from '../analysis/models';
import { GraphResources } from './../analysis/models/graph-resources';
import * as AnalysisResultsFilterHelper from './analysis-results-filter.helper';
import { MatGridTile } from '@angular/material/grid-list';
import { FilterData } from '../../components/new-graph-node-menu/new-graph-node-menu.component';
import { LibraryTargetsEntry } from '../analysis/models/library-targets';

export enum SidePanelViewType {
  None,
  Legend,
  Filter,
  Settings,
  Clipboard,
  Computations,
  ReactionReport,
  MoleculeList,
  Parameters,
}

export enum SortOptions {
  PATH_SCORE = 'Path rank',
  RANK = 'Rank',
  STEPS = 'Number of steps',
  PROTECTION_STEPS = 'Number of protection steps',
  SIMILARITY = 'Similarity to published reactions',
  SIMILARITY_TO_TARGET = 'Similarity to original target',
  TARGET_WEIGHT = 'Molecular weight',
}

export interface ISortParametersNew {
  score: {
    apply: boolean;
    ascending: boolean;
  };
  numberOfReactions: {
    apply: boolean;
    ascending: boolean;
  };
  numberOfProtectionSteps: {
    apply: boolean;
    ascending: boolean;
  };
  similarity: {
    apply: boolean;
    ascending: boolean;
  };
}

export interface ISortDiversityParameters {
  score: {
    apply: boolean;
    ascending: boolean;
  };
  similarityToTarget: {
    apply: boolean;
    ascending: boolean;
  };
  targetWeight: {
    apply: boolean;
    ascending: boolean;
  };
}

export interface ComputationEntryStatus {
  pending: number;
  in_queue: number;
  in_progress: number;
  success: number;
  error: number;
  interrupted_by_user: number;
  stopped: number;
  failure: number;
  started: number;
  aborted: number;
}

export interface compoundsToModify {
  compound_smiles: string;
  reaction_smiles: string;
}

export interface ReactionsToModify {
  [reactionName: string]: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AnalysisResultsService {
  public selectedMenu: BehaviorSubject<number>;
  public isMenuOpen: BehaviorSubject<boolean>;
  public resultsFilterSubject: BehaviorSubject<ResultsFilterModels>;
  public resetFilterSubject: BehaviorSubject<boolean>;
  public addStructureFilterFromPopup: BehaviorSubject<IFilterCriteria>;
  public addDiversityFilterFromPopup: BehaviorSubject<IFilterCriteria>;
  public filteredPathList: BehaviorSubject<any>;
  public diversityResultMolecules: BehaviorSubject<GraphSyntheticPathEntry[]>;
  public pathListSubject: BehaviorSubject<any>;
  public addReactionFilterFromPopup: BehaviorSubject<IReactionFilter>;
  public filterImagePopupDetails: BehaviorSubject<IFilterImagePopupData>;
  public resultsLoadingIndicator: BehaviorSubject<boolean>;
  public isNewGraphAvailable: BehaviorSubject<boolean>;
  public isNewResultAvailable: BehaviorSubject<boolean>;
  public loadingAnalysis: BehaviorSubject<boolean>;
  public processingOfNewResults: BehaviorSubject<boolean>;
  public loadNewGraphResults: BehaviorSubject<boolean>;
  public didSelectPath: BehaviorSubject<boolean>;
  public isReactionReportFromMolecule: BehaviorSubject<boolean>;
  public hasComputationError: BehaviorSubject<boolean>;
  public showWarningMessage: BehaviorSubject<boolean>;
  public hasWarnings: BehaviorSubject<boolean>;
  public expandedComputationIds: BehaviorSubject<number[]>;
  public downloadPathAsPDF: Subject<any>;
  public showComputationError: BehaviorSubject<boolean>;
  public isAllPathwaysLoaded: BehaviorSubject<boolean>;
  public isComputationActive: BehaviorSubject<boolean>;
  public consolidatedFilteredPathList: BehaviorSubject<any[]>;
  public targetPathDiversity: BehaviorSubject<GraphSyntheticPathEntry>;
  public showLoader: BehaviorSubject<boolean>;
  public isGraphHashChanged: BehaviorSubject<boolean>;
  public reloadMoleculeReport: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showGraphUpdates: BehaviorSubject<boolean>;
  public showHeaderLabels: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showPathsCount: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isFilterApplied: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filteredResultsLength: BehaviorSubject<number>;
  public isFirstChunkLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedSortOption: BehaviorSubject<string> = new BehaviorSubject<string>(
    SortOptions.PATH_SCORE,
  );
  public isAscending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isAutomaticRetrosynthesisAnalysis: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public stopButtonMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public stoppingComputation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public libraryTargets: BehaviorSubject<LibraryTargetsEntry> =
    new BehaviorSubject<LibraryTargetsEntry>(null);
  public isLibraryResultsView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filteredLibrarySets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public resetDiverisifyConfiguration: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  public diversityTarget: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public diversityTargetExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedDiversityTargets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public selectedDiversityTargetsHtml: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public selectedDiversityFilterData: BehaviorSubject<FilterData> = new BehaviorSubject<FilterData>(
    null,
  );
  public selectionFlag: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public eventEmitter: EventEmitter<{ pathId: number[]; flag: string }> = new EventEmitter();
  public eventEmitterGroupAddToShop: EventEmitter<''> = new EventEmitter();
  public setAnnotateReactionRules: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private rdKitService: RdKitBridgeService,
    private decimal: DecimalPipe,
  ) {
    this.initBehaviorSubjects();
  }

  public initBehaviorSubjects() {
    this.selectedMenu = new BehaviorSubject<number>(SidePanelViewType.None);
    this.isMenuOpen = new BehaviorSubject<boolean>(false);
    this.resultsFilterSubject = new BehaviorSubject(null);
    this.resetFilterSubject = new BehaviorSubject(false);
    this.addStructureFilterFromPopup = new BehaviorSubject(null);
    this.addDiversityFilterFromPopup = new BehaviorSubject(null);
    this.addReactionFilterFromPopup = new BehaviorSubject(null);
    this.filterImagePopupDetails = new BehaviorSubject(null);
    this.resultsLoadingIndicator = new BehaviorSubject<boolean>(false);
    this.filteredPathList = new BehaviorSubject<any>([]);
    this.diversityResultMolecules = new BehaviorSubject<GraphSyntheticPathEntry[]>([]);
    this.pathListSubject = new BehaviorSubject<any>([]);
    this.isNewGraphAvailable = new BehaviorSubject<boolean>(false);
    this.isNewResultAvailable = new BehaviorSubject<boolean>(false);
    this.loadingAnalysis = new BehaviorSubject<boolean>(true);
    this.processingOfNewResults = new BehaviorSubject<boolean>(false);
    this.loadNewGraphResults = new BehaviorSubject<boolean>(false);
    this.didSelectPath = new BehaviorSubject<boolean>(false);
    this.isReactionReportFromMolecule = new BehaviorSubject<boolean>(false);
    this.hasComputationError = new BehaviorSubject<boolean>(false);
    this.showComputationError = new BehaviorSubject<boolean>(false);
    this.showWarningMessage = new BehaviorSubject<boolean>(false);
    this.hasWarnings = new BehaviorSubject<boolean>(false);
    this.expandedComputationIds = new BehaviorSubject<number[]>([]);
    this.downloadPathAsPDF = new Subject();
    this.isAllPathwaysLoaded = new BehaviorSubject<boolean>(false);
    this.isComputationActive = new BehaviorSubject<boolean>(false);
    this.consolidatedFilteredPathList = new BehaviorSubject<any[]>([]);
    this.targetPathDiversity = new BehaviorSubject<GraphSyntheticPathEntry>(null);
    this.showLoader = new BehaviorSubject<boolean>(false);
    this.isGraphHashChanged = new BehaviorSubject<boolean>(false);
    this.showGraphUpdates = new BehaviorSubject<boolean>(false);
    this.filteredResultsLength = new BehaviorSubject<number>(0);
  }

  public setDefaultValues() {
    this.selectedMenu.next(SidePanelViewType.None);
    this.isMenuOpen.next(false);
    this.resultsFilterSubject.next(null);
    this.resetFilterSubject.next(false);
    this.addStructureFilterFromPopup.next(null);
    this.addDiversityFilterFromPopup.next(null);
    this.addReactionFilterFromPopup.next(null);
    this.filterImagePopupDetails.next(null);
    this.resultsLoadingIndicator.next(false);
    this.filteredPathList.next([]);
    this.diversityResultMolecules.next([]);
    this.pathListSubject.next([]);
    this.isNewGraphAvailable.next(false);
    this.isNewResultAvailable.next(false);
    this.loadingAnalysis.next(true);
    this.processingOfNewResults.next(false);
    this.loadNewGraphResults.next(false);
    this.didSelectPath.next(false);
    this.showComputationError.next(false);
    this.showWarningMessage.next(false);
    this.hasWarnings.next(false);
    this.isReactionReportFromMolecule.next(false);
    this.hasComputationError.next(false);
    this.isAllPathwaysLoaded.next(false);
    this.isComputationActive.next(false);
    this.consolidatedFilteredPathList.next([]);
    this.showLoader.next(false);
    this.isGraphHashChanged.next(false);
    this.showGraphUpdates.next(false);
  }

  public emitEvent(pathId: number[], flag: string) {
    this.eventEmitter.emit({ pathId, flag });
  }

  public emitGroupAddToShop() {
    this.eventEmitterGroupAddToShop.emit();
  }

  public loadComputationError() {
    this.showComputationError.next(true);
  }

  public loadWarningMessage() {
    this.showWarningMessage.next(true);
  }

  public isAllStructureFilterRemoved(section: StructureSectionCategory) {
    return AnalysisResultsFilterHelper.isAllStructureFilterRemoved(section);
  }

  public applyPathStructureFilter(
    filteredPathList: GraphSyntheticPathEntry[],
    structureFilter: StructureFilters,
    isDiversityTargetFilter: boolean,
  ) {
    if (structureFilter) {
      filteredPathList = this.applyPathStructureFilterLimitTo(
        filteredPathList,
        structureFilter.limitTo,
        isDiversityTargetFilter,
      );
      filteredPathList = this.applyPathStructureFilterExclude(
        filteredPathList,
        structureFilter.exclude,
      );
    }
    return filteredPathList;
  }

  public applyPathKeywordFilter(
    filteredPathList: GraphSyntheticPathEntry[],
    keywordFilters: KeywordsFilter,
  ) {
    const { limitTo, exclude }: KeywordsFilter = keywordFilters;
    if (limitTo && Array.isArray(limitTo.keywords) && limitTo.keywords.length > 0) {
      filteredPathList = this.applyPathKeywordFilterCriteria(
        filteredPathList,
        limitTo.keywords,
        false,
      );
    }
    if (exclude && Array.isArray(exclude.keywords) && exclude.keywords.length > 0) {
      filteredPathList = this.applyPathKeywordFilterCriteria(
        filteredPathList,
        exclude.keywords,
        true,
      );
    }
    return filteredPathList;
  }

  public applyPathReactionFilter(
    filteredPathList: GraphSyntheticPathEntry[],
    reactionFilter: IReactionFilter,
  ) {
    if (reactionFilter) {
      filteredPathList = this.applyPathReactionFilterLimitTo(
        filteredPathList,
        reactionFilter.limitTo.reactions,
      );
      filteredPathList = this.applyPathReactionFilterExclude(
        filteredPathList,
        reactionFilter.exclude.reactions,
      );
      if (reactionFilter.lastDisconnected) {
        filteredPathList = this.applyPathReactionFilterLimitTo(
          filteredPathList,
          reactionFilter.lastDisconnected.limitTo.reactions,
        );
        filteredPathList = this.applyPathReactionFilterExclude(
          filteredPathList,
          reactionFilter.lastDisconnected.exclude.reactions,
        );
      }
    }
    return filteredPathList;
  }

  public applyPathReactionFilterLimitTo(
    filteredPathList: GraphSyntheticPathEntry[],
    limitToReactions: IReactionData[],
  ) {
    limitToReactions.forEach((reaction) => {
      filteredPathList = this.pathFilterByReactionName(filteredPathList, reaction.smiles, false);
    });
    return filteredPathList;
  }

  public applyPathReactionFilterExclude(
    filteredPathList: GraphSyntheticPathEntry[],
    excludeReactions: IReactionData[],
  ) {
    excludeReactions.forEach((reaction) => {
      filteredPathList = this.pathFilterByReactionName(filteredPathList, reaction.smiles, true);
    });
    return filteredPathList;
  }

  public applyPathPriceFilter(
    filteredPathList: GraphSyntheticPathEntry[],
    priceFilter: ISliderRange,
  ) {
    const priceFilterList = [];
    if (filteredPathList.length > 0) {
      for (const path of filteredPathList) {
        const startingKnownMolecules: GraphMoleculeNode[] = path.nodes.filter(
          (pathNode: GraphMoleculeNode) =>
            isMoleculeNode(pathNode) &&
            pathNode.isLastSubstrate &&
            pathNode.molecule.mushroom !== null &&
            !pathNode.molecule.cost,
        );
        const startingCommercialMolecules: GraphMoleculeNode[] = path.nodes.filter(
          (pathNode: GraphMoleculeNode) =>
            isMoleculeNode(pathNode) &&
            pathNode.isLastSubstrate &&
            pathNode.molecule.cost !== null &&
            pathNode.molecule.cost !== 0 &&
            pathNode.molecule.mushroom !== null,
        );
        if (startingKnownMolecules.length > 0 && startingCommercialMolecules.length === 0) {
          priceFilterList.push(path);
        } else {
          const moleculesWithinPrice: GraphMoleculeNode[] = startingCommercialMolecules.filter(
            (pathNode: GraphMoleculeNode) => {
              const moleculeCost: number = Number(
                this.decimal.transform(pathNode.molecule.cost, '1.2-2').replace(/,/g, ''),
              );
              return moleculeCost >= priceFilter.min && moleculeCost <= priceFilter.max;
            },
          );
          if (
            moleculesWithinPrice.length > 0 &&
            startingCommercialMolecules.length === moleculesWithinPrice.length
          ) {
            priceFilterList.push(path);
          }
        }
      }
    }
    return priceFilterList;
  }

  public applyPathReactionSliderFilter(
    filteredPathList: GraphSyntheticPathEntry[],
    numberOfReactionSliderFilter: ISliderRange,
  ): GraphSyntheticPathEntry[] {
    const reactionFilterList = [];
    if (filteredPathList.length > 0) {
      for (const path of filteredPathList) {
        const pathReactions: GraphReactionNode[] = path.nodes.filter(
          (pathNode: GraphReactionNode) => isReactionNode(pathNode),
        );
        if (
          pathReactions.length > 0 &&
          pathReactions.length >= numberOfReactionSliderFilter.min &&
          pathReactions.length <= numberOfReactionSliderFilter.max
        ) {
          reactionFilterList.push(path);
        }
      }
    }
    return reactionFilterList;
  }

  public applyPathProtectiveSliderFilter(
    filteredPathList: GraphSyntheticPathEntry[],
    numberOfProtectiveSteps: ISliderRange,
  ): GraphSyntheticPathEntry[] {
    const protectionFilterList = [];
    if (filteredPathList.length > 0) {
      for (const path of filteredPathList) {
        const protectiveStepsLength = path.nodes.filter(
          (node) => node.type !== GraphNodeType.REACTION && node.needsProtection,
        ).length;
        if (
          protectiveStepsLength >= 0 &&
          protectiveStepsLength >= numberOfProtectiveSteps.min &&
          protectiveStepsLength <= numberOfProtectiveSteps.max
        ) {
          protectionFilterList.push(path);
        }
      }
    }
    return protectionFilterList;
  }

  public isResetDisabled() {
    const resultFilters: ResultsFilterModels = this.resultsFilterSubject.value;
    return !resultFilters
      ? true
      : AnalysisResultsFilterHelper.isStructureFilterEmpty(resultFilters, false) &&
          AnalysisResultsFilterHelper.isStructureFilterEmpty(resultFilters, true) &&
          AnalysisResultsFilterHelper.isKeywordFilterEmpty(resultFilters) &&
          AnalysisResultsFilterHelper.isReactionFilterEmpty(resultFilters) &&
          !resultFilters.priceFilter &&
          !resultFilters.numberOfReactionSliderFilter &&
          !resultFilters.numberOfProtectiveSteps &&
          resultFilters.pathwaySimilarity === 100;
  }

  public applyGraphStructureFilter(
    filteredGraphApiResult: GraphResources,
    structureFilter: StructureFilters,
  ) {
    if (structureFilter) {
      filteredGraphApiResult = this.applyLimitToStructureFilerOnGraph(
        filteredGraphApiResult,
        structureFilter.limitTo,
      );
      filteredGraphApiResult = this.applyExcludeStructureFilerOnGraph(
        filteredGraphApiResult,
        structureFilter.exclude,
      );
    }

    return filteredGraphApiResult;
  }

  public applyGraphKeywordFilter(
    filteredGraphApiResult: GraphResources,
    keywordFilters: KeywordsFilter,
  ) {
    const { limitTo, exclude }: KeywordsFilter = keywordFilters;
    if (exclude && Array.isArray(exclude.keywords) && exclude.keywords.length > 0) {
      filteredGraphApiResult = this.applyGraphKeywordFilterCriteria(
        filteredGraphApiResult,
        exclude.keywords,
        true,
      );
    }
    if (limitTo && Array.isArray(limitTo.keywords) && limitTo.keywords.length > 0) {
      filteredGraphApiResult = this.applyGraphKeywordFilterCriteria(
        filteredGraphApiResult,
        limitTo.keywords,
        false,
      );
    }
    return filteredGraphApiResult;
  }

  public applyGraphReactionFilter(
    filteredGraphApiResult: GraphResources,
    reactionFilter: IReactionFilter,
  ): GraphResources {
    if (reactionFilter) {
      filteredGraphApiResult = this.applyGraphReactionFilterExclude(
        filteredGraphApiResult,
        reactionFilter.exclude.reactions,
      );
      filteredGraphApiResult = this.applyGraphReactionFilterLimitTo(
        filteredGraphApiResult,
        reactionFilter.limitTo.reactions,
      );
      if (reactionFilter.lastDisconnected) {
        filteredGraphApiResult = this.applyGraphReactionFilterLimitTo(
          filteredGraphApiResult,
          reactionFilter.lastDisconnected.limitTo.reactions,
        );
        filteredGraphApiResult = this.applyGraphReactionFilterExclude(
          filteredGraphApiResult,
          reactionFilter.lastDisconnected.exclude.reactions,
        );
      }
    }
    return filteredGraphApiResult;
  }

  private applyPathStructureFilterExclude(
    filteredPathList: GraphSyntheticPathEntry[],
    exclude: StructureSectionCategory,
  ) {
    if (exclude) {
      if (exclude.exact && exclude.exact.criteria && exclude.exact.criteria.length > 0) {
        filteredPathList = this.applyPathStructureExcludeFP(
          filteredPathList,
          exclude.exact.criteria,
        );
      }
      if (exclude.similar && exclude.similar.criteria && exclude.similar.criteria.length > 0) {
        filteredPathList = this.applyPathStructureExcludeFP(
          filteredPathList,
          exclude.similar.criteria,
        );
      }
      if (
        exclude.structure &&
        exclude.structure.criteria &&
        exclude.structure.criteria.length > 0
      ) {
        filteredPathList = this.applyPathStructureExcludeStructure(
          filteredPathList,
          exclude.structure.criteria,
        );
      }
    }
    return filteredPathList;
  }

  private applyPathStructureExcludeFP(
    filteredPathList: GraphSyntheticPathEntry[],
    criteria: SVGSmilesSimilarity[],
  ) {
    criteria.forEach((value) => {
      if (value.smiles && value.similarity) {
        filteredPathList = this.pathFilterOnFingerPrint(
          filteredPathList,
          value.smiles,
          value.similarity,
          true,
        );
      }
    });
    return filteredPathList;
  }

  private applyPathStructureExcludeStructure(
    filteredPathList: GraphSyntheticPathEntry[],
    criteria: SVGSmilesSimilarity[],
  ) {
    criteria.forEach((value) => {
      if (value.smarts) {
        filteredPathList = this.pathFilterOnSubStructure(filteredPathList, value.smarts, true);
      }
    });
    return filteredPathList;
  }

  private applyPathStructureFilterLimitTo(
    filteredPathList: GraphSyntheticPathEntry[],
    limitTo: StructureSectionCategory,
    isDiversityTargetFilter: boolean,
  ) {
    if (limitTo) {
      if (limitTo.exact && limitTo.exact.criteria && limitTo.exact.criteria.length > 0) {
        filteredPathList = this.applyPathStructureLimitFP(
          filteredPathList,
          limitTo.exact.criteria,
          isDiversityTargetFilter,
        );
      }
      if (limitTo.similar && limitTo.similar.criteria && limitTo.similar.criteria.length > 0) {
        filteredPathList = this.applyPathStructureLimitFP(
          filteredPathList,
          limitTo.similar.criteria,
          isDiversityTargetFilter,
        );
      }
      if (
        limitTo.structure &&
        limitTo.structure.criteria &&
        limitTo.structure.criteria.length > 0
      ) {
        filteredPathList = this.applyPathStructureLimitStructure(
          filteredPathList,
          limitTo.structure.criteria,
        );
      }
    }
    return filteredPathList;
  }

  private applyPathStructureLimitFP(
    filteredPathList: GraphSyntheticPathEntry[],
    criteria: SVGSmilesSimilarity[],
    isDiversityTargetFilter: boolean,
  ) {
    const pathListToFilter: GraphSyntheticPathEntry[] = filteredPathList;
    const diversityResultPathList = [];
    criteria.forEach((value) => {
      if (value.smiles && value.similarity) {
        if (!isDiversityTargetFilter) {
          filteredPathList = this.pathFilterOnFingerPrint(
            filteredPathList,
            value.smiles,
            value.similarity,
            false,
          );
        } else {
          diversityResultPathList.push(
            ...this.pathFilterOnFingerPrint(
              pathListToFilter,
              value.smiles,
              value.similarity,
              false,
            ),
          );
          filteredPathList = [...new Set(diversityResultPathList)];
        }
      }
    });
    return filteredPathList;
  }

  private applyPathStructureLimitStructure(
    filteredPathList: GraphSyntheticPathEntry[],
    criteria: SVGSmilesSimilarity[],
  ) {
    criteria.forEach((value) => {
      if (value.smarts) {
        filteredPathList = this.pathFilterOnSubStructure(filteredPathList, value.smarts, false);
      }
    });
    return filteredPathList;
  }

  private pathFilterOnFingerPrint(
    filteredPathList: GraphSyntheticPathEntry[],
    smilesToFilter: string,
    similarity: number,
    exclude: boolean,
  ) {
    const smilesToFilterMol = this.rdKitService.getMolecule(smilesToFilter);
    if (smilesToFilterMol.is_valid()) {
      const smileToFilterFP = this.rdKitService.getFingerPrint(smilesToFilterMol);
      const pathListToFilter: GraphSyntheticPathEntry[] = filteredPathList;
      filteredPathList = [];
      for (const path of pathListToFilter) {
        const pathMolecules: GraphMoleculeNode[] = path.nodes.filter((pathNode) =>
          isMoleculeNode(pathNode),
        );
        for (const moleculeNode of pathMolecules) {
          const { molecule } = moleculeNode;
          const smiles = molecule.smiles;
          if (similarity === 100) {
            if (smiles === smilesToFilter) {
              filteredPathList.push(path);
              break;
            }
          } else {
            const pathMoleculeRDKit = this.rdKitService.getMolecule(smiles);
            if (pathMoleculeRDKit.is_valid()) {
              const pathMoleculeFP = this.rdKitService.getFingerPrint(pathMoleculeRDKit);
              const similarityScore =
                this.rdKitService.getTanimoto(pathMoleculeFP, smileToFilterFP) * 100;
              pathMoleculeRDKit.delete();
              if (similarityScore >= similarity) {
                filteredPathList.push(path);
                break;
              }
            }
          }
        }
      }
      if (exclude) {
        const excludeList: GraphSyntheticPathEntry[] = filteredPathList;
        filteredPathList = [];
        filteredPathList = pathListToFilter.filter((path: GraphSyntheticPathEntry) => {
          return !excludeList.find((excludePath: GraphSyntheticPathEntry) => {
            return excludePath.syntheticPath === path.syntheticPath;
          });
        });
      }
    }
    smilesToFilterMol.delete();

    return filteredPathList;
  }

  private pathFilterOnSubStructure(
    filteredPathList: GraphSyntheticPathEntry[],
    smartsToFilter: string,
    exclude: boolean,
  ) {
    const smartsToFilterMol = this.rdKitService.getQMolecule(smartsToFilter);
    if (smartsToFilterMol.is_valid()) {
      const pathListToFilter: GraphSyntheticPathEntry[] = filteredPathList;
      filteredPathList = [];
      for (const path of pathListToFilter) {
        const pathMolecules: GraphMoleculeNode[] = path.nodes.filter((pathNode) =>
          isMoleculeNode(pathNode),
        );
        for (const moleculeNode of pathMolecules) {
          const { molecule } = moleculeNode;
          const smiles = molecule.smiles;
          const pathMoleculeRDKit = this.rdKitService.getMolecule(smiles);
          if (pathMoleculeRDKit.is_valid()) {
            const substructureMatch = JSON.parse(
              this.rdKitService.getSubstructureMatch(pathMoleculeRDKit, smartsToFilterMol),
            );
            pathMoleculeRDKit.delete();
            if (substructureMatch.atoms && substructureMatch.atoms.length) {
              filteredPathList.push(path);
              break;
            }
          }
        }
      }
      if (exclude) {
        const excludeList: GraphSyntheticPathEntry[] = filteredPathList;
        filteredPathList = [];
        filteredPathList = pathListToFilter.filter((path: GraphSyntheticPathEntry) => {
          return !excludeList.find((excludePath: GraphSyntheticPathEntry) => {
            return excludePath.syntheticPath === path.syntheticPath;
          });
        });
      }
    }
    smartsToFilterMol.delete();
    return filteredPathList;
  }

  private applyPathKeywordFilterCriteria(
    filteredPathList: GraphSyntheticPathEntry[],
    keywordFilters: string[],
    exclude: boolean = false,
  ) {
    type ListEmptyRef = {
      filteredList: GraphSyntheticPathEntry[];
      isEmpty: boolean;
    };
    let filteredFinalList: GraphSyntheticPathEntry[] = [];
    let filteredResult: GraphSyntheticPathEntry[] = [];
    let filterByMolName: ListEmptyRef;
    let filterByReactionName: ListEmptyRef;
    let filterByRetroName: ListEmptyRef;
    let filterByReactionConditions: ListEmptyRef;
    keywordFilters.forEach((keyword: string) => {
      filterByMolName = this.pathKeywordFilterByMoleculeName(filteredPathList, keyword, exclude);
      if (!filterByMolName.isEmpty) {
        filteredResult = [...filterByMolName.filteredList];
      }
      filterByReactionName = this.pathFilterKeywordByReactionName(
        exclude ? filteredResult : filteredPathList,
        keyword,
        exclude,
      );
      if (!filterByReactionName.isEmpty) {
        filteredResult = [...filterByReactionName.filteredList];
      }
      filterByRetroName = this.pathkeywordFilterByReactionRetroSynthesisId(
        exclude ? filteredResult : filteredPathList,
        keyword,
        exclude,
      );
      if (!filterByRetroName.isEmpty) {
        filteredResult = [...filterByRetroName.filteredList];
      }
      filterByReactionConditions = this.pathKeywordFilterByReactionConditions(
        exclude ? filteredResult : filteredPathList,
        keyword,
        exclude,
      );
      if (!filterByReactionConditions.isEmpty) {
        filteredResult = [...filterByReactionConditions.filteredList];
      }
      if (exclude) {
        if (
          filterByMolName.isEmpty ||
          filterByReactionName.isEmpty ||
          filterByRetroName.isEmpty ||
          filterByReactionConditions.isEmpty
        ) {
          return (filteredResult = []);
        }
        filteredFinalList = filteredPathList = filteredResult;
      } else {
        if (
          filterByMolName.isEmpty &&
          filterByReactionName.isEmpty &&
          filterByRetroName.isEmpty &&
          filterByReactionConditions.isEmpty
        ) {
          return (filteredResult = []);
        }
        filteredFinalList = filteredFinalList.concat(filteredResult);
      }
    });
    return filteredFinalList;
  }

  private pathKeywordFilterByMoleculeName(
    filteredPathList: GraphSyntheticPathEntry[],
    keyword: string,
    exclude: boolean = false,
  ) {
    let keywordFilteredList = [];
    let keywordFilterMoleculeNode: GraphMoleculeNode;
    for (const filterPath of filteredPathList) {
      const pathMolecules: GraphMoleculeNode[] = filterPath.nodes.filter((pathNode) =>
        isMoleculeNode(pathNode),
      );
      keywordFilterMoleculeNode = pathMolecules.find((moleculeNode: GraphMoleculeNode) => {
        return AnalysisResultsFilterHelper.isKeywordIncluded(
          keyword,
          moleculeNode.molecule,
          AnalysisResultsFilterHelper.KeywordFilterAttributes.Name,
        );
      });
      if (keywordFilterMoleculeNode && keywordFilterMoleculeNode.molecule) {
        keywordFilteredList.push(filterPath);
      }
    }
    keywordFilteredList = AnalysisResultsFilterHelper.excludeFilteredPathList(
      filteredPathList,
      keywordFilteredList,
      exclude,
    );
    return keywordFilteredList.length > 0
      ? { filteredList: keywordFilteredList, isEmpty: false }
      : { filteredList: keywordFilteredList, isEmpty: true };
  }

  private pathFilterByReactionName(
    filteredPathList: GraphSyntheticPathEntry[],
    reactionSmiles: string,
    exclude = false,
  ) {
    let filteredList = [];
    let filterReactionNode: GraphReactionNode;
    for (const filterPath of filteredPathList) {
      const pathReactions = filterPath.nodes.filter((pathNode) =>
        isReactionNode(pathNode),
      ) as GraphReactionNode[];
      filterReactionNode = pathReactions.find((reactionNode: GraphReactionNode) => {
        return (
          reactionNode.reaction &&
          reactionNode.reaction.smiles &&
          reactionSmiles === reactionNode.reaction.smiles
        );
      });
      if (filterReactionNode && filterReactionNode.reaction) {
        filteredList.push(filterPath);
      }
    }
    filteredList = AnalysisResultsFilterHelper.excludeFilteredPathList(
      filteredPathList,
      filteredList,
      exclude,
    );
    return filteredList;
  }

  private pathFilterKeywordByReactionName(
    filteredPathList: GraphSyntheticPathEntry[],
    keyword: string,
    exclude: boolean = false,
  ) {
    let filteredList = [];
    let filterReactionNode: GraphReactionNode;
    if (filteredPathList) {
      for (const filterPath of filteredPathList) {
        const pathReactions = filterPath.nodes.filter((pathNode) =>
          isReactionNode(pathNode),
        ) as GraphReactionNode[];

        filterReactionNode = pathReactions.find((reactionNode: GraphReactionNode) => {
          return AnalysisResultsFilterHelper.isKeywordIncluded(
            keyword,
            reactionNode.reaction,
            AnalysisResultsFilterHelper.KeywordFilterAttributes.Name,
          );
        });

        if (filterReactionNode && filterReactionNode.reaction) {
          filteredList.push(filterPath);
        }
      }
    }
    filteredList = AnalysisResultsFilterHelper.excludeFilteredPathList(
      filteredPathList,
      filteredList,
      exclude,
    );
    return filteredList.length > 0
      ? { filteredList: filteredList, isEmpty: false }
      : { filteredList: filteredList, isEmpty: true };
  }

  private pathkeywordFilterByReactionRetroSynthesisId(
    filteredPathList: GraphSyntheticPathEntry[],
    keyword: string,
    exclude: boolean = false,
  ) {
    let keywordFilteredList = [];
    let keywordFilterReactionNode: GraphReactionNode;
    if (filteredPathList) {
      for (const filterPath of filteredPathList) {
        const pathReactions = filterPath.nodes.filter((pathNode) =>
          isReactionNode(pathNode),
        ) as GraphReactionNode[];
        keywordFilterReactionNode = pathReactions.find((reactionNode: GraphReactionNode) => {
          return AnalysisResultsFilterHelper.isKeywordIncluded(
            keyword,
            reactionNode.reaction,
            AnalysisResultsFilterHelper.KeywordFilterAttributes.RetroSynthesis,
          );
        });
        if (keywordFilterReactionNode && keywordFilterReactionNode.reaction) {
          keywordFilteredList.push(filterPath);
        }
      }
    }
    keywordFilteredList = AnalysisResultsFilterHelper.excludeFilteredPathList(
      filteredPathList,
      keywordFilteredList,
      exclude,
    );
    if (keywordFilteredList.length > 0) {
      return { filteredList: keywordFilteredList, isEmpty: false };
    }
    return { filteredList: keywordFilteredList, isEmpty: true };
  }

  private pathKeywordFilterByReactionConditions(
    filteredPathList: GraphSyntheticPathEntry[],
    keyword: string,
    exclude: boolean = false,
  ) {
    let keywordFilteredList = [];
    let keywordFilterReactionNode: GraphReactionNode;
    for (const filterPath of filteredPathList) {
      const pathReactions = filterPath.nodes.filter((pathNode) =>
        isReactionNode(pathNode),
      ) as GraphReactionNode[];
      keywordFilterReactionNode = pathReactions.find((reactionNode: GraphReactionNode) => {
        return AnalysisResultsFilterHelper.isKeywordIncluded(
          keyword,
          reactionNode.reaction,
          AnalysisResultsFilterHelper.KeywordFilterAttributes.Conditions,
        );
      });
      if (keywordFilterReactionNode && keywordFilterReactionNode.reaction) {
        keywordFilteredList.push(filterPath);
      }
    }
    keywordFilteredList = AnalysisResultsFilterHelper.excludeFilteredPathList(
      filteredPathList,
      keywordFilteredList,
      exclude,
    );
    if (keywordFilteredList.length > 0) {
      return { filteredList: keywordFilteredList, isEmpty: false };
    }
    return { filteredList: keywordFilteredList, isEmpty: true };
  }

  private applyLimitToStructureFilerOnGraph(
    filteredGraphApiResult: GraphResources,
    limitTo: StructureSectionCategory,
  ): GraphResources {
    if (limitTo) {
      if (limitTo.exact && limitTo.exact.criteria && limitTo.exact.criteria.length > 0) {
        filteredGraphApiResult = this.applyLimitStructureFilterOnGraphFP(
          filteredGraphApiResult,
          limitTo.exact.criteria,
        );
      }
      if (limitTo.similar && limitTo.similar.criteria && limitTo.similar.criteria.length > 0) {
        filteredGraphApiResult = this.applyLimitStructureFilterOnGraphFP(
          filteredGraphApiResult,
          limitTo.similar.criteria,
        );
      }
      if (
        limitTo.structure &&
        limitTo.structure.criteria &&
        limitTo.structure.criteria.length > 0
      ) {
        filteredGraphApiResult = this.applyLimitStructureFilterOnGraphSubStructure(
          filteredGraphApiResult,
          limitTo.structure.criteria,
        );
      }
    }
    return filteredGraphApiResult;
  }

  private applyExcludeStructureFilerOnGraph(
    filteredGraphApiResult: GraphResources,
    exclude: StructureSectionCategory,
  ): GraphResources {
    if (exclude) {
      if (exclude.exact && exclude.exact.criteria && exclude.exact.criteria.length > 0) {
        filteredGraphApiResult = this.applyExcludeStructureFilterOnGraphFP(
          filteredGraphApiResult,
          exclude.exact.criteria,
        );
      }
      if (exclude.similar && exclude.similar.criteria && exclude.similar.criteria.length > 0) {
        filteredGraphApiResult = this.applyExcludeStructureFilterOnGraphFP(
          filteredGraphApiResult,
          exclude.similar.criteria,
        );
      }
      if (
        exclude.structure &&
        exclude.structure.criteria &&
        exclude.structure.criteria.length > 0
      ) {
        filteredGraphApiResult = this.applyExcludeStructureFilterOnGraphSubStructure(
          filteredGraphApiResult,
          exclude.structure.criteria,
        );
      }
    }
    return filteredGraphApiResult;
  }

  private applyLimitStructureFilterOnGraphFP(
    filteredGraphApiResult: GraphResources,
    criteria: SVGSmilesSimilarity[],
  ): GraphResources {
    criteria.forEach((value) => {
      if (value.smiles && value.similarity) {
        filteredGraphApiResult = this.graphFilterOnFingerPrint(
          filteredGraphApiResult,
          value.smiles,
          value.similarity,
          false,
        );
      }
    });
    return filteredGraphApiResult;
  }

  private applyLimitStructureFilterOnGraphSubStructure(
    filteredGraphApiResult: GraphResources,
    criteria: SVGSmilesSimilarity[],
  ): GraphResources {
    criteria.forEach((value) => {
      if (value.smarts) {
        filteredGraphApiResult = this.graphFilterOnSubStructure(
          filteredGraphApiResult,
          value.smarts,
          false,
        );
      }
    });
    return filteredGraphApiResult;
  }

  private applyExcludeStructureFilterOnGraphFP(
    filteredGraphApiResult: GraphResources,
    criteria: SVGSmilesSimilarity[],
  ): GraphResources {
    criteria.forEach((value) => {
      if (value.smiles && value.similarity) {
        filteredGraphApiResult = this.graphFilterOnFingerPrint(
          filteredGraphApiResult,
          value.smiles,
          value.similarity,
          true,
        );
      }
    });
    return filteredGraphApiResult;
  }
  private applyExcludeStructureFilterOnGraphSubStructure(
    filteredGraphApiResult: GraphResources,
    criteria: SVGSmilesSimilarity[],
  ): GraphResources {
    criteria.forEach((value) => {
      if (value.smarts) {
        filteredGraphApiResult = this.graphFilterOnSubStructure(
          filteredGraphApiResult,
          value.smarts,
          true,
        );
      }
    });
    return filteredGraphApiResult;
  }

  private graphFilterOnFingerPrint(
    filteredGraphApiResult: GraphResources,
    smilesToFilter: string,
    similarity: number,
    exclude: boolean,
  ): GraphResources {
    let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
    const smilesToFilterMol = this.rdKitService.getMolecule(smilesToFilter);
    if (smilesToFilterMol.is_valid()) {
      const smileToFilterFP = this.rdKitService.getFingerPrint(smilesToFilterMol);
      for (const molecule of filteredGraphApiResult.molecules) {
        const smiles = molecule.smiles;
        const graphMoleculeRDKit = this.rdKitService.getMolecule(smiles);
        if (graphMoleculeRDKit.is_valid()) {
          const graphMoleculeFP = this.rdKitService.getFingerPrint(graphMoleculeRDKit);
          const similarityScore =
            this.rdKitService.getTanimoto(graphMoleculeFP, smileToFilterFP) * 100;
          graphMoleculeRDKit.delete();
          if (similarityScore >= similarity) {
            const reactionNodeEntries =
              AnalysisResultsFilterHelper.getReactionNodesFilteredMolecule(
                filteredGraphApiResult,
                graphReactionNodeEntryList,
                molecule,
              );
            const uniqueNodes = AnalysisResultsFilterHelper.removeDuplicateGraphReactionNodeEntry(
              graphReactionNodeEntryList,
              reactionNodeEntries,
            );
            graphReactionNodeEntryList.push(...uniqueNodes);
          }
        }
      }
    }
    smilesToFilterMol.delete();
    graphReactionNodeEntryList = AnalysisResultsFilterHelper.excludeGraphReactionNodeEntryList(
      filteredGraphApiResult.reaction_nodes,
      graphReactionNodeEntryList,
      exclude,
    );
    graphReactionNodeEntryList = AnalysisResultsFilterHelper.getAllReactionNodesToBuildGraph(
      filteredGraphApiResult,
      graphReactionNodeEntryList,
    );
    filteredGraphApiResult.reaction_nodes =
      AnalysisResultsFilterHelper.sortGraphReactionNodeEntries(graphReactionNodeEntryList);
    return filteredGraphApiResult;
  }

  private graphFilterOnSubStructure(
    filteredGraphApiResult: GraphResources,
    smartsToFilter: string,
    exclude: boolean,
  ): GraphResources {
    let graphReactionNodeEntryList: GraphReactionNodeEntry[] = [];
    const smartsToFilterMol = this.rdKitService.getQMolecule(smartsToFilter);
    if (smartsToFilterMol.is_valid()) {
      for (const molecule of filteredGraphApiResult.molecules) {
        const smiles = molecule.smiles;
        const graphMoleculeRDKit = this.rdKitService.getMolecule(smiles);
        if (graphMoleculeRDKit.is_valid()) {
          const substructureMatch = JSON.parse(
            this.rdKitService.getSubstructureMatch(graphMoleculeRDKit, smartsToFilterMol),
          );
          graphMoleculeRDKit.delete();
          if (substructureMatch.atoms && substructureMatch.atoms.length) {
            const reactionNodeEntries =
              AnalysisResultsFilterHelper.getReactionNodesFilteredMolecule(
                filteredGraphApiResult,
                graphReactionNodeEntryList,
                molecule,
              );
            const uniqueNodes = AnalysisResultsFilterHelper.removeDuplicateGraphReactionNodeEntry(
              graphReactionNodeEntryList,
              reactionNodeEntries,
            );
            graphReactionNodeEntryList.push(...uniqueNodes);
          }
        }
      }
    }
    smartsToFilterMol.delete();
    graphReactionNodeEntryList = AnalysisResultsFilterHelper.excludeGraphReactionNodeEntryList(
      filteredGraphApiResult.reaction_nodes,
      graphReactionNodeEntryList,
      exclude,
    );
    graphReactionNodeEntryList = AnalysisResultsFilterHelper.getAllReactionNodesToBuildGraph(
      filteredGraphApiResult,
      graphReactionNodeEntryList,
    );

    filteredGraphApiResult.reaction_nodes =
      AnalysisResultsFilterHelper.sortGraphReactionNodeEntries(graphReactionNodeEntryList);
    return filteredGraphApiResult;
  }

  private applyGraphKeywordFilterCriteria(
    filteredGraphApiResult: GraphResources,
    keywords: string[],
    exclude: boolean = false,
  ): GraphResources {
    type GraphReactionNodeEmptyRef = {
      graphReactionNodeEntryList: any[];
      isEmpty: boolean;
    };
    let filterByMolName: GraphReactionNodeEmptyRef;
    let filterByReactionName: GraphReactionNodeEmptyRef;
    let filterByRetroName: GraphReactionNodeEmptyRef;
    let filterByReactionConditions: GraphReactionNodeEmptyRef;
    keywords.forEach((keyword: string) => {
      filterByMolName = AnalysisResultsFilterHelper.graphKeywordFilterByMoleculeName(
        filteredGraphApiResult,
        keyword,
        exclude,
      );
      if (!filterByMolName.isEmpty) {
        filteredGraphApiResult.reaction_nodes = filterByMolName.graphReactionNodeEntryList;
      }
      filterByReactionName = AnalysisResultsFilterHelper.graphFilterKeywordByReactionName(
        filteredGraphApiResult,
        keyword,
        exclude,
      );
      if (!filterByReactionName.isEmpty) {
        filteredGraphApiResult.reaction_nodes = filterByReactionName.graphReactionNodeEntryList;
      }
      filterByRetroName = AnalysisResultsFilterHelper.graphkeywordFilterByReactionRetroSynthesisId(
        filteredGraphApiResult,
        keyword,
        exclude,
      );
      if (!filterByRetroName.isEmpty) {
        filteredGraphApiResult.reaction_nodes = filterByRetroName.graphReactionNodeEntryList;
      }
      filterByReactionConditions =
        AnalysisResultsFilterHelper.graphKeywordFilterByReactionConditions(
          filteredGraphApiResult,
          keyword,
          exclude,
        );
      if (!filterByReactionConditions.isEmpty) {
        filteredGraphApiResult.reaction_nodes =
          filterByReactionConditions.graphReactionNodeEntryList;
      }
      if (
        filterByMolName.isEmpty &&
        filterByReactionName.isEmpty &&
        filterByRetroName.isEmpty &&
        filterByReactionConditions.isEmpty
      ) {
        return (filteredGraphApiResult.reaction_nodes = []);
      }
    });
    filteredGraphApiResult.reaction_nodes =
      AnalysisResultsFilterHelper.sortGraphReactionNodeEntries(
        filteredGraphApiResult.reaction_nodes,
      );

    return filteredGraphApiResult;
  }

  private applyGraphReactionFilterLimitTo(
    filteredGraphApiResult: GraphResources,
    limitToReactions: IReactionData[],
  ): GraphResources {
    limitToReactions.forEach((reaction) => {
      filteredGraphApiResult = AnalysisResultsFilterHelper.graphFilterByReactionSmiles(
        filteredGraphApiResult,
        reaction.smiles,
        false,
      );
    });
    filteredGraphApiResult.reaction_nodes =
      AnalysisResultsFilterHelper.sortGraphReactionNodeEntries(
        filteredGraphApiResult.reaction_nodes,
      );

    return filteredGraphApiResult;
  }
  private applyGraphReactionFilterExclude(
    filteredGraphApiResult: GraphResources,
    excludeReactions: IReactionData[],
  ): GraphResources {
    excludeReactions.forEach((reaction) => {
      filteredGraphApiResult = AnalysisResultsFilterHelper.graphFilterByReactionSmiles(
        filteredGraphApiResult,
        reaction.smiles,
        true,
      );
    });
    filteredGraphApiResult.reaction_nodes =
      AnalysisResultsFilterHelper.sortGraphReactionNodeEntries(
        filteredGraphApiResult.reaction_nodes,
      );

    return filteredGraphApiResult;
  }
}
