import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ErrorsHandlerService, ParsedConnectionError } from '../errors-handler';
import { MatTableDataSource } from '@angular/material/table';

export const backendEntryPointMoleculeSets =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/molecule-sets/';
export const backendEntryPointElementSets = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/element-sets/';
export const backendEntryPointMoleculeIdBySmiles =
  APP_CONFIG.CHEMATICA_API_URL + '/api/v1/molecule-id-by-smiles/';
export const backendEntryPointSmartsSets = APP_CONFIG.CHEMATICA_API_URL + '/api/v1/smarts-sets/';

enum TableIndex {
  CenterTable,
  RightTable,
}

export enum ViewType {
  MoleculeSets,
  SmartsSets,
}

export enum MoleculeActionType {
  CopyTo,
  MoveTo,
  Delete,
  Add,
  Edit,
}

/**
 * Context for elements set table.
 *
 * @property dataLoading - used to display a spinner while requesting data.
 * @property errorMessage - string of an error message to be displayed under the table if there are errors.
 * @property setName - name of the set.
 * @property owner - owner of the set - null means that set is global.
 * @property rowsSelected - an object with boolean values to determine if there are any rows selected.
 * @property setId - id of the set.
 * @property table - TableIndex enum value of the table. Allowed values are CenterTable and RightTable.
 * @property tableDataSource is a MatTableDataSource type required to display data in MatTable.
 */

export interface ITableContext {
  dataLoading: boolean;
  errorMessage: string;
  setName: string;
  owner: null | number;
  rowsSelected: {
    all: boolean;
    none: boolean;
    some: boolean;
  };
  setId: number;
  table: TableIndex;
  // tableDataSource type is implied to be IAvoidedSmartsDataRow, ISmartsDataRow or any other type of data rows
  // but using type unions for these complex types causes lots of compilation errors and I am yet to figure out
  // how to declare this property without errors. - GW
  tableDataSource: MatTableDataSource<any>;
}

/*tslint:disable:max-classes-per-file*/
export interface GroupElementSet {
  regulatedList: any[];
  userList: any[];
}

@Injectable()
export class MoleculeSetsService {
  /*tslint:enable:max-classes-per-file*/

  public moleculeSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public smartsSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private http: HttpClient,
    private errorsHandler: ErrorsHandlerService,
  ) {
    this.getAndStoreMoleculeSets().subscribe();
    this.getAndStoreSmartsSets().subscribe();
  }

  public getAndStoreMoleculeSets() {
    return this.getMoleculeSets().pipe(
      flatMap((moleculeSets: any[]) => {
        this.moleculeSets.next(moleculeSets);
        return of(moleculeSets);
      }),
    );
  }

  public getMoleculeSets() {
    return this.http.get(backendEntryPointMoleculeSets, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
    );
  }

  /*
   * Returns a list of templates (current versions) of molecule sets because most often only these
   * are required to be displayed. In other cases moleculeSets BehaviorSubject should be used.
   */
  public getSortedMoleculeSetTemplates(): Observable<any[]> {
    return this.moleculeSets.asObservable().pipe(
      map((moleculeSets: any[]) => {
        const filteredAndSortedSets: any[] = this.filterSet(moleculeSets);
        return filteredAndSortedSets;
      }),
    );
  }

  public getMoleculesFromSet(setId: number) {
    const entryPointUrl: string = backendEntryPointMoleculeSets + setId + '/molecules/';
    return this.http.get(entryPointUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  public saveMoleculeSet(moleculeSetName: { name: string }) {
    return this.http
      .post(backendEntryPointMoleculeSets, JSON.stringify(moleculeSetName), { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public addMoleculesToSet(setAndMoleculeIds: { setId: number; moleculeId: string }) {
    const entryPointUrl: string =
      backendEntryPointMoleculeSets + setAndMoleculeIds.setId + '/molecules/';
    return this.http
      .post(entryPointUrl, JSON.stringify({ id: setAndMoleculeIds.moleculeId }), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public addMultipleMoleculesToSet(setId: number, moleculeIds: string[]) {
    const entryPointUrl: string = backendEntryPointMoleculeSets + setId + '/add-many-molecules/';
    return this.http
      .post(entryPointUrl, JSON.stringify({ ids: moleculeIds }), { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public getMoleculeIdBySmiles(molecules: string[]) {
    return this.http
      .post(backendEntryPointMoleculeIdBySmiles, JSON.stringify({ smiles: molecules }), {
        observe: 'response',
      })
      .pipe(
        map((moleculeIds: HttpResponse<any>) => {
          try {
            return moleculeIds.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public deleteMoleculeSet(setId: number) {
    const entryPointUrl: string = backendEntryPointMoleculeSets + setId + '/';
    return this.http.delete(entryPointUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  public deleteMultipleMoleculesFromSet(setId: number, moleculeIds: string[]) {
    const entryPointUrl: string = backendEntryPointMoleculeSets + setId + '/remove-many-molecules/';
    return this.http
      .post(entryPointUrl, JSON.stringify({ ids: moleculeIds }), { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public deleteMultipleMoleculesFromSubstructrureList(
    substructrureListId: number,
    smarts: string[],
  ) {
    const entryPointUrl: string =
      backendEntryPointSmartsSets + substructrureListId + '/remove-many-smarts/';
    return this.http
      .post(entryPointUrl, JSON.stringify({ smarts: smarts }), { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public freezeElementSets(setIds: number[]) {
    const options = {
      ids: setIds,
    };
    return this.http
      .post(backendEntryPointElementSets + 'freeze/', JSON.stringify(options), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public getAndStoreSmartsSets() {
    return this.getSmartsSets().pipe(
      flatMap((smartsSets: any[]) => {
        this.smartsSets.next(smartsSets);
        return of(smartsSets);
      }),
    );
  }

  public getSmartsSets() {
    return this.http.get(backendEntryPointSmartsSets, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  /*
   * Returns a list of templates (current versions) of SMARTS sets because most often only these
   * are required to be displayed. In other cases smartsSets BehaviorSubject should be used.
   */
  public getSortedSmartsSetTemplates() {
    return this.smartsSets.asObservable().pipe(
      map((smartsSets: any[]) => {
        const filteredAndSortedSets: any[] = this.filterSet(smartsSets);
        return filteredAndSortedSets;
      }),
    );
  }

  public getSmartsFromSet(setId: number) {
    const entryPointUrl: string = backendEntryPointSmartsSets + setId + '/smarts/';
    return this.http.get(entryPointUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  public saveSmartsSet(smartsSetName: { name: string }) {
    return this.http
      .post(backendEntryPointSmartsSets, JSON.stringify(smartsSetName), { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public addSmartsToSet(setAndSmartsIds: {
    setId: number;
    smartsSource: string;
    smartsDescription: string;
  }) {
    const entryPointUrl: string = backendEntryPointSmartsSets + setAndSmartsIds.setId + '/smarts/';

    return this.http
      .post(
        entryPointUrl,
        JSON.stringify({
          id: setAndSmartsIds.smartsSource,
          description: setAndSmartsIds.smartsDescription,
        }),
        { observe: 'response' },
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public addMultipleSmartsToSet(
    setId: number,
    smarts: {
      smarts: string[];
    },
  ) {
    // FIXME: add descriptions when possible
    const entryPointUrl: string = backendEntryPointSmartsSets + setId + '/add-many-smarts/';
    return this.http
      .post(
        entryPointUrl,
        JSON.stringify({
          smarts: smarts.smarts,
        }),
        { observe: 'response' },
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          try {
            return response.body;
          } catch (error) {
            return new Error('Unexpected format of response. ' + error.message);
          }
        }),
        catchError(this.getElementSetsError.bind(this)),
      );
  }

  public deleteSmartsSet(setId: number) {
    const entryPointUrl: string = backendEntryPointSmartsSets + setId + '/';
    return this.http.delete(entryPointUrl, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  public updateMoleculeSet(setItem: any) {
    const entryPointUrl: string = backendEntryPointMoleculeSets + setItem.id + '/';
    const patchData = { name: setItem.name };
    return this.http.patch(entryPointUrl, JSON.stringify(patchData), { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  public updateSmartsSet(setItem: any) {
    const entryPointUrl: string = backendEntryPointSmartsSets + setItem.id + '/';
    const patchData = { name: setItem.name };
    return this.http.patch(entryPointUrl, JSON.stringify(patchData), { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        try {
          return response.body;
        } catch (error) {
          return new Error('Unexpected format of response. ' + error.message);
        }
      }),
      catchError(this.getElementSetsError.bind(this)),
    );
  }

  public filterUserAndRegulatedList(elementSets) {
    const groupElementSet: GroupElementSet = { userList: [], regulatedList: [] };
    for (const item of elementSets) {
      if (item.owner) {
        groupElementSet.userList.push(item);
      } else {
        groupElementSet.regulatedList.push(item);
      }
    }
    return groupElementSet;
  }

  public sortSet(elementSets: any[]): any[] {
    return elementSets.sort((setA, setB) => {
      return setA.name.localeCompare(setB.name);
    });
  }

  private getElementSetsError(error: HttpResponse<any> | any) {
    // Let components handle 400, 403 and 404 errors.
    // Handle some other server errors.
    if (error.status !== 400 && error.status !== 404 && error.status !== 403) {
      const parsedError = new ParsedConnectionError(error);
      if (parsedError.isRecognized() && parsedError.isGlobal()) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage);
        return throwError(parsedError);
      } else {
        return throwError('Server responded with an error. ');
      }
    } else {
      return throwError(error);
    }
  }

  private filterSet(elementSets: any[]): any[] {
    return elementSets.filter((elementSet: any) => {
      return elementSet.version === 0 || elementSet.version === null;
    });
  }
}
