import { Subject, timer, Observable, of } from 'rxjs';
import { takeUntil, finalize, filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { AuthorizationService } from '../authorization/authorization.service';
import {
  Router,
  UrlTree,
  UrlSegmentGroup,
  UrlSegment,
  ActivationStart,
  ActivationEnd,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { Location } from '@angular/common';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { flatMap } from 'rxjs/operators';
import { ChematicaRouteStateService } from '../shared/services/ch-route-state.service';
import { AppState } from 'src/app/app-state.service';
import { UserEntry } from '../authorization/models/user-entry';
import { MidendConfiguration } from '../shared/services/models/midend-configuration';
import { CredentialsService, AnalysisService, ParametersStashService } from '../shared/services';
import { PendoAnalyticsService } from '../shared/services/analytics/pendo-analytics.service';
import { EmdHandlerService } from '../synthia-lite/services/emd-handler.service';

const ANNOUNCEMENT_POLLING_INTERVAL: number = 3600000;
@Component({
  selector: 'ch-main-frame',
  styleUrls: ['./main-frame.component.scss'],
  templateUrl: './main-frame.component.html',
})
export class MainFrameComponent implements OnInit, OnDestroy {
  public isAuthorized: boolean = false;
  public mustChangePassword: boolean = false;
  public showForUnauthorized: boolean = false;
  public matDialogRef: MatDialogRef<TemplateRef<any>>;
  public isLoading: boolean = false;
  public enableCustomerPolicyAcceptance: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  public showLoader: boolean = false;

  @ViewChild('siteAnnouncementTemplate')
  public siteAnnouncementTemplate: TemplateRef<any>;
  private unsubscriberSubject: Subject<void> = new Subject<void>();
  private userDashboardUrl: string = '';
  private adminDefaultLandingPageUrl: string = '';

  constructor(
    private changeDetector: ChangeDetectorRef,
    private authorizationService: AuthorizationService,
    private location: Location,
    private router: Router,
    private matDialog: MatDialog,
    private routeStateService: ChematicaRouteStateService,
    private appStateService: AppState,
    private credentialsService: CredentialsService,
    private analysisService: AnalysisService,
    private stashService: ParametersStashService,
    private pendoAnalyticsService: PendoAnalyticsService,
    private emdHandlerService: EmdHandlerService,
  ) {
    this.routeStateService.emdCode.pipe(takeUntil(this.unsubscriberSubject)).subscribe((code) => {
      if (code) {
        this.router.navigate(['/sign-in'], { queryParams: { code: code } });
      }
    });
    this.routeStateService.isComingFromChemdraw
      .pipe(
        takeUntil(this.unsubscriberSubject),
        filter((isComingFromChemdraw: boolean) => isComingFromChemdraw === true),
      )
      .subscribe(() => {
        this.router.navigate(['/sign-in'], { queryParams: { source: 'chemdraw' } });
      });
    this.routeStateService.loadMarvinFromChemdraw
      .pipe(
        takeUntil(this.unsubscriberSubject),
        filter((loadMarvinFromChemdraw: boolean) => loadMarvinFromChemdraw === true),
      )
      .subscribe(() => {
        this.router.navigate(['home/new'], { queryParams: { source: 'chemdraw' } });
      });
    this.routeStateService.planSubscriptionStatus
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((planSubscriptionStatus) => {
        if (planSubscriptionStatus) {
          this.routeStateService.planSubscriptionStatus.next('');
          this.router.navigate(['/subscription-plans'], {
            queryParams: { success: planSubscriptionStatus },
          });
        }
      });
    this.router.events.pipe(takeUntil(this.unsubscriberSubject)).subscribe((routerEvent) => {
      if (
        this.location.path().startsWith('/information') ||
        this.location.path().startsWith('/tech-support') ||
        this.location.path().startsWith('/terms')
      ) {
        this.showForUnauthorized = true;
      } else {
        this.showForUnauthorized = false;
      }
      if (routerEvent instanceof ActivationStart) {
        if (this.location.path() === '') {
          this.showCustomerPolicyStatement();
        }
      }
      if (routerEvent instanceof ActivationEnd) {
        if (
          this.location.path() !== '' &&
          !this.location.path().startsWith('/sign-in') &&
          !this.location.path().startsWith('/sign-out') &&
          !this.location.path().startsWith('/compliance-statement') &&
          !this.location.path().startsWith('/tech-support') &&
          !this.location.path().startsWith('/information') &&
          !this.location.path().startsWith('/registration')
        ) {
          this.showCustomerPolicyStatement(this.location.path());
        }
      }
    });

    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((config: MidendConfiguration) => {
        if (config) {
          this.adminDefaultLandingPageUrl = config.DEFAULT_LANDING_PAGE_URL
            ? config.DEFAULT_LANDING_PAGE_URL
            : '';
          this.enableCustomerPolicyAcceptance = !!config.ENABLE_CUSTOMER_POLICY_ACCEPTANCE;
        }
      });
  }

  public ngOnInit() {
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.authorizationService
      .isAuthorized()
      .pipe(
        takeUntil(this.unsubscriberSubject),
        flatMap((authorized: boolean) => {
          this.isLoading = true;
          this.isAuthorized = authorized;
          this.changeDetector.detectChanges();
          if (authorized) {
            return this.authorizationService.getUserInformation();
          }
          return of(null);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((user: UserEntry) => {
        this.isLoading = false;
        if (user) {
          if (
            !!this.appStateService.midendConfiguration.value &&
            this.appStateService.midendConfiguration.value.PENDO_ANALYTICS_ENABLED
          ) {
            this.pendoAnalyticsService.initialize();
          }
          this.setLandingPageUrl();
          if (this.authorizationService.isCustomerPolicyAccepted(user)) {
            this.getTokens();
            this.getMidendDatasets();
          }
        }
      });

    this.authorizationService
      .userMustChangePassword()
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((mustChangePassword) => {
        this.mustChangePassword = mustChangePassword;
        this.changeDetector.detectChanges();
      });

    this.authorizationService.mustChangePasswordCompleted
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isCompleted: boolean) => {
        if (isCompleted) {
          this.getTokens();
          this.getMidendDatasets();
        }
      });

    this.authorizationService.isComplianceStatementAccepted
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAccepted) => {
        if (isAccepted) {
          this.getTokens();
          this.getMidendDatasets();
        }
      });

    this.emdHandlerService.isPlanSubscribed
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isSubscribed: boolean) => {
        if (isSubscribed) {
          this.getTokens();
          this.getMidendDatasets();
        }
      });
    this.startAnnouncementPolling();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe((route) => {
        if (route['url']) {
          if (route['url'] === '/sign-in' && !this.isSynthiaLiteEnabled) {
            this.showLoader = true;
          } else {
            this.showLoader = false;
          }
        } else {
          this.showLoader = false;
        }
      });

    this.authorizationService.authorized
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((isAuthorized) => {
        this.isAuthorized = isAuthorized;
      });
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
  }

  public getAndDisplayAnnouncement() {
    this.authorizationService
      .getAnnouncements()
      .pipe(
        flatMap((announcement: { message: string }) => {
          if (
            !!announcement &&
            announcement.message &&
            announcement.message !== sessionStorage.getItem('announcement')
          ) {
            const config: MatDialogConfig = {
              data: {
                message: announcement.message,
              },
              hasBackdrop: false,
              position: {
                top: '32px',
              },
              maxWidth: '1200px',
              width: '90%',
              disableClose: true,
              panelClass: 'ch-site-notification',
            };
            if (this.matDialogRef) {
              this.matDialogRef.close();
            }
            this.matDialogRef = this.matDialog.open(this.siteAnnouncementTemplate, config);
            return this.matDialogRef.beforeClosed();
          }
          return of('');
        }),
      )
      .subscribe((gotIt: string) => {
        if (gotIt) {
          sessionStorage.setItem('announcement', gotIt);
        }
      });
  }

  public getTokens() {
    this.isLoading = true;
    this.credentialsService
      .getAnalysisPermission()
      .pipe(
        takeUntil(this.unsubscriberSubject),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((isAuthorizedToStartAnalysis: boolean) => {
        this.analysisService.isAuthorizedForRunAnalysis.next(isAuthorizedToStartAnalysis);
        this.isLoading = false;
      });
  }

  public isNavBarEnabled() {
    return (
      (this.isAuthorized || this.showForUnauthorized) &&
      (!this.mustChangePassword || (this.mustChangePassword && this.showForUnauthorized)) &&
      !this.isECommAuthorizedUrl() &&
      !this.isAuthorizedUrl()
    );
  }

  public isECommAuthorizedUrl() {
    return this.router.url.includes('/ecomm-authorized');
  }

  public splitDashboardUrl(url: string) {
    const urlTree: UrlTree = this.router.parseUrl(url);
    const queryParams = urlTree.queryParams;
    const segmentGroup: UrlSegmentGroup = urlTree.root.children['primary'];
    const treeSegments: UrlSegment[] = segmentGroup.segments;
    let routeSegments = treeSegments.map((segment) => {
      return segment.path;
    });

    if (routeSegments.length !== 4) {
      routeSegments = ['dashboard', 'analyses', 'all', '1'];
    }
    return { routeSegments, queryParams };
  }

  public setLandingPageUrl() {
    this.userDashboardUrl = this.adminDefaultLandingPageUrl;
    this.routeStateService.landingPageUrl.next(this.userDashboardUrl);
  }

  private startAnnouncementPolling() {
    const pollInterval: Observable<number> = timer(100, ANNOUNCEMENT_POLLING_INTERVAL);
    pollInterval.subscribe(() => this.getAndDisplayAnnouncement());
  }

  private showCustomerPolicyStatement(nextUrl?: string) {
    if (this.authorizationService.authorized.getValue() && !this.mustChangePassword) {
      if (!this.authorizationService.userInformation.value.id) {
        this.authorizationService
          .getUserInformation()
          .pipe(takeUntil(this.unsubscriberSubject))
          .subscribe((user: UserEntry) => {
            if (!this.authorizationService.isCustomerPolicyAccepted(user)) {
              this.authorizationService.navigateToCustomerPolicy(nextUrl);
            }
          });
      } else if (
        !this.authorizationService.isCustomerPolicyAccepted(
          this.authorizationService.userInformation.value,
        )
      ) {
        setTimeout(() => {
          this.authorizationService.navigateToCustomerPolicy(nextUrl);
        }, 10);
      }
    }
  }

  private isAuthorizedUrl() {
    return this.router.url.includes('/user-authorized');
  }

  private getMidendDatasets() {
    this.appStateService.getPictogramData().subscribe();
    this.stashService.getRulesDataset().subscribe();
    this.stashService.getReactionDataset().subscribe();
  }
}
