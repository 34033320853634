import { isNullOrUndefined, isString } from '../../../components/utils';
import { AngleStrain } from './angle-strain';
import { BondStrain } from './bond-strain';
import { DihedralStrain } from './dihedral-strain';
import { StrainConformer } from './strain-conformer';

/*
 Wrapps a molecule strain report from v1/molecular-strain/report entry point on backend.
 */
export class MolecularStrainReport {
  /* tslint:disable:variable-name */
  public warnings: string = '';
  public error: string = '';
  public strain_problem_detected: boolean = false;
  public minimum_energy_conformer_image: string = '';
  public numbered_structure_image: string = '';
  public conformers: StrainConformer[] = [];
  public dihedrals_strain: DihedralStrain[] = [];
  public angles_strain: AngleStrain[] = [];
  public bonds_strain: BondStrain[] = [];
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        switch (field) {
          case 'conformers':
            for (const itemData of jsonData[field]) {
              this[field].push(new StrainConformer(itemData));
            }
            break;
          case 'angles_strain':
            for (const itemData of jsonData[field]) {
              this[field].push(new AngleStrain(itemData));
            }
            break;
          case 'bonds_strain':
            for (const itemData of jsonData[field]) {
              this[field].push(new BondStrain(itemData));
            }
            break;
          case 'dihedrals_strain':
            for (const itemData of jsonData[field]) {
              this[field].push(new DihedralStrain(itemData));
            }
            break;
          case 'minimum_energy_conformer_image':
          case 'numbered_structure_image':
            this[field] = !isNullOrUndefined(jsonData[field])
              ? APP_CONFIG.CHEMATICA_API_URL + jsonData[field]
              : jsonData[field];
            break;
          default:
            this[field] = jsonData[field];
        }
      } else {
        console.error(`Unexpeced field '${field}' in data provided to MolecularStrainReport:ctor.`);
      }
    }
  }
}
