import { CountryDetails } from './country-details';
import { isString } from '../../../components/utils';

export class CompanyDetails {
  public id: number = 0;
  public name: string = '';
  public country_details: CountryDetails = null;
  public address_line_1: string = '';
  public address_line_2: string = '';
  public locality: string = '';
  public state: string = '';
  public postal_code: string = '';

  constructor(data?: any) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (field === 'country_details' && jsonData[field] !== null) {
          try {
            this[field] = new CountryDetails(jsonData[field]);
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else {
          if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
            this[field] = jsonData[field];
          } else {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to CompanyDetails model.`);
      }
    }
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to CompanyDetails model.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
