import { isString } from '../../../components/utils';

export class AssociatedUser {
  /* tslint:disable:variable-name */
  public id: number = 0;
  public first_name: string = '';
  public last_name: string = '';
  public email: string = '';
  /* tslint:enable:variable-name */

  constructor(data?) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (typeof jsonData[field] === typeof this[field]) {
          this[field] = jsonData[field];
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to AssociatedUser.`);
      }
    }
  }

  public getFullName(): string {
    const hasName: boolean = this.first_name.length + this.last_name.length > 0;
    if (hasName) {
      return `${this.first_name} ${this.last_name}`;
    }
    return null;
  }

  public getEmailUsername(): string {
    return this.email.split('@')[0];
  }
}
