import { isString } from '../../../components/utils';
import { GraphEdge, GraphMoleculeNode, GraphReactionNode } from '../../graph-builder';
import { PathwayBuildingBlocks } from './pathway-building-blocks';
import { TargetSubstrate } from './target-substrate';

interface BuildingBlock {
  main_retron_disconnections: number[][];
  mapped_reaction_smiles: string[];
  reaction_name_svg_class_name_map: any;
  substrates: Substrates[];
  target_svg: string;
  target_svgs: TargetSubstrate;
}

export interface Substrates {
  molecule_id: string;
  svg: string;
  smiles: string;
  is_diversifiable?: boolean;
}
export class GraphSyntheticPathEntry {
  private static dateFields = new Set(['created_at', 'updated_at']);

  /* tslint:disable:variable-name */
  public created_at?: Date = null;
  public id?: number = 0;
  public reaction_nodes?: number[] = [];
  public score?: number = 0;
  public target_score?: string = '';
  public updated_at?: Date = null;
  public graph?: number = 0;
  public analysis?: number = 0;
  public computation?: number = 0;
  public path_hash?: number = 0;
  public rank?: number = 0;
  public is_favorite?: boolean = false;
  public building_blocks?: PathwayBuildingBlocks | BuildingBlock;
  public pathway_rank?: number | null;
  public reactions_to_diversify?: string[] = [];
  public nodes?: GraphMoleculeNode[] & GraphReactionNode[] = [];
  public edges?: GraphEdge[] = [];
  public target_name?: string = '';
  public syntheticPath?: number = 0;
  public set_rank?: number = 0;
  public isDisplayed?: boolean = false;
  public toDisplay?: boolean[];
  public isFavorite?: boolean = false;
  public pathIndex?: number = 0;
  public repeated_intermediates?: number;
  public imagePath?: boolean;
  public query_target_similarity?: number | null;
  public targetSVG?: string = '';
  public is_diversifiable?: boolean = true;
  public cost?: string;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    if (!Object.prototype.hasOwnProperty.call(jsonData, 'is_diversifiable')) {
      jsonData.is_diversifiable = this.is_diversifiable;
    }
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (GraphSyntheticPathEntry.dateFields.has(field)) {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else {
          if (typeof jsonData[field] === typeof this[field]) {
            this[field] = jsonData[field];
          } else {
            console.error(
              `Unexpected type of field '${field}' in data provided to GraphSyntheticPathEntry:ctor.` +
                `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
            );
          }
          switch (field) {
            case 'score':
            case 'graph':
              if (jsonData[field] === null) {
                // Use null instead of default value because
                // null define that this attribute is empty and
                // not a 0 as we take info from the midend
                // side. Maybe it will be changed to more robust data structure.
                this[field] = null;
              }
              break;
            case 'query_target_similarity':
              if (jsonData[field] === null) {
                this[field] = 0;
              }
              break;
          }
        }
      } else if (field === 'building_blocks') {
        this.building_blocks = jsonData['building_blocks'];
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
