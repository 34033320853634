import { isString } from '../../../components/utils';

export class TargetSetDetailsEntry {
  private static dateFields = new Set(['created_at', 'updated_at']);

  /* tslint:disable:variable-name */
  public repeated_intermediates: number = 0;
  public shared_molecule_info: number[] = [];
  public shared_reaction_info: number[] = [];
  public target_set_hash: number = 0;
  public unique_reactions: number = 0;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    /* tslint:disable:no-switch-case-fall-through */
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (TargetSetDetailsEntry.dateFields.has(field)) {
          if (jsonData[field] !== null) {
            this[field] = new Date(jsonData[field]);
          }
        } else {
          switch (field) {
            case 'score':
            case 'graph':
              if (jsonData[field] === null) {
                // Use null instead of default value because
                // null define that this attribute is empty and
                // not a 0 as we take info from the midend
                // side. Maybe it will be changed to more robust data structure.
                this[field] = null;
                break;
              }
            default:
              if (typeof jsonData[field] === typeof this[field]) {
                this[field] = jsonData[field];
              } else {
                console.error(
                  `Unexpected type of field '${field}' in data provided to TargetSetDetailsEntry:ctor.` +
                    `\nUsed default value "${this[field]}" instead of provided "${jsonData[field]}"`,
                );
              }
          }
        }
      } else {
        console.error(
          `Unexpected field '${field}' in data provided to TargetSetDetailsEntry:ctor.`,
        );
      }
    }
    /* tslint:enable:no-switch-case-fall-through */
  }
}
