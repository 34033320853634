import { isString } from '../../../components/utils';

const ALLOWED_DEFAULT_NAMES = {
  Basic: true,
  'Large molecule': true,
  'Low protection': true,
  'Avoid protection': true,
  'EXAMPLE: avoid benzene and aniline': true,
  'EXAMPLE: promote Angew. papers': true,
  'EXAMPLE: use 2-Clpyridine': true,
  'EXAMPLE: avoid Pd and Ru': true,
  'EXAMPLE: avoid bromobenzene': true,
  'Cut-In-Half': true,
  'Cut-In-Half strong': true,
  'Cut-In-Half with Rings': true,
  'Cut-In-Half with Rings and Stereo': true,
  'Distance to target': true,
  'Small steps': true,
  'Publication year': true,
  'Reaction name': true,
  'Number of reactants': true,
  'Create Stereocenters': true,
  'Buyable/Known wo Unknown': true,
  'Cut in Half': true,
  'Create Rings': true,
  'Use Buyable and Known': true,
  'Small Molecule': true,
  'New Search Wide': true,
  Combo: true,
  'Search Deep': true,
  'Price equalizer': true,
};

export class ScoringFunction {
  private static categories = new Set(['MOLECULE_SCORE', 'REACTION_SCORE', 'REACTION_ORDER']);

  /* tslint:disable:variable-name */
  public id: number = 0;
  public name: string = '';
  public category: string = '';
  public rpn_source_code: string = '';
  public source_code: string = '';
  public owner: number | null = null;
  /* tslint:enable:variable-name */

  constructor(data) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (this.isValidType(jsonData[field], field)) {
          this[field] = jsonData[field];
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to ScoringFunctions:ctor.`);
      }
    }

    this.checkDefaultFunction();
  }

  private isValidType(jsonDataField: any, field: any) {
    if (typeof jsonDataField === typeof this[field]) {
      return true;
    } else if (field === 'owner') {
      return typeof jsonDataField === 'number';
    } else if (field === 'category') {
      if (ScoringFunction.categories.has(jsonDataField[field])) {
        this[field] = jsonDataField[field];
      } else {
        console.error(
          `Unexpected value of field 'category' in data provided to ScoringFunctions:ctor` +
            `\nUsed default value "${this[field]}" instead of provided "${jsonDataField[field]}"`,
        );
      }
    } else {
      console.error(
        `Unexpected type of field '${field}' in data provided to ScoringFunctions:ctor.` +
          `\nUsed default value "${this[field]}" instead of provided "${jsonDataField[field]}"`,
        jsonDataField,
      );
      return false;
    }
  }

  private checkDefaultFunction() {
    if (this.owner === null && !(this.name in ALLOWED_DEFAULT_NAMES)) {
      console.error(
        `Unexpected name of predefined function '${this.name}' in data provided to ScoringFunctions:ctor.`,
      );
    }
  }
}
