import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { delay, takeUntil, filter } from 'rxjs/operators';
import {
  AnalysisResultsService,
  AnalysisService,
  AppConstantsService,
  DisplayType,
  MoleculeSearchService,
} from 'src/app/shared/services';
import {
  filterTypes,
  IFilterCriteria,
  ResultsFilterMoleculeEditorComponent,
} from './../results-filter-molecule-editor/results-filter-molecule-editor.component';
import {
  EditStructureFilter,
  IReactionFilter,
  IRemoveReactionFilter,
  RemoveStructuralFilter,
  StructureCategory,
  StructureFilters,
  StructureFilterView,
  StructureSectionCategory,
  SVGSmilesSimilarity,
  IReactionData,
  KeywordsFilter,
  KeywordsData,
  Keywords,
  EditKeywordFilter,
  IReactions,
} from './results-default-filter.models';
import { ResultsFilterModels } from '../results-filter-base/results-filter-models';
import { RDKitModule } from '@rdkit/rdkit/dist';

enum parentClassEnum {
  StructureDraw = 'StructureDraw',
  StructureEdit = 'StructureEdit',
}
export interface FilterEditorDialogData {
  isAuthorizedToStartAnalysis: boolean;
  elementType: string;
  inputMode: string;
  parentClass: parentClassEnum;
}
@Component({
  selector: 'ch-results-filter-default',
  templateUrl: './results-filter-default.component.html',
  styleUrls: ['./results-filter-default.component.scss'],
})
export class ResultsFilterDefaultComponent implements OnInit, OnDestroy {
  @Output() public structureFilterEmitter: EventEmitter<StructureFilters> =
    new EventEmitter<StructureFilters>();
  @Output() public diversityFilterEmitter: EventEmitter<StructureFilters> =
    new EventEmitter<StructureFilters>();
  @Output() public keywordFilter: EventEmitter<KeywordsFilter> = new EventEmitter<KeywordsFilter>();
  @Output() public reactionFilter: EventEmitter<IReactionFilter> =
    new EventEmitter<IReactionFilter>();
  public moleculeEditorDialogRef: MatDialogRef<ResultsFilterMoleculeEditorComponent>;
  public similarity: number;
  public structureFilters: StructureFilters;
  public diversityFilters: StructureFilters;
  public keywordFiltersList: KeywordsFilter;
  public reactionFiltersList: IReactionFilter;
  public structureFilterView: StructureFilterView;
  public diversityFilterView: StructureFilterView;
  public isReactionFilters: boolean;
  public isDefaultFiltersLoad: boolean;
  public unSubscriberSubject: Subject<void> = new Subject();
  public enableMaxStructureFiltersWarning: boolean = false;
  public enableMaxDiversityFiltersWarning: boolean = false;
  public fromDiversityMoleculeCard: boolean = true;
  public diversityResultView: boolean = false;
  public filterDataSmiles: SafeResourceUrl | string;
  private rdkit?: RDKitModule;

  constructor(
    public dialog: MatDialog,
    public appConstantsService: AppConstantsService,
    public analysisService: AnalysisService,
    private analysisResultsService: AnalysisResultsService,
    private moleculeSearchService: MoleculeSearchService,
  ) {
    this.moleculeSearchService.getRdkitObject().subscribe((rdkit: RDKitModule) => {
      this.rdkit = rdkit;
    });
  }

  public ngOnDestroy(): void {
    this.unSubscriberSubject.next();
    this.unSubscriberSubject.complete();
  }

  public ngOnInit() {
    this.initDefaultFilters();
    this.analysisResultsService.resultsFilterSubject
      .pipe(
        takeUntil(this.unSubscriberSubject),
        filter((resultFilters: ResultsFilterModels) => {
          if (!this.isDefaultFiltersLoad) {
            return resultFilters !== null;
          }
          return false;
        }),
      )
      .subscribe((resultFilters: ResultsFilterModels) => {
        if (resultFilters) {
          this.isDefaultFiltersLoad = true;
          if (resultFilters.structureFilter) {
            this.structureFilters = resultFilters.structureFilter;
            this.enableMaxStructureFiltersWarning = this.enableWarningForStructureFilters();
            this.updateSVGOnLoad();
          }
          if (resultFilters.diversityFilter) {
            this.diversityFilters = resultFilters.diversityFilter;
            this.enableMaxDiversityFiltersWarning = this.enableWarningForDiversityFilters();
            this.updateSVGOnDiversityLoad();
          }
          if (resultFilters.keywordFilter) {
            this.keywordFiltersList = resultFilters.keywordFilter;
          }
          if (resultFilters.reactionFilter) {
            this.reactionFiltersList = resultFilters.reactionFilter;
            this.isReactionFilters = true;
          }
        }
      });
    this.analysisResultsService.resetFilterSubject
      .pipe(delay(0), takeUntil(this.unSubscriberSubject))
      .subscribe((value: boolean) => {
        if (value) {
          this.enableMaxStructureFiltersWarning = false;
          this.enableMaxDiversityFiltersWarning = false;
          this.initStructureView();
          this.initStructureFilters();
          this.initDiversityView();
          this.initDiversityFilters();
          this.initKeywordFilters();
          this.initReactionFilters();
          this.isReactionFilters = false;
        }
      });
    this.analysisResultsService.addStructureFilterFromPopup
      .pipe(takeUntil(this.unSubscriberSubject))
      .subscribe((filterCriteria: IFilterCriteria) => {
        if (filterCriteria) {
          if (!this.structureFilters) {
            this.initStructureFilters();
          }
          this.populateStructureFilter(filterCriteria);
        }
      });

    this.analysisResultsService.addDiversityFilterFromPopup
      .pipe(takeUntil(this.unSubscriberSubject))
      .subscribe((filterCriteria: IFilterCriteria) => {
        if (filterCriteria) {
          if (!this.diversityFilters) {
            this.initDiversityFilters();
          }
          this.populateDiversityFilter(filterCriteria);
        }
      });

    this.analysisResultsService.addReactionFilterFromPopup
      .pipe(takeUntil(this.unSubscriberSubject))
      .subscribe((reactionFilter: IReactionFilter) => {
        if (reactionFilter) {
          if (!this.reactionFiltersList) {
            this.initReactionFilters();
          }
          this.isReactionFilters = true;
          this.saveReactionFilter(reactionFilter);
        }
      });

    this.analysisService.isDiversityResultView
      .pipe(takeUntil(this.unSubscriberSubject))
      .subscribe((diversityView: boolean) => {
        this.diversityResultView = diversityView;
      });
  }

  public initDefaultFilters() {
    this.isDefaultFiltersLoad = false;
    this.isReactionFilters = false;
    this.initStructureView();
    this.initStructureFilters();
    this.initDiversityView();
    this.initDiversityFilters();
    this.initReactionFilters();
    this.initKeywordFilters();
  }

  public initKeywordFilters() {
    this.keywordFiltersList = {
      limitTo: {
        keywords: [],
      },
      exclude: {
        keywords: [],
      },
    };
  }

  public initStructureFilters() {
    const createCategory = (): StructureCategory => ({
      name: '',
      criteria: [],
    });
    const limitTo: StructureSectionCategory = {
      exact: createCategory(),
      similar: createCategory(),
      structure: createCategory(),
      name: this.appConstantsService.limitTo,
    };
    const exclude: StructureSectionCategory = {
      exact: createCategory(),
      similar: createCategory(),
      structure: createCategory(),
      name: this.appConstantsService.exclude,
    };
    this.structureFilters = {
      limitTo: limitTo,
      exclude: exclude,
    };
  }

  public initDiversityFilters() {
    const createCategory = (): StructureCategory => ({
      name: '',
      criteria: [],
    });
    const limitTo: StructureSectionCategory = {
      exact: createCategory(),
      similar: createCategory(),
      structure: createCategory(),
      name: this.appConstantsService.limitTo,
    };
    const exclude: StructureSectionCategory = {
      exact: createCategory(),
      similar: createCategory(),
      structure: createCategory(),
      name: this.appConstantsService.exclude,
    };
    this.diversityFilters = {
      limitTo: limitTo,
      exclude: exclude,
    };
  }

  public initStructureView() {
    this.structureFilterView = {
      isLimitToSection: false,
      isExcludeSection: false,
    };
  }

  public initDiversityView() {
    this.diversityFilterView = {
      isLimitToSection: false,
      isExcludeSection: false,
    };
  }

  public initReactionFilters() {
    this.reactionFiltersList = {
      limitTo: {
        reactions: [],
      },
      exclude: {
        reactions: [],
      },
      lastDisconnected: {
        limitTo: {
          reactions: [],
        },
        exclude: {
          reactions: [],
        },
      },
    };
  }

  public addFilterForStructure() {
    const data = {
      isAuthorizedToStartAnalysis: true,
      elementType: 'SMARTS',
      inputMode: 'FILTER',
      parentClass: parentClassEnum.StructureDraw,
    };
    this.setupFilterEditorDialog(data);
    this.getSmilesToFilter();
  }

  public setupFilterEditorDialog(data: FilterEditorDialogData) {
    const config: MatDialogConfig = {
      disableClose: false,
      width: '900px',
      height: '80%',
      panelClass: 'filter-editor-molecule',
    };
    config.data = data;
    this.moleculeEditorDialogRef = this.dialog.open(ResultsFilterMoleculeEditorComponent, config);
  }

  public getSmilesToFilter() {
    this.moleculeEditorDialogRef
      .afterClosed()

      .subscribe((filterData: IFilterCriteria) => {
        if (filterData && filterData.smiles) {
          this.analysisResultsService.resultsLoadingIndicator.next(true);
          if (!this.structureFilters) {
            this.initStructureFilters();
          }
          this.populateStructureFilter(filterData);
        }
        setTimeout(() => {
          this.analysisResultsService.resultsLoadingIndicator.next(false);
        }, 500);
      });
  }

  public getEditedSmilesToFilter(editFilter: EditStructureFilter, isDiversity: boolean) {
    this.moleculeEditorDialogRef
      .beforeClosed()

      .subscribe((filterData: IFilterCriteria) => {
        if (filterData) {
          this.analysisResultsService.resultsLoadingIndicator.next(true);
          const removeFilter: RemoveStructuralFilter = {
            index: editFilter.index,
            categoryName: editFilter.categoryName,
            sectionName: editFilter.sectionName,
          };
          if (isDiversity) {
            this.removeDiversityFilter(removeFilter);
            if (filterData.smiles && !filterData.isFilterToBeRemoved) {
              this.populateDiversityFilter(filterData);
            }
          } else {
            this.removeStructuralFilter(removeFilter);
            if (filterData.smiles && !filterData.isFilterToBeRemoved) {
              this.populateStructureFilter(filterData);
            }
          }
          setTimeout(() => {
            this.analysisResultsService.resultsLoadingIndicator.next(false);
          }, 500);
        }
      });
  }

  public removeStructuralFilter(event: RemoveStructuralFilter) {
    const section = this.structureFilters[event.sectionName] as StructureSectionCategory;
    const category = section[event.categoryName] as StructureCategory;
    if (category.criteria && Array.isArray(category.criteria)) {
      category.criteria.splice(event.index, 1);
      if (category.criteria.length === 0) {
        category.name = '';
      }
    }
    this.isSectionEmpty(event.sectionName);
    this.emitStructureFilter();
    this.enableMaxStructureFiltersWarning = this.enableWarningForStructureFilters();
  }

  public removeDiversityFilter(event: RemoveStructuralFilter) {
    const section = this.diversityFilters[event.sectionName] as StructureSectionCategory;
    const category = section[event.categoryName] as StructureCategory;
    if (category.criteria && Array.isArray(category.criteria)) {
      category.criteria.splice(event.index, 1);
      if (category.criteria.length === 0) {
        category.name = '';
      }
    }
    this.isDiversitySectionEmpty(event.sectionName);
    this.emitDiversityFilter();
    this.enableMaxDiversityFiltersWarning = this.enableWarningForStructureFilters();
  }

  public editStructureFilter(editFilter: EditStructureFilter, isDiversity: boolean = false) {
    const data = {
      isAuthorizedToStartAnalysis: true,
      elementType: 'SMARTS',
      inputMode: 'EDIT FILTER',
      inputData: [editFilter],
      parentClass: parentClassEnum.StructureEdit,
    };
    this.setupFilterEditorDialog(data);
    this.getEditedSmilesToFilter(editFilter, isDiversity);
  }

  public saveKeyword(keywordDetails: KeywordsData) {
    const section =
      keywordDetails.sectionName === this.appConstantsService.limitTo
        ? this.appConstantsService.filterSection.limitTo
        : this.appConstantsService.filterSection.exclude;
    const keywordsInSection = this.keywordFiltersList[section] as Keywords;
    const index = keywordsInSection.keywords.findIndex((value: string) => {
      return value === keywordDetails.keyword;
    });
    if (index === -1) {
      keywordsInSection.keywords.push(keywordDetails.keyword);
      this.keywordFilter.emit(this.keywordFiltersList);
    }
  }

  public removeKeyword(keywordDetails: KeywordsData) {
    const keywordsInSection = this.keywordFiltersList[keywordDetails.sectionName] as Keywords;
    keywordsInSection.keywords.splice(keywordDetails.keywordIndex, 1);
    this.keywordFilter.emit(this.keywordFiltersList);
  }

  public editKeyword(editKeywordFilter: EditKeywordFilter) {
    const keywordsInSection = this.keywordFiltersList[editKeywordFilter.sectionName] as Keywords;
    keywordsInSection.keywords[editKeywordFilter.index] = editKeywordFilter.keyword;
    if (editKeywordFilter.isKeywordRemoved) {
      keywordsInSection.keywords.splice(editKeywordFilter.index, 1);
    }
    this.keywordFilter.emit(this.keywordFiltersList);
  }

  public saveReactionFilter(reactionFilter: IReactionFilter) {
    if (reactionFilter) {
      if (reactionFilter.lastDisconnected) {
        const { lastDisconnected } = reactionFilter;
        const { limitTo, exclude } = lastDisconnected;
        if (limitTo && limitTo.reactions.length) {
          for (const reaction of limitTo.reactions) {
            if (!this.duplicateReactionFilters(reaction, 'limitTo', true)) {
              this.reactionFiltersList.lastDisconnected.limitTo.reactions.push(reaction);
            }
          }
        } else if (exclude && exclude.reactions.length) {
          for (const reaction of exclude.reactions) {
            if (!this.duplicateReactionFilters(reaction, 'exclude', true)) {
              this.reactionFiltersList.lastDisconnected.exclude.reactions.push(reaction);
            }
          }
        }
      }
      if (reactionFilter.limitTo && reactionFilter.limitTo.reactions.length) {
        for (const reaction of reactionFilter.limitTo.reactions) {
          if (!this.duplicateReactionFilters(reaction, 'limitTo', false)) {
            this.reactionFiltersList.limitTo.reactions.push(reaction);
          }
        }
      } else if (reactionFilter.exclude && reactionFilter.exclude.reactions.length) {
        for (const reaction of reactionFilter.exclude.reactions) {
          if (!this.duplicateReactionFilters(reaction, 'exclude', false)) {
            this.reactionFiltersList.exclude.reactions.push(reaction);
          }
        }
      }
    }
    this.reactionFilter.emit(this.reactionFiltersList);
    this.analysisResultsService.addReactionFilterFromPopup.next(null);
  }

  public removeReactionFilter(removeReactionFilter: IRemoveReactionFilter) {
    if (removeReactionFilter.isDisconnected) {
      const { reactions } = this.reactionFiltersList.lastDisconnected[
        removeReactionFilter.sectionName
      ] as IReactions;
      reactions.splice(removeReactionFilter.index, 1);
    } else {
      const { reactions } = this.reactionFiltersList[
        removeReactionFilter.sectionName
      ] as IReactions;
      reactions.splice(removeReactionFilter.index, 1);
    }
    this.isReactionFilters = !this.isReactionFilterEmpty();
    this.reactionFilter.emit(this.reactionFiltersList);
  }

  private isSectionEmpty(name: string) {
    const section = this.structureFilters[name] as StructureSectionCategory;
    if (
      section.exact.criteria.length === 0 &&
      section.structure.criteria.length === 0 &&
      section.similar.criteria.length === 0
    ) {
      name === 'limitTo'
        ? (this.structureFilterView.isLimitToSection = false)
        : (this.structureFilterView.isExcludeSection = false);
    }
  }

  private isDiversitySectionEmpty(name: string) {
    const section = this.diversityFilters[name] as StructureSectionCategory;
    if (
      section.exact.criteria.length === 0 &&
      section.structure.criteria.length === 0 &&
      section.similar.criteria.length === 0
    ) {
      name === 'limitTo'
        ? (this.diversityFilterView.isLimitToSection = false)
        : (this.diversityFilterView.isExcludeSection = false);
    }
  }

  private populateStructureFilter(filterData: IFilterCriteria) {
    if (filterData.filterType === filterTypes.Structure) {
      this.filterDataSmiles = this.drawSvgWithRdkit(filterData.smarts, DisplayType.Substructure);
    } else {
      this.filterDataSmiles = this.drawSvgWithRdkit(filterData.smiles);
    }
    if (filterData.showCategory.toLowerCase() === this.appConstantsService.limitTo.toLowerCase()) {
      this.showLimitTo(filterData, this.filterDataSmiles);
    } else {
      this.showExclude(filterData, this.filterDataSmiles);
    }
    this.enableMaxStructureFiltersWarning = this.enableWarningForStructureFilters();
    this.analysisResultsService.addStructureFilterFromPopup.next(null);
  }

  private populateDiversityFilter(filterData: IFilterCriteria) {
    if (filterData.filterType === filterTypes.Structure) {
      this.filterDataSmiles = this.drawSvgWithRdkit(filterData.smarts, DisplayType.Substructure);
    } else {
      this.filterDataSmiles = this.drawSvgWithRdkit(filterData.smiles);
    }
    if (filterData.showCategory.toLowerCase() === this.appConstantsService.limitTo.toLowerCase()) {
      this.showDiversityLimitTo(filterData, this.filterDataSmiles);
    } else {
      this.showDiversityExclude(filterData, this.filterDataSmiles);
    }
    this.enableMaxDiversityFiltersWarning = this.enableWarningForDiversityFilters();
    this.analysisResultsService.addDiversityFilterFromPopup.next(null);
  }

  private enableWarningForStructureFilters() {
    const { limitTo, exclude } = this.structureFilters;
    let noOfFilters: number = 0;
    if (limitTo) {
      if (limitTo.exact && limitTo.exact.criteria) {
        noOfFilters += limitTo.exact.criteria.length;
      }
      if (limitTo.similar && limitTo.similar.criteria) {
        noOfFilters += limitTo.similar.criteria.length;
      }
      if (limitTo.structure && limitTo.structure.criteria) {
        noOfFilters += limitTo.structure.criteria.length;
      }
    }
    if (exclude) {
      if (exclude.exact && exclude.exact.criteria) {
        noOfFilters += exclude.exact.criteria.length;
      }
      if (exclude.similar && exclude.similar.criteria) {
        noOfFilters += exclude.similar.criteria.length;
      }
      if (exclude.structure && exclude.structure.criteria) {
        noOfFilters += exclude.structure.criteria.length;
      }
    }
    return noOfFilters > 3;
  }

  private enableWarningForDiversityFilters() {
    const { limitTo, exclude } = this.diversityFilters;
    let noOfFilters: number = 0;
    if (limitTo) {
      if (limitTo.exact && limitTo.exact.criteria) {
        noOfFilters += limitTo.exact.criteria.length;
      }
      if (limitTo.similar && limitTo.similar.criteria) {
        noOfFilters += limitTo.similar.criteria.length;
      }
      if (limitTo.structure && limitTo.structure.criteria) {
        noOfFilters += limitTo.structure.criteria.length;
      }
    }
    if (exclude) {
      if (exclude.exact && exclude.exact.criteria) {
        noOfFilters += exclude.exact.criteria.length;
      }
      if (exclude.similar && exclude.similar.criteria) {
        noOfFilters += exclude.similar.criteria.length;
      }
      if (exclude.structure && exclude.structure.criteria) {
        noOfFilters += exclude.structure.criteria.length;
      }
    }
    return noOfFilters > 3;
  }

  private showLimitTo(filterData: IFilterCriteria, svg: SafeResourceUrl) {
    this.structureFilterView.isLimitToSection = true;
    const svgSmilesSimilarity: SVGSmilesSimilarity = {
      svg,
      smiles: filterData.smiles,
      smarts: filterData.smarts,
      similarity: filterData.similarity,
    };
    const { limitTo } = this.structureFilters;
    if (filterData.filterType === filterTypes.Drawn) {
      const { exact } = limitTo;
      exact.name = 'Exact';
      if (!this.duplicateStructureFilters(exact, svgSmilesSimilarity)) {
        exact.criteria.push(svgSmilesSimilarity);
      }
      limitTo.exact = exact;
    } else if (filterData.filterType === filterTypes.Structure) {
      const { structure } = limitTo;
      structure.name = 'Substructure';
      if (!this.duplicateStructureFilters(structure, svgSmilesSimilarity)) {
        structure.criteria.push(svgSmilesSimilarity);
      }
      limitTo.structure = structure;
    } else if (filterData.filterType === filterTypes.Similar) {
      const { similar } = limitTo;
      similar.name = 'Similar';
      if (!this.duplicateStructureFilters(similar, svgSmilesSimilarity)) {
        similar.criteria.push(svgSmilesSimilarity);
      }
      limitTo.similar = similar;
    }
    this.structureFilters.limitTo = limitTo;
    this.emitStructureFilter();
  }

  private showExclude(filterData: IFilterCriteria, svg: SafeResourceUrl) {
    this.structureFilterView.isExcludeSection = true;
    const svgSmilesSimilarity: SVGSmilesSimilarity = {
      svg,
      smiles: filterData.smiles,
      smarts: filterData.smarts,
      similarity: filterData.similarity,
    };
    const { exclude } = this.structureFilters;
    if (filterData.filterType === filterTypes.Drawn) {
      const { exact } = exclude;
      exact.name = 'Exact';
      if (!this.duplicateStructureFilters(exact, svgSmilesSimilarity)) {
        exact.criteria.push(svgSmilesSimilarity);
      }
      exclude.exact = exact;
    } else if (filterData.filterType === filterTypes.Structure) {
      const { structure } = exclude;
      structure.name = 'Substructure';
      if (!this.duplicateStructureFilters(structure, svgSmilesSimilarity)) {
        structure.criteria.push(svgSmilesSimilarity);
      }
      exclude.structure = structure;
    } else if (filterData.filterType === filterTypes.Similar) {
      const { similar } = exclude;
      similar.name = 'Similar';
      if (!this.duplicateStructureFilters(similar, svgSmilesSimilarity)) {
        similar.criteria.push(svgSmilesSimilarity);
      }
      exclude.similar = similar;
    }
    this.structureFilters.exclude = exclude;
    this.emitStructureFilter();
  }

  private showDiversityLimitTo(filterData: IFilterCriteria, svg: SafeResourceUrl) {
    this.diversityFilterView.isLimitToSection = true;
    const svgSmilesSimilarity: SVGSmilesSimilarity = {
      svg,
      smiles: filterData.smiles,
      smarts: filterData.smarts,
      similarity: filterData.similarity,
    };
    const { limitTo } = this.diversityFilters;
    if (filterData.filterType === filterTypes.Drawn) {
      const { exact } = limitTo;
      exact.name = 'Exact';
      if (!this.duplicateDiversityFilters(exact, svgSmilesSimilarity)) {
        exact.criteria.push(svgSmilesSimilarity);
      }
      limitTo.exact = exact;
    } else if (filterData.filterType === filterTypes.Structure) {
      const { structure } = limitTo;
      structure.name = 'Substructure';
      if (!this.duplicateDiversityFilters(structure, svgSmilesSimilarity)) {
        structure.criteria.push(svgSmilesSimilarity);
      }
      limitTo.structure = structure;
    } else if (filterData.filterType === filterTypes.Similar) {
      const { similar } = limitTo;
      similar.name = 'Similar';
      if (!this.duplicateDiversityFilters(similar, svgSmilesSimilarity)) {
        similar.criteria.push(svgSmilesSimilarity);
      }
      limitTo.similar = similar;
    }
    this.diversityFilters.limitTo = limitTo;
    this.emitDiversityFilter();
  }

  private showDiversityExclude(filterData: IFilterCriteria, svg: SafeResourceUrl) {
    this.diversityFilterView.isExcludeSection = true;
    const svgSmilesSimilarity: SVGSmilesSimilarity = {
      svg,
      smiles: filterData.smiles,
      smarts: filterData.smarts,
      similarity: filterData.similarity,
    };
    const { exclude } = this.diversityFilters;
    if (filterData.filterType === filterTypes.Drawn) {
      const { exact } = exclude;
      exact.name = 'Exact';
      if (!this.duplicateDiversityFilters(exact, svgSmilesSimilarity)) {
        exact.criteria.push(svgSmilesSimilarity);
      }
      exclude.exact = exact;
    } else if (filterData.filterType === filterTypes.Structure) {
      const { structure } = exclude;
      structure.name = 'Substructure';
      if (!this.duplicateDiversityFilters(structure, svgSmilesSimilarity)) {
        structure.criteria.push(svgSmilesSimilarity);
      }
      exclude.structure = structure;
    } else if (filterData.filterType === filterTypes.Similar) {
      const { similar } = exclude;
      similar.name = 'Similar';
      if (!this.duplicateDiversityFilters(similar, svgSmilesSimilarity)) {
        similar.criteria.push(svgSmilesSimilarity);
      }
      exclude.similar = similar;
    }
    this.diversityFilters.exclude = exclude;
    this.emitDiversityFilter();
  }

  private emitStructureFilter() {
    this.structureFilterEmitter.emit(this.structureFilters);
  }

  private emitDiversityFilter() {
    this.diversityFilterEmitter.emit(this.diversityFilters);
  }

  private isReactionFilterEmpty() {
    if (!this.reactionFiltersList) {
      return true;
    } else {
      const { limitTo, exclude } = this.reactionFiltersList;
      const { lastDisconnected } = this.reactionFiltersList;
      return (
        limitTo.reactions.length === 0 &&
        exclude.reactions.length === 0 &&
        lastDisconnected.limitTo.reactions.length === 0 &&
        lastDisconnected.exclude.reactions.length === 0
      );
    }
  }

  private duplicateStructureFilters(
    category: StructureCategory,
    svgSmilesSimilarity: SVGSmilesSimilarity,
  ) {
    const index = category.criteria.findIndex((value: SVGSmilesSimilarity) => {
      return (
        value.smiles === svgSmilesSimilarity.smiles &&
        value.similarity === svgSmilesSimilarity.similarity
      );
    });
    return !!(index !== -1);
  }

  private duplicateDiversityFilters(
    category: StructureCategory,
    svgSmilesSimilarity: SVGSmilesSimilarity,
  ) {
    const index = category.criteria.findIndex((value: SVGSmilesSimilarity) => {
      return (
        value.smiles === svgSmilesSimilarity.smiles &&
        value.similarity === svgSmilesSimilarity.similarity
      );
    });
    return !!(index !== -1);
  }

  private duplicateReactionFilters(
    reaction: IReactionData,
    sectionName: string,
    isLastDisconnected: boolean,
  ) {
    let index: number;
    if (isLastDisconnected) {
      index = this.reactionFiltersList.lastDisconnected[sectionName].reactions.findIndex(
        (reactionData: IReactionData) => {
          return (
            reactionData.reactionName === reaction.reactionName &&
            reactionData.smiles === reaction.smiles
          );
        },
      );
    } else {
      index = this.reactionFiltersList[sectionName].reactions.findIndex(
        (reactionData: IReactionData) => {
          return (
            reactionData.reactionName === reaction.reactionName &&
            reactionData.smiles === reaction.smiles
          );
        },
      );
    }
    return !!(index !== -1);
  }

  private updateSVGOnLoad() {
    const { limitTo, exclude } = this.structureFilters;
    if (limitTo) {
      const { exact, similar, structure } = limitTo;
      if (exact && exact.criteria.length > 0) {
        this.structureFilterView.isLimitToSection = true;
        for (const data of exact.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (similar && similar.criteria.length > 0) {
        this.structureFilterView.isLimitToSection = true;
        for (const data of similar.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (structure && structure.criteria.length > 0) {
        this.structureFilterView.isLimitToSection = true;
        for (const data of structure.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smarts, DisplayType.Substructure);
        }
      }
    }
    if (exclude) {
      const { exact, similar, structure } = exclude;
      if (exact && exact.criteria.length > 0) {
        this.structureFilterView.isExcludeSection = true;
        for (const data of exact.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (similar && similar.criteria.length > 0) {
        this.structureFilterView.isExcludeSection = true;
        for (const data of similar.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (structure && structure.criteria.length > 0) {
        this.structureFilterView.isExcludeSection = true;
        for (const data of structure.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smarts, DisplayType.Substructure);
        }
      }
    }
  }

  private updateSVGOnDiversityLoad() {
    const { limitTo, exclude } = this.diversityFilters;
    if (limitTo) {
      const { exact, similar, structure } = limitTo;
      if (exact && exact.criteria.length > 0) {
        this.diversityFilterView.isLimitToSection = true;
        for (const data of exact.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (similar && similar.criteria.length > 0) {
        this.diversityFilterView.isLimitToSection = true;
        for (const data of similar.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (structure && structure.criteria.length > 0) {
        this.diversityFilterView.isLimitToSection = true;
        for (const data of structure.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smarts, DisplayType.Substructure);
        }
      }
    }
    if (exclude) {
      const { exact, similar, structure } = exclude;
      if (exact && exact.criteria.length > 0) {
        this.diversityFilterView.isExcludeSection = true;
        for (const data of exact.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (similar && similar.criteria.length > 0) {
        this.diversityFilterView.isExcludeSection = true;
        for (const data of similar.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smiles);
        }
      }
      if (structure && structure.criteria.length > 0) {
        this.diversityFilterView.isExcludeSection = true;
        for (const data of structure.criteria) {
          data.svg = this.drawSvgWithRdkit(data.smarts, DisplayType.Substructure);
        }
      }
    }
  }

  private drawSvgWithRdkit(smiles: string, displayType: DisplayType = DisplayType.Molecule) {
    return this.moleculeSearchService.drawSvgWithRdkit(smiles, displayType, this.rdkit);
  }
}
