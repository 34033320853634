import { throwError, of } from 'rxjs';
import { Component, OnDestroy, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as forge from 'node-forge';
import { PendoAnalyticsService } from '../../shared/services/analytics/pendo-analytics.service';
import {
  jwtHelperService,
  AuthorizationService,
  AuthTokenStorageName,
  ConfirmationCodeMethod,
  EmdAccessType,
} from '../authorization.service';
import { InfoDialogService, InfoDialogContent } from '../../shared/services/info-dialog.service';
import { ErrorsHandlerService, ParsedConnectionError } from '../../shared/services/errors-handler';
import { ParametersStashService } from '../../shared/services/parameters-stash/parameters-stash.service';
import * as TermsHtml from '!raw-loader!../../../terms.html';
import { SingleSignOnService } from '../single-sign-on.service';
import { Subject, from } from 'rxjs';
import { finalize, flatMap, mergeMap, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app-state.service';
import { ChematicaRouteStateService } from '../../shared/services/ch-route-state.service';
import { AppConstantsService } from '../../shared/services/app-constants/app-constants.service';
import { isNullOrUndefined } from '../../shared/components/utils';
import { EmdToken } from '../models/emd-token';
import { StorageHandlerService } from 'src/app/shared/services/storage-handler.service';
import { CustomPreloadStrategyService } from 'src/app/core/custom-preload-strategy.service';

export interface SSOStatus {
  is_sso_enabled: boolean;
  sso_provider_id: string;
}

@Component({
  selector: 'ch-sign-in',
  styleUrls: ['./sign-in.component.scss'],
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {
  public isSSOMode: boolean = false;
  public isStandardLogin: boolean = false;
  public ssoInProgress: boolean = true;
  public isEMDLogin: boolean = false;
  public minPasswordLength: number = 8;
  public maxPasswordLength: number = 128;
  public nextUrl: string;
  public pendingAuthorization: boolean = false;
  public errors: string[] = [];
  public username: string = '';
  public login: string = '';
  public password: string = '';
  public confirmationCode: string = '';
  public multifactorAuthorization: boolean = false;
  public urlConfirmation: boolean = false;
  public newCodeTimer: number = this.appConstantsService.confirmationCodeResendTimer;
  public timer: any;
  public showNewCodeAlert: boolean = false;
  public passwordPattern: string =
    '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])' + "(?=.*['@', '$', '#', '%', '^', '&', '*', '_', '!']).*$$";
  public presentPasswordError: string = null;
  public mustChangePassword: boolean = false;
  public currentPassword: string = '';
  public newPassword: string = '';
  public repeatNewPassword: string = '';
  public pendingPasswordChange: boolean = false;
  public passwordChangeError: boolean = false;
  public passwordEcryptionEnabled: boolean = true;
  public passwordEcryptionKey: string = '';
  public invalidToken: boolean = false;
  public paramsToken: boolean = false;
  public ssoProvider: string = '';
  public userDashboardUrl: string = '';
  public enableCustomerPolicyAcceptance: boolean = false;
  public isSynthiaLiteEnabled: boolean = false;
  public isShowConfirmationCodeView: boolean = true;
  public selectedMethod: ConfirmationCodeMethod = ConfirmationCodeMethod.TEXT;
  public isChinaRegion: boolean = APP_CONFIG.CHINA_REGION_INSTANCE;
  public logoText: string = '';
  public isEmailEntered: boolean = false;
  public rememberMe: boolean = false;

  private unsubscribeFromAll: Subject<void> = new Subject<void>();
  private isFromChemDraw: boolean = false;

  constructor(
    public infoDialogService: InfoDialogService,
    public errorsHandler: ErrorsHandlerService,
    public authService: AuthorizationService,
    public route: ActivatedRoute,
    public router: Router,
    public singleSignOnService: SingleSignOnService,
    public appStateService: AppState,
    public routeStateService: ChematicaRouteStateService,
    public appConstantsService: AppConstantsService,
    private stashService: ParametersStashService,
    private pendoAnalyticsService: PendoAnalyticsService,
    private location: Location,
    private storageHandlerService: StorageHandlerService,
    private customPreloadStrategy: CustomPreloadStrategyService,
  ) {
    this.route.queryParams.subscribe((queryParams) => {
      if (!isNullOrUndefined(queryParams['code'])) {
        this.getEmdToken(queryParams['code']);
      } else if (
        !isNullOrUndefined(queryParams['source']) &&
        queryParams['source'] === 'chemdraw'
      ) {
        this.authService.authorized.value
          ? this.openChemDraw()
          : this.authService.redirectToEmdAccess();
      } else if (
        !isNullOrUndefined(queryParams['auto-approved-user']) &&
        queryParams['auto-approved-user'] === 'true'
      ) {
        if (this.authService.emdToken.value.access_token !== '') {
          this.validateUser();
        } else {
          const params = { ...queryParams };
          delete params['auto-approved-user'];
          const url = this.router
            .createUrlTree([], { relativeTo: this.route, queryParams: params })
            .toString();
          this.location.replaceState(url);
        }
      }
    });
  }

  public ngOnInit() {
    if (localStorage.getItem('isSSOMode') === 'true') {
      this.isSSOMode = true;
    }
    this.currentPassword = '';
    this.newPassword = '';
    this.repeatNewPassword = '';
    this.isFromChemDraw = false;
    this.isSynthiaLiteEnabled = this.stashService.isSynthiaLiteEnabled;
    this.logoText = this.isSynthiaLiteEnabled
      ? this.appConstantsService.synthiaLiteLogoText
      : this.appConstantsService.synthiaEnterpriseLogoText;

    this.appStateService.midendConfiguration
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((config) => {
        if (config) {
          this.isEMDLogin = config.EMD_AUTHENTICATION_ENABLED;
          this.isStandardLogin = !this.isEMDLogin;
          this.enableCustomerPolicyAcceptance = !!config.ENABLE_CUSTOMER_POLICY_ACCEPTANCE;
          this.minPasswordLength = config.MINIMUM_PASSWORD_LENGTH;
          this.checkAuthorization();
        }
      });

    this.appStateService.passwordEncryptionConfiguration
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((config) => {
        if (config) {
          this.passwordEcryptionEnabled = config.PASSWORD_ENCRYPTION_ENABLED;
          this.passwordEcryptionKey = config.PASSWORD_ENCRYPTION_PUBLIC_KEY;
        }
      });

    this.checkRememberMeCookie();

    if (sessionStorage.getItem(AuthTokenStorageName) !== null) {
      this.username = jwtHelperService.decodeToken(
        sessionStorage.getItem(AuthTokenStorageName),
      ).username;
    } else {
      this.username = '';
    }

    this.route.params.pipe(takeUntil(this.unsubscribeFromAll)).subscribe((params) => {
      if (params.next) {
        // For missing next parameter resolve to default authorization success path
        // For some reason the next parameter sometimes begins with /sign-out which results in unwanted behavior.
        this.nextUrl = params.next.startsWith('/sign-out') ? '/home' : params.next;
      } else {
        this.nextUrl = '/home';
      }
      if (params.verification_code) {
        this.urlConfirmation = true;
        this.paramsToken = true;
        this.confirmationCode = params.verification_code;
        this.multifactorAuthorization = true;
        this.confirmAuthorization();
      }
    });
    this.routeStateService.landingPageUrl
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((userDashboardUrl: string) => {
        this.userDashboardUrl = userDashboardUrl;
        if (
          this.userDashboardUrl &&
          this.authService.userInformation.value.id &&
          !this.mustChangePassword &&
          this.authService.authorized.getValue() &&
          !this.authService.isComingFromChemDraw()
        ) {
          if (this.authService.isCustomerPolicyAccepted(this.authService.userInformation.value)) {
            if (this.authService.isPlanSubscribed(this.authService.userInformation.value)) {
              this.routeStateService.navigateToHome();
            }
          } else {
            this.authService.navigateToCustomerPolicy(this.nextUrl);
          }
        }
      });
  }

  public ngAfterViewInit() {
    this.customPreloadStrategy.signInPageLoaded.next(true);
  }

  public checkAuthorization() {
    this.authService
      .isAuthorized()
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe((authorized) => {
        if (authorized && !this.mustChangePassword) {
          this.signInCallSuccess(true);
        } else {
          if (APP_CONFIG.SSO_MODE) {
            this.ssoInProgress = true;
            this.singleSignOnService
              .firebaseGetRedirectResult()
              .then((result) => {
                if (result?.user) {
                  this.signInSSO();
                } else {
                  this.ssoInProgress = false;
                }
              })
              .catch((error) => {
                this.signInCallError(error);
              });
          } else {
            this.ssoInProgress = false;
          }
        }
      });
  }

  public ngOnDestroy() {
    this.unsubscribeFromAll.next();
    this.unsubscribeFromAll.complete();
  }

  @HostListener('document:keypress', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      if (this.isSSOMode && !this.isStandardLogin && !this.pendingAuthorization) {
        this.signInFirebase();
      }
    }
  }

  public toggleStandardSSOLogin() {
    localStorage.removeItem('isSSOMode');
    this.isStandardLogin = !this.isStandardLogin;
    this.errors = [];
  }

  public signInFirebase() {
    this.ssoInProgress = true;
    this.signInInitialSetUp();
    try {
      this.singleSignOnService
        .firebaseSetPersistence(this.ssoProvider)
        .then((result) => {
          from(result?.user?.getIdToken())
            .pipe(mergeMap((token) => this.authService.beginSSOAuthorization(token)))
            .subscribe((status) => this.finishAuthorization(status));
        })
        .catch((error) => {
          if (error.code === 'auth/popup-closed-by-user') {
            error.message = `The popup has been closed by the user before finalizing the operation.`;
          } else if (error.code === 'auth/cancelled-popup-request') {
            error.message = `Login cancelled. Please complete the login process in the popup window.`;
          } else if (error.code === 'auth/popup-blocked') {
            error.message = `The sign-in window was blocked. Please check your browser's popup settings and allow popups from this website.`;
          } else {
            error.message = `Something went wrong. Please try again.`;
          }
          this.signInCallError(error);
        });
    } catch (error) {
      this.signInCallError(error);
    }
    localStorage.removeItem('isSSOMode');
  }

  public signInSSO() {
    this.ssoInProgress = true;
    this.singleSignOnService
      .firebaseIsAuthorized()
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        flatMap((user) => {
          return from(user?.getIdToken());
        }),
        flatMap((token) => {
          return this.authService.beginSSOAuthorization(token);
        }),
      )
      .subscribe(
        (status) => {
          this.finishAuthorization(status);
        },
        (error) => {
          this.signInCallError(error);
        },
      );
  }

  public resendConfirmationCode() {
    if (this.isSSOMode) {
      this.signInSSO();
    } else if (this.isEMDLogin) {
      this.validateUser();
    } else {
      this.signIn();
    }
    this.newCodeAlert();
  }

  public signIn() {
    this.signInInitialSetUp();
    let password: any;

    if (this.passwordEcryptionEnabled) {
      password = this.getEncryptedPassword();
    } else {
      password = this.password;
    }

    this.authService
      .beginAuthorization(this.login, password)
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (authorizationStatus) => {
          this.finishAuthorization(authorizationStatus);
        },
        (error) => {
          this.signInCallError(error);
        },
      );
  }

  public redirectToEmdAccess() {
    this.authService.redirectToEmdAccess();
  }

  public getEmdToken(code: string) {
    this.signInInitialSetUp();
    this.authService
      .getEmdToken(code)
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (emdToken: EmdToken) => {
          if (emdToken) {
            this.validateUser();
          }
        },
        (error: ParsedConnectionError) => {
          this.pendingAuthorization = false;
          this.ssoInProgress = false;
          this.errorsHandler.showGlobalError('Authorization error', error.promptMessage);
        },
      );
  }

  public validateUser() {
    this.authService
      .validateUser()
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (authorizationStatus: any) => {
          this.finishAuthorization(authorizationStatus);
        },
        (error) => {
          this.handleUserValidationError(error.error);
        },
      );
  }

  public handleUserValidationError(error: any) {
    if (!isNullOrUndefined(error.errors) && error.errors[0].code === 'invalid_synthia_user') {
      this.pendingAuthorization = false;
      this.router.navigate(['/synthia-lite-registration'], {
        queryParams: { email: error.errors[0].message },
      });
    } else if (!isNullOrUndefined(error.errors) && error.errors[0].code === 'account-deactivated') {
      this.pendingAuthorization = false;
      this.errorsHandler.showGlobalError(error.errors[0].message);
    } else if (
      !isNullOrUndefined(error.errors) &&
      error.errors[0].code === 'user-registration-approval-pending'
    ) {
      this.pendingAuthorization = false;
      this.router.navigate(['/synthia-lite-registration'], {
        queryParams: { status: error.errors[0].code },
      });
    } else {
      this.errorsHandler.showGlobalError('Authorization error', 'Please try again');
    }
  }

  public confirmAuthorization() {
    this.pendingAuthorization = true;
    this.errors = [];
    this.authService
      .confirmAuthorization(
        localStorage.getItem('user_id'),
        localStorage.getItem('verification_token'),
        this.confirmationCode,
      )
      .pipe(takeUntil(this.unsubscribeFromAll))
      .subscribe(
        (authorizationStatus) => {
          if (this.authService.isComingFromChemDraw() && !authorizationStatus.multifactor) {
            this.openChemDraw();
          }
          if (authorizationStatus.authorized) {
            this.username = authorizationStatus.username;
          }
          if (authorizationStatus.authorized && !authorizationStatus.multifactor) {
            clearInterval(this.timer);
            this.newCodeTimer = this.appConstantsService.confirmationCodeResendTimer;
          } else if (!authorizationStatus.authorized && authorizationStatus.multifactor) {
            this.multifactorAuthorization = true;
            this.pendingAuthorization = false;
            this.timer = setInterval(() => {
              this.updateTime();
            }, 1000);
          } else {
            clearInterval(this.timer);
            this.newCodeTimer = 0;
            this.urlConfirmation = false;
            this.multifactorAuthorization = true;
            this.errorsHandler.showGlobalError('Authorization error', 'Please try again');
          }
        },
        (error) => {
          this.signInCallError(error);
        },
      );
  }

  public returnToFirstStep() {
    this.login = '';
    this.password = '';
    this.confirmationCode = '';
    this.errors = [];
    this.invalidToken = false;
    this.multifactorAuthorization = false;
    this.urlConfirmation = false;
    this.paramsToken = false;
    clearInterval(this.timer);
    this.newCodeTimer = this.appConstantsService.confirmationCodeResendTimer;
    this.isEmailEntered = false;
    if (!this.isEMDLogin) {
      this.isStandardLogin = true;
    }
  }

  public newCodeAlert() {
    this.showNewCodeAlert = true;
    this.newCodeTimer = this.appConstantsService.confirmationCodeResendTimer;
  }

  public showTerms() {
    let terms: string = TermsHtml;
    if (!terms || terms === '') {
      terms = '<h4 align="center">Something is wrong... Please try again later</h4>';
    }
    const termsContent = new InfoDialogContent();
    termsContent.title = 'Terms and conditions';
    termsContent.message = terms;
    this.infoDialogService.info(termsContent);
  }

  public confirmPasswordChange() {
    this.errors = [];
    if (this.newPassword === this.repeatNewPassword) {
      this.passwordChangeError = false;
      this.pendingPasswordChange = true;

      // abort pending change if any
      this.authService
        .changePassword(this.currentPassword, this.newPassword)
        .pipe(
          takeUntil(this.unsubscribeFromAll),
          finalize(() => {
            this.pendingPasswordChange = false;
          }),
        )
        .subscribe(
          () => this.signInCallSuccess(true),
          (error: any) => this.changePasswordCallError(error),
        );
    } else {
      this.errors.push('New password and repeated password do not match.');
    }
  }

  public resetInputs() {
    this.password = '';
    this.confirmationCode = '';
    this.currentPassword = '';
    this.newPassword = '';
    this.repeatNewPassword = '';
    this.errors = [];
  }

  public onInputPassword() {
    this.presentPasswordError = '';
    this.errors = [];
  }

  public onSelectConfirmationMethod(selectedMethod: ConfirmationCodeMethod) {
    if (!isNullOrUndefined(selectedMethod)) {
      this.selectedMethod = selectedMethod;
      this.pendingAuthorization = true;
      this.authService
        .updateVerificationMethod(localStorage.getItem('user_id'), this.getVerificationMethod())
        .pipe(takeUntil(this.unsubscribeFromAll))
        .subscribe(
          (authorizationStatus) => {
            this.pendingAuthorization = false;
            if (
              !authorizationStatus.authorized &&
              authorizationStatus.multifactor &&
              !authorizationStatus.showMultifactorMethods
            ) {
              this.isShowConfirmationCodeView = false;
              this.multifactorAuthorization = true;
            } else {
              this.isShowConfirmationCodeView = true;
              this.multifactorAuthorization = true;
            }
          },
          (error) => {
            this.pendingAuthorization = false;
            const parsedError = new ParsedConnectionError(error);
            this.errorsHandler.showGlobalError(parsedError.promptMessage);
          },
        );
    } else {
      this.isShowConfirmationCodeView = false;
      this.multifactorAuthorization = false;
      this.urlConfirmation = false;
    }
  }

  public getVerificationMethod(): string {
    let selectedVerificationMethod: string = '';
    switch (this.selectedMethod) {
      case ConfirmationCodeMethod.TEXT:
        selectedVerificationMethod = this.appConstantsService.verificationMethodText;
        break;
      case ConfirmationCodeMethod.EMAIL:
        selectedVerificationMethod = this.appConstantsService.verificationMethodEmail;
        break;
      default:
        selectedVerificationMethod = '';
        break;
    }
    return selectedVerificationMethod;
  }

  public signInInitialSetUp() {
    this.errors = [];
    this.pendingAuthorization = true;
    this.paramsToken = false;
  }

  public finishAuthorization(authorizationStatus: any) {
    if (this.authService.isComingFromChemDraw() && !authorizationStatus.multifactor) {
      this.openChemDraw();
    }
    if (authorizationStatus.authorized) {
      this.username = authorizationStatus.username;
    }
    if (authorizationStatus.authorized && !authorizationStatus.multifactor) {
      clearInterval(this.timer);
      this.newCodeTimer = this.appConstantsService.confirmationCodeResendTimer;
    } else if (!authorizationStatus.authorized && authorizationStatus.multifactor) {
      this.multifactorAuthorization = true;
      this.isShowConfirmationCodeView = false;
      this.selectedMethod = ConfirmationCodeMethod.EMAIL;
      if (authorizationStatus.showMultifactorMethods) {
        this.onSelectConfirmationMethod(ConfirmationCodeMethod.EMAIL);
      } else {
        this.isSSOMode = this.ssoInProgress;
      }
      this.pendingAuthorization = false;
      this.timer = setInterval(() => {
        this.updateTime();
      }, 1000);
    } else {
      clearInterval(this.timer);
      this.newCodeTimer = this.appConstantsService.confirmationCodeResendTimer;
      this.timer = setInterval(() => {
        this.updateTime();
      }, 1000);
      this.errorsHandler.showGlobalError(
        'Authorization error',
        'Unexpected authorization response. Please try again',
      );
    }
    this.ssoInProgress = false;
  }

  public changePasswordCallError(error: any) {
    if (error.isRecognized()) {
      if (!this.handleLocalError(error)) {
        this.errorsHandler.showGlobalError(error.promptMessage, error.detailsMessage);
      }
    } else {
      this.errors.push('Unable to change password. ' + error);
    }
  }

  public updateTime() {
    this.newCodeTimer -= 1;
    if (this.newCodeTimer === 0) {
      clearInterval(this.timer);
    }
  }

  public signInCallSuccess(result: boolean) {
    this.pendingAuthorization = false;
    this.errors = [];
    if (result) {
      this.login = '';
      this.password = '';
      this.multifactorAuthorization = false;
      this.urlConfirmation = false;
      this.paramsToken = false;
      this.errors = [];
      this.isEmailEntered = false;

      this.authService
        .userMustChangePassword()
        .pipe(
          takeUntil(this.unsubscribeFromAll),
          flatMap((mustChange) => {
            if (this.mustChangePassword !== mustChange) {
              this.mustChangePassword = mustChange;
            }
            if (this.mustChangePassword) {
              this.ssoInProgress = false;
              return of(null);
            } else {
              if (this.authService.userInformation.value.id) {
                return of(this.authService.userInformation.value);
              } else {
                return this.authService.getUserInformation();
              }
            }
          }),
        )
        .subscribe((userInfo) => {
          if (!this.mustChangePassword) {
            if (userInfo) {
              if (
                !!this.appStateService.midendConfiguration.value &&
                this.appStateService.midendConfiguration.value.PENDO_ANALYTICS_ENABLED
              ) {
                this.pendoAnalyticsService.identify();
              }
              if (this.authService.isCustomerPolicyAccepted(userInfo)) {
                if (!this.authService.isPlanSubscribed(userInfo) && !this.isFromChemDraw) {
                  this.authService.navigateToSubscriptionPlans();
                } else if (this.nextUrl?.startsWith('/analysis')) {
                  this.router.navigateByUrl(this.nextUrl);
                } else {
                  if (this.userDashboardUrl && !this.authService.isComingFromChemDraw()) {
                    this.routeStateService.navigateToHome();
                  }
                }
              } else {
                this.authService.navigateToCustomerPolicy(this.nextUrl);
              }
            }
          }
        });
    } else {
      throwError('Authorization error. Unexpected result: ' + result);
    }
  }

  public signInCallError(error: any) {
    this.pendingAuthorization = false;
    this.ssoInProgress = false;
    const parsedError = new ParsedConnectionError(error);
    if (parsedError.isRecognized()) {
      if (!this.handleLocalError(parsedError)) {
        this.errorsHandler.showGlobalError(parsedError.promptMessage, parsedError.detailsMessage);
      }
    } else {
      this.errors.push('Authorization error. ' + error);
    }
  }

  public getEncryptedPassword(): string {
    const publicKey = forge.pki.publicKeyFromPem(this.passwordEcryptionKey);
    const encodedStr = forge.util.encodeUtf8(this.password);
    const encrypted = publicKey.encrypt(encodedStr, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
      mgf1: forge.mgf.mgf1.create(null),
    });
    const encrypted_password = forge.util.encode64(encrypted);
    return encrypted_password;
  }

  public handleLocalError(parsedError: ParsedConnectionError): boolean {
    const handleNonGlobal = (error) => {
      const nonGlobal = !error.isGlobal();
      if (nonGlobal) {
        this.errors.push(
          `Authorization error. ${error.response.statusText || ''} (code: ${error.status})`,
        );
      }
      return nonGlobal;
    };

    switch (parsedError.status) {
      case 400:
        // Backend errors with code 400:
        // - for fields: {"username" : ["This field is required."]}, {"password":["This field is required."]}
        // - other: {"non_field_errors":["Unable to login with provided credentials."]}
        // FIXME: does not work or another error code?
        if ('username' in parsedError.bodyJson) {
          this.errors.push('Missing login.'); // Should not happen with a proper form validation.
          return true;
        } else if ('password' in parsedError.bodyJson) {
          this.errors.push('Missing password.'); // Should not happen with a proper form validation.
          return true;
        } else if (parsedError.bodyJson.errors[0].code === 'account-locked') {
          this.errors.push(`The number of allowed login attempts has been exceeded.
                            Please contact with your administrator`);
          return true;
        } else if (parsedError.bodyJson.errors) {
          for (const validationError of Object.keys(parsedError.bodyJson.errors)) {
            this.errors.push(parsedError.bodyJson.errors[validationError].message);
          }
          return true;
        } else if ('non_field_errors' in parsedError.bodyJson) {
          // possible at all for this entry point?
          this.errors.push(parsedError.bodyJson.non_field_errors.join(' '));
          return true;
        }
        return handleNonGlobal(parsedError);

      case 403:
        if ('code' in parsedError.bodyJson && parsedError.bodyJson.code === 'permission_denied') {
          if (parsedError.bodyJson.message.indexOf('old_password is missing') > -1) {
            this.presentPasswordError = 'Wrong password';
          } else if (
            parsedError.bodyJson.message.indexOf('was not found.') > -1 &&
            parsedError.bodyJson.message.indexOf('Verification code') > -1
          ) {
            this.urlConfirmation = false;
            this.invalidToken = true;
            if (this.paramsToken) {
              this.errors.push('The link used has already expired. Please use valid link.');
            } else {
              this.errors.push('The code used has already expired. Please use valid code.');
            }
          } else if (
            parsedError.bodyJson.message.indexOf('The authenticated user has no rights') > -1
          ) {
            this.errorsHandler.showGlobalError(parsedError.promptMessage);
          } else {
            this.errors.push('Incorrect confirmation code! Please check it and try again.');
          }
          return true;
        }
        return handleNonGlobal(parsedError);

      default:
        return handleNonGlobal(parsedError);
    }
  }

  public onRegister() {
    if (!this.isSynthiaLiteEnabled) {
      this.router.navigate(['/registration']);
    } else {
      this.authService.redirectToEmdAccess(EmdAccessType.Register);
    }
  }

  public checkSSOUser() {
    localStorage.removeItem('isSSOMode');
    this.errors = [];
    this.pendingAuthorization = true;
    this.authService
      .checkUserSSOEnabled(this.login)
      .pipe(
        takeUntil(this.unsubscribeFromAll),
        finalize(() => {
          this.pendingAuthorization = false;
        }),
      )
      .subscribe(
        (data: SSOStatus) => {
          this.updateRememberMeCookie();
          this.isSSOMode = data.is_sso_enabled;
          if (this.isSSOMode) {
            localStorage.setItem('isSSOMode', 'true');
          }
          this.ssoProvider = data.sso_provider_id;
          this.isStandardLogin = !this.isSSOMode;
          this.isEmailEntered = true;
          setTimeout(() => {
            const passwordField: HTMLElement = document.getElementById('password-input');
            if (!isNullOrUndefined(passwordField)) {
              passwordField.focus();
            }
          }, 200);
        },
        (error) => {
          const parsedError: ParsedConnectionError = new ParsedConnectionError(error);
          for (const bodyJsonError of parsedError.bodyJson.errors) {
            this.errors.push(bodyJsonError.message);
          }
        },
      );
  }

  public editLoginEmail() {
    this.errors = [];
    this.password = '';
    this.isEmailEntered = false;
    this.isStandardLogin = true;
  }

  private checkRememberMeCookie() {
    const rememberMeCookie: string = this.storageHandlerService.getCookie(
      this.appConstantsService.rememberMeCookie,
    );
    this.rememberMe = !!rememberMeCookie;
    if (this.rememberMe) {
      this.login = rememberMeCookie;
    }
  }

  private updateRememberMeCookie() {
    if (this.rememberMe) {
      this.storageHandlerService.setCookie(this.appConstantsService.rememberMeCookie, this.login);
    } else {
      this.storageHandlerService.deleteCookie(this.appConstantsService.rememberMeCookie);
    }
  }

  private openChemDraw() {
    this.pendingAuthorization = true;
    if (this.authService.isNewRegistration()) {
      this.authService.navigateToSubscriptionPlans();
    } else {
      this.isFromChemDraw = true;
      this.authService.openChemDraw();
    }
  }
}
