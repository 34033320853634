import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNullOrUndefined, sortArray, sortBy } from 'src/app/shared/components/utils';
import {
  AnalysisService,
  ChemicalEntry,
  DashboardAnalysisEntry,
  MoleculeData,
} from 'src/app/shared/services';
import { LibraryTargetsEntry } from 'src/app/shared/services/analysis/models/library-targets';
import { ViewType } from 'src/app/shared/services/molecule-sets';

@Component({
  selector: 'ch-target-view-dialog',
  templateUrl: './target-view-dialog.component.html',
  styleUrls: ['./target-view-dialog.component.scss'],
})
export class TargetViewDialogComponent implements OnInit, OnDestroy {
  public analysis: DashboardAnalysisEntry;
  public analysisName: string = '';
  public columns: number = 4;
  public targetSmiles: string[] = [];
  public moleculesToDisplay: MoleculeData[] = [];
  public isCustomList: boolean = false;
  public viewType: ViewType = ViewType.MoleculeSets;
  public isTargetResultsPage: boolean = false;
  public libraryTargets: LibraryTargetsEntry;
  public targetsWithPathsFound: MoleculeData[] = [];
  public targetsWithNoPathsFound: MoleculeData[] = [];
  public libraryTargetDetailsLoading: boolean = false;
  public analysisId: number;
  private unsubscriberSubject: Subject<any> = new Subject();

  constructor(
    public analysisService: AnalysisService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TargetViewDialogComponent>,
  ) {
    if (data) {
      this.isTargetResultsPage = data.isTargetResultsPage;
      if (this.isTargetResultsPage) {
        if (data.target) {
          this.libraryTargets = data.target;
        }
        this.analysisId = data.analysisId;
      } else {
        this.analysis = data.target;
        this.targetSmiles = this.analysis.smiles.split('.');
      }
    }
  }

  public ngOnDestroy(): void {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
    this.analysisService.libraryTargetDetailsLoading.next(false);
  }

  public ngOnInit(): void {
    if (!this.isTargetResultsPage) {
      this.updateColumns(window.innerWidth);
      this.targetSmiles.forEach((smiles: string, index: number) => {
        const smilesResponse = { smiles: smiles };
        const molecule = new ChemicalEntry(JSON.parse(JSON.stringify(smilesResponse)));
        molecule.name = 'Target ' + (index + 1);
        molecule.id = String(index);
        this.moleculesToDisplay.push(this.moleculesToData(molecule));
      });
    } else {
      if (isNullOrUndefined(this.libraryTargets)) {
        this.analysisService.libraryTargetDetailsLoading.next(true);
        this.getLibraryTargetDetails();
      } else {
        this.analysisService.libraryTargetDetailsLoading.next(false);
      }
    }

    this.analysisService.libraryTargetDetailsLoading
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((libraryTargetDetailsLoading: boolean) => {
        this.libraryTargetDetailsLoading = libraryTargetDetailsLoading;
        if (!this.libraryTargetDetailsLoading) {
          this.showLibraryTarget();
        }
      });
  }

  public getLibraryTargetDetails() {
    this.analysisService
      .getLibraryTargetDetails(this.analysisId)
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe((libraryTargets: LibraryTargetsEntry) => {
        this.libraryTargets = libraryTargets;
        this.analysisService.libraryTargetDetailsLoading.next(false);
      });
  }

  public showLibraryTarget() {
    this.updateColumns(window.innerWidth);
    this.libraryTargets?.targets?.with_result?.forEach((smiles: string) => {
      const smilesResponse = { smiles: smiles };
      const molecule = new ChemicalEntry(JSON.parse(JSON.stringify(smilesResponse)));
      molecule.name = this.libraryTargets?.target_mapping[smiles];
      this.targetsWithPathsFound.push(this.moleculesToData(molecule));
    });
    this.libraryTargets?.targets?.without_result?.forEach((smiles: string) => {
      const smilesResponse = { smiles: smiles };
      const molecule = new ChemicalEntry(JSON.parse(JSON.stringify(smilesResponse)));
      molecule.name = this.libraryTargets?.target_mapping[smiles];
      this.targetsWithNoPathsFound.push(this.moleculesToData(molecule));
    });
    this.targetsWithPathsFound = sortArray(
      this.targetsWithPathsFound,
      sortBy.NUMBER,
      false,
      'Target',
      'moleculeName',
    );
    this.targetsWithNoPathsFound = sortArray(
      this.targetsWithNoPathsFound,
      sortBy.NUMBER,
      false,
      'Target',
      'moleculeName',
    );
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: Event) {
    this.updateColumns((event.target as Window).innerWidth);
  }

  public moleculesToData(molecule: ChemicalEntry): MoleculeData {
    const tableDataRow: MoleculeData = {
      moleculeName: molecule.name ? molecule.name : '',
      moleculeSmiles: molecule.smiles ? molecule.smiles : '',
      moleculeSelected: false,
      moleculeExpanded: true,
      moleculeId: molecule.id ? molecule.id : '',
      moleculeMolFile: !isNullOrUndefined(molecule.molfile) ? molecule.molfile : '',
      moleculeSmarts: '',
    };
    return tableDataRow;
  }

  private updateColumns(windowWidth: number): void {
    this.columns = windowWidth < 1200 ? 3 : 4;
  }
}
