import { isString } from '../../../components/utils';
import { CountryDetails } from './country-details';

export enum SelfRegisteredUserStatus {
  APPROVED = 'Approved',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
}

export enum SelfRegisteredUserPendingStatusReason {
  MAX_SUBSCRIPTIONS_LIMIT = 'permission-group-full',
  CLOSED = 'registration-closed',
  OTHER = 'other',
}

export class SelfRegisteredUser {
  public id: number = 0;
  public first_name: string = '';
  public last_name: string = '';
  public email: string = '';
  public phone_number: string = '';
  public country_details: CountryDetails = null;
  public status: string = '';
  public created_at: string = '';
  public two_factor_authentication: boolean = false;

  constructor(data?: any) {
    const jsonData = isString(data) ? JSON.parse(data) : data;
    for (const field in jsonData) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        if (field === 'country_details' && jsonData[field] !== null) {
          try {
            this[field] = new CountryDetails(jsonData[field]);
          } catch (e) {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        } else {
          if (typeof jsonData[field] === typeof this[field] || jsonData[field] === null) {
            this[field] = jsonData[field];
          } else {
            console.error(this.getInvalidFieldTypeError(field, jsonData[field]));
          }
        }
      } else {
        console.error(`Unexpected field '${field}' in data provided to RegisteredUser model.`);
      }
    }
  }

  private getInvalidFieldTypeError(field: string, jsonDataField: string): string {
    return `Unexpected type of field '${field}' in data provided to RegisteredUser model.\n
            Used default value '${this[field]}' instead of provided '${jsonDataField}'.`;
  }
}
