<div class="ch-molecule-list">
  <h3 class="ch-title">MOLECULE REPORT</h3>
  <div class="subtitle-container">
    <p class="subtitle-text subtitle" *ngIf="isMoleculeReportLoading || reloadMoleculeReport">
      Molecule report loading
    </p>
    <p
      *ngIf="
        molecules &&
        molecules.length !== 0 &&
        isAllPathwaysLoaded &&
        !isMoleculeReportLoading &&
        !reloadMoleculeReport
      "
      class="subtitle"
    >
      {{ subtitle }}
    </p>
    <p
      *ngIf="
        (!molecules || molecules.length === 0) && !isMoleculeReportLoading && !reloadMoleculeReport
      "
      class="subtitle"
    >
      No molecules in the graph.
    </p>
  </div>
  <button
    *ngIf="molecules && molecules.length !== 0"
    mat-raised-button
    color="primary"
    (click)="deselectAllNodes()"
    [disabled]="!isAnyNodeSelected() || mode == ResultsView.PATHWAYS"
    class="ch-molecule-list-deselect-button"
  >
    DESELECT ALL
  </button>

  <div *ngIf="molecules.length > 0 && !loadingMolecules">
    <ng-container *ngFor="let molecule of molecules; let i = index">
      <div class="ch-molecule-list-item" *ngIf="i < moleculeLoadingLimiter">
        <ch-new-clipboard-item
          #nodeItem
          class="animate-stagger"
          [node]="molecule"
          [algorithm]="algorithm"
          [mode]="mode"
          [isMoleculeList]="true"
          (onAddMoleculeToSet)="addMoleculeToSet($event)"
          (onDetails)="clipboardService.dispatchNodeEvent($event, 'details')"
          (onMouseDown)="clipboardService.dispatchNodeEvent($event, 'mousedown', 'true')"
          (onMouseUp)="clipboardService.dispatchNodeEvent($event, 'mouseup')"
          (onStrainReport)="clipboardService.dispatchNodeEvent($event, 'strainreport')"
          (onStartNewAnalysis)="clipboardService.dispatchNodeEvent($event, 'newanalysis')"
          height="240"
        >
        </ch-new-clipboard-item>
      </div>
    </ng-container>
    <div
      class="more-loader"
      ch-viewport-visibility
      *ngIf="shouldShowLoadMoreMolecules()"
      (visibilityChange)="loadMoreMolecules($event.value)"
    >
      <mat-spinner
        [diameter]="30"
        [strokeWidth]="2"
        *ngIf="loadingMoreMoleculesTimeout"
      ></mat-spinner>
      <p>Loading more molecules...</p>
    </div>
  </div>

  <div *ngIf="loadingMolecules" class="ch-molecule-list-loading">
    <mat-spinner [strokeWidth]="1" [diameter]="30" class="ch-molecule-list-spinner"> </mat-spinner
    ><br />
    <div class="ch-molecule-list-status">Loading...</div>
  </div>
</div>
