import { Subscription } from 'rxjs';
import {
  Directive,
  ElementRef,
  Output,
  Input,
  AfterViewInit,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { ViewportVisibilityService } from '../services';

export enum ViewportVisibilityDirection {
  Both,
  Vertical,
  Horizontal,
}

@Directive({
  selector: '[ch-viewport-visibility]',
})
export class ViewportVisibilityDirective implements OnDestroy, AfterViewInit {
  @Input() public partialVisibility: boolean = true;
  @Output() public visibilityChange: EventEmitter<any>;
  public observer: IntersectionObserver;
  public config: {
    rootElement?: any;
    partial?: any;
    direction?: ViewportVisibilityDirection;
  };

  private viewportVisibilityServiceSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private viewportVisibilityService: ViewportVisibilityService,
  ) {
    this.visibilityChange = new EventEmitter<any>();
  }

  public ngAfterViewInit() {
    this.config = {
      rootElement: this.elementRef.nativeElement,
      partial: this.partialVisibility,
      direction: ViewportVisibilityDirection.Both,
    };
    this.viewportVisibilityServiceSubscription =
      this.viewportVisibilityService.serviceTrigger.subscribe(
        (entries: IntersectionObserverEntry[]) => this.check(entries),
      );
    this.viewportVisibilityService.addTarget(this.elementRef.nativeElement);
  }

  public ngOnDestroy() {
    this.viewportVisibilityService.removeTarget(this.elementRef.nativeElement);

    if (
      this.viewportVisibilityServiceSubscription &&
      !this.viewportVisibilityServiceSubscription.closed
    ) {
      this.viewportVisibilityServiceSubscription.unsubscribe();
    }
  }

  public check(entries: IntersectionObserverEntry[]) {
    const entry = entries.find((item) => item.target === this.elementRef.nativeElement);
    if (entry) {
      const value =
        this.config && this.config.partial
          ? entry.intersectionRatio > 0
          : entry.intersectionRatio === 1;
      this.visibilityChange.emit({
        target: entry.target,
        value,
      });
    }
  }
}
