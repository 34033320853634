import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from '../../authorization/authorization.service';
import {
  AppConstantsService,
  ParametersStashService,
  InfoDialogContent,
  InfoDialogService,
} from '../services';

/**
 * This directive disables a feature when it is not needed like the case of Synthia Lite.
 * This mainly disabled the click action and provide a popup with disabled information.
 * Every other events like hover will be handled as before.
 * 'disabledInSynthiaLite' is an optional attribute to explicity enable any features/folder which comes in a loop.
 * 'disabledClass' is an optional attribute to provide any custom class to an element while disabling.
 */
@Directive({
  selector: '[synthiaLiteDisabledFeature]',
})
export class SynthiaLiteDisabledFeatureDirective implements OnInit, OnDestroy, OnChanges {
  @Input() public disabledInSynthiaLite: boolean = this.stashService.isSynthiaLiteEnabled;
  @Input() public disabledClass: string = 'disabled-in-synthia-lite';
  @Input() public isShowExpiryMessage: boolean = false;
  @Output() public callWhenEnabled: EventEmitter<any> = new EventEmitter();
  private domElement: ElementRef['nativeElement'];
  private listenHandler: Function;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private infoDialogService: InfoDialogService,
    private stashService: ParametersStashService,
    private appContantsService: AppConstantsService,
    private authorizationService: AuthorizationService,
    private dialog: MatDialog,
  ) {}

  public ngOnInit() {
    this.disableElement();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('disabledInSynthiaLite')) {
      this.disableElement();
    }
  }

  public ngOnDestroy() {
    this.removeListenHandler();
  }

  private disableElement() {
    if (this.verifySynthiaLiteDisabledStatus()) {
      this.domElement = this.element.nativeElement;
      this.domElement.removeAllListeners('click');
      this.removeListenHandler();
      this.listenHandler = this.renderer.listen(
        this.domElement,
        'click',
        this.clickEvent.bind(this),
      );
      this.setDisabledStyle();
    } else {
      this.removeDisabled();
    }
  }

  private verifySynthiaLiteDisabledStatus() {
    if (this.stashService.isSynthiaLiteEnabled === false) {
      return false;
    }
    return this.disabledInSynthiaLite;
  }

  private clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showDisabledFeatureMessage();
    return false;
  }

  private showDisabledFeatureMessage() {
    const messageContent = new InfoDialogContent();
    messageContent.title = this.getAlertTitle();
    messageContent.message = '<p>' + this.getAlertMessage() + '</p>';
    messageContent.closeButtonText = this.getCloseButtonText();
    messageContent.synthiaPromoButtton = !this.isShowExpiryMessage;
    messageContent.showCloseButton = !!this.isShowExpiryMessage;
    messageContent.showUpgradeOptionButton = !!this.isShowExpiryMessage;
    messageContent.dimensions = { width: '800px', height: '200px' };
    this.infoDialogService.info(messageContent).subscribe((action) => {
      if (action === 'goToSynthiaPromoPage') {
        this.goToSynthiaPromoPage();
      } else if (action === 'goToUpgradeOptions') {
        this.dialog.closeAll();
        this.authorizationService.navigateToSubscriptionPlans();
      }
    });
  }

  private goToSynthiaPromoPage() {
    window.open(this.stashService.synthiaPromoUrl, '_blank');
  }

  private setDisabledStyle() {
    this.renderer.addClass(this.domElement, this.disabledClass);
  }

  private getAlertTitle() {
    let title: string = this.appContantsService.synthiaLiteDisabledFeatureDialogTitle;
    if (this.isShowExpiryMessage) {
      title = this.appContantsService.synthiaLiteDisabledAnalysisDialogTitle;
    }
    return title;
  }

  private getAlertMessage() {
    let message: string = this.appContantsService.synthiaLiteDisabledFeatureDialogMessage;
    if (this.isShowExpiryMessage) {
      message = this.appContantsService.synthiaLiteDisabledDialogMessage;
    }
    return message;
  }

  private getCloseButtonText() {
    let buttonText: string =
      this.appContantsService.synthiaLiteDisabledFeatureDialogCloseButtonText;
    if (this.isShowExpiryMessage) {
      buttonText = this.appContantsService.synthiaLiteDisabledDialogCloseButtonText;
    }
    return buttonText;
  }

  private emitCallWhenEnabled() {
    this.callWhenEnabled.emit();
  }

  private removeDisabled() {
    this.removeListenHandler();
    if (this.domElement) {
      this.renderer.removeClass(this.domElement, this.disabledClass);
      this.listenHandler = this.renderer.listen(
        this.domElement,
        'click',
        this.emitCallWhenEnabled.bind(this),
      );
    }
  }

  private removeListenHandler() {
    if (this.listenHandler !== undefined) {
      this.listenHandler();
    }
  }
}
