<div class="ch-scoring-function-calculator ch-new-calculator-container">
  <div class="ch-dialog-top">
    <span>
      Customize
      <ng-container *ngIf="functionType === FunctionType.Reaction">Reaction</ng-container>
      <ng-container *ngIf="functionType === FunctionType.Chemical">Chemical</ng-container>
      <ng-container *ngIf="functionType === FunctionType.Sorting">Sort</ng-container>
      Scoring Function
      <mat-icon (click)="closeDialog(false)" class="icon-18"> close </mat-icon>
    </span>
  </div>

  <div class="ch-dialog-body">
    <div class="ch-scoring-function-calculator-container">
      <div class="ch-scoring-function-calculator-left">
        <mat-form-field class="ch-scoring-function-calculator-input-form-field">
          <textarea
            matInput
            placeholder="{{ scoringFunction ? scoringFunction.name : 'Enter your function here' }}"
            maxlength="2000"
            (input)="onCalculatorInput()"
            [(ngModel)]="tempScoringFunction"
            name="scoringFunction"
            class="ch-scoring-function-calculator-textarea"
          >
          </textarea>
          <mat-hint *ngIf="inputError" class="ch-clr-warn">{{ inputError }}</mat-hint>
          <mat-hint *ngIf="tempScoringFunction.length === 2000" class="ch-clr-warn"
            >Character limit reached.</mat-hint
          >
        </mat-form-field>

        <div class="ch-scoring-function-calculator-edit">
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            [disabled]="inputHistory.length <= 1"
            (click)="undo()"
            class="ch-scoring-function-calculator-edit-button"
          >
            UNDO
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            [disabled]="isSourceCode()"
            (click)="resetScoringFunction()"
            class="ch-scoring-function-calculator-edit-button"
          >
            RESET
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            [disabled]="tempScoringFunction === ''"
            (click)="clearScoringFunction()"
            class="ch-scoring-function-calculator-edit-button"
          >
            CLEAR
          </button>
        </div>

        <div class="ch-scoring-function-calculator-buttons-left">
          <div class="ch-scoring-function-button-container">
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('(')"
            >
              (
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction(')')"
            >
              )
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction(',')"
            >
              ,</button
            ><br />
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('^2')"
            >
              x^2
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('^3')"
            >
              x^3
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('^')"
            >
              x^y</button
            ><br />
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('10^')"
            >
              10^x
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('sqrt')"
            >
              &radic;
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('1/')"
            >
              1/x</button
            ><br />
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('log10')"
            >
              log10
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('ln')"
            >
              ln
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              (click)="addToScoringFunction('e^')"
            >
              e^x
            </button>
          </div>
        </div>
        <div class="ch-scoring-function-calculator-buttons-right">
          <div class="ch-scoring-function-button-container">
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('7')"
            >
              7
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('8')"
            >
              8
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('9')"
            >
              9
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('+')"
            >
              +</button
            ><br />
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('4')"
            >
              4
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('5')"
            >
              5
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('6')"
            >
              6
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('-')"
            >
              -</button
            ><br />
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('1')"
            >
              1
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('2')"
            >
              2
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('3')"
            >
              3
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('*')"
            >
              *</button
            ><br />
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small"
              [ngClass]="
                functionType === FunctionType.Chemical || functionType === FunctionType.Reaction
                  ? 'numericals'
                  : 'zero'
              "
              (click)="addToScoringFunction('0')"
            >
              0
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              *ngIf="
                functionType === FunctionType.Chemical || functionType === FunctionType.Reaction
              "
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction(',')"
            >
              ,
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('.')"
            >
              .
            </button>
            <button
              mat-button
              [buttonOutline]="'default-grey'"
              class="ch-scoring-function-calculator-button-small numericals"
              (click)="addToScoringFunction('/')"
            >
              /
            </button>
          </div>
        </div>
      </div>

      <div [ngSwitch]="functionType" class="ch-scoring-function-calculator-right">
        <div
          class="ch-scoring-function-calculator-type-chemical"
          *ngSwitchCase="FunctionType.Chemical"
        >
          <button
            mat-button
            *ngFor="let parameter of advancedCSFAvailableFunctions"
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="onCalculatorParameterClick(parameter)"
            matTooltip="{{ parameter.description }}"
            matTooltipPosition="before"
          >
            {{ parameter.parameter_name }}
          </button>
        </div>
        <div
          class="ch-scoring-function-calculator-type-reaction"
          *ngSwitchCase="FunctionType.Reaction"
        >
          <button
            mat-button
            *ngFor="let parameter of advancedRSFAvailableFunctions"
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="onCalculatorParameterClick(parameter)"
            matTooltip="{{ parameter.description }}"
            matTooltipPosition="before"
          >
            {{ parameter.parameter_name }}
          </button>
        </div>
        <div
          class="ch-scoring-function-calculator-type-sorting"
          *ngSwitchCase="FunctionType.Sorting"
        >
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('RINGS')"
            matTooltip="Prefer reactions with formation of rings"
            matTooltipPosition="before"
          >
            RINGS
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('KNOWN')"
            matTooltip="Prefer reactions with known molecules"
            matTooltipPosition="before"
          >
            KNOWN
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('UNKNOWN')"
            matTooltip="Prefer reactions with unknown molecules"
            matTooltipPosition="before"
          >
            UNKNOWN
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('BUYABLE')"
            matTooltip="Prefer reactions with buyable molecules"
            matTooltipPosition="before"
          >
            BUYABLE
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('STEREO')"
            matTooltip="Prefer reactions with stereocenters"
            matTooltipPosition="before"
          >
            STEREO
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('MREL')"
            matTooltip="Prefer reactions in which retron is cut into synthons of similar sizes"
            matTooltipPosition="before"
          >
            MREL
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('MDIFF')"
            matTooltip="Prefer reactions in which the heaviest synthon is smaller than retron"
            matTooltipPosition="before"
          >
            MDIFF
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('WEIRD')"
            matTooltip="Penalize very small molecules that are unknown (molecular weight < 100g/mol & heteroatom/carbon ratio > 1.5)"
            matTooltipPosition="before"
          >
            WEIRD
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('SYNTHONS')"
            matTooltip="Prefer reactions with higher number of synthons"
            matTooltipPosition="before"
          >
            SYNTHONS
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('PRODUCTS')"
            matTooltip="Prefer reactions with higher number of products"
            matTooltipPosition="before"
          >
            PRODUCTS
          </button>
          <button
            mat-button
            [buttonOutline]="'default-grey'"
            class="ch-scoring-function-calculator-button-regular"
            (click)="addToScoringFunction('PROTECT')"
            matTooltip="Prefer reactions that do not require protection of functional groups"
            matTooltipPosition="before"
          >
            PROTECT
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="ch-dialog-bottom">
    <div class="ch-dialog-controls" *ngIf="!savingFunction && !modifyFunction && !deletingFunction">
      <div class="ch-dialog-controls-left">
        <span
          matTooltip="Save this function as new. It will allow you to use it later"
          matTooltipShowDelay="500"
          matTooltipPosition="above"
        >
          <button mat-button [buttonOutline]="'primary'" (click)="enterSaveFunction()">
            <mat-icon class="icon-16">save</mat-icon>
          </button>
        </span>
        <span
          matTooltip="Save changes to this function{{
            isFunctionGlobal() ? '. Predefined functions cannot be modified' : ''
          }}"
          matTooltipShowDelay="500"
          matTooltipPosition="above"
        >
          <button
            mat-button
            [buttonOutline]="'primary'"
            (click)="enterModifyFunction()"
            [disabled]="shouldEditDeleteBtnsBeDisabled()"
          >
            <mat-icon class="icon-16">edit</mat-icon>
          </button>
        </span>
        <span
          matTooltip="Delete an existing function. This will remove it from your account{{
            isFunctionGlobal() ? '. Predefined functions cannot be deleted' : ''
          }}"
          matTooltipShowDelay="500"
          matTooltipPosition="above"
        >
          <button
            mat-button
            [buttonOutline]="'warn'"
            (click)="deletingFunction = true"
            [disabled]="shouldEditDeleteBtnsBeDisabled()"
          >
            <mat-icon class="icon-16">delete</mat-icon>
          </button>
        </span>
      </div>
      <span class="bottom-function-name">{{ currentFunction ? currentFunction.name : '' }}</span>
      <button
        mat-stroked-button
        class="ch-outline-warn-btn"
        color="warn"
        [buttonOutline]="warn"
        (click)="closeDialog(false)"
      >
        CANCEL
      </button>
      <button
        mat-raised-button
        (click)="closeDialog(true)"
        color="primary"
        [disabled]="tempScoringFunction === ''"
      >
        APPLY
      </button>
    </div>
    <div
      class="ch-scoring-function-calculator-save-function"
      *ngIf="savingFunction || modifyFunction || deletingFunction"
    >
      <mat-form-field
        *ngIf="savingFunction || modifyFunction"
        class="ch-scoring-function-calculator-save-input-form-field"
      >
        <input
          matInput
          [placeholder]="'Function name'"
          [(ngModel)]="scoringFunctionName"
          class="ch-scoring-function-calculator-save-input"
          [type]="'text'"
          (keyup.enter)="savingFunction ? saveCustomFunction() : modifyExistingFunction()"
        />
      </mat-form-field>
      <div *ngIf="savingFunction || modifyFunction">
        <button
          mat-button
          [buttonOutline]="'warn'"
          (click)="savingFunction = false; modifyFunction = false"
        >
          RETURN
        </button>
        <button
          mat-raised-button
          (click)="savingFunction ? saveCustomFunction() : modifyExistingFunction()"
          color="primary"
          [disabled]="shouldSaveFunctionButtonDisabled()"
        >
          {{ savingFunction ? 'SAVE FUNCTION' : 'SAVE CHANGES' }}
        </button>
      </div>
      <span class="delete-warning" *ngIf="deletingFunction">
        This will remove current custom function from your account.
      </span>
      <div *ngIf="deletingFunction">
        <button mat-button [buttonOutline]="'warn'" (click)="deletingFunction = false">
          RETURN
        </button>
        <button
          mat-raised-button
          (click)="deleteFunction()"
          color="warn"
          [disabled]="shouldRequestButtonBeDisabled()"
        >
          CONFIRM DELETE
        </button>
      </div>
    </div>
  </div>
</div>
