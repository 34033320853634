import {
  Directive,
  ElementRef,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
} from '@angular/core';

import { MatTooltip } from '@angular/material/tooltip';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[matTooltip][appShowIfTruncated]',
})
export class ShowIfTruncatedDirective implements OnInit, OnDestroy, OnChanges {
  private unsubscriberSubject: Subject<void> = new Subject<void>();
  @Input() public matTooltip;

  constructor(
    private matTooltipOb: MatTooltip,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  public ngOnInit(): void {
    // Wait for DOM update
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.unsubscriberSubject))
      .subscribe(() => this.onWindowResize());
    this.onWindowResize();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.matTooltip) {
      this.onWindowResize();
    }
  }

  public ngOnDestroy() {
    this.unsubscriberSubject.next();
    this.unsubscriberSubject.complete();
    this.matTooltipOb.ngOnDestroy();
  }

  public onWindowResize() {
    setTimeout(() => {
      const element = this.getElement();
      this.matTooltipOb.disabled = element.scrollWidth <= element.clientWidth;
    }, 500);
  }

  public getElement() {
    if (
      this.elementRef.nativeElement &&
      this.elementRef.nativeElement.className &&
      this.elementRef.nativeElement.className.includes('mat-select')
    ) {
      const el = this.elementRef.nativeElement.querySelector('.mat-select-value');
      return el ? el : this.elementRef.nativeElement;
    } else {
      return this.elementRef.nativeElement;
    }
  }
}
